import {
  ERRORCODE,
  LOGINUSER,
  LOGOUTUSER,
  REGISTERERRORS,
  VALIDATECODE,
} from "../constants/User";
export default (
  state = { errors: null, code: null, errorCode: null, data: {} },
  action
) => {
  switch (action.type) {
    case LOGINUSER:
      return Object.assign({}, state, { data: action.payload });
    case REGISTERERRORS:
      return Object.assign({}, state, { errors: action.payload });
    case VALIDATECODE:
      return Object.assign({}, state, { code: action.payload });
    case ERRORCODE:
      return Object.assign({}, state, { errorCode: action.payload });
    case LOGOUTUSER:
      return Object.assign({}, state, { data: action.payload });
    default:
      return state;
  }
};
