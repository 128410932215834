import styled from "styled-components";
import icoArrow from "./images/arrow.svg";

export const Wrapper = styled.section`
  float: right;
  @media print {
    display: none;
  }
`;
export const LanguageSelectorWrapper = styled.div``;
export const LanguageSelector = styled.p`
  font-family: hn-medium, Arial;
  font-size: 12px;
  color: #000000;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  cursor: pointer;
`;
export const LanguageSelectorCTA = styled.span`
  color: #000000;
  font-family: hn-semibold, Arial;
  font-size: 12px;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 20px;
  text-align: left;
  &:after {
    content: '';
    background: url(${icoArrow}) no-repeat center;
    display: inline-block;
    width: 10px;
    height: 6px;
    margin-left: 5px;
  }
  &.active:after {
    transform: rotate(180deg);


  }
`;
export const LanguageOptionsWrapper = styled.div`
  width: 161px;
  background-color: #ffffff;
  position: absolute;
  top: 61px;
  right: 0;
  z-index: 10;
  display: ${({ active }) => (active ? "block" : "none")};
`;
export const LanguageOption = styled.p`
  color: #000000;
  font-family: hn-medium, Arial;
  font-size: 12px;
  font-style: normal;
  border-bottom: 1px solid #f2f2f2;
  letter-spacing: 0.31px;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
  padding: 10px 30px;
  margin: 0;
`;
export const Overlay = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  content: "";
  position: fixed;
  left: 0px;
  top: 0px;
  opacity: 0.3;
  z-index: 9;
  display: ${({ active }) => (active ? "block" : "none")};
`;
