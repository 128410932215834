import React, { useEffect } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { LoadingResource } from "./../Patient/styles";
import loadingIcon from "./../Patient/images/loading.svg";

import {
  DownloadCTA,
  Subtitle,
  Title,
  TopElementsWrapper,
  TreatmentPageWrapper,
  BottomCTAsWrapper,
  ProtocolsCTAs,
  Advice,
  HomeLegend,
  FirstPageGraphics,
  ColumnsWrapper,
  Column,
  ReferencesWrapper,
  ReferenceColor,
  ReferenceLegend,
  References,
  Percents,
  ColumnsInnerWrapper,
  LowerLegendBar,
  LowerLegend,
  PopupContent,
  PopupTitle,
} from "./styles";
import { Trans, useTranslation } from "react-i18next";
import "swiper/swiper-bundle.css";
import { useState } from "react";
import TestBar from "components/antiaging/TestBar";
import { useHistory } from "react-router";
import { Overlay, Popup } from "../Resources/style";
import PatientData from "components/PatientData";
import EditPatient from "components/EditPatient";
import {
  getPatientProtocolUtil,
  savePatientProtocolUtil,
  updatePatientUtil,
} from "redux/utils/antiaging/Patients";
import { ProfessionalProtocolViewer } from "components/ProfessionalProtocol";
import { QtySwitcher } from "components/QtySwitcher";
import axios from "axios";
import { AddProductComponent } from "../Patient/utils";
import HomeProtocol from "components/HomeProtocol";
import EditHomeProtocol from "components/EditHomeProtocol";
import { CloseButton } from "components/EditHomeProtocol/styles";
import { resetPatient } from "redux/actions/Patient";
const boosterColors = {
  BRIGHTENING: "#ae9b89",
  ANTIWRINKLE: "#b4918f",
  FIRMING: "#a6a9aa",
  REDENSIFYING: "#8C7A4F",
};
const sortBoostersByPointDesc = (boosters) => {
  return boosters.sort(function (a, b) {
    if (a.accumulator > b.accumulator) {
      return 1;
    }
    if (a.accumulator < b.accumulator) {
      return -1;
    }
    return 0;
  });
};
const Treatment = ({
  protocol,
  errors,
  patient,
  test,
  selectedLang,
  updatePatient,
  token,
  getPatientProtocol,
  saveProtocol,
  resetPatient,
}) => {
  const [homeView, setHomeView] = useState(false);
  const [firstPage, setFirstPage] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [pulsations, setPulsations] = useState(false);
  const [professionalBoosters, setProfesionalBoosters] = useState([]);
  const [edit, setEdit] = useState(false);
  const [homeProducts, setHomeProducts] = useState({});
  const [coadyuvantes, setCoadyuvantes] = useState([]);
  const [gammaProducts, setGammaProducts] = useState([]);
  const [productsToPopup, setProductsToPopup] = useState(false);
  const handleEditSubmit = (modPatient) => {
    updatePatient(token, modPatient, patient.id, selectedLang);
    setEdit(false);
    getPatientProtocol(patient.id, token, selectedLang);
  };
  useEffect(() => {
    if (test.professionalMatrix) {
      setProfesionalBoosters(test.professionalMatrix);
    }
  }, [test]);
  const backToProfessionalProtocol = () => {
    setHomeView(false);
  };

  const [t] = useTranslation("global");
  const history = useHistory();
  const calculateHeightColumn = (points) => {
    let generalPoints;
    if (!totalPoints) {
      generalPoints = calculateTotalPoints(professionalBoosters);
    }
    if (points) {
      const left = +points * 100;
      const right = +totalPoints || +generalPoints;
      return Math.round(
        Number(left).toFixed(2) / Number(right).toFixed(2)
      ).toFixed(2);
    }
  };
  const calculateTotalPoints = (boosters) => {
    let accumulator = 0;
    boosters.map((boost) => {
      return (accumulator = Number(accumulator) + Number(boost.accumulator));
    });
    setTotalPoints(accumulator);
    return accumulator;
  };
  const onSaveProtocol = () => {
    saveProtocol(patient.id, token, protocol, selectedLang);
  };
  useEffect(() => {
    if (protocol) setHomeProducts(protocol.home);
  }, [protocol]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/products`, {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        const results = data.results;
        const coad = [];
        const gamma = [];
        results.map((product) => {
          if (product.gamma) return gamma.push(product);
          return coad.push(product);
        });
        setCoadyuvantes([...coad]);
        setGammaProducts([...gamma]);
      })
      .catch(console.error);
    if (patient) {
      getPatientProtocol(patient.id, token, selectedLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedLang, getPatientProtocol]);
  const removeProduct = (productId, gamma) => {
    if (gamma) {
      const newGammaProducts = homeProducts.gamma.filter(
        (prod) => prod.id !== productId
      );
      const newHomeProducts = { ...homeProducts };
      newHomeProducts.gamma = [...newGammaProducts];
      protocol.home = { ...newHomeProducts };
      saveProtocol(patient.id, token, protocol);
    }
    if (!gamma) {
      const newProducts = homeProducts.products.filter(
        (prod) => prod.id !== productId
      );
      const newHomeProducts = { ...homeProducts };
      newHomeProducts.products = [...newProducts];
      protocol.home = { ...newHomeProducts };
      saveProtocol(patient.id, token, protocol);
    }
  };
  const addProducts = (products, gamma) => {
    if (gamma) {
      const newHomeProducts = { ...homeProducts };
      products = products.filter(
        (x) => !newHomeProducts.gamma.find((y) => y.id === x.id)
      );
      newHomeProducts.gamma = [...homeProducts.gamma, ...products];
      setHomeProducts({ ...newHomeProducts });
      protocol.home = { ...newHomeProducts };
      saveProtocol(patient.id, token, protocol);
    }
    if (!gamma) {
      const newHomeProducts = { ...homeProducts };
      products = products.filter(
        (x) => !newHomeProducts.products.find((y) => y.id === x.id)
      );
      newHomeProducts.products = [...homeProducts.products, ...products];
      setHomeProducts({ ...newHomeProducts });
      protocol.home = { ...newHomeProducts };
      saveProtocol(patient.id, token, protocol);
    }
  };
  if (firstPage) {
    return (
      <TreatmentPageWrapper>
        <TestBar width="75%" />
        <TopElementsWrapper>
          <Title>{t("treatment.fistPageTitle")}</Title>
          <Subtitle firstPage={true}>
            <Trans t={t} i18nKey={"treatment.fistPageSubitle"}>
              La gráfica muestra los principales signos de envejecimiento a
              tratar de acuerdo con los resultados obtenidos con{" "}
              <strong>age element diagnosis system™</strong>
            </Trans>
          </Subtitle>
        </TopElementsWrapper>
        <FirstPageGraphics>
          <ColumnsWrapper>
            {professionalBoosters &&
              sortBoostersByPointDesc(professionalBoosters).map((boost) => (
                <ColumnsInnerWrapper key={boost.name}>
                  <Column
                    bgcolor={
                      boosterColors[
                        boost.name.split("-").join("").toUpperCase()
                      ]
                    }
                    colheight={Math.trunc(
                      calculateHeightColumn(boost.accumulator)
                    )}
                  />
                  <Percents>
                    {Math.round(calculateHeightColumn(boost.accumulator))}%
                  </Percents>
                </ColumnsInnerWrapper>
              ))}
          </ColumnsWrapper>
          <ReferencesWrapper>
            {professionalBoosters &&
              sortBoostersByPointDesc(professionalBoosters).map((boost) => (
                <References key={boost.name}>
                  <ReferenceColor
                    bgcolor={
                      boosterColors[
                        boost.name.split("-").join("").toUpperCase()
                      ]
                    }
                  />
                  <ReferenceLegend>
                    {t(
                      "treatment." +
                        boost.name.split("-").join("").toLowerCase()
                    )}
                  </ReferenceLegend>
                </References>
              ))}
          </ReferencesWrapper>
        </FirstPageGraphics>
        <LowerLegendBar>
          <LowerLegend>
            <Trans t={t} i18nKey="treatment.firstPageLegend">
              Acceda a <strong>age element ID profile™</strong> para consultar
              el protocolo profesional y domiciliario personalizado recomendado
              para su cliente
            </Trans>
          </LowerLegend>
        </LowerLegendBar>
        <ProtocolsCTAs protocolId={true} onClick={() => setFirstPage(false)}>
          age element ID profile™
        </ProtocolsCTAs>
      </TreatmentPageWrapper>
    );
  }
  if (protocol.professional && !homeView) {
    return (
      <TreatmentPageWrapper>
        <Overlay active={showPopup} />
        <Popup active={showPopup}>
          <CloseButton
            onClick={() => setShowPopup(false)}
            style={{ top: "-15px" }}
          />
          <PopupTitle>{`${t("treatment.popup.phase")} 1: ${t(
            "treatment.popup.diagnosis"
          )}`}</PopupTitle>
          <PopupContent>
            <Trans t={t} i18nKey="treatment.popup.diagnosisText">
              <strong>age element diagnosis system™</strong> analiza la
              predisposición a expresar los principales signos del
              envejecimiento y las necesidades específicas para cada cliente,
              definiendo su <strong>age element ID profile™:</strong> un
              tratamiento profesional y domiciliario personalizado
            </Trans>
          </PopupContent>

          <PopupTitle>{`${t("treatment.popup.phase")} 2: ${t(
            "treatment.popup.preparation"
          )}`}</PopupTitle>
          <PopupContent>
            <Trans t={t} i18nKey="treatment.popup.preparationText">
              Empezar con la fase de limpieza con
              <strong>age element cleansing balm</strong> para eliminar
              eficazmente el maquillaje y las impurezas, y preparar la piel para
              la aportación de los activos
            </Trans>
          </PopupContent>

          <PopupTitle>{`${t("treatment.popup.phase")} 3: ${t(
            "treatment.popup.estimulation"
          )}`}</PopupTitle>
          <PopupContent>
            <Trans t={t} i18nKey="treatment.popup.estimulationText">
              Aplicar <strong>age element resurfacing solution</strong> para
              favorecer la renovación celular, atenuar las imperfecciones
              presentes en la piel y optimizar la penetración de los activos
              aplicados posteriormente
            </Trans>
          </PopupContent>

          <PopupTitle>{`${t("treatment.popup.phase")} 4: ${t(
            "treatment.popup.activation"
          )}`}</PopupTitle>
          <PopupContent>
            <Trans t={t} i18nKey="treatment.popup.activationText">
              Mezclar la crema vehiculizadora
              <strong>age element hydrabalance cream</strong> con la combinación
              de boosters recomendada por la plataforma
              <strong>age element diagnosis system™</strong> para obtener el
              protocolo antiedad personalizado óptimo
            </Trans>
          </PopupContent>

          <PopupTitle>{`${t("treatment.popup.phase")} 5: ${t(
            "treatment.popup.empowerment"
          )}`}</PopupTitle>
          <PopupContent>
            <Trans t={t} i18nKey="treatment.popup.empowermentText">
              Aplica el <strong>tratamiento finalizador</strong> específico
              recomendado
              <strong>
                age element crystal fiber mask o age element instant firming
                mask
              </strong>
              indicado en la plataforma
              <strong>age element diagnosis system™</strong> para potenciar el
              resultado del tratamiento
            </Trans>
          </PopupContent>
        </Popup>
        <TestBar
          width={!homeProducts ? "100%" : "75%"}
          professionalProtocol
          homeProducts={homeProducts}
        />
        <TopElementsWrapper>
          <Title style={{ marginTop: "60px" }}>age element ID profile™</Title>
          <PatientData patient={patient} handleEdit={setEdit} edit={edit} />
          {edit && (
            <>
            <div className="overlay" onClick={() => setEdit(false)} ></div>

            <EditPatient
              patient={patient}
              selectedLang={selectedLang}
              onSubmit={handleEditSubmit}
              close={() => setEdit(false)}
            />
            </>
          )}
          <QtySwitcher
            pulsations={pulsations}
            setPulsations={setPulsations}
            t={t}
          />
          <DownloadCTA onClick={() => window.print()}>
            {t("treatment.download")}
          </DownloadCTA>
        </TopElementsWrapper>
        <ProfessionalProtocolViewer
          protocol={protocol.professional}
          pulsations={pulsations}
          patient={patient}
          onSaveProtocol={onSaveProtocol}
          protocolId={protocol.id}
        />
        <BottomCTAsWrapper>
          <Advice>{t("treatment.advice")}</Advice>
          <ProtocolsCTAs
            onClick={() => {
              setShowPopup(!showPopup);
              window.scrollTo(0, 0);
            }}
          >
            {t("treatment.professionalProtocol")}
          </ProtocolsCTAs>
          {homeProducts && (
            <ProtocolsCTAs protocolId={true} onClick={() => setHomeView(true)}>
              {t("treatment.homeProtocol")}
            </ProtocolsCTAs>
          )}
          {!homeProducts && (
            <ProtocolsCTAs
              protocolId={true}
              onClick={() => {
                resetPatient();
                return history.push(`/antiaging/home`);
              }}
            >
              {t("treatment.home")}
            </ProtocolsCTAs>
          )}
        </BottomCTAsWrapper>
      </TreatmentPageWrapper>
    );
  } else if (homeProducts && homeView) {
    return (
      <TreatmentPageWrapper domiciliario={true}>
        <TestBar
          handleClick={backToProfessionalProtocol}
          width="100%"
          homeProtocol
        />

        <TopElementsWrapper>
          <Title style={{ marginTop: "60px" }}>age element ID profile™</Title>
          <PatientData patient={patient} handleEdit={setEdit} edit={edit} />
          {edit && (
            <EditPatient
              patient={patient}
              selectedLang={selectedLang}
              onSubmit={handleEditSubmit}
              close={() => setEdit(false)}
            />
          )}
          <DownloadCTA onClick={() => window.print()}>
            {t("treatment.download")}
          </DownloadCTA>
          <Subtitle>{t("treatment.homeProtocol")}</Subtitle>
          <HomeLegend>{t("treatment.homeLegend")}</HomeLegend>
          <AddProductComponent
            handleClick={setProductsToPopup}
            coadyuvantes={coadyuvantes}
            gammaProducts={gammaProducts}
            t={t}
          />
        </TopElementsWrapper>

        <HomeProtocol
          homeProducts={homeProducts}
          removeProduct={removeProduct}
          t={t}
        />
        <BottomCTAsWrapper>
          <Advice>{t("treatment.advice")}</Advice>
          <ProtocolsCTAs onClick={() => history.push(`/antiaging/resources`)}>
            age element resources
          </ProtocolsCTAs>
          <ProtocolsCTAs
            protocolId={true}
            onClick={() => {
              resetPatient();
              history.push(`/antiaging`);
            }}
          >
            {t("treatment.home")}
          </ProtocolsCTAs>
        </BottomCTAsWrapper>
        {productsToPopup && (
          <EditHomeProtocol
            products={productsToPopup}
            handleClick={addProducts}
            close={() => setProductsToPopup(false)}
          />
        )}
        <Overlay active={productsToPopup} />
      </TreatmentPageWrapper>
    );
  } else {
    return (
      (errors && (
        <div>
          <p style={{textAlign: "center"}}>{errors.message}</p>
        </div>
      )) || (
        <LoadingResource>
          <img src={loadingIcon} alt="Loading Resource" />
        </LoadingResource>
      )
    );
  }
};

Treatment.propTypes = {
  protocol: propTypes.object,
  homeProducts: propTypes.object,
  errors: propTypes.object,
  professionalBoosters: propTypes.arrayOf(propTypes.object),
  patient: propTypes.object,
};

const mapStateToProps = (state) => ({
  protocol: state.protocols,
  homeProducts: state.protocols.home,
  errors: state.protocols.error,
  patient: state.protocols.patient,
  token: state.user.data.api_token,
  test: state.patient.test,
  selectedLang: state.languages.selectedLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  getPatientProtocol: (id, token, selectedLang) =>
    dispatch(getPatientProtocolUtil(id, token, selectedLang)),
  updatePatient: (token, patient, patientId, selectedLang) =>
    dispatch(updatePatientUtil(token, patient, patientId, selectedLang)),
  saveProtocol: (patientId, token, protocol, selectedLang) =>
    dispatch(savePatientProtocolUtil(patientId, token, protocol, selectedLang)),
  resetPatient: () => dispatch(resetPatient()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Treatment);
