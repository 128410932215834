import React, { useEffect, useState } from "react";
import StringInput from "components/Input";
import Button from "components/Button";
import { Form } from "components/Form";
import { useInput } from "components/Input/input.hook";
import { Trans, useTranslation } from "react-i18next";
import {
  FormWrapper,
  Wrapper,
  ToogleFormBtnsWrapper,
  ToggleBtn,
  TextAfterForm,
  TextB4Form,
  BlockWrapper,
  ForgotPassword,
  BackButton,
  LoadingResource,
  SuccessMessage
} from "./style";
import { connect } from "react-redux";
import { LoginUser, RecoveryPasswordUser } from "redux/utils/transverse/User";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import Codeform from "./Codeform";
import { useForm } from "react-hook-form";
import { validateCode } from "redux/utils/transverse/User";
import { Fragment } from "react";
import useLocalStorage from "utils/transverse/useLocalStorage";
import localstoragekeys from "constants/localstoragekeys";
import routes from "constants/routes";
import apps from "constants/apps";
import { getAllowedLanguagesUtil } from "redux/utils/transverse/CountryLang";
import { changeApplicationUtil } from "redux/utils/transverse/Application";
import loadingIcon from "./images/loading.svg";
import { errorsUserAction } from "redux/actions/User";


const Login = ({
  loginUser,
  user,
  validatedCode,
  userErrors,
  validateCode,
  errorCode,
  selectedLang,
  changeApp,
  getAllowedLanguages,
  recoveryPasswordUser,
  clearErrors
}) => {
  const history = useHistory();
  const [prechargeApp, setPrechargeApp] = useLocalStorage(localstoragekeys.app);
  const [, setUserData] = useLocalStorage(localstoragekeys.appUser);
  const { handleSubmit, register, errors } = useForm();
  const [showRegister, setShowRegister] = useState(true);
  const [recoverPassw, setRecoverPass] = useState(false);
  const [successMessage, setSuccessMessage] = useState("You will receive an email to change the password");

  const [t] = useTranslation("global");
  const [email, setEmail, emailActive] = useInput("");
  const [password, setPassword, passwordActive] = useInput("");
  const [code, setCode, codeActive] = useInput("");
  const [wantQR, setWantQR] = useState(false);

  const [loader, setLoader] = useState(false);
  
  useEffect(() => {
    if (window.location.search) {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let code = params.get("qr");
      validateCode(code, history);
    }
    if (user.api_token) {
      setUserData(user);
      if (prechargeApp) {
        changeApp(prechargeApp);
        return history.push(`/${prechargeApp}`);
      }
      return history.push(`/${routes.selector}`);
    }
    if(userErrors === 'Send email recovery pass') {
      setTimeout(() => {
        setRecoverPass(false);
        clearErrors();
      }, 2000);
    }
    getAllowedLanguages();
  }, [
    getAllowedLanguages,
    user.api_token,
    validateCode,
    history,
    prechargeApp,
    setUserData,
    user,
    setPrechargeApp,
    changeApp,
  ]);
  const handleSubmitLogin = () => {
    changeApp(apps.NONE);
    setPrechargeApp(apps.NONE);
    loginUser(email, password);
    setLoader(true);
  };
  const checkCode = () => {
    validateCode(code, history);
  };
  const handleScan = (data) => {
    validateCode(data, history);
  };
  const handleShowRegister = (state) => {
    setShowRegister(state);
    setRecoverPass(false);
    clearErrors();
  }

  const handleRecoveryPass = () => {
    recoveryPasswordUser(email, selectedLang);
  };
  document.title = "Log in mesoestetic diagnosis solutions™";
  return (
    <Wrapper>
      <BlockWrapper>
        <ToogleFormBtnsWrapper>
          <ToggleBtn active={showRegister} onClick={() => handleShowRegister(true)}>
            {t("register.register")}
          </ToggleBtn>
          <ToggleBtn active={!showRegister} onClick={() => handleShowRegister(false)}>
            {t("register.login")}
          </ToggleBtn>
        </ToogleFormBtnsWrapper>
        <FormWrapper>
          {showRegister && !recoverPassw && (
            <Fragment>
              <TextB4Form>
                <Trans t={t} i18nKey={"register.textB4Form"}>
                  <strong>Introduzca el código</strong> de acceso proporcionado en su kit de
                  productos, o
                  <strong> realice una fotografía al código QR</strong> para crear su cuenta
                </Trans>
              </TextB4Form>

              <Codeform
                checkCode={checkCode}
                setWantQR={setWantQR}
                setCode={setCode}
                handleScan={handleScan}
                wantQR={wantQR}
                codeActive={codeActive}
                register={register}
                errors={errors}
                code={code}
                errorCode={errorCode}
                validatedCode={validatedCode}
              />
              <TextAfterForm>
                <Trans t={t} i18nKey="register.moreInfo">
                  Si es usted profesional estético y quiere más información sobre mesoestetic o
                  ponerse en contacto con un comercial de tu zona, contáctanos
                  <a href="https://www.mesoestetic.com">aquí</a>.
                </Trans>
              </TextAfterForm>
              <div className="help-links">
                <span onClick={() => history.push("/faq")}>{t("userMenu.FAQ")}</span>
                <span onClick={() => (window.location = "mailto:support-age@mesoestetic.com")}>
                  {t("userMenu.help")}
                </span>
              </div>
            </Fragment>
          )}
          {!showRegister && !recoverPassw && (
            <>
              <Form onSubmit={handleSubmit(handleSubmitLogin)}>
                <StringInput
                  name="email"
                  type="email"
                  defaultValue={email}
                  required
                  label={t("register.email") + "*"}
                  onChange={setEmail}
                  active={emailActive}
                />
                <StringInput
                  name="password"
                  active={passwordActive}
                  type="password"
                  defaultValue={password}
                  onChange={setPassword}
                  label={t("register.password") + "*"}
                />
                {userErrors && <p>{t(`register.${userErrors}`)}</p>}
                <Button style={{ margin: "auto" }} type="submit">
                  {t("register.validate")}
                </Button>
                <ForgotPassword onClick={() => setRecoverPass(true)}>{t("login.forgotPassword")}</ForgotPassword>

              </Form>
              <TextAfterForm>
                <Trans t={t} i18nKey="register.moreInfo">
                  Si es usted profesional estético y quiere más información sobre mesoestetic o ponerse en contacto con
                  un comercial de tu zona, contáctanos
                  <a href="https://www.mesoestetic.com">aquí</a>.
                </Trans>
              </TextAfterForm>
            </>
          )}
          {recoverPassw && (
            <>
              <Form onSubmit={handleSubmit(handleRecoveryPass)}>
                <StringInput
                  name="email"
                  type="email"
                  defaultValue={email}
                  required
                  label={t("register.email") + "*"}
                  onChange={setEmail}
                  active={emailActive}
                />
                {userErrors === "Invalid credentials" ? (
                  <p>Invalid credentials</p>
                ) :  userErrors === "Send email recovery pass" ? (
                  
                  <SuccessMessage>{successMessage}</SuccessMessage>
                ): (
                  null
                )}

                <Button style={{ margin: "auto" }} type="submit">
                  {t("register.validate")}
                </Button>
                <BackButton onClick={() => handleShowRegister(true)}>{t("testBar.back")}</BackButton>
              </Form>
              <TextAfterForm>
                <Trans t={t} i18nKey="register.moreInfo">
                  Si es usted profesional estético y quiere más información sobre mesoestetic o
                  ponerse en contacto con un comercial de tu zona, contáctanos
                  <a href="https://www.mesoestetic.com">aquí</a>.
                </Trans>
              </TextAfterForm>
            </>
          )}
        </FormWrapper>
      </BlockWrapper>

    </Wrapper>
  );

};
Login.propTypes = {
  loginUser: propTypes.func,
  recoveryPasswordUser: propTypes.func,
  user: propTypes.object,
  validateCode: propTypes.func,
  validatedCode: propTypes.string,
  errorCode: propTypes.string,
  selectedLang: propTypes.string,
};
const mapDispatchToProps = (dispatch) => ({
  loginUser: (email, password) => dispatch(LoginUser(email, password)),
  recoveryPasswordUser: (email, selectedLang) => dispatch(RecoveryPasswordUser(email, selectedLang)),
  validateCode: (code, history) => dispatch(validateCode(code, history)),
  changeApp: (selectedApp) => dispatch(changeApplicationUtil(selectedApp)),
  getAllowedLanguages: () => dispatch(getAllowedLanguagesUtil()),
  clearErrors: () => dispatch(errorsUserAction(null))
});
const mapStateToProps = (state) => ({
  user: state.user.data,
  userErrors: state.user.errors,
  validatedCode: state.user.code,
  errorCode: state.user.errorCode,
  selectedLang: state.languages.selectedLanguage,
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
