import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  BackButton,
  BottomAlert,
  Content,
  CTAtoPatientForm,
  GrayWrapper,
  HelpPageWrapper,
  Line,
  Paragraph,
  ProfessionalIcon,
  Title,
} from "./helpPageStyle";

export const HelpPage = ({ handleClick, backButton }) => {
  const [t] = useTranslation("global");
  document.title = "What is age element ID test™";
  return (
    <HelpPageWrapper>
      <BackButton onClick={backButton}>{t("testBar.back")}</BackButton>
      <Content>
        <Title>{t("helpPage.topTitle")}</Title>
        <Paragraph>
          <Trans t={t} i18nKey="helpPage.firstParagraph">
            <strong>age element diagnosis system™</strong> es la innovadora
            herramienta de diagnóstico de <strong>age element</strong>, la línea
            profesional antiedad que combina la personalización y la tecnología
            estética más avanzada
          </Trans>
        </Paragraph>
        <Paragraph>
          <Trans t={t} i18nKey="helpPage.secondParagraph">
            Un exclusivo test analiza la predisposición a expresar los
            principales signos del envejecimiento y las necesidades específicas
            para cada cliente, definiendo su
            <strong> age element ID profile™</strong>: un tratamiento
            profesional y domiciliario personalizado para prevenir y corregir
            los signos del envejecimiento cutáneo y obtener los máximos
            resultados
          </Trans>
        </Paragraph>
        <Line />
        <Title>{t("helpPage.bottomTitle")}</Title>
        <Paragraph>
          <Trans t={t} i18nKey="helpPage.thirdParagraph">
            <strong>age element ID test™</strong> consta de un set de preguntas
            relativas a las necesidades de la piel, los hábitos de vida y las
            condiciones que el cliente desea mejorar con el fin de detectar las
            variables epigenéticas que pueden afectar su proceso de
            envejecimiento
          </Trans>
        </Paragraph>
        <GrayWrapper>
          <h3>{t("helpPage.important")}</h3>
          <p>{t("helpPage.grayFirst")}</p>
          <p>
            {t("helpPage.graySecond")} <ProfessionalIcon />
          </p>
          <p>{t("helpPage.grayThird")}</p>
        </GrayWrapper>
        <Paragraph>
          <Trans t={t} i18nKey="helpPage.fourthParagraph">
            Como resultado de las respuestas obtenidas se genera el
            <strong> age element ID profile™</strong> , que incluye el
            <strong> tratamiento profesional y domiciliario</strong>
            personalizado para obtener la máxima respuesta terapéutica para el
            paciente
          </Trans>
        </Paragraph>
        <BottomAlert>{t("helpPage.bottomAlert")}</BottomAlert>
        <CTAtoPatientForm onClick={handleClick}>
          {t("helpPage.CTAtoPatientForm")}
        </CTAtoPatientForm>
      </Content>
    </HelpPageWrapper>
  );
};
