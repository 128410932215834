import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
  BannerWrapper,
  BannerInnerWrapper,
  Banner,
  BannerLogo,
  BannerTextWrapper,
  H1,
  Exclusive,
  HomeContentWrapper,
  ButtonsWrapper,
  ActionWrapper,
  IconImg,
  HomeButton,
  ButtonText,
} from '../Home/style';
import {
  Wrapper,
  BottomCTAsWrapper,
  ProtocolsCTAs
} from './style';
import miniLogo from '../Home/images/mini-logo.svg';
import about from './images/about.svg';
import material from './images/material.svg';
import advices from './images/advices.svg';
import protocol from './images/protocol.svg';
import routes from "constants/routes";


const Resources = function ({ ...props }) {
  const [t] = useTranslation('global');
  const history = useHistory();

  document.title = 'Resource area bodyshock';
  return (
    <Wrapper>
      <BannerWrapper>
        <BannerInnerWrapper>
          <Banner />
          <BannerLogo src={miniLogo} />
          <BannerTextWrapper>
            <H1><Trans t={t} i18nKey="home.welcomeBodyshock">welcome to <span>bodyshock diagnosis solutions™</span></Trans></H1>
            <Exclusive>{t('home.exclusive')}</Exclusive>
          </BannerTextWrapper>
        </BannerInnerWrapper>
      </BannerWrapper>
      <HomeContentWrapper>
        <ButtonsWrapper resources>
          <ActionWrapper onClick={() => history.push(`${routes.about}`)}>
            <IconImg src={about} alt="Icon" />
            <div>
              <HomeButton primary={false}>
                {t('resourcesBodyshock.aboutBodyshock')}
              </HomeButton>
              <ButtonText>{t('resourcesBodyshock.aboutBodyshockSub')}</ButtonText>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.protocol}`)}>
            <IconImg src={protocol} alt="Icon" />
            <div>
              <HomeButton primary={false}>{t('resourcesBodyshock.protocol')}</HomeButton>
              <ButtonText>{t('resourcesBodyshock.protocolSub')}</ButtonText>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.advices}`)}>
            <IconImg src={advices} alt="Icon" />
            <div>
              <HomeButton primary={false}>
                {t('resourcesBodyshock.advicePage.advice')}
              </HomeButton>
              <ButtonText>{t('resourcesBodyshock.advicePage.adviceSub')}</ButtonText>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.material}`)}>
            <IconImg src={material} alt="Icon" />
            <div>
              <HomeButton primary={false}>
                {t('resourcesBodyshock.centerMaterial')}
              </HomeButton>
              <ButtonText>{t('resourcesBodyshock.centerMaterialSub')}</ButtonText>
            </div>
          </ActionWrapper>
        </ButtonsWrapper>
        <BottomCTAsWrapper>
          <ProtocolsCTAs className='whiteButton'
            onClick={() => history.push(`${routes.helpPage}`)} 
          >
            {t("resourcesBodyshock.newTest")}
          </ProtocolsCTAs>
          <ProtocolsCTAs onClick={() => history.push(`${routes.homeBodyshock}`)} >
            home
          </ProtocolsCTAs>
        </BottomCTAsWrapper>
      </HomeContentWrapper>
    </Wrapper>
  );
};
export default connect()(Resources);
