import styled from "styled-components";
import closeIcon from './images/ico-close.svg';
import editIconBs from "./images/edit-icon-bs.svg";
export const PatientDataWrapper = styled.section`
  z-index: 3;
  position: relative;
  @media print {
      visibility: visible;
    }
  .toggle {
    border: 1px solid #e8e9ea;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
    height: 70px;
    max-width: 384px;
    margin: auto;
    padding: 16px 55px;
    display: grid;
    grid-template-columns: 40px auto;
    column-gap: 25px;
    cursor: pointer;
    background-color: white;
    @media(min-width: 600px) {
      &:hover {
        border: 1px solid black;
      }
    }
    @media(max-width: 600px) {
      max-width:335px;
      padding-left: 33px;
      padding-right: 33px;
    }
    @media print {
      display: none;
    }
    .name {
      margin: 0;
      font-size: 14px;
      font-family: hn-semibold;
      font-size: 14px;
      letter-spacing: 0.39px;
      line-height: 20px;
    }
    span {
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.39px;
    }

  }
  .contentToggle {
    display: none;
    bottom: 0;
    width: 100%;
    background-color: white;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 1px rgba(0,0,0,0.3),0 0 10px rgba(0,0,0,0.15);
    overflow-x: hidden;
    position: fixed;
    z-index: 3;
    top: 20%;
    @media print {
      display: block !important;
      visibility: visible;
      position: relative;
      top: initial;
      bottom: unset;
    }
    .App-handle-container {
      width: 100%;
      height: 40px;
      top: 10px;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      @media print {
        display: none;
      }
    }
    .App-handle {
      margin: 0 auto;
  
      width: 36px;
      height: 5px;
      border-radius: 3px;
      background-color: #000000;
      opacity: 0.2;
      display: block;
      top: 6px;
    }
    .header {
      padding: 30px 20px 15px;
      border-bottom: 1px solid #e2e2e2;
      .contentName {
        max-width: 670px;
        margin: auto;
        position: relative;
      }
      .name {
        margin: 0;
        text-align: center;
        font-size: 18px;
        line-height: 26px;
        font-family: hn-semibold;
        font-weight: 600;
      }
      .close {
        background: url(${closeIcon}) no-repeat center;
        display: inline-block;
        width: 28px;
        height: 28px;
        position: absolute;
        top: -15px;
        left: 0;
        cursor: pointer;
        z-index: 3;
      }
      .editButton {
        position: absolute;
        color: #d25462;
        font-family: hn-medium, Arial;
        letter-spacing: 0.31px;
        font-size: 12px;
        line-height: 20px;
        text-decoration: underline;
        justify-content: center;
        right: 20px;
        top: 7px;
        cursor: pointer;
        display: flex;
        z-index: 1;
        &::before {
          content: url(${editIconBs});
          margin-right: 10px;
        }
      }
      @media print {
        display: none;
      }
    }

    .content {
      border-bottom: 1px solid #e2e2e2;
      > div {
        max-width: 670px;
        padding: 40px 20px;
        margin: auto;
      }
      

      p {
        margin: 0;
        font-weight: 600;
        font-family: hn-semibold;
        font-size: 14px;
        line-height: 22px;
        span {
          font-family: hn-medium;
          font-weight: 400;
          letter-spacing: 0.36px;
        }
      }
    }
    .otherData {
      > div {
        max-width: 670px;
        padding: 35px 20px;
        margin: auto;
      }
      .title {
        margin: 0;
        font-size: 10px;
        font-family: hn-semibold;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 1px;
        margin-bottom: 10px;
      }
      .otherData-content {
        background-color: #f2f2f2;
        padding: 15px;
        p {
          margin: 0;
          font-size: 12px;
          letter-spacing: 0.31px;
          line-height: 18px;
          max-width: 484px;
        }
      }
      @media print {
        display: none;
      }
    }

    &.active {
      display: block;
    }
  }
  .overlay-editpatient {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
    z-index: 3;
    top: 0;
    cursor: pointer;
  }
  .line {
    position: absolute;
    top: 50%;
    border-top: 2px solid #f7f7f7;
    width: 100%;
    z-index: -1;
  }
`;
