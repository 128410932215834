import styled from 'styled-components';
import leftbg from './images/left-bg.png';
import download from './images/ico-download.svg';

export const ProtocolWrapper = styled.main`
    min-height: calc(100vh - 65px);
    > header {
        border-bottom: 0;
    }    
    .overlay-edit {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.4;
        z-index: 4;
        top: 0;
        cursor: pointer;
      }
    .professionalTreatmentWrapper {
        top: -35px;
        padding-top: 25px;
        overflow: hidden;
        @media print {
          overflow: visible;
          position: relative;
          top: initial;
          height: 100%;
          width: 100%;
        }
        @media(max-width: 520px) {
            top: -20px;
            padding-top: 8px;
        }
        .sessionsList {
            @media(max-width: 520px) {
                > p {
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.39px;
                }
            }
            
        } 
      }
`;

export const Header = styled.header`
    position: relative;
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;

    @media(max-width: 520px) {
        &:before {
            max-width: 320px;
            width: 100%;
        }
    }
    &:before {
        content: "";
        background: url(${leftbg}) no-repeat center;
        position: absolute;
        left: 0;
        top: -65px;
        z-index: -1;
        width: 375px;
        height: 175px;
        background-size: contain;
      }

    h1 {
        font-family: "hn-semibold", Arial;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
        @media(max-width: 520px) {
            line-height: 26px;
            font-size: 18px;
        }
    }
    .subtitle {
        font-family: hn-medium, Arial;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-top: 1px;
        margin-bottom: 0;
    }
    .Download {
        background: url(${download}) no-repeat center;
        display: inline-block;
        width: 16px;
        height: 17px;
        position: absolute;
        right: 47px;
        top: 40px;
        display: none;
    }
`;


