import React, { Fragment, useEffect, useState } from "react";
import "./App.css";
import Header from "components/transverse/Header";
import { Switch, Route, useHistory } from "react-router";
import Login from "./transverse/Login";
import Register from "./transverse/Register";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { getLanguages, setLanguage } from "redux/utils/transverse/CountryLang";
import { loginAction, logoutAction } from "redux/actions/User";
import { IsUserAllowed } from "./isUserAllowed";
import { verifyUserToken } from "redux/utils/transverse/User";
import { useTranslation } from "react-i18next";
import NoticeLegalPage from "./LegalNotice";
import FaqPage from "./transverse/Faq";
import PolicyPage from "./transverse/PolicyGeneral";
import TermsConditionPage from "./transverse/TermsAndConditions";
import { getNavigatorLanguage } from "utils/transverse/getNavigatorLanguage";
import AppSelector from "./transverse/AppSelector";
import Antiaging from "./antiaging/Antiaging";
import useLocalStorage from "utils/transverse/useLocalStorage";
import localstoragekeys from "constants/localstoragekeys";
import Bodyshock from "./bodyshock/Bodyshock";
import ResetPassword from "./transverse/ResetPassword";
const App = ({
  fetchLanguages,
  languages,
  user,
  logUser,
  isValidToken,
  selectLanguage,
  logout,
}) => {
  const [, i18next] = useTranslation("global");
  const [userData, setUserData] = useLocalStorage(localstoragekeys.appUser);
  const [appLanguage, setAppLanguage] = useLocalStorage(
    localstoragekeys.appLanguage
  );
  const [isUserLogged, setIsUserLogged] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    if (userData) {
      logUser(userData);
    }
  }, [logUser, userData]);

  async function isLogged(token) {
    if(token){
      const result = isValidToken(token);
      return result;
    }
  }
  useEffect(() => {
    if (appLanguage) {
      selectLanguage(appLanguage);
      i18next.changeLanguage(appLanguage);
    } else {
      const lang = getNavigatorLanguage();
      selectLanguage(lang);
      i18next.changeLanguage(lang);
      setAppLanguage(lang);
    }
    if (!isLogged(user.api_token)) {
      setUserData("");
      logout();
      return history.push("/");
    }
    return setIsUserLogged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isValidToken,
    history,
    i18next,
    selectLanguage,
    appLanguage,
    user.api_token,
  ]);

  return (
    <Fragment>
      <Header languages={languages} isUserLogged={isUserLogged} />
      <Switch>
        <IsUserAllowed
          path={"/selector"}
          Component={AppSelector}
          isUserLogged={isUserLogged}
        />
          <IsUserAllowed
            path={"/privacy-policy"}
            Component={PolicyPage}
            isUserLogged={isUserLogged}
          />
        <IsUserAllowed
          path={`/antiaging`}
          Component={Antiaging}
          isUserLogged={isUserLogged}
        />
        <IsUserAllowed
          path={`/bodyshock`}
          Component={Bodyshock}
          isUserLogged={isUserLogged}
        />
        <IsUserAllowed
          path={"/legal-notice"}
          Component={NoticeLegalPage}
          isUserLogged={isUserLogged}
        />
        <IsUserAllowed
          path={"/privacy-policy"}
          Component={PolicyPage}
          isUserLogged={isUserLogged}
        />
        <Route path="/faq">
          <FaqPage />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsConditionPage />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsConditionPage />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/resetpassword">
          <ResetPassword />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Fragment>
  );
};
const mapDispatchToProps = (dispatch) => ({
  fetchLanguages: () => dispatch(getLanguages()),
  selectLanguage: (lang) => dispatch(setLanguage(lang)),
  logUser: (user) => dispatch(loginAction(user)),
  isValidToken: (token) => dispatch(verifyUserToken(token)),
  logout: () => dispatch(logoutAction()),
});
const mapsStateToProps = (state) => ({
  languages: state.languages.languagesAvailables,
  selectedLanguage: state.languages.selectedLanguage,
  user: state.user.data,
});
App.propTypes = {
  fetchLanguages: propTypes.func,
  languages: propTypes.array,
  user: propTypes.object,
  logUser: propTypes.func,
};
export default connect(mapsStateToProps, mapDispatchToProps)(App);
