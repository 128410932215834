import questions_categories from "constants/bodyshock/questions_categories";
import React from "react";
import { RedBarWrapper, RedBarItem } from "./style";
import { useTranslation } from "react-i18next";
const RedBar = ({ question, finish }) => {
  const [t] = useTranslation("global");
  const isHistory = question?.category === questions_categories.HISTORIAL;
  const isLifeHabits = question?.category === questions_categories.LIFEHABITS;
  const isPhysicalAnalysis = question?.category === questions_categories.PHYSICALANALYSIS;
  
  return (
    <RedBarWrapper 
      className={isLifeHabits ? "activeLife": "passedLife" 
      && isHistory? "activeHistory": "passedHistory"
              && isPhysicalAnalysis ? "activephysicalAnalysis": "passedphysicalAnalysis" 
              
              }>
      <RedBarItem className={!question && !finish ? "patientdata active" : "patientdata passed"}>
        <span>{t("testBar.patientData")}</span></RedBarItem>
      <RedBarItem className={isHistory ? "history active" : isLifeHabits || isPhysicalAnalysis || finish ? "passed" : "history"}>
        <span>{t("testBar.history")}</span>
      </RedBarItem>
      <RedBarItem className={isLifeHabits ? "active" : isPhysicalAnalysis || finish ? "passed " : "lifeHabits"}>
        <span>{t("testBar.lifeHabits")}</span>
      </RedBarItem>
      <RedBarItem className={isPhysicalAnalysis ? "active" : finish ? "passed" : "physicalAnalysis"}>
        <span>{t("testBar.physicalAnalysis")}</span> 
      </RedBarItem>
    </RedBarWrapper>
  );
};
export default RedBar;
