import styled, { css } from 'styled-components';
import downloadIcon from './images/download.svg';
import playIcon from './images/icono-play.svg';
import bg_left from './images/bg-home-bottom-left.png';
import header_bg_left from './images/bg-home-top-left.png';
import header_bg_right from './images/bg-home-top-right.png';
import list from './images/about/list.svg';
import body_0 from './images/protocol/body_0.png';
import body_1 from './images/protocol/body_1.png';
import body_2 from './images/protocol/body_2.png';
import calendar_1 from './images/protocol/calendar_1.svg';
import calendar_2 from './images/protocol/calendar_2.svg';

export const Wrapper = styled.main`
  padding-bottom: 57px;
  position: relative;
  min-height: calc(100vh - 65px);
  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 230px;
    width: 493px;
    bottom: 0;
    left: 0;
    background: url(${bg_left}) no-repeat;
    z-index: -1;
    @media (max-width: 560px) {
      background-size: contain;
      height: 130px;
      width: 300px;
    }
  }
`;
export const MiniBannerWrapper = styled.section`
  position: relative;
  text-align: center;
  background-image: linear-gradient(95deg, #e8e9ea 0%, #f5f5f5 100%);
  height: 115px;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    display: block;
  }
  &::before {
    background: url(${header_bg_left}) no-repeat;
    left: 0;
    height: 100%;
    width: 100%;
    @media (max-width: 560px) {
      background-size: contain;
      left: -20px;
    }
  }

  &::after {
    background: url(${header_bg_right}) no-repeat right center;
    right: 10px;
    height: 100%;
    width: 100%;
    @media (max-width: 768px) {
      content: none;
    }
  }
  
`;
export const MiniBannerInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;
export const MiniBannerTitle = styled.h1`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  margin: 0;
  text-align: center;
  margin-bottom: 5px;
`;
export const MiniBannerSubtitle = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 26px;
  margin: 0;
  text-align: center;
`;
// ABOUT PAGE
export const AboutPageWrapper = styled.section`
  strong {
    font-weight: 600;
    font-family: 'hn-semibold', Arial;
  }
  p {
    margin: 0;
  }
  section {
    max-width: 670px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
  }
  .line {
    height: 1px;
    background-color: #e2e2e2;
    width: 90%;
    margin: 40px auto;
  
  }
  .intro {
    margin-top: 35px;
    font-size: 14px;
    letter-spacing: 0.36px;
    line-height: 20px;

    .content-img {
      column-gap: 6%;
      margin-top: 25px;
      margin-bottom: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      row-gap: 30px;
      
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      span {
        min-height: 40px;
        margin-bottom: 15px;
        line-height: 18px;
        text-align: center;
        margin-top: 5px;
        display: flex;
        align-items: center;
      }
    }
  }
  .block {
    display: flex;
    margin: 25px auto 40px;
    align-items: flex-start;

    > div {
      margin-top: 15px;
      margin-left: -6px;
    }
    .title {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 9px;
      max-width: 310px;
    }
    .text {
      font-size: 12px;
      letter-spacing: 0.31px;
      line-height: 17px;
      max-width: 460px;
    }
  }
  .block-red {
    background-color: #f3e3e2;
    padding: 20px;
    margin-bottom: 40px;
    position: relative;
    
    > div {
      width: 85%;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      font-family: 'hn-semibold', Arial;
      line-height: 15px;
      margin-bottom: 10px;
    }
    img {
      position: absolute;
      right: 20px;
      bottom: 10px;
    }
    ul {
      padding-left: 3px;
      margin: 0;
      font-size: 12px;
      letter-spacing: 0.31px;
      line-height: 17px;
    }
    li {
      list-style: none;
      display: flex;
      margin-bottom: 5px;
      &:before {
        content: url(${list});
        display: inline-block;
        margin-right: 7px;
      }
    }
  }

`;

// PROTOCOL PAGE
export const ProtocolPageWrapper = styled.section`
  strong {
    font-weight: 600;
    font-family: 'hn-semibold', Arial;
  }
  p {
    margin: 0;
  }
  .video-wrapper {
    max-width: 670px;
    margin: 35px auto 0;
    padding: 0 20px;
    div {
      max-width: 522px;
      margin: 25px auto 0;
    }
    p {
      font-size: 14px;
      letter-spacing: 0.36px;
      line-height: 20px;
    }
  }
  section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .line {
    height: 1px;
    background-color: #e2e2e2;
    width: 90%;
    margin: 30px auto;
  
  }
  .block-red {
    max-width: 860px;
    background-color: #f3e3e2;
    margin: 0 auto;
    padding: 32px 62px;
    display: block;
    margin-bottom: 35px;
    @media(max-width: 640px) {
      padding: 30px 20px;
    }

    .title {
      font-size: 17px;
      font-weight: 600;
      line-height: 20px;
      font-family: 'hn-semibold', Arial;
      padding-bottom: 7px;
      border-bottom: 1px solid #000;
      text-transform: uppercase;
      margin: 0;
      max-width: initial;
      margin-bottom: 30px;
      @media(max-width: 640px) {
        margin-bottom: 20px;
      }
    }
  }
  .program-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 40px;
    width: 100%;
    font-size: 12px;
    letter-spacing: 0.31px;
    line-height: 17px;

    .first-column {
        grid-column: 1/1;
    }
    .third-column {
        grid-column: 3/3;
    }
    .first-img {
      content: url(${body_0});
      object-fit: contain;
      grid-column: 1/4;
      max-width: 100%;
    }
    
    .duration {
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 51px;
      margin-bottom: 20px;
      min-height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
          margin: 0 0 0 80px;
      }
      &.first-column {
          background-image: url(${calendar_1});
      }
      &.third-column {
          background-image: url(${calendar_2});
      }
    }
    .protocolo-domiciliario, .protocolo-cabina {
      margin-bottom: 27px;
      p:first-child {
        margin-bottom: 6px;
      }
    }
    .bodycare {
      margin-top: 14px;
      p {
        &.red {
            color: #bd3d4b;
            font-family: "hn-semibold";
        }
      }
    }
    @media(max-width: 640px) {

      display: flex;
      flex-direction: column;
      .duration {
        order: 0;
        margin-bottom: 10px;
      }
      .duration.third-column {
        margin-bottom: 25px;
      }
      .protocolo-cabina {
        order: 1;
        margin-bottom: 20px;
        p {
          margin: 0;
        }
      }
      .protocolo-domiciliario {
        order: 4;
        margin-bottom: 20px;
        p {
          margin: 0;
        }
      }
      .first-img {
        content: url(${body_1});
        width: 100%;
        max-width: 330px;
        order: 2;
      }
      .third-img {
        content: url(${body_2});
        width: 100%;
        max-width: 330px;
        order: 5;
      }
      .bodycare.first-column {
        order: 3;
        margin-top: 15px;
        margin-bottom: 65px;
        p {
          margin: 0;
        }
      }
      .bodycare.third-column {
        order: 6;
        margin-top: 15px;
        p {
          margin: 0;
        }
      }
    }
  }
  h2,
  article {
    max-width: 730px;
    margin: 0 auto;
  }
  .phase {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 45px;
    }
    
    p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.36px;
      line-height: 20px;
    }

    div:not(.images) {
      max-width: 330px;
      margin-right: 20px;
      @media (max-width: 640px) {
        width: 100%;
        max-width: initial;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
    .images2 {
      margin-bottom: 25px;
      p:first-of-type {
        grid-row: 1;
        grid-column: 2;
        @media(max-width: 640px) {
          margin-bottom: 8px;
        }

      }
      p:last-of-type {
        grid-column: 2;
        grid-row: 2;
      }
      img {
        grid-row: 1/3;
      }
    }
    .images {
      align-items: center;
      display: grid;
      grid-template-columns: auto 132px;
      max-width: 310px;
      width: 100%;
      column-gap: 20px;
      @media (max-width: 640px) {
        max-width: initial;
        justify-content: center;
        img {
          width: 100%;
        }
      }
      p{
        font-size: 12px;
        letter-spacing: 0.31px;
        line-height: 14px;
      }
      div {
        width: 100%;
        padding-bottom: 20px;
        strong {
          display: block;
          font-size: 9px;
          line-height: 15px;
          padding-top: 10px;
        }
      }
    }
  }

  h3 {
    width: 100%;
    font-family: "hn-semibold", Arial;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    border-bottom: 1px #000 dashed;
    padding: 14px 0;
    margin: 0px 0px 25px 0px;
  }

  @media (max-width: 640px) {
    h2,
    article {
      max-width: none;
      margin: 0 24px;
    }
    div.images {
      width: 100%;
    }

    .session-info {
      height: 96px;
      span {
        margin-top: 15px;
      }
    }
  }
`;

//MATERIAL PAGE
export const CenterMaterialWrapper = styled.section`
  margin: 0 auto;
  margin-bottom: 50px;
  .line {
    height: 1px;
    background-color: #e2e2e2;
    width: 90%;
    margin: 0px auto 25px;
  
  }
  p {
    font-size: 14px;
    letter-spacing: 0.36px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    @media (max-width: 560px) {
      font-size: 12px;
      line-height: 19.8px;
    }
  }
  strong {
    font-family: 'hn-semibold', Arial;
    font-weight: 600;
  }
  header {
    text-align: center;
    padding: 6px 20px 20px;
  }
  .material-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    justify-items: center;
    @media (max-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 400px) {
      grid-template-columns: 1fr;
    }
  }
  .section-title {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    margin: 25px auto 30px;
    font-weight: 400;
    @media (max-width: 560px) {
      font-size: 18px;
      line-height: 19.8px;
    }
  }
  .pdf-block {
    background-image: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
    padding-bottom: 40px;
  }
  .inner-block {
    max-width: 872px;
    width: 90%;
    padding: 0 20px;
    margin: auto;
  }
  .card {
    max-width: 178px;
    img {
      width: 100%;
      display: block;
      border: 1px solid #e8e9ea;
    }
    p.download {
      color: #bd3d4b;
      font-size: 10px;
      font-weight: 600;
      font-family: 'hn-semibold', Arial;
      line-height: 28px;
      text-align: left;
      border: 1px solid #bd3d4b;
      background-color: #ffffff;
      margin: 0;
      text-align: center;
      z-index: 12;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 35px;
      &::before {
        content: url(${downloadIcon});
        margin-right: 5px;
      }
      span {
        text-transform: uppercase;
        font-family: 'hn-semibold', Arial;
        margin-left: 5px;
      }
    }
    &.video {
      .video-wrapper {
        position: relative;
        cursor: pointer;

        &::before {
          content: url(${playIcon});
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -50%);
          z-index: 8;
        }
        &::after {
          content: "";
          width: 100%;
          position: absolute;
          height: 100%;
          top: 0;
          display: block;
          background-color: rgba(0, 0, 0, 0.4);
          left: 0;
        }
      }
      .video-info {
        border: 1px solid #e8e9ea;
        background-color: #ffffff;
        text-align: center;
        p:first-child {
          font-family: "hn-semibold", Arial;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          letter-spacing: normal;
          line-height: 15px;
          text-align: center;
          margin-bottom: 3px;
          span {
            text-transform: uppercase;
            margin-left: 5px;
            font-family: "hn-semibold", Arial;

          }
        }
        p:last-child {
          margin-top: 0;
          font-family: "hn-medium", Arial;
          font-size: 10px;
          font-weight: 400;
          font-style: normal;
          letter-spacing: normal;
          line-height: normal;
          span {
            text-transform: uppercase;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 300px;
    margin: 20px auto 0;
    position: relative;
    @media (max-width: 500px) {
      max-width: 300px;
    }
    span:first-child {
      border-radius: 4px 0 0 4px;
    }
    span:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;
export const ActionsBtn = styled.span`
  color: #bd3d4b;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 18px;
  text-align: center;
  border: 1px solid #bd3d4b;
  padding: 4px;
  cursor: pointer;
  ${({ active }) => active
    && css`
      background-color: #bd3d4b;
      color: white;
    `}
`;
export const BottomCTAsWrapper = styled.section`
  @media print {
    display: none;
  }
  background-color: white;
  width: 100%;
  justify-content: center;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 25px;
  max-width: 600px;
  margin: auto;
  @media(max-width: 640px) {
    grid-template-columns: repeat(1,2fr);
    row-gap: 20px;
  }
`;

export const ProtocolsCTAs = styled.span`
  padding: 10px 15px;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  width: 100%;
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  display: block;
  min-width: 285px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  &.whiteButton {
    background-color: white;
    color: black;
  }
  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }
  @media(max-width: 640px) {
    grid-template-columns: repeat(1,2fr);
    row-gap: 20px;
    width: 80%;
  }
`;
export const LoadingResource = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  opacity: 0.5;
  img {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
  }
`;