import React, { Fragment, useEffect, useState } from "react";
import { useInput } from "components/Input/input.hook";
import {
  Wrapper,
  LocationWrapper,
  LocationLabel,
  SuggestionsBox,
  FormWrapper,
  Title,
  Subtitle,
  RequiredLegend,
  PrivacyPolicyLabel,
  ErrorPass,
  RegisteredInfo,
} from "./style";
import { ErrorBox } from "./style";
import { useTranslation, Trans } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Button from "components/Button";
import StringInput from "components/Input";
import PrivacyComponent from "components/Privacy";
import { Form, Form as RegisterForm } from "components/Form";
import { SuggestionsItem } from "./style";
import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { registerNewApp, RegisterUser, validateCode } from "redux/utils/transverse/User";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { useForm } from "react-hook-form";
import Geocode from "react-geocode";
import Dropdown from "components/Dropdown";
import axios from "axios";
import { userExist } from "utils/transverse/verifyUserExist";
import useLocalStorage from "utils/transverse/useLocalStorage";
import localstoragekeys from "constants/localstoragekeys";
import apps from "constants/apps";
import { ForgotPassword, TextAfterForm } from "../Login/style";
import { changeApplicationUtil } from "redux/utils/transverse/Application";
const Register = ({
  registerUser,
  userErrors,
  loggedUser,
  validatedCode,
  addAppToUser,
  changeApp,
  selectedLang,
}) => {
  const [, setSelectedApp] = useLocalStorage(localstoragekeys.app);
  const [languageSelected] = useLocalStorage(localstoragekeys.appLanguage);
  const [isRegistered, setIsRegistered] = useState(false);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const history = useHistory();
  const [t] = useTranslation("global");
  const [openMenu, setOpenMenu] = useState(false);
  const [recoverPassw, setRecoverPass] = useState(false);
  const referency = useOnclickOutside(() => {
    setOpenMenu(false);
  });
  const handleRecoveryPass = () => {
    axios
      .post(
        `${process.env.REACT_APP_ANTIAGING_API}api/accounts/reset/password`,
        {
          email,
        },
        {
          headers: {
            "Content-Language": selectedLang,
          },
        }
      )
      .then(() => {
        history.push("/login");
      })
      .catch(console.error);
  };
  useEffect(() => {
    setSelectedApp(apps.NONE);
    changeApp(apps.NONE);
    if (!validatedCode) history.push("/login");
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/countries`)
      .then(({ data }) => {
        setCountries(data.results);
        setCountryCode(data.results[0]);
      })
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { handleSubmit, register, errors } = useForm();
  const [clinicName, setClinicName] = useInput("");
  const [email, setEmail] = useInput("");
  const [privacy, setPrivacy] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [vatin, setVatin] = useInput("");
  const [password, setPassword] = useInput("");
  const [confirmPassword, setConfirmPassword] = useInput("");
  const [confirmEmail, setConfirmEmail] = useInput("");
  const [passError, setPassError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const {
    ready,
    value: address,
    suggestions: { status, data },
    setValue: setAddress,
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 300 });
  const SuggestionRef = useOnclickOutside(() => {
    clearSuggestions();
  });
  const handleInput = (e) => {
    setAddress(e.target.value);
  };
  const handleSelect =
    ({ description }) =>
    () => {
      setAddress(description, false);
      clearSuggestions();
    };
  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <SuggestionsItem key={index} onClick={handleSelect(suggestion)}>
          {main_text}, {secondary_text}
        </SuggestionsItem>
      );
    });
  useEffect(() => {
    document.title = "Create user age element diagnosis system";
    if (loggedUser.api_token) {
      history.push("/home");
    }
  }, [loggedUser, history]);

  const onSubmit = () => {
    if (isRegistered && password && email) {
      return addAppToUser(email, password, validatedCode);
    }
    if (password === confirmPassword && email === confirmEmail) {
      return registerUser({
        clinicName,
        email,
        address,
        password,
        register_code: validatedCode,
        country_id: countryCode.id,
        vatin,
        newsletter,
        language: languageSelected,
      });
    }
    if (password !== confirmPassword) {
      setPassError(true);
    }
    if (email !== confirmEmail) {
      setEmailError(true);
    }
    setTimeout(() => {
      setPassError(false);
      setEmailError(false);
    }, 3000);
  };
  const handleCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then((data) =>
        setAddress(data.results[0].formatted_address)
      );
    });
  };
  async function checkEmail() {
    if (email) {
      const exist = await userExist(email);
      if (exist) return setIsRegistered(exist);
      return setIsRegistered(exist);
    }
  }

  return (
    <Wrapper>
      <FormWrapper>
        <Title>{t("register.title")}</Title>
        <Subtitle>{t("register.professional")}</Subtitle>
        <PrivacyComponent
          openMenu={openMenu}
          onClick={() => setOpenMenu(!openMenu)}
          referency={referency}
        />
        {!recoverPassw && isRegistered && (
          <RegisteredInfo>
            <span>{t("register.userLogged")}</span>
          </RegisteredInfo>
        )}
        {!recoverPassw && (
          <RegisterForm onSubmit={handleSubmit(onSubmit)}>
            <StringInput
              name="email"
              type="email"
              defaultValue={email}
              onChange={setEmail}
              placeholder={t("register.emailPlaceholder")}
              label={t("register.email") + "*"}
              error={errors.email}
              ref={register({ required: true })}
              onBlur={checkEmail}
              autoFocus
            />
            {!isRegistered && (
              <Fragment>
                <StringInput
                  name="confirm-email"
                  type="email"
                  defaultValue={confirmEmail}
                  onChange={setConfirmEmail}
                  placeholder={t("register.emailPlaceholder")}
                  label={t("register.confirmemail") + "*"}
                  autocomplete={false}
                  error={errors.confirmEmail}
                  ref={register({ required: true })}
                />
                <StringInput
                  name="vatin"
                  type="text"
                  defaultValue={vatin}
                  onChange={setVatin}
                  label={t("register.vatin")}
                  error={errors.vatin}
                  ref={register({ required: false })}
                />
                <StringInput
                  name="clinicName"
                  type="text"
                  defaultValue={clinicName}
                  onChange={setClinicName}
                  label={t("register.clinicName") + "*"}
                  ref={register({ required: true })}
                  error={errors.clinicName}
                />
                <LocationWrapper ref={SuggestionRef}>
                  <StringInput
                    name="address"
                    value={address}
                    placeholder={t("register.centerLocationPlaceholder")}
                    onChange={handleInput}
                    disabled={!ready}
                    ref={register({ required: true })}
                  />
                  <LocationLabel htmlFor="address">{t("register.centerLocation")} *</LocationLabel>
                  {status === "OK" && (
                    <SuggestionsBox>
                      <SuggestionsItem location onClick={handleCurrentPosition}>
                        {t("register.actualLocation")}
                      </SuggestionsItem>
                      {renderSuggestions()}
                    </SuggestionsBox>
                  )}
                  {errors.address && <ErrorBox>{t("errors.requiredField")}</ErrorBox>}
                </LocationWrapper>
                <Dropdown
                  name="country"
                  label={t("register.country")}
                  defaultValue={country}
                  onChange={(evt) => {
                    setCountryCode(countries.find((country) => country.name === evt.target.value));
                    setCountry(evt.target.value);
                  }}
                  ref={register({ required: true })}
                  error={errors.country}
                >
                  {countries &&
                    countries
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((country) => country.name)}
                </Dropdown>
                <StringInput
                  name="password"
                  type="password"
                  defaultValue={password}
                  minLength={6}
                  onChange={setPassword}
                  error={errors.password}
                  ref={register({ required: true })}
                  label={t("register.password") + "*"}
                  tooltip={true}
                  tooltipText={t("register.tooltipText")}
                />
                <StringInput
                  name="confirmPassword"
                  type="password"
                  defaultValue={confirmPassword}
                  minLength={6}
                  onChange={setConfirmPassword}
                  error={errors.confirmPassword}
                  ref={register({ required: true })}
                  label={t("register.confirmPassword") + "*"}
                />
                <PrivacyPolicyLabel>
                  <input
                    type="checkbox"
                    defaultValue={privacy}
                    name="privacy"
                    onChange={() => setPrivacy(!privacy)}
                    ref={register({ required: true })}
                  />
                  <div className="label">
                    <Trans t={t} i18nKey="register.privacy">
                      I read and accept the
                      <Link to={t("register.linkPolicy")} target="blank">
                        privacy policies
                      </Link>
                    </Trans>
                  </div>
                </PrivacyPolicyLabel>
                <PrivacyPolicyLabel>
                  <input
                    type="checkbox"
                    defaultValue={newsletter}
                    name="newsletter"
                    onChange={() => setNewsletter(!newsletter)}
                    ref={register({ required: false })}
                  />
                  <div className="label">
                    <Trans t={t} i18nKey="register.newsletter">
                      Join the <strong>mesoestetic® beauty club</strong> to receive promotions, tips
                      and new features. I accept its
                      <span onClick={() => history.push("/terms-and-conditions")}>
                        terms and conditions
                      </span>
                      .
                    </Trans>
                  </div>
                </PrivacyPolicyLabel>
              </Fragment>
            )}
            {isRegistered && !recoverPassw && (
              <StringInput
                name="password"
                type="password"
                defaultValue={password}
                minLength={6}
                onChange={setPassword}
                error={errors.password}
                ref={register({ required: true })}
                label={t("register.password") + "*"}
                tooltip={true}
                tooltipText={t("register.tooltipText")}
              />
            )}
            {errors.privacy && <ErrorBox>{t("errors.requiredField")}</ErrorBox>}
            {passError && <ErrorPass>{t("register.errorPass")}</ErrorPass>}
            {emailError && <ErrorPass>{t("register.errorEmail")}</ErrorPass>}
            {userErrors && <p>{userErrors}</p>}
            <Button style={{ margin: "auto" }} type="submit">
              {t("register.submitButtonCreate")}
            </Button>
            <RequiredLegend>{t("register.required")}</RequiredLegend>
            <ForgotPassword onClick={() => setRecoverPass(true)}>
              {t("login.forgotPassword")}
            </ForgotPassword>
          </RegisterForm>
        )}
        {recoverPassw && (
          <>
            <Form onSubmit={handleSubmit(handleRecoveryPass)}>
              <StringInput
                name="email"
                type="email"
                defaultValue={email}
                required
                label={t("register.email") + "*"}
                onChange={setEmail}
              />
              <Button style={{ margin: "auto" }} type="submit">
                {t("register.validate")}
              </Button>
            </Form>
            <TextAfterForm>
              <Trans t={t} i18nKey="register.moreInfo">
                Si es usted profesional estético y quiere más información sobre mesoestetic o
                ponerse en contacto con un comercial de tu zona, contáctanos
                <a href="https://www.mesoestetic.com">aquí</a>.
              </Trans>
            </TextAfterForm>
          </>
        )}
      </FormWrapper>
    </Wrapper>
  );
};
Register.propTypes = {
  loginUser: propTypes.func,
  user: propTypes.object,
  languages: propTypes.arrayOf(propTypes.object),
  formErrors: propTypes.object,
  validateCode: propTypes.func,
  validatedCode: propTypes.string,
};

const mapStateToProps = (state) => ({
  languages: state.languages.languagesAvailables,
  userErrors: state.user.errors,
  validatedCode: state.user.code,
  loggedUser: state.user.data,
  selectedLang: state.languages.selectedLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  registerUser: (user) => dispatch(RegisterUser(user)),
  addAppToUser: (email, password, code) => dispatch(registerNewApp(email, password, code)),
  validateCode: (code) => dispatch(validateCode(code)),
  changeApp: (selectedApp) => dispatch(changeApplicationUtil(selectedApp)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Register);
