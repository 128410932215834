import React, { useState } from "react";
import propTypes from "prop-types";
import {
  Wrapper,
  Input,
  Label,
  ErrorBox,
  TooltipTextBox,
  TooltipIcon,
} from "./style";
import { useTranslation } from "react-i18next";

const StringInput = React.forwardRef(
  (
    {
      name,
      type,
      placeholder,
      onChange,
      className,
      value,
      error,
      children,
      label,
      active,
      required,
      tooltip,
      tooltipText,
      minLength,
      key,
      defaultValue,
      limit,
      ...props
    },
    ref
  ) => {
    const [t] = useTranslation("global");
    const [tooltipActive, setTooltipActive] = useState(false);
    return (
      <Wrapper>
        <Input
          id={name}
          key={key}
          name={name}
          type={type}
          onChange={onChange}
          minLength={minLength}
          placeholder={placeholder}
          ref={ref}
          value={value}
          defaultValue={defaultValue}
          style={error && { borderBottom: "solid 1px red" }}
          child={!!children}
          maxLength={limit ? limit : ""}
          {...props}
        />
        {limit &&
          <p className="limit">{`${defaultValue.length ? defaultValue.length : 0}/190`}</p>
        }
        {children}
        <Label active={active} htmlFor={name}>
          {label}
        </Label>
        {tooltip && (
          <TooltipIcon tooltipActive={tooltipActive} onClick={() => setTooltipActive(!tooltipActive)} />
        )}
        {tooltipText && (
          <TooltipTextBox tooltipActive={tooltipActive}>
            <p>{tooltipText}</p>
          </TooltipTextBox>
        )}
        {error && <ErrorBox>{t("errors.requiredField")}</ErrorBox>}
      </Wrapper>
    );
  }
);
StringInput.propTypes = {
  name: propTypes.string,
  type: propTypes.string,
  placeholder: propTypes.string,
  onChange: propTypes.func,
  className: propTypes.string,
  value: propTypes.string,
  error: propTypes.object,
  children: propTypes.any,
  label: propTypes.string,
  register: propTypes.func,
};
export default StringInput;
