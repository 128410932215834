import styled from "styled-components";
import videobg from "./images/videobg.png";
import header_bg_left from "./images/resource-bg-1.png";
import header_bg_right from "./images/superior-derecho.svg";
export const Wrapper = styled.main``;
export const MiniBannerWrapper = styled.section`
  position: relative;
  text-align: center;
  background: linear-gradient(0.25turn, #d5d5d5, #f9f9f9, #d5d5d5);
  height: 134px;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    display: block;
  }
  &::before {
    background: url(${header_bg_left}) no-repeat;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &::after {
    background: url(${header_bg_right}) no-repeat right center;
    right: 0;
    height: 100%;
    width: 100%;
  }
`;
export const MiniBannerInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;
export const MiniBannerTitle = styled.h1`
  color: #000000;
  font-family: "hn-light", Arial;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  margin: 0;
  text-align: center;
`;
export const MiniBannerSubtitle = styled.p`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 26px;
  margin: 0;
  text-align: center;
`;
export const Content = styled.div`
  max-width: 665px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px 0;
`;
export const LogoContainer = styled.div`
  display: flex;
  margin: 25px auto;
  .title {
    color: #000000;
    font-family: "hn-medium", Arial;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 15px;
    margin: 2px auto;
  }
  .subtitle {
    color: #000000;
    text-align: left;
    font-family: "hn-semibold", Arial;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 2px auto;
  }
  .text {
    font-family: "hn-medium", Arial;
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 17px;
    margin: 2px auto;
    text-align: left;
  }
`;
export const TextLogoContainer = styled.div`
  max-width: 265px;
  margin-top: 30px;
`;
export const ActivesWrapper = styled.div`
  width: 100%;
  max-width: 555px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 185px 1fr;
`;
export const ActivesLeft = styled.div`
  display: flex;
  align-items: center;
  padding-right: 12px;
  .bigNumber {
    font-family: hn-bold, Arial;
    font-size: 46px;
  }
  div {
    margin-left: 5px;
    border-right: 1px solid black;
    p {
      color: #000000;
      font-family: "hn-medium", Arial;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: normal;
      line-height: 17px;
      text-align: left;
      margin: 0;
      strong {
        display: block;
      }
    }
  }
`;
export const ActivesRight = styled.div`
  p {
    color: #000000;
    font-family: "hn-medium", Arial;
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.26px;
    margin: 0;
    line-height: 15px;
    text-align: left;
  }
  .activeCTA {
    font-family: "hn-semibold", Arial;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
    font-style: normal;
    letter-spacing: 0.39px;
    line-height: normal;
    cursor: pointer;
    text-decoration: underline;
  }
`;
export const CustomizableWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 555px;
  margin: 50px auto;
  h1 {
    color: #000000;
    font-family: "hn-semibold", Arial;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 15px;
    text-align: left;
  }
  .p__break-lines {
    white-space: pre-line;
  }
  div {
    p {
      color: #000000;
      font-family: "hn-medium", Arial;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.31px;
      line-height: 20px;
      text-align: left;
      margin: 0;
    }
  }
  img {
    position: absolute;
    top: -45px;
    right: 0;
  }
`;
export const VideoWrapper = styled.section`
  width: 100%;
  background-image: url(${videobg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0 40px;
  text-align: center;
  overflow: hidden;
  iframe {
    max-width: 640px;
    margin: auto;
  }
`;
export const Overlay = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  content: "";
  position: fixed;
  left: 0px;
  top: 0px;
  opacity: 0.3;
  z-index: 3;
  display: ${({ active }) => (active ? "block" : "none")};
`;
export const Popup = styled.div`
  display: ${({ active }) => (active ? "block" : "none")};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 3;
  color: #000000;
  padding: 35px;
  width: 80%;
  max-width: 670px;
  .title {
    font-size: 14px;
    text-align: left;
    font-family: "hn-semibold", Arial;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
  }
  p {
    font-family: "hn-medium", Arial;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.36px;
    line-height: 25px;
    text-align: left;
  }
  @media (max-width: 650px) {
    transform: translate(-50%, -25%);
  }
`;
export const IngredientsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const IngredientsInfoWrapper = styled.div`
  max-width: 370px;
  width: 100%;
`;
// ABOUT PAGE
export const CTAsWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  max-width: 660px;
  width: 100%;
  margin: 20px auto;
  padding-top: 20px;
  button {
    width: 48%;
    text-transform: lowercase;
    border-radius: 0;
    font-family: "hn-semibold", Arial;
    font-size: 14px;
    padding: 8px;
    z-index: 1;
  }
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    button {
      margin-bottom: 20px;
      width: 80%;
    }
  }
`;
