import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Overlay, PhaseEditWrapper, Button } from "./style";
import { useTranslation } from "react-i18next";
import { PrintProductQtys, PrintProductCards, isABooster } from "./utils";
import { ComponentCheck } from "components/EditHomeProtocol/styles";

const PhaseEdit = ({ phaseData, closePhase, handleSubmit, products }) => {
  const [t_global] = useTranslation("global");
  const [activeProducts, setActiveProducts] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [haveBoosters, setHaveBoosters] = useState(false);

  // if(phaseData.name === "final") {
  //   products[phaseData.name].forEach(product => {
  //     const sku = product.product_sku;
  //     const product2 = protocols[phaseData.sessionNumber]['final'].find( product => product.product_sku === sku);
  //     product.product_qty = product2.product_qty;
  //   });
  // }

  useEffect(() => {
    if (phaseData.phase) {
      const copiedPhase = JSON.parse(JSON.stringify(phaseData.phase));
      return setActiveProducts([...copiedPhase]);
    }
    return () => setActiveProducts(null);
  }, [phaseData.phase]);
  const onSubmit = () => {
    setLoading(true);
    phaseData.phase = [...activeProducts];
    handleSubmit();
    return setTimeout(() => {
      setLoading(false);
      closePhase();
    }, 2500);
  };
  const onDeleteProduct = (index) => {
    const newActiveProducts = [...activeProducts];
    const productIsABooster = isABooster(newActiveProducts[index]);
    const newBoosterQty = productIsABooster ? haveBoosters - 1 : haveBoosters;
    if ((productIsABooster && haveBoosters > 1) || !productIsABooster) {
      newActiveProducts.splice(index, 1);
      setHaveBoosters(newBoosterQty);
      return setActiveProducts(newActiveProducts);
    }
    setError(t_global("Tiene que haber un booster al menos (faltan traducciones)"));
    return setTimeout(() => {
      setError(false);
    }, 2500);
  };
  return (
    <>
      <Overlay onClick={() => closePhase()}></Overlay>
      <PhaseEditWrapper>
        <div className="header">
          <h3>{t_global(`treatment.${phaseData.name}`)}</h3>
          <p className="subtitle">{t_global("phase.subtitle")}</p>
          <span className="close" onClick={() => closePhase()}></span>
        </div>
        <div className="content">
          {activeProducts && (
            <PrintProductCards
              products={products[phaseData.name]}
              activeProducts={activeProducts}
              setActiveProducts={setActiveProducts}
              // fixedQty={phaseData.productQty}
              setHaveBoosters={setHaveBoosters}
              haveBoosters={haveBoosters}
            />
          )}
          <div className="boosters-wrapper">
            {error && (
              <div className="errors">
                <p>{error}</p>
              </div>
            )}
            {activeProducts?.map((product, index) => (
              <PrintProductQtys
                product={product}
                key={`${product.product_name}_${index}`}
                setError={setError}
                index={index}
                onDeleteProduct={onDeleteProduct}
                // phaseData={phaseData}
              />
            ))}
          </div>
        </div>
        <div style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
          {loading ? (
            <ComponentCheck size="40px" color="black" />
          ) : (
            <Button onClick={onSubmit}>{t_global("products.save")}</Button>
          )}
        </div>
      </PhaseEditWrapper>
    </>
  );
};
const mapStateToProps = (state) => ({
  products: state.products.bodyshock,
  // protocols: state.protocols.protocol.professional,
});

export default connect(mapStateToProps, null)(PhaseEdit);
