import React, { useState } from "react";
import {
  HeaderWrapper,
  Img,
  UserLogo,
  UserMenu,
  UserMenuClinicEmail,
  UserMenuClinicName,
  UserMenuInfo,
  UserMenuInfoInnerWrapper,
  UserMenuLanguageBack,
  UserMenuLanguageLabel,
  UserMenuLanguagesInnerWrapper,
  UserMenuLanguagesWrapper,
  UserMenuLanguageTitle,
  UserMenuOkBtn,
  UserMenuOptions,
  UserMenuTitle,
  UserMenuWrapper,
} from "./style";
import logoMesoestetic from "./images/logo-mst.svg";
import logoAntiaging from "./images/logo-agelement.jpg";
import logoBodyshock from "./images/logo-bodyshock.svg";
import { useTranslation } from "react-i18next";
import LangSelector from "components/LangSelector";
import { setLanguage } from "redux/utils/transverse/CountryLang";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import centroestetico from "./images/centro-estetico.svg";
import useOnclickOutside from "react-cool-onclickoutside";
import { LogOut } from "redux/utils/transverse/User";
import { Overlay } from "components/LangSelector/style";
import useLocalStorage from "utils/transverse/useLocalStorage";
import localstoragekeys from "constants/localstoragekeys";
import AppSelector from "../AppSelector";
import apps from "constants/apps";
import routes from "constants/routes";
import { changeApplicationUtil } from "redux/utils/transverse/Application";
import { Notification } from "components/transverse/Notification";
import { getNotificationUtil, resetNotificationUtil } from "redux/utils/transverse/Notification";
import { Link } from "react-router-dom";

const Header = ({
  languages,
  selectLanguage,
  selectedLanguage,
  user,
  logout,
  appFromStore,
  changeApp,
  notification,
  getNotifications,
  resetNotifications,
}) => {
  const history = useHistory();
  const [, setStoredLanguage] = useLocalStorage(localstoragekeys.appLanguage);
  const [, setUserData] = useLocalStorage(localstoragekeys.appUser);
  const [, setAppSelected] = useLocalStorage(localstoragekeys.app);
  const [t, i18next] = useTranslation("global");
  const [openMenu, setOpenMenu] = useState(false);
  const [openApps, setOpenApps] = useState(false);
  const [showLangSelector, setShowLangSelector] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [, setSaveNotification] = useLocalStorage(localstoragekeys.notification);

  const logos = {
    [apps.BODYSHOCK]: logoBodyshock,
    [apps.ANTIAGING]: logoAntiaging,
    default: logoMesoestetic,
  };
  const handleNotification = () => {
    if (!notification.exist) {
      getNotifications(selectedLanguage, appFromStore);

      if (notification.content) {
        setShowNotification(true);
      }
    }
    else {
      if (notification.content) {
        setShowNotification(true);
      }
    }

  }
  const closeNotification = () => {
    setShowNotification(false);
    setSaveNotification(true);
  }
  const referency = useOnclickOutside(() => {
    setOpenMenu(false);
    setOpenApps(false);
    setShowLangSelector(false);
  });
  const getLangLabel = (arr) => {
    const language = arr.find(
      (lang) => lang.iso_language.toLowerCase() === selectedLanguage.toLowerCase()
    );
    if (language) return language.language;
    return;
  };
  const handleClick = (isoLang) => {
    languages.map((lang) => {
      if (lang.iso_language.toLowerCase() === isoLang.toLowerCase()) {
        setStoredLanguage(isoLang.toLowerCase());
        selectLanguage(isoLang.toLowerCase());
        i18next.changeLanguage(isoLang.toLowerCase());
      }
      return false;
    });
  };

  const isLogged = !!user.api_token;
  return (
    <HeaderWrapper>
      {isLogged && <Overlay active={openMenu} />}
      <Link to={`/${appFromStore}`} style={{ lineHeight: 0 }}>
      {isLogged && appFromStore ? (
          <Img src={logos[appFromStore]} alt="Mesoestetic" />
        ) : (
          <Img src={logos.default} alt="Mesoestetic" />
        )}
      </Link>
      {!isLogged && (
        <LangSelector
          value={languages && getLangLabel(languages)}
          onClick={handleClick}
          languages={languages}
        ></LangSelector>
      )}
      {isLogged && (
        <div className="logo-box" onClick={() => setOpenMenu(!openMenu)}>
          <UserLogo src={centroestetico} alt="User menu" />
          <p>
            {user.first_name &&
              user.first_name.split(" ").map((words) => words[0] && words[0].toUpperCase())}
          </p>
        </div>
      )}
      {isLogged && !showLangSelector && !openApps && (
        <UserMenuWrapper active={openMenu} ref={referency}>
          <UserMenuTitle>{t("userMenu.account")}</UserMenuTitle>
          <UserMenuOkBtn onClick={() => setOpenMenu(!openMenu)}>OK</UserMenuOkBtn>
          <UserMenuInfo>
            <UserLogo src={centroestetico} alt="User menu" />
            <UserMenuInfoInnerWrapper>
              <UserMenuClinicName>{user.name}</UserMenuClinicName>
              <UserMenuClinicEmail>{user.email}</UserMenuClinicEmail>
            </UserMenuInfoInnerWrapper>
          </UserMenuInfo>
          <UserMenu>
            {user.permission.length > 1 && (
              <UserMenuOptions className="submenu" onClick={() => setOpenApps(true)}>
                {t("appSelector.changePlatform")}
              </UserMenuOptions>
            )}
            <UserMenuOptions className="submenu" onClick={() => setShowLangSelector(true)}>
              {t("header.language")}
            </UserMenuOptions>
            <UserMenuOptions
              onClick={() => {
                handleNotification();
                setOpenMenu(!openMenu);
              }}
            >
              {t("userMenu.notification")}
            </UserMenuOptions>
            <UserMenuOptions
              style={{ marginTop: 12 }}
              onClick={() => (window.location = "mailto:support-age@mesoestetic.com")}
            >
              {t("userMenu.help")}
            </UserMenuOptions>
            <UserMenuOptions
              onClick={() => {
                history.push("/faq");
                setOpenMenu(!openMenu);
              }}
            >
              {t("userMenu.FAQ")}
            </UserMenuOptions>
            <UserMenuOptions
              style={{ marginTop: 12 }}
              onClick={() => {
                history.push(`/${appFromStore}/${routes.legalNotice}`);
                setOpenMenu(!openMenu);
              }}
            >
              {t("userMenu.Policy")}
            </UserMenuOptions>

            <UserMenuOptions
              onClick={() => {
                history.push("/privacy-policy");
                setOpenMenu(!openMenu);
              }}
            >
              {t("userMenu.PolicyGeneral")}
            </UserMenuOptions>
            <UserMenuOptions
              onClick={() => {
                logout(history);
                setAppSelected(apps.NONE);
                changeApp(apps.NONE);
                setUserData("");
                setSaveNotification(false);
                resetNotifications();
              }}
              style={{ borderRadius: "0 0 10px 10px" }}
            >
              {t("userMenu.logout")}
            </UserMenuOptions>
          </UserMenu>
        </UserMenuWrapper>
      )}
      {showLangSelector && languages && (
        <UserMenuLanguagesWrapper ref={referency}>
          <UserMenuLanguagesInnerWrapper>
            <UserMenuLanguageBack
              onClick={() => {
                setShowLangSelector(false);
                setOpenMenu(true);
              }}
            >
              {t("questionnaire.back")}
            </UserMenuLanguageBack>
            <UserMenuLanguageTitle> {t("header.language")}</UserMenuLanguageTitle>
            {languages.map((lang) => (
              <UserMenuLanguageLabel
                onClick={() => {
                  handleClick(lang.iso_language);
                  resetNotifications();
                  setShowLangSelector(false);
                  setOpenMenu(false);

                }}
                key={lang.iso_language}
              >
                {lang.language}
              </UserMenuLanguageLabel>
            ))}
          </UserMenuLanguagesInnerWrapper>
        </UserMenuLanguagesWrapper>
      )}
      {openApps && (
        <UserMenuLanguagesWrapper ref={referency}>
          <UserMenuLanguagesInnerWrapper>
            <UserMenuLanguageBack
              onClick={() => {
                setOpenApps(false);
                setOpenMenu(true);
              }}
            >
              {t("appSelector.account")}
            </UserMenuLanguageBack>
            <UserMenuLanguageTitle> {t("appSelector.platforms")}</UserMenuLanguageTitle>
            <AppSelector
              permission={user.permission}
              customHandleClick={(appSelected) => {
                setOpenApps(false);
                setAppSelected(appSelected);
                resetNotifications();
                changeApp(appSelected);
                setOpenMenu(false);
              }}
            />
          </UserMenuLanguagesInnerWrapper>
        </UserMenuLanguagesWrapper>
      )}
      {showNotification && notification.exist && (
        <Notification notification={notification} closeNotification={closeNotification} />
      )}
    </HeaderWrapper>
  );
};
const mapDispatchToProps = (dispatch) => ({
  selectLanguage: (language) => dispatch(setLanguage(language)),
  logout: (history) => dispatch(LogOut(history)),
  changeApp: (selectedApp) => dispatch(changeApplicationUtil(selectedApp)),
  getNotifications: (selectedLanguage, appFromStore) => dispatch(getNotificationUtil(selectedLanguage, appFromStore)),
  resetNotifications: () => dispatch(resetNotificationUtil()),
});
const mapStateToProps = (state) => ({
  selectedLanguage: state.languages.selectedLanguage,
  user: state.user.data,
  appFromStore: state.application,
  notification: state.notification,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
