import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseButton, ComponentCheck, ProductListWrapper } from "./styles";
import { Product } from "./styles";
const EditHomeProtocol = ({ products, handleClick, close }) => {
  const [t] = useTranslation("global");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [success, setSuccess] = useState(false);
  const selectProduct = (product) => {
    if (selectedProducts.some((prod) => prod.id === product.id)) {
      const newSelectedProducts = selectedProducts.filter(
        (prod) => prod.id !== product.id
      );
      return setSelectedProducts([...newSelectedProducts]);
    }
    return setSelectedProducts([...selectedProducts, product]);
  };
  const handleSave = (selectedProds, gamma, e) => {
    e.preventDefault();
    handleClick(selectedProds, gamma);
    setSuccess(true);
    setTimeout(() => {
      close();
      setSuccess(false);
    }, 2000);
  };
  return (
    <ProductListWrapper>
      <div className="inner">
        <CloseButton onClick={close} />
        <h3>{t("editHome.productList")}</h3>
        <div className="search">
          <input
            value={searchTerm}
            onChange={(evt) => setSearchTerm(evt.target.value)}
            type="text"
            placeholder={t("editHome.productListPlaceholder")}
          />
        </div>
        <div className="product-labels">
          <article>
            {products[0].gamma && (
              <span>{t("editHome.productGamma")}</span>
            )}
            <span>{t("editHome.productName")}</span>
          </article>
        </div>
        <ul>
          {products
            .filter((prod) =>
              (prod.gamma+prod.name).toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((product) => (
              <Product
                key={product.id}
                onClick={() => selectProduct(product)}
                active={
                  !!selectedProducts.some((prod) => prod.id === product.id)
                }
                unique={!product.gamma}
              >
                {product.gamma && <span>{product.gamma}</span>}
                <span>{product.name}</span>
              </Product>
            ))}
        </ul>

        <div className="actions">
          {success && <ComponentCheck size="4px" color="black" />}
          {!success && (
            <button
              onClick={(e) =>
                handleSave(selectedProducts, selectedProducts[0].gamma, e)
              }
            >
              {t("editHome.save")}
            </button>
          )}
        </div>
      </div>
    </ProductListWrapper>
  );
};
export default EditHomeProtocol;
