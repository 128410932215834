import React, { useEffect } from "react";
import routes from "constants/routes";
import { Switch, Route, useHistory } from "react-router";
import Home from "./Home";
import Questionnaire from "./Questionnaire";
import Treatment from "./Treatment";
import Resources from "./Resources";
import LegalNotice from "pages/antiaging/LegalNotice";
import NewPatient from "../transverse/NewPatient";
import AboutPage from "./Resources/AboutPage";
import Patients from "./Patients";
import Patient from "./Patient";
import ProtocolPage from "./Resources/ProtocolPage";
import CenterMaterial from "./Resources/CenterMaterial";
import { useRouteMatch } from "react-router-dom";
import apps from "constants/apps";
import { connect } from "react-redux";
import { getAllowedLanguagesUtil } from "redux/utils/transverse/CountryLang";

const Antiaging = ({ permissions, getAllowedLanguages }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    if (permissions) {
      if (!permissions.includes(apps.ANTIAGING)) history.push(`${routes.selector}`);
    }
    getAllowedLanguages();
  }, [permissions, history, getAllowedLanguages]);
  return (
    <>
      <Switch>
        <Route path={`${url}/${routes.patient}/:id`}>
          <Patient />
        </Route>
        <Route path={`${url}/${routes.start}`}>
          <Questionnaire />
        </Route>
        <Route path={`${url}/${routes.resources}`}>
          <Resources />
        </Route>
        <Route path={`${url}/${routes.treatment}`}>
          <Treatment />
        </Route>
        <Route path={`${url}/${routes.newpatient}`}>
          <NewPatient />
        </Route>
        <Route path={`${url}/${routes.about}`}>
          <AboutPage />
        </Route>
        <Route path={`${url}/${routes.patients}`}>
          <Patients />
        </Route>
        <Route path={`${url}/${routes.protocol}`}>
          <ProtocolPage />
        </Route>
        <Route path={`${url}/${routes.material}`}>
          <CenterMaterial />
        </Route>
        <Route path={`${url}/${routes.legalNotice}`}>
          <LegalNotice />
        </Route>
        <Route path={`/${apps.ANTIAGING}`}>
          <Home />
        </Route>
      </Switch>
    </>
  );
};
const mapStateToProps = (state) => ({
  permissions: state.user.data.permission,
});
const mapDispatchToProps = (dispatch) => ({
  getAllowedLanguages: () => dispatch(getAllowedLanguagesUtil()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Antiaging);
