import styled from "styled-components";
import icondropdown from "./images/icondropdown.svg";

export const SessionDateWrapper = styled.section`
  @media print {
    display: none;
  }
  display: ${({ active }) => (active ? "block" : "none")};
  position: absolute;
  top: 15%;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  z-index: 10;
  width: 80%;
  max-width: 464px;
  background-color: #ffffff;
  .content {
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    height: auto;
  }
  h3 {
    color: #000000;
    font-family: "hn-light", Arial;
    font-size: 25px;
    text-transform: lowercase;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }
  label,
  p {
    font-family: "hn-medium", Arial;
    letter-spacing: 1.5px;
    line-height: 24px;
    font-size: 11px;
    color: #000000;
    margin-bottom: 0;
  }

  .separator {
    margin: 0 8px;
  }
  select {
    appearance: none;
    border: none;
    border-bottom: 1px solid #4a4a4a;
    width: 36px;
    background: url(${icondropdown}) transparent no-repeat right center;
    margin-bottom: 10px;
    line-height: 26px;
    font-size: 14px;
  }
  .email__box {
    display: grid;
    grid-template-columns: 20px auto;
    position: relative;
    margin-top: 20px;
  }
  #appointment-email {
    position: absolute;
    opacity: 0;
    top: 0;
    z-index: 2;
    left: 0;
  }
  textarea {
    border: 1px solid #888;
    margin-top: 20px;
    padding: 12px;
    font-family: "hn-medium", Arial;
    letter-spacing: 1.5px;
    line-height: 24px;
    font-size: 11px;
    color: #000000;
    width: 100%;
    &:focus {
      border-radius: 0;
      border-color: black;
    }
  }
  .actions button {
    border: 1px solid #000000;
    padding: 10px;
    background-color: #000000;
    color: #ffffff;
    font-family: "hn-semibold", Arial;
    font-size: 14px;
    letter-spacing: 0.34px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    margin: 15px 0;
    cursor: pointer;
  }
  .actions {
    text-align: center;
  }
  .checkmark::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid #4a4a4a;
    background-color: transparent;
  }

  input[type="checkbox"]:checked ~ .checkmark::before {
    position: absolute;
    content: "";
    width: 3px;
    height: 12px;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(45deg);
    z-index: 1;
    left: 7px;
  }
  
`;
