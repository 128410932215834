import styled, { css } from 'styled-components';

import clock from './images/clock.svg';
import arrow from './images/ico-arrow.svg';

// ABDOMEN PAGE

export const AdvicePageWrapper = styled.section`
  strong {
    font-weight: 600;
    font-family: 'hn-semibold', Arial;
  }
  .divisor {
    display: block;
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
  }
  .video-wrapper {
    background-image: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
    border-bottom: 1px solid #e2e2e2;
    p {
      padding: 35px 20px 30px;
      max-width: 770px;
      margin: 0;
      margin: auto;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.36px;
      @media(max-width: 640px) {
        font-size: 12px;
        letter-spacing: 0.39px;
        line-height: 18px;
      }
    }
    div {
      padding: 0 20px;
      max-width: 770px;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .preparation-phase {
    display: grid;
    grid-template-columns: 48% 48%;
    column-gap: 4%;
    @media(max-width: 640px) {
      grid-template-columns: auto;
    }
  }
  .content-advices {
    max-width: 770px;
    padding: 0 20px;
    margin: 36px auto 40px;
    h3 {
      margin: 0px;
      font-size: 17px;
      line-height: 20px;
      padding-bottom: 14px;
      border-bottom: 1px dashed #000;
      text-transform: uppercase;
      font-family: 'hn-semibold';
      font-weight: 600;
      margin-bottom: 20px;
      @media(max-width: 640px) {
        font-size: 14px;
        letter-spacing: 0.36px;
        padding-bottom: 10px;
      }
    }
    .subtitle {
      margin-top: 26px;
      margin-bottom: 10px;
      font-size: 17px;
      line-height: 20px;
      @media(max-width: 640px) {
        font-size: 14px;
        letter-spacing: 0.36px;
        margin-top: 0;
      }
    }
  }
  .content-tabs {
      position: relative;
      border-bottom: 1px solid #e2e2e2;
      padding: 0 20px 25px;
      .tabs {
          max-width: 300px;
          margin: auto;
          min-height: 28px;

          display: grid;
          grid-template-columns: auto auto;
          @media(max-width: 520px) {
              max-width: 262px;
          }

          .tab {
              color: #bd3d4b;
              font-size: 10px;
              font-family: 'hn-semibold';
              font-weight: 600;
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border: 1px solid #bd3d4b;
              &.active {
                  color: white;
                  background-color: #bd3d4b;
              }
          }
          #tab-first {
              border-radius: 3px 0px 0px 3px;
          }
          #tab-last {
              border-radius: 0px 3px 3px 0px;
              border-left: 0;
          }
          #tab-second {
            border-left: 0;
            border-radius: 0;
          }

      }
      .tabs3 {
        grid-template-columns: auto auto auto;
        max-width: 450px;
      }
  }
  .titleToggle {
    padding: 12px 20px 16px;
    border-bottom: 1px solid #e2e2e2;
  }
  #openManiobra {
    cursor: pointer;
    color: #d25462;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    margin: auto;
    text-align: center;


    &::after {
      content: '';
      background: url(${arrow}) no-repeat center;
      width: 10px;
      height: 6px;
      display: inline-block;
      margin: 0px 10px;
      transform: rotate(180deg);
    }
    &.active {
      border-bottom: 0px;
      &:after {
          transform: rotate(360deg);
      }
    }
    @media(max-width: 640px) {
      width: 80%;
    }
  }

  #contentToggle {
    display: none;
    &.active {
      display: block;
    }
  }
  .advices {
    max-width: 970px;
    margin: 38px auto;
    h2 {
      text-align: center;
      font-size: 25px;
      line-height: 30px;
      font-family: 'hn-medium';
      font-weight: 400;
      margin: 0 0 6px;
      padding: 0 20px;
      @media(max-width: 640px) {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 10px;
      }

    }
    .sub {
      margin: 0 auto 20px;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0.36px;
      padding: 0 20px;
      @media(max-width: 640px) {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.31px;
        margin-bottom: 15px;
      }
    }
  }
  .advice {
    display: none;
    &.active {
      display: block;
    }
    p {
      margin-bottom: 30px;
      font-size: 14px;
      letter-spacing: 0.36px;
      line-height: 20px;
      @media(max-width: 640px) {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.31px;
        margin-bottom: 25px;
      }
    }
    .photo-title {
      margin: 0;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.31px;
    }
    > div {
      margin-bottom: 40px;
    }
  }
  .columns2 {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 2%;
    @media(max-width: 640px) {
        grid-template-columns: auto;
        gap: 20px;
    }
    > div {
        &:nth-child(2) {
            grid-row: 2;
            display: flex;
        }
    }
  }

  .number-light {
    color: #f3e3e2;
    font-weight: 600;
    font-family: 'hn-semibold', Arial;
    font-size: 25px;
  }
  .content-img {
    display: flex;
    align-items: center;
    gap: 5px;
    @media(max-width: 640px) {
        justify-content: center;
        gap: 20px;
    }
   }
  .number {
      position: absolute;
      padding: 0px 10px 20px;
      z-index: 1;
      top: -14px;
      left: -20px;
      color: #bd3d4b;
      font-weight: 600;
      font-family: 'hn-semibold', Arial;
      font-size: 25px;
      background-color: #f3e3e2;
      @media(max-width: 640px) {
        padding-bottom: 10px;
      }

    }
  .block-red {
    background-color: #f3e3e2;
    h2 {
      font-weight: 600;
      font-family: 'hn-semibold', Arial;
      text-transform: uppercase;
      font-size: 17px;
      margin-bottom: 5px;
      line-height: 20px;
      text-align: center;
      @media(max-width: 640px) {
        font-size: 14px;
        letter-spacing: 0.36px;
      }
    }
    .subtitle {
      text-align: center;
      font-size: 17px;
      margin-bottom: 30px;
      line-height: 20px;
      @media(max-width: 640px) {
        margin-bottom: 40px;
        font-size: 14px;
        letter-spacing: 0.36px;
      }
    }
    > div {
      max-width: 770px;
      padding: 40px 20px;
      margin: auto;
      @media(max-width: 640px) {
        padding-top: 20px;
      }
    }
    .steps {
      position: relative;
      border: 1px solid #d25462;
      border-bottom: 5px solid #db7e88;
      padding: 13px 40px 20px;
      display: flex;
      justify-content: space-between;
      gap: 2%;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
        &:nth-of-type(2) {
          align-items: flex-end;
        }
      }
      @media(max-width: 640px) {
        padding: 18px 14px 16px;
      }
    }
    p {
      font-size: 12px;
      letter-spacing: 0.31px;
      line-height: 17px;
      margin: 0;
    }
    .time {
      font-size: 14px;
      letter-spacing: 0.36px;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 5px;

      &::before {
        content: url(${clock});
      }
      & ~ span {
        font-size: 12px;
        letter-spacing: 0.31px;
        line-height: 17px;
        display: block;
        text-align: right;
      }
    }
    .steps-content {
      display: grid;
      row-gap: 30px;
    }
    .step-title {
      font-size: 17px;
      line-height: 23px;
      margin-bottom: 2px;
      @media(max-width: 640px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

`;

// Advices page
export const AdvicesWrapper = styled.section`
  max-width: 690px;
  margin: 0px auto 50px;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 30px;
  @media(max-width: 640px) {
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
    > article {
      grid-template-columns: auto;
      row-gap: 10px;
      p {
        text-align: center;
      }
    }
  }
`;
export const Subtitle = styled.section`
  text-align: center;
    max-width: 690px;
    margin: 30px auto 25px;
    width: 90%;
    .title {
      font-size: 17px;
      line-height: 30px;
      margin-bottom: 5px;
      strong {
        font-family: "hn-semibold", Arial;
        font-weight: 600;
      }
    }
    .subtitle {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.36px;
      margin-top: 0;
      strong {
        font-family: "hn-semibold", Arial;
        font-weight: 600;
      }
    }
`;