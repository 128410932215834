import styled from 'styled-components';

export const NotificationWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 4;
    background: white;
    max-width: 464px;
    width: 90%;
    padding: 40px 30px;
    text-align: center;

    @media(max-width:520px) {
        padding: 20px;
    }
    h2 {
        font-family: hn-semibold;
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
        margin-top: 4px;
        margin-bottom: 25px;
    }
    p {
        font-size: 14px;
        letter-spacing: 0.36px;
        line-height: 22px;
        margin-top: 0;
        max-width: 350px;
        text-align: center;
        margin: 0 auto 40px;
    }
`;
export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.3;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    cursor: pointer;
`;
export const Button = styled.div`
    background: black;
    color: white;
    max-width: 285px;
    height: 40px;
    font-family: hn-semibold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.34px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
`;