import React from "react";
import { VideoWrapper,Overlay } from "./styles";
export default ({ videoId, close, refProp }) => {
  return (
    <VideoWrapper ref={refProp}>
      <article>
      <Overlay onClick={() => close()}></Overlay>
        <div className="iframe-wrapper">
          <iframe
            title="vimeo-player"
            src={`https://player.vimeo.com/video/${videoId}`}
            width="100%"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
          ></iframe>
        </div>
      </article>
    </VideoWrapper>
  );
};
