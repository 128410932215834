import axios from "axios";
import { getProductsAction } from "redux/actions/transverse/Product";

const getAllProductsUtil = (token, selectedLanguage) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_ANTIAGING_API}api/bodyshock/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Language": selectedLanguage,
      },
    })
    .then(({ data }) => {
      if (data.results) dispatch(getProductsAction(data.results));
    })
    .catch(console.error);
};

export { getAllProductsUtil };
