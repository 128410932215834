import styled from "styled-components";
import addicon from "./images/adduser.svg";
import search from "./images/lupa.svg";
import bg1 from "./images/bg1.svg";
import bg2 from "./images/bg2.svg";
import bg3 from "./images/bg3.svg";
import icoedit from "./images/icoedit.svg";
import icotrash from "./images/icotrash.svg";

export const Wrapper = styled.main`
  @media (max-width: 600px) {
    padding: 20px;
  }
  padding: 50px;
  background-image: url(${bg1});
  background-position: left top;
  background-repeat: no-repeat;
  position: relative;
  &::before {
    background-image: url(${bg2});
    content: "";
    width: 142px;
    height: 168px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }
  &::after {
    background-image: url(${bg3});
    background-position: right bottom;
    background-repeat: no-repeat;
    content: "";
    position: fixed;
    bottom: 0;
    right: 0;
    width: 244px;
    height: 280px;
    z-index: -1;
  }
  .overlay {
    background-color: black;
    width: 100%;
    height: 100%;
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.3;
    cursor: pointer;
  }
`;

export const AddPatient = styled.button`
  width: 37px;
  height: 37px;
  background-color: #323e48;
  border-radius: 50%;
  border: 0px;
  cursor: pointer;
  background-image: url(${addicon});
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
`;

export const Header = styled.div`
  text-align: center;
  position: relative;
  h1 {
    color: #000000;
    font-family: "hn-light", Arial;
    font-size: 25px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 38px;
  }
`;

export const Searcher = styled.div`
  text-align: center;
  height: 60px;
  input {
    position: relative;
    background-image: url(${search});
    background-position: right 14px;
    background-repeat: no-repeat;
    border-width: 0 0 1px 0;
    border-color: #4a4a4a;
    background-color: transparent;
    padding: 18px 40px 10px 0;
    width: 100%;
    max-width: 400px;
  }
  input:focus-within {
    border-width: 0 0 1px 0;
    outline: none;
  }
  &::before {
    content: "";
    background-color: #f2f2f2;
    width: 100%;
    position: absolute;
    left: 0;
    height: 60px;
    border-bottom: 1px solid #e2e2e2;
  }
`;

export const Results = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const ResultsContainer = styled.div`
  overflow-x: auto;
`;

export const Patient = styled.tr`
  border-bottom: 1px solid #e2e2e2;
  color: #000000;
  font-family: "hn-light", Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 22px;
  text-align: left;
  cursor: pointer;
  td {
    padding: 15px 10px;
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

export const Actions = styled.div`
  text-align: right;
  button {
    width: 27px;
    height: 27px;
    border-radius: 14px;
    color: #a6a9aa;
    border: 1px solid #a6a9aa;
    background-color: #ffffff;
    cursor: pointer;
    padding: 0;
    position: relative;
    span {
      font-weight: bold;
      position: absolute;
      top: -2px;
      width: 100%;
      left: 0;
      font-size: 18px;
    }
    :hover,
    .active {
      border: 1px solid #323e48;
      background-color: #323e48;
      color: white;
    }
  }
  ul {
    display: "none";
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    padding: 15px 0px;
    background: #f2f2f2;
    border: 1px solid #e2e2e2;
    width: 190px;
    right: 0;
    margin-right: 20px;

    &::after,
    &::before {
      bottom: 100%;
      right: 45px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-color: rgba(242, 242, 242, 0);
      border-bottom-color: #f2f2f2;
      border-width: 5px;
      margin-left: -5px;
    }
    &::before {
      border-color: rgba(226, 226, 226, 0);
      border-bottom-color: #e2e2e2;
      border-width: 6px;
      margin-left: -6px;
    }

    li:first-child {
      border-top: 1px solid #e2e2e2;
    }
    li {
      background-color: white;
      border-bottom: 1px solid #e2e2e2;
      list-style: none;
      text-align: left;
      color: #000000;
      font-family: "hn-medium", Arial;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.31px;
      line-height: 21px;
      padding: 10px 33px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: 10px center;
    }
    li.icotrash {
      background-image: url(${icotrash});
    }
    li.icoedit {
      background-image: url(${icoedit});
    }
  }
`;

export const BottomCTAsWrapper = styled.section`
  @media print {
    display: none;
  }
  background-color: white;
  width: 100%;
  display: grid;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const ProtocolsCTAs = styled.span`
  padding: 10px 15px;
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  border: 1px solid #000000;
  max-width: 250px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  text-transform: lowercase;
  max-width: 285px;
  width: 100%;
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  margin: 30px auto 40px;
  display: block;
    
`;