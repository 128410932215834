import React, { useEffect } from "react";
import routes from "constants/routes";
import { Switch, Route, useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import apps from "constants/apps";
import Home from "./Home";
import Questionnaire from "./Questionnaire";
import NewPatient from "../transverse/NewPatient";
import Treatment from "./Treatment";
import LegalNotice from "pages/bodyshock/LegalNotice";
import { connect } from "react-redux";
import { getAllowedLanguagesUtil } from "redux/utils/transverse/CountryLang";
import Resources from './Resources';
import AboutPage from './Resources/AboutPage';
import Patients from "./Patients";
import Patient from "./Patient";
import useLocalStorage from "utils/transverse/useLocalStorage";
import localstoragekeys from "constants/localstoragekeys";
import { getAllProductsUtil } from "redux/utils/bodyshock/Products";
import ProtocolPage from './Resources/ProtocolPage';
import CenterMaterial from './Resources/CenterMaterial';
import { HelpPage } from "./HelpPage";
import Advices from "./Resources/Advices";
import AbdomenPage from "./Resources/AbdomenPage";
import ArmsPage from "./Resources/ArmsPage";
import ButtocksPage from "./Resources/ButtocksPage";
import BreastsPage from "./Resources/BreastsPage";
import LegsPage from "./Resources/LegsPage";
import ChinPage from "./Resources/ChinPage";

const Bodyshock = function ({ user, getAllowedLanguages, getAllProducts }) {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [selectedLanguage] = useLocalStorage(localstoragekeys.appLanguage);
  useEffect(() => {
    if (user.permissions) {
      if (!user.permissions.includes(apps.BODYSHOCK)) history.push(`${routes.selector}`);
    }
    getAllowedLanguages(apps.BODYSHOCK);
    getAllProducts(user.api_token, selectedLanguage);
  }, [user, history, getAllowedLanguages, getAllProducts, selectedLanguage]);
  return (
    <Switch>
      <Route path={`${url}/${routes.patient}/:id`}>
        <Patient />
      </Route>
      <Route path={`${url}/${routes.start}`}>
        <Questionnaire />
      </Route>
      <Route path={`${url}/${routes.resources}`}>
        <Resources />
      </Route>
      <Route path={`${url}/${routes.treatment}`}>
        <Treatment />
      </Route>
      <Route path={`${url}/${routes.newpatient}`}>
        <NewPatient />
      </Route>
      <Route path={`${url}/${routes.helpPage}`}>
        <HelpPage />
      </Route>
      <Route path={`${url}/${routes.legalNotice}`}>
        <LegalNotice />
      </Route>
      <Route path={`${url}/${routes.about}`}>
        <AboutPage />
      </Route>
      <Route path={`${url}/${routes.patients}`}>
        <Patients />
      </Route>
      <Route path={`${url}/${routes.protocol}`}>
        <ProtocolPage />
      </Route>
      <Route path={`${url}/${routes.material}`}>
        <CenterMaterial />
      </Route>
      <Route path={`${url}/${routes.advices}`}>
        <Advices />
      </Route>
      <Route path={`${url}/${routes.abdomen}`}>
        <AbdomenPage />
      </Route>
      <Route path={`${url}/${routes.arms}`}>
        <ArmsPage />
      </Route>
      <Route path={`${url}/${routes.buttocks}`}>
        <ButtocksPage />
      </Route>
      <Route path={`${url}/${routes.legs}`}>
        <LegsPage />
      </Route>
      <Route path={`${url}/${routes.breasts}`}>
        <BreastsPage />
      </Route>
      <Route path={`${url}/${routes.chin}`}>
        <ChinPage />
      </Route>
      <Route path={`/${apps.BODYSHOCK}`}>
        <Home />
      </Route>
    </Switch>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.data,
});
const mapDispatchToProps = (dispatch) => ({
  getAllowedLanguages: (app) => dispatch(getAllowedLanguagesUtil(app)),
  getAllProducts: (token, language) => dispatch(getAllProductsUtil(token, language)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Bodyshock);
