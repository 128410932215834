import styled from 'styled-components';
import back from './images/ico-back.svg';

export const RecommendedProductWrapper = styled.div`
    position: fixed;
    top: 65px;
    background: white;
    width: 100%;
    height: calc(100% - 65px);
    z-index: 3;
    padding: 0 !important;
    overflow-y: scroll;
    
    .header {
        padding: 15px 20px 17px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(248, 248, 248, 0.82);
        border-bottom: 1px solid #e8e9ea;

        .back {
            position: absolute;
            top: 10px;
            left: 20px;
            cursor: pointer;
        }
        img {
            border-radius: 6px;
            margin-bottom: 5px;
            @media(max-width: 520px) {
                max-width: 71px;
            }
        }
        h3 {
            margin: 0;
            font-size: 18px;
            line-height: 25px;
            font-family: hn-semibold;
            font-weight: 600;
        }
        .subtitle {
            margin: 0;
            font-size: 18px;
            line-height: 25px;
            font-family: hn-medium;
            font-weight: 400;
        }
    }
    .content {
        font-size: 12px;
        letter-spacing: 0.31px;
        line-height: 18px;
        padding: 30px 50px 50px;

        @media(max-width: 520px) {
            padding: 30px 20px 20px;
        }

        p {
            margin-top: 0;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            strong {
                font-family: hn-semibold;
                font-weight: 600;
            }
        }
    }
`;

export const Button = styled.div`
    background: black;
    color: white;
    max-width: 285px;
    height: 40px;
    font-family: hn-semibold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.34px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    width: 100%;
    @media (min-width: 1100px) {
        &:hover {
        text-decoration: underline;
        }
    }
`;

export const BackButton = styled.p `
  font-family: "hn-semibold", Arial;
  position: absolute;
  left: 60px;
  top: 15px;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: left;
  cursor: pointer;
  color: #989ea3;
  font-weight: 600;
  line-height: 23.4px;
  text-align: left;
  z-index:2;
  margin: 0;
  @media (max-width: 520px) {
    left: 20px;
    top: 10px;
    font-size: 10px;
  }

  &::before {
    content: "";
    background: url(${back}) no-repeat center;
    display: inline-block;
    width: 5px;
    height: 8px;
    margin-right: 5px;
  }
`;