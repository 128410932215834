import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { useHistory } from 'react-router';
import images from "./images/index";
import routes from "constants/routes";
import loadingIcon from "./images/loading.svg";


import {
  MiniBannerInner,
  MiniBannerSubtitle,
  MiniBannerTitle,
  MiniBannerWrapper,
  Wrapper,
  AboutPageWrapper,
  BottomCTAsWrapper,
  ProtocolsCTAs,
  LoadingResource
} from './style';

const AboutPage = ({ selectedLang, token }) => {
  const [t] = useTranslation('global');
  const history = useHistory();
  const [content, setContent] = useState("");

  document.title = 'About bodyshock diagnosis solutions';
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/pages`, {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
        params: { identifier: "about-bs" },
      })
      .then(({ data }) => setContent(data.results));
  }, [token, selectedLang]);
  if(content) {

    return (
      <Wrapper>
        <MiniBannerWrapper>
          <MiniBannerInner>
            <MiniBannerTitle>bodycare solutions</MiniBannerTitle>
            <MiniBannerSubtitle>
              {t('resourcesBodyshock.aboutPage.subtitle')}
            </MiniBannerSubtitle>
          </MiniBannerInner>
        </MiniBannerWrapper>
        <AboutPageWrapper>
          {parse(content, {
            replace: function (domNode) {
              if (domNode.name === "img") {
                domNode.attribs.src =
                  images[domNode.attribs.src.replace("{", "").replace("}", "")];
              }
            }
          })}
        </AboutPageWrapper>
        <BottomCTAsWrapper>
            <ProtocolsCTAs className='whiteButton'
              onClick={() => history.goBack()} 
            >
              bodyshock® resources
            </ProtocolsCTAs>
            <ProtocolsCTAs onClick={() => history.push(`${routes.homeBodyshock}`)} >
              home
            </ProtocolsCTAs>
        </BottomCTAsWrapper>
      </Wrapper>
    );
  }
  else {
    return(

      <LoadingResource>
        <img src={loadingIcon} alt="Loading Resource" />
      </LoadingResource>
    )
  }
};
const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});
export default connect(mapStateToProps, null)(AboutPage);
