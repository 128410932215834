import React, { useEffect, useState } from "react";
import { Overlay, ProductListWrapper, Button, Searcher } from "./style";
import { useTranslation } from "react-i18next";
import { ComponentCheck } from "components/EditHomeProtocol/styles";

export const ProductList = ({
  allProducts,
  closeEdit,
  products,
  handleSubmit,
  productCategory,
}) => {
  const [t] = useTranslation("global");
  const [searchValue, setSearchValue] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const copyFromProducts = JSON.parse(JSON.stringify(allProducts));
    const filteredProductsByActives = copyFromProducts.map((product) => {
      products.forEach((activeProduct) => {
        if (activeProduct.product_name?.trim().includes(product.product_name.trim())) product.exist = true;
      });
      return product;
    });
    const definitiveProducts = filteredProductsByActives.filter((product) => !product.exist);
    setProductOptions([...definitiveProducts]);
  }, [products, allProducts]);
  const handleClick = (product) => {
    setSelectedProducts([...selectedProducts, product]);
  };
  const onSubmit = () => {
    setLoading(true);
    products = [...products, ...selectedProducts];
    handleSubmit(products, productCategory);
    return setTimeout(() => {
      setLoading(false);
      closeEdit();
    }, 2500);
  };
  return (
    <>
      <Overlay onClick={() => closeEdit()}></Overlay>
      <ProductListWrapper>
        <div className="header">
          <h3>{t("editHome.productListBodyshock")}</h3>
          <span className="close" onClick={() => closeEdit()}></span>
        </div>
        <Searcher>
          <input
            name="search"
            type="text"
            placeholder={t("editHome.productListPlaceholder")}
            defaultValue={searchValue}
            onChange={(evt) => setSearchValue(evt.target.value)}
          />
        </Searcher>
        <div className="header-content">
          <div>
            <span>{t("editHome.brand")}</span>
            <span>{t("editHome.productName")}</span>
          </div>
        </div>

        <div className="content-products">
          <div>
            {productOptions
              .filter((product) =>
                (product.product_brand + product.product_name)
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
              )
              .map((product) => {
                const isActive = selectedProducts.some(
                  (selectedProduct) => selectedProduct.product_name === product.product_name
                );
                return (
                  <div
                    className={`product-content ${isActive ? "active" : ""}`}
                    onClick={() => handleClick(product)}
                    key={product.product_name}
                  >
                    <span>{product.product_brand}</span>
                    <span>{product.product_name}</span>
                  </div>
                );
              })}
              {productOptions.length === 0 && <p>{t("editHome.noProducts")}</p>}
          </div>
        </div>
        <div style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
          {loading ? (
            <ComponentCheck size="40px" color="black" />
          ) : (
            <Button onClick={onSubmit}>{t("editHome.save")}</Button>
          )}
        </div>
      </ProductListWrapper>
    </>
  );
};
