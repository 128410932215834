import styled, { css } from "styled-components";
import syringe from "./images/syringe.svg";
import antiwrinkle from "./images/antiwrinkle.svg";
import redensifying from "./images/redensyfing.svg";
import firming from "./images/firming.svg";
import brightening from "./images/brightening.svg";
import icoedit from "./images/icoedit.svg";
import icocalendar from "./images/icocalendar.svg";
const boostersIcons = {
  antiwrinkle: antiwrinkle,
  redensifying: redensifying,
  firming: firming,
  brightening: brightening,
};
export const TreatmentWrapper = styled.section`
  max-width: 930px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
  @media (max-width: 767px) {
    overflow-x: auto;
    .sessions:nth-child(6) {
      position: relative;
      ul {
        top: -190px;

        &::after,
        &::before {
          bottom: 100%;
          bottom: -13px;
          right: 16px;
          transform: rotate(180deg);
        }
      }
    }
  }
  @media print {
    max-width: none;
    overflow-x: visible;
  }
`;
export const TopTable = styled.div`
  display: grid;
  grid-template-columns: 20% 40% 5% 15% 15%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f8f8f8;
  background-color: #f2f2f2;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  margin-bottom: 5px;
  padding: 4px 0;
  @media print {
    grid-template-columns: 10% 50% 10% 15% 15%;
    border-bottom: none;
  }
  @media (max-width: 767px) {
    grid-template-columns: 25% 35% 5% 18% 18%;
    justify-content: flex-start;
    min-width: 560px;
    width: auto;
  }
`;
export const TopTableText = styled.span`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  line-height: 23.4px;
  text-align: center;
  text-transform: lowercase;
  @media print {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const PatientSessionWrapper = styled.div`
  position: relative;
  @media (max-width: 767px) {
    & > div {
      left: 10px;
      top: 35%;
      ul {
        left: 0;
      }
      ul::before,
      ul::after {
        left: 20px;
      }
    }
  }
`;
export const Session = styled.article`
  display: grid;
  grid-template-columns: 20% 40% 5% 15% 15%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding: 15px 0 26px;
  position: relative;
  opacity: 1;
  @media print {
    grid-template-columns: 10% 50% 10% 15% 15%;
    page-break-inside: avoid;
  }
  @media (max-width: 767px) {
    grid-template-columns: 25% 35% 5% 18% 18%;
    justify-content: flex-start;
    min-width: 560px;
    width: auto;
  }

  ${({ expired }) =>
    expired &&
    css`
      opacity: 0.5;
    `}
`;
export const SessionNumber = styled.p`
  color: #000000;
  font-family: "hn-semibold", Arial;
  text-transform: lowercase;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
`;
export const SessionQtys = styled.div`
  justify-self: center;
  ${({ full }) =>
    full
      ? css`
          width: 100%;
        `
      : ""}
`;
export const Plus = styled.span`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 12px;
`;
export const MaskWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #000000;
    font-family: "hn-medium", Arial;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 23.4px;
    text-align: center;
    margin: 0;
  }
`;
export const MaskIcon = styled.img`
  margin-top: 10px;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
`;
export const BoostersNames = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 30px;
  @media (max-width: 650px) {
    height: 45px;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;
export const Booster = styled.p`
  color: ${({ color }) => color};
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: flex;
  float: left;
  align-items: center;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: left;

  &:before {
    content: "";
    background-image: url(${({ icon }) => boostersIcons[icon.toLowerCase()]});
    width: 20px;
    height: 15px;
    margin-right: 5px;
    background-position: left;
    background-repeat: no-repeat;
    display: inline-block;
  }
  @media (max-width: 650px) {
    margin: 0;
    display: block;
    &:before {
      display: block;
      margin: 3px auto;
    }
  }
`;
export const BoostersProportions = styled.div`
  background-image: url(${syringe});
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  position: relative;
  width: 294px;
  height: 44px;
  background-repeat: no-repeat;
  margin: auto;
  @media (max-width: 650px) {
    width: 176px;
    height: 26px;
    background-size: contain;
    background-position: center;
  }
`;
export const BoostersQtys = styled.div`
  position: absolute;
  width: 142px;
  height: 30px;
  left: 76px;
  top: 6px;
  display: flex;
  @media (max-width: 650px) {
    width: 87px;
    height: 22px;
    left: 43px;
    top: 3px;
  }
`;
export const Mls = styled.span`
  height: 32px;
  border: 1px solid white;
  width: ${({ proportion }) => proportion}%;
  background-color: ${({ bgcolor }) => bgcolor};
  display: flex;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  color: #ffffff;
  font-family: "hn-medium", Arial;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.29px;
  line-height: 17.5px;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    height: 19px;
  }
`;
export const Actions = styled.div`
  text-align: left;
  position: absolute;
  top: 8px;
  right: 8px;
  @media print {
    display: none;
  }
  button {
    width: 27px;
    height: 27px;
    border-radius: 14px;
    color: #a6a9aa;
    border: 1px solid #a6a9aa;
    background-color: #ffffff;
    cursor: pointer;
    padding: 0;
    position: relative;
    span {
      font-weight: bold;
      position: absolute;
      top: -2px;
      width: 100%;
      left: 0;
      font-size: 18px;
    }
    :hover,
    .active {
      border: 1px solid #323e48;
      background-color: #323e48;
      color: white;
    }
  }
  ul {
    display: "none";
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    padding: 15px 0px;
    background: #f2f2f2;
    border: 1px solid #e2e2e2;
    width: 240px;
    right: 0;
    margin-right: 4px;
    z-index: 10;
    &::after,
    &::before {
      bottom: 100%;
      right: 16px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-color: rgba(242, 242, 242, 0);
      border-bottom-color: #f2f2f2;
      border-width: 5px;
      margin-left: -5px;
    }
    &::before {
      border-color: rgba(226, 226, 226, 0);
      border-bottom-color: #e2e2e2;
      border-width: 6px;
      margin-left: -6px;
    }

    li:first-child {
      border-top: 1px solid #e2e2e2;
    }
    li {
      background-color: white;
      border-bottom: 1px solid #e2e2e2;
      list-style: none;
      text-align: left;
      color: #000000;
      font-family: "hn-medium", Arial;
      font-size: 12px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.31px;
      line-height: 21px;
      padding: 10px 6px 10px 40px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: 14px center;
    }
    li.icocalendar {
      background-image: url(${icocalendar});
    }
    li.icoedit {
      background-image: url(${icoedit});
    }
  }
`;
export const DateWrapper = styled.div`
  p {
    color: #000000;
    font-family: "hn-semibold", Arial;
    font-size: 12px;
    line-height: 23.4px;
    text-align: center;
    margin: 0;
  }
`;
