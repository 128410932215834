import styled, { css } from "styled-components";
import oldlady from "./images/old-lady.png";
import downloadIcon from "../Treatment/images/downloadcta.svg";
import playIcon from "./images/icono-play.svg";
export const CenterMaterialWrapper = styled.main`
  .header {
    background-image: linear-gradient(to right, #b9b9ba 0%, #f5f5f5 100%);
    text-align: center;
    position: relative;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &::before {
      content: url(${oldlady});
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  h1 {
    color: #000000;
    font-family: "hn-light", Arial;
    font-size: 25px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
    text-align: center;
    margin: 0;
    z-index: 2;
    @media (max-width: 560px) {
      text-align: left;
      font-size: 18px;
      line-height: 19.8px;
    }
  }
  .subtitle {
    margin-top: 5px;
    color: #000000;
    font-family: "hn-semibold", Arial;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.36px;
    line-height: 26px;
    text-align: center;
    z-index: 2;
    @media (max-width: 560px) {
      text-align: left;
      font-size: 12px;
      line-height: 19.8px;
    }
  }
  .content {
    margin: 0 auto;
    header {
      text-align: center;
      padding: 40px 20px 20px;
      border-bottom: 1px solid #e2e2e2;
      p {
        color: #000000;
        font-family: "hn-medium", Arial;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.36px;
        line-height: 20px;
        text-align: center;
        @media (max-width: 560px) {
          font-size: 12px;
          line-height: 19.8px;
        }
      }
    }
    .material-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 40px;
      @media (max-width: 650px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
      @media (max-width: 400px) {
        grid-template-columns: 1fr;
        grid-gap: 10px;
      }
    }
    .section-title {
      color: #000000;
      font-family: "hn-light", Arial;
      font-size: 25px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: normal;
      line-height: 30px;
      text-align: center;
      margin: 25px auto 30px;
      @media (max-width: 560px) {
        font-size: 18px;
        line-height: 19.8px;
      }
    }
    .pdf-block {
      background-image: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
      padding-bottom: 40px;
    }
    .inner-block {
      max-width: 930px;
      width: 90%;
      padding: 0 20px;
      margin: auto;
    }
    .card {
      img {
        width: 100%;
        display: block;
      }
      p.download {
        color: #000000;
        font-family: "hn-medium", Arial;
        font-size: 10px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28px;
        text-align: left;
        border: 1px solid #323e48;
        background-color: #ffffff;
        margin: 0;
        text-align: center;
        z-index: 12;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::before {
          content: url(${downloadIcon});
          height: 19px;
          margin-right: 5px;
        }
      }
      &.video {
        .video-wrapper {
          position: relative;
          cursor: pointer;

          &::before {
            content: url(${playIcon});
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            z-index: 10;
          }
          &::after {
            content: "";
            width: 100%;
            position: absolute;
            height: 100%;
            top: 0;
            display: block;
            background-color: rgba(0, 0, 0, 0.4);
            left: 0;
          }
        }
        .video-info {
          border: 1px solid #e8e9ea;
          background-color: #ffffff;
          text-align: center;
          p:first-child {
            color: #000000;
            font-family: "hn-semibold", Arial;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: normal;
            line-height: 15px;
            text-align: center;
            margin-bottom: 3px;
          }
          p:last-child {
            margin-top: 0;
            font-family: "hn-medium", Arial;
            font-size: 10px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
          }
        }
      }
    }
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    max-width: 450px;
    margin: 20px auto 0;
    position: relative;
    @media (max-width: 500px) {
      max-width: 300px;
    }
    span:first-child {
      border-radius: 4px 0 0 4px;
    }
    span:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;
export const ActionsBtn = styled.span`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 18px;
  text-align: center;
  border: 1px solid #323e48;
  padding: 4px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background-color: #323e48;
      color: white;
    `}
`;
