import styled from "styled-components";
import icon_application from "./images/protocol/icon-circle.svg";
import icon_home from "./images/protocol/icon-home.svg";
import icon_calendar from "./images/protocol/icon-calendar.png";
import icon_briefcase from "./images/protocol/icon_briefcase.png";
import icon_week from "./images/protocol/icon-week.png";
export const ProtocolPageWrapper = styled.section`
  max-width: 930px;
  margin: 0 auto;

  .video-wrapper {
    border-bottom: 1px solid #e2e2e2;
    padding: 40px;
    div {
      max-width: 522px;
      margin: 20px auto 0;
    }
    p {
      max-width: 660px;
      margin: 0 auto;
      color: #000000;
      font-family: "hn-medium", Arial;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.36px;
      line-height: 20px;
      strong {
        font-family: "hn-semibold", Arial;
        font-weight: 600;
      }
    }
    @media (max-width: 766px) {
      padding: 40px 24px;
    }
  }

  h2,
  article {
    max-width: 730px;
    margin: 0 auto;
  }
  .icon {
    padding: 30px 0;
    &::before {
      content: "";
      display: inline-block;
      width: 26px;
      height: 25px;
      padding-left: 12px;
    }
    &.application {
      padding-bottom: 10px;

      &::before {
        background: url(${icon_application}) no-repeat left bottom;
      }
    }
    &.home::before {
      background: url(${icon_home}) no-repeat left bottom;
    }
    &.professional::before {
      background: url(${icon_briefcase}) no-repeat left bottom;
    }
  }
  .session-info {
    display: block;
    width: 100%;
    height: 60px;
    font-size: 17px;
    .sessions,
    .application {
      width: auto;
      position: relative;
      display: inline-block;
      padding: 20px 30px 0 48px;
      &::before {
        position: absolute;
        left: 0;
        bottom: -10px;
      }
    }

    .sessions::before {
      content: url(${icon_week});
    }
    .application::before {
      content: url(${icon_calendar});
    }
  }
  article {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      color: #000000;
      font-family: "hn-medium", Arial;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.31px;
      line-height: 20px;
      margin-top: 0;
      strong {
        font-family: "hn-semibold", Arial;
        font-weight: 600;
      }
      i {
        line-height: 17px;
      }
    }

    div:not(.images) {
      width: 50%;
      @media (max-width: 766px) {
        width: 100%;
      }
    }
    .images {
      width: 46%;
      text-align: end;
      alig-self: flex-start;
      div {
        width: 100%;
        padding-bottom: 20px;
        strong {
          display: block;
          font-family: "hn-semibold", Arial;
          font-size: 9px;
          line-height: 15px;
          padding-top: 10px;
        }
      }
      img {
        width: auto;
        vertical-align: bottom;
        &:nth-child(even) {
          padding-left: 20px;
        }
      }
    }
  }

  h2 {
    color: #000000;
    font-family: "hn-light", Arial;
    font-size: 25px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    line-height: 30px;
  }
  h3 {
    width: 100%;
    color: #000000;
    font-family: "hn-bold", Arial;
    font-size: 17px;
    font-weight: 700;
    line-height: 15px;
    text-transform: uppercase;
    border-bottom: 1px #000 dashed;
    padding: 14px 0;
  }

  @media (max-width: 766px) {
    h2,
    article {
      max-width: none;
      margin: 0 24px;
    }
    div.images {
      width: 100%;
      text-align: center;
    }

    .session-info {
      height: 96px;
      span {
        margin-top: 15px;
      }
    }
  }
`;
