import React from "react";
import { useTranslation } from "react-i18next";
import { PatientDataWrapper } from "./styles";

export default ({ patient, handleEdit, edit, application }) => {
  const [t] = useTranslation("global");
  return (
    <PatientDataWrapper className={application}>
      <div>
        <p>
          {t("register.name")}:{" "}
          <span>
            {patient.name}, {patient.last_name}
          </span>
        </p>
        <p>
          {t("register.email")}: <span>{patient.email}</span>
        </p>
        <p>
          {t("register.phoneNumber")}: <span>{patient.phone_number}</span>
        </p>
        <p>
          {t("register.address")}: <span>{patient.address || "-"}</span>
        </p>
        <p>
          {t("register.dob")}: <span>{patient.date_of_birth || "-"}</span>
        </p>
      </div>
      <div>
        <p>
          {t("register.other")}: <span onClick={() => handleEdit(!edit)}>{t("patients.edit")}</span>
        </p>
        <div>
          <p>{patient.considerations || "-"}</p>
        </div>
      </div>
    </PatientDataWrapper>
  );
};
