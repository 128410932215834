import React from "react";
import { Wrapper, Select, Label, ErrorBox } from "./style";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
const Dropdown = React.forwardRef(
  ({ name, type, onChange, value, error, label, children, ...props }, ref) => {
    const [t] = useTranslation("global");
    return (
      <Wrapper>
        <Select
          id={name}
          name={name}
          onChange={onChange}
          value={value}
          style={error && { border: "solid 1px red" }}
          ref={ref}
        >
          {children &&
            children.map((child) => (
              <option value={child} key={child}>
                {child}
              </option>
            ))}
        </Select>
        <Label htmlFor={name}>{label}</Label>
        {error && <ErrorBox>{t("errors.requiredField")}</ErrorBox>}
      </Wrapper>
    );
  }
);
Dropdown.propTypes = {
  name: propTypes.string,
  type: propTypes.string,
  onChange: propTypes.func,
  value: propTypes.string,
  error: propTypes.object,
  label: propTypes.string,
  chldren: propTypes.any,
};
export default Dropdown;
