import React, { useState } from "react";
import {
  LanguageOption,
  LanguageSelectorCTA,
  LanguageSelectorWrapper,
  Wrapper,
  LanguageOptionsWrapper,
  LanguageSelector,
  Overlay,
} from "./style";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import useOnclickOutside from "react-cool-onclickoutside";
const LangSelector = React.forwardRef(
  (
    { name, type, onClick, value, error, label, languages, children, ...props },
    ref
  ) => {
    const [t] = useTranslation("global");
    const [active, setActive] = useState(false);
    const referency = useOnclickOutside(() => {
      setActive(false);
    });
    return (
      <Wrapper>
        <Overlay active={active} />
        <LanguageSelectorWrapper>
          <LanguageSelector onClick={() => setActive(!active)}>
            {t("header.language")}:{" "}
            <LanguageSelectorCTA className={ active? 'active': ''}>{value}</LanguageSelectorCTA>
          </LanguageSelector>
        </LanguageSelectorWrapper>
        <LanguageOptionsWrapper ref={referency} active={active}>
          {languages &&
            languages.map((child) => (
              <LanguageOption
                onClick={() => {
                  onClick(child.iso_language);
                  setActive(!active);
                }}
                value={child.iso_language}
                key={child.iso_language}
              >
                {child.language}
              </LanguageOption>
            ))}
        </LanguageOptionsWrapper>
      </Wrapper>
    );
  }
);
LangSelector.propTypes = {
  name: propTypes.string,
  type: propTypes.string,
  onChange: propTypes.func,
  value: propTypes.string,
  error: propTypes.object,
  label: propTypes.string,
  chldren: propTypes.any,
};
export default LangSelector;
