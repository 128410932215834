import React, { useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import VideoModal from 'components/bodyshock/VideoModal';
import { useHistory } from 'react-router';
import routes from "constants/routes";
import loadingIcon from "./images/loading.svg";


import { 
  MiniBannerInner,
  MiniBannerSubtitle,
  MiniBannerTitle,
  MiniBannerWrapper,
  CenterMaterialWrapper, 
  Wrapper,
  ActionsBtn,
  ProtocolsCTAs,
  BottomCTAsWrapper,
  LoadingResource
} from './style';

const dateSortFunction = (a, b) => {
  if (a.iso_date < b.iso_date) {
    return -1;
  }
  if (a.iso_date > b.iso_date) {
    return 1;
  }
  return 0;
};
const CenterMaterial = function ({ selectedLang, token }) {
  const history = useHistory();
  const [t] = useTranslation('global');
  const [material, setMaterial] = useState({});
  const [section, setSection] = useState('commercial');
  const [video, setVideo] = useState('');

  const refToVideo = useRef();
  const refToVideoModal = () => {
    setTimeout(() => {
      refToVideo.current.scrollIntoView({
        behavior: 'smooth',
      });
    });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/bodyshock/resources`, {
        headers: {
          'Content-Language': selectedLang,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => setMaterial({ ...data.results }));
  }, [token, selectedLang]);

  return (
    <Wrapper>
      <MiniBannerWrapper>
        <MiniBannerInner>
          <MiniBannerTitle>bodycare solutions</MiniBannerTitle>
          <MiniBannerSubtitle>
          {t('resourcesBodyshock.aboutPage.subtitle')}
          </MiniBannerSubtitle>
        </MiniBannerInner>
      </MiniBannerWrapper>
      <CenterMaterialWrapper>
        <header>
          <p>
            <Trans t={t} i18nKey="resourcesBodyshock.materialPage.mainMessage">
              Descargue todo el material comercial y formativo <strong>bodyshock®</strong> para su centro:
            </Trans>
          </p>
          <div className="actions">
            <ActionsBtn
              active={section === 'commercial'}
              onClick={() => setSection('commercial')}
            >
              {t('resourcesBodyshock.materialPage.commercial')}
            </ActionsBtn>
            <ActionsBtn
              active={section === 'protocol'}
              onClick={() => setSection('protocol')}
            >
              {t('resourcesBodyshock.materialPage.protocol')}
            </ActionsBtn>
          </div>
        </header>
        <div className='line'></div>
        {material[section] ?
          <article>
            {material[section] && material[section].pdf && (
              <div className="pdf-block">
                <div className="inner-block">
                  <h2 className="section-title">
                    <Trans t={t} i18nKey="resourcesBodyshock.materialPage.titleCatalog">catálogo <strong>bodyshock®</strong></Trans>
                  </h2>
                  <div className="material-grid">
                    {material[section].pdf.sort(dateSortFunction).map((pdf) => (
                      <div key={pdf.id} className="card">
                        <img src={pdf.thumbnail} alt={`${section} pdf`} />
                        <p
                          onClick={() => window.open(pdf.url)}
                          className="download"
                        >
                          {t('resourcesBodyshock.materialPage.download')}<span>{pdf.iso_language}</span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {material[section] && material[section].folleto && (
              <div className="flyer-block">
                <div className="inner-block">
                  <h2 className="section-title">
                    <Trans t={t} i18nKey="resourcesBodyshock.materialPage.titleFlyer">folleto consumidor <strong>bodyshock®</strong></Trans>
                  </h2>
                  <div className="material-grid">
                    {material[section].folleto.sort(dateSortFunction).map((folleto) => (
                      <div key={folleto.id} className="card">
                        <img src={folleto.thumbnail} alt={`${section} pdf`} />
                        <p
                          onClick={() => window.open(folleto.url)}
                          className="download"
                        >
                          {t('resourcesBodyshock.materialPage.download')}<span>{folleto.iso_language}</span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {material[section] && material[section].video && (
              <div>
                <div className="inner-block">
                  <h2 className="section-title">
                    <Trans t={t} i18nKey="resourcesBodyshock.materialPage.titleVideo">vídeo protocolo <strong>bodyshock®</strong></Trans>
                  </h2>
                  <div className="material-grid">
                    {material[section].video
                      .sort(dateSortFunction)
                      .map((video) => (
                        <div key={video.id} className="card video">
                          <div
                            className="video-wrapper"
                            onClick={() => {
                              video.url
                              && video.url.includes('https://cloud.mesoestetic.com')
                                ? window.open(video.url)
                                : setVideo(video.url) && refToVideoModal();
                            }}
                          >
                            <img src={video.thumbnail} alt={`${section} video`} />
                          </div>
                          <div className="video-info">
                            <p>bodyshock  <span>{video.iso_language}</span> </p>
                            <p>
                              {t('resourcesBodyshock.materialPage.subtitlesLabel')} <span>{video.iso_language}</span>
                            </p>
                          </div>
                          {video.download_url && (
                            <p
                              onClick={() => window.open(video.download_url)}
                              className="download"
                            >
                              {t('resourcesBodyshock.materialPage.download')} <span>{video.iso_language}</span>
                            </p>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </article>
          :
          <LoadingResource>
            <img src={loadingIcon} alt="Loading Resource" />
          </LoadingResource>
        }
        {video && (
          <VideoModal
            refProp={refToVideo}
            videoId={video}
            close={() => setVideo('')}
          />
        )}
      </CenterMaterialWrapper>
      <BottomCTAsWrapper>
        <ProtocolsCTAs className='whiteButton'
          onClick={() => history.goBack()}
        >
          bodyshock® resources
        </ProtocolsCTAs>
        <ProtocolsCTAs
          protocolId
          onClick={() => history.push(`${routes.homeBodyshock}`)}
        >
          home
        </ProtocolsCTAs>
      </BottomCTAsWrapper>
    </Wrapper>
  );

};

CenterMaterial.propTypes = {
  selectedLang: propTypes.string,
  token: propTypes.string,
};
const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});

export default connect(mapStateToProps, null)(CenterMaterial);
