import mocked_es from "./mockedData-es.json";
import mocked_en from "./mockedData-en.json";
import mocked_fr from "./mockedData-fr.json";
import mocked_nl from "./mockedData-nl.json";
import mocked_pt from "./mockedData-pt.json";
import mocked_pl from "./mockedData-pl.json";
const mocks = { 
    es: mocked_es, 
    en: mocked_en, 
    fr: mocked_fr,
    nl: mocked_nl,
    pt: mocked_pt,
    pl: mocked_pl,
};
export default mocks;
