import styled from 'styled-components';

export const MassageWrapper = styled.div`
    @media print {
        display: none;
    }
    .content {
        max-width:970px;
        padding: 20px 20px 30px;
        margin: auto;

    }
    iframe {
        border: none;
        @media(max-width: 520px) {
            width: 100%;
        }
    }
    h3 {
        margin: 0;
        font-size: 14px;
        letter-spacing: 0.39px;
        line-height: 20px;
        font-family: hn-semibold;
        font-weight: 600;
        margin-top: 23px;
    }

`;
