import styled, { css } from "styled-components";

export const TestBarWrapper = styled.section`
  background-color: #323e48;
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  overflow: hidden;
  @media print {
    display: none;
  }
`;
export const BackButton = styled.p`
  color: #989ea3;
  font-family: "hn-semibold", Arial;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 23.4px;
  text-align: left;
  text-transform: lowercase;
  margin: 0;
  position: absolute;
  margin-left: 50px;
  @media (max-width: 930px) {
    margin-left: 10px;
  }
  cursor: pointer;
  padding: 3px 10px 3px 25px;
  text-transform: lowercase;
  &::before {
    content: "";
    width: 7px;
    height: 7px;
    border-left: 1px solid #5b646c;
    border-top: 1px solid #5b646c;
    transform: rotate(-48deg);
    margin-right: 5px;
    display: inline-block;
  }
`;
export const BarWrapper = styled.div`
  width: 100%;
  max-width: 540px;
  margin: auto;
  ${({ homeProtocol }) =>
    homeProtocol
      ? css`
          @media (max-width: 580px) {
            display: block;
            position: absolute;
            left: 120px;
            width: 540px;
            p:first-child {
              display: none;
            }
            p:nth-child(2) {
              display: none;
            }
            p {
              text-align: left !important;
            }
          }
        `
      : ""}
  ${({ professionalProtocol }) =>
    professionalProtocol
      ? css`
          @media (max-width: 580px) {
            display: block;
            position: absolute;
            left: 120px;
            width: 540px;
            p:first-child {
              display: none;
            }
            p:nth-child(2) {
              display: none;
            }
          }
        `
      : ""}
  ${({ test }) =>
    test
      ? css`
          @media (max-width: 580px) {
            position: absolute;
            left: 20px;
            width: 540px;
          }
        `
      : ""}
      ${({ testForm }) =>
    testForm
      ? css`
          @media (max-width: 580px) {
            position: absolute;
            left: 120px;
            width: 540px;
          }
        `
      : ""}
`;
export const BarTitles = styled.div`
  display: grid;

  grid-template-columns: repeat(${({ qty }) => qty || 4}, 1fr);
`;
export const BarTitle = styled.p`
  color: ${({ active }) => (active ? "#fff" : "#989ea3")};
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
  margin: 0 0 5px;
  cursor: pointer;
  &:last-child {
    text-align: right;
  }
  &:first-child {
    text-align: left;
  }
`;
export const BarContainer = styled.div`
  position: relative;
  height: 2px;
`;
export const BarBg = styled.div`
  background-color: #5b646c;
  width: 100%;
  border-radius: 1px;
  position: absolute;
  height: 2px;
  top: 0px;
  left: 0;
`;
export const Bar = styled.div`
  border-radius: 1px;
  position: absolute;
  top: 0px;
  left: 0;
  height: 2px;
  width: ${({ width }) => width || "20%"};
  background-color: #fff;
  z-index: 2;
  ${({ professionalProtocol }) =>
    professionalProtocol
      ? css`
          @media (max-width: 580px) {
            width: 25%;
          }
        `
      : ""}
  ${({ homeProtocol }) =>
    homeProtocol
      ? css`
          @media (max-width: 580px) {
            width: 100%;
          }
        `
      : ""}
`;
