import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { PolicyWrapper, ProtocolsCTAs, BottomCTAsWrapper, PolicyContent, BackButton } from "./styles";
import parse from "html-react-parser";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import routes from "constants/routes";




const NoticeLegalPage = ({ selectedLang, token }) => {
  const history = useHistory();
  const [content, setContent] = useState("");
  const [t] = useTranslation("global");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/pages`, {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
        params: { identifier: "legal-notice-bs" },
      })
      .then(({ data }) => setContent(data.results))
      .catch(console.error);
  }, [token, selectedLang]);
  return (
    <PolicyWrapper>
      <BackButton onClick={() => history.goBack()}>{t("productsBodyshock.back")}</BackButton>
      <PolicyContent>

        {parse(content)}
      </PolicyContent>

      <BottomCTAsWrapper>
        <ProtocolsCTAs 
          protocolId={true} 
          onClick={() => history.push(`${routes.homeBodyshock}`)} 
        >home
        </ProtocolsCTAs>
      </BottomCTAsWrapper>
    </PolicyWrapper>
  )

};


const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});

export default connect(mapStateToProps, null)(NoticeLegalPage);