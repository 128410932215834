import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {IMCWrapper} from "./style";

const IMC = ({ calculatedValue, handleBlur, values}) => {
    const [t] = useTranslation("global");
    const weightValue = (typeof values?.weight == 'number') ?  values?.weight : values?.weight?.value;
    const heightValue = (typeof values?.height == 'number') ?  values?.height : values?.height?.value;
    let result = 0;
    if((typeof values?.height == 'number') && (typeof values?.weight == 'number')) {
        const decimalHeight = heightValue / 100;
        result = (weightValue / (decimalHeight * decimalHeight)).toFixed(2);
    }
    const finalValue = Boolean(calculatedValue) ? calculatedValue.toFixed(2) : result;
    const isOver0 = finalValue > 0;
    const isOver20 = finalValue < 20;
    const isbetween20and25 = finalValue >= 20 && finalValue < 25;
    const isbetween25and30 = finalValue >= 25 && finalValue < 30;
    const isbetween30and40 = finalValue >= 30 && finalValue < 40;
    const isMoreThan40 = finalValue >= 40;
    const isLessThan80 = finalValue < 80;
    return (
        <IMCWrapper>
            <p>
                <label className="label">{t("questionnaire.weight")}</label>
                <input type="text" id="weight" name="weight" onBlur={handleBlur} defaultValue={weightValue}/>
                <span className="inputLabel">Kg</span>
            </p>
            <p>
                <label className="label">{t("questionnaire.height")}</label>
                <input
                  type="text"
                  name="height"
                  id="height"
                  onBlur={handleBlur}
                  onChange={handleBlur}
                  defaultValue={heightValue}
                />
                <span className="inputLabel">cm</span>
            </p>
            <div>
                <p>
                    <label className="label">
                        <Trans t={t} i18nKey="questionnaire.IMC">
                        <strong>Automatic calculation of BMI</strong> from weight and height:
                        </Trans>
                    </label>
                    <input
                        type="text"
                        value={ finalValue}
                        disabled
                        placeholder="-"
                    />
                    <span className="inputLabel">{t("questionnaire.IMCvalue")}</span>
                </p>
              </div>
              {isOver0 && isLessThan80 && (

                <div className="imcContainer">
                    <div className="imcValues">
                        <div className={isOver0 && isOver20 ? "active underweight" : "underweight"}>{"<"} 20</div>
                        <div className={isbetween20and25 ? "active healthy" : "healthy"}>20 - 25</div>
                        <div className={isbetween25and30 ? "active overweight" : "overweight"}>25 - 30</div>
                        <div className={isbetween30and40 ? "active obesity1" : "obesity1"}>30 - 40</div>
                        <div className={isMoreThan40 ? "active obesity2" : "obesity2"}>{">"} 40</div>
                    </div>
                    <div className="imcResults">
                        <p>
                            {isOver0 && isOver20 && (
                                <>
                                <span>{t("questionnaire.underweightText")}</span>:{" "} <strong>{t("questionnaire.underweight")}</strong>
                                </>
                            )}
                            {isbetween20and25 && (
                                <>
                                <span>{t("questionnaire.healthyText")}</span>:{" "} <strong>{t("questionnaire.healthy")}</strong>
                                </>
                            )}
                            {isbetween25and30 && (
                                <>
                                <span>{t("questionnaire.overweightText")}</span>:{" "} <strong>{t("questionnaire.overweight")}</strong>
                                </>
                            )}
                            {isbetween30and40 && (
                                <>
                                <span>{t("questionnaire.obesity1Text")}</span>:{" "} <strong>{t("questionnaire.obesity1")}</strong>
                                </>
                            )}
                            {isMoreThan40 && (
                                <>
                                <span>{t("questionnaire.obesity2Text")}</span>:{" "} <strong>{t("questionnaire.obesity2")}</strong>
                                </>
                            )}
                        </p>
                    </div>
                </div>
              )}
        </IMCWrapper>
    );
}
export default IMC;