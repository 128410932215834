import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { TermsConditionsWrapper, TermsConditionsContent, BackButton } from "./styles";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";


const TermsConditionPage = ({ selectedLang, token }) => {
  const [content, setContent] = useState("");
  const history = useHistory();
  const [t] = useTranslation("global");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/pages`, {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
        params: { identifier: "terms-condition" },
      })
      .then(({ data }) => setContent(data.results));
  }, [token, selectedLang]);
  return (
    <TermsConditionsWrapper>

      <BackButton onClick={() => history.goBack()}>{t("testBar.back")}</BackButton>

      <TermsConditionsContent>

        {parse(content)}
      </TermsConditionsContent>
    </TermsConditionsWrapper>

  )

};
const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});
export default connect(mapStateToProps, null)(TermsConditionPage);
