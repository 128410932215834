import React, { useEffect, useState } from "react";
import { Overlay, ApparatusEditWrapper, Button, Searcher } from "./style";
import { useTranslation } from "react-i18next";
import { ComponentCheck } from "components/EditHomeProtocol/styles";


export const ApparatusEdit = ({
    allProducts,
    closeEdit,
    apparatus,
    handleApparatus
}) => {
    const [t] = useTranslation("global");
    const [searchValue, setSearchValue] = useState("");
    const [productOptions, setProductOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const copyFromAllProducts = JSON.parse(JSON.stringify(allProducts));
        const settingProducts = copyFromAllProducts.map((product) => {
            apparatus.forEach((activeProduct) => {
                if (activeProduct.product_sku === product.product_sku) {
                    product.active = true;
                }
            });
            return product;
        });
        setProductOptions(settingProducts);
    }, [apparatus, allProducts, setProductOptions])



    const handleClick = (product) => {
        const copyFromProductOptions = JSON.parse(JSON.stringify(productOptions));
        copyFromProductOptions.forEach((copyProduct) => {
            if(copyProduct.product_sku === product.product_sku) {
                copyProduct.active = !copyProduct.active;
            }
        })
        setProductOptions(copyFromProductOptions);

    };
    const onSubmit = () => {
        setLoading(true);
        apparatus = productOptions.filter(product => product.active);

        handleApparatus(apparatus);
        return setTimeout(() => {
            setLoading(false);
            closeEdit();
        }, 2500);
    };
    return (
        <>
            <Overlay onClick={() => closeEdit()}></Overlay>
            <ApparatusEditWrapper>
                <div className="header">
                    <h3>{t("phase.listApparatus")}</h3>
                    <p className="subtitle">{t("phase.subtitle")}</p>
                    <span className="close" onClick={() => closeEdit()}></span>
                </div>
                <Searcher>
                    <input
                        name="search"
                        type="text"
                        placeholder={t("phase.searchProblem")}
                        defaultValue={searchValue}
                        onChange={(evt) => setSearchValue(evt.target.value)}
                    />
                </Searcher>
                <div className="content">
                    <div className="header-content">
                        <div>
                            <span>{t("products.product")}</span>
                            <span>{t("products.consultation")}</span>
                        </div>
                    </div>
                    <div className="content-products">
                        <div>
                            {productOptions
                                .filter((product) =>
                                    (product.product_name + product.product_concern)
                                        .toLowerCase()
                                        .includes(searchValue.toLowerCase())
                                )
                                .map((product) => {
                                    return (
                                        <div
                                            className={`product-content ${product.active ? "active" : ""}`}
                                            onClick={() => handleClick(product)}
                                            key={product.product_sku}
                                        >
                                            <span>{product.product_name}</span>
                                            <span>{product.product_concern}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                    {loading ? (
                        <ComponentCheck size="40px" color="black" />
                    ) : (
                        <Button
                            onClick={onSubmit}
                        >
                            {t("products.save")}
                        </Button>
                    )}
                </div>
            </ApparatusEditWrapper>
        </>
    );
};
