import styled from "styled-components";
import deleteImg from "./images/deleteImg.png";
import camera from "./images/camera-icon.svg";
import closeIcon from './images/ico-close.svg';

export const TracingWrapper = styled.div`
    > section {
        top: 5%;
        bottom:0;
        width: 100%;
        background-color: white;
        overflow-y: scroll;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.15);
        overflow-x: hidden;
        position: fixed;
        z-index: 3; 
    }
    .App-handle-container {
        width: 100%;
        height: 40px;
        top: 10px;
        position: absolute;
        z-index: 1;
        cursor: pointer;
     
      }
      .App-handle {
        margin: 0 auto;
     
        width: 36px;
        height: 5px;
        border-radius: 3px;
        background-color: #000000;
        opacity: 0.2;
        display: block;
        top: 6px;
      }
      .header {
        padding: 25px 20px 15px;
        background-color: white;
        border-bottom: 1px solid #e2e2e2;
     }
     h2 {
        margin: 0;
        text-align: center;
        font-size: 18px;
        font-family: hn-semibold, Arial;
        font-weight: 600;
        line-height: 26px;
  
      }
      .subtitle {
        margin: 0;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        @media(max-width: 520px) {
          font-size: 12px;
          letter-spacing: 0.39px;
          line-height: 12px;
        }
      }
      .close {
        background: url(${closeIcon}) no-repeat center;
        display: inline-block;
        width: 28px;
        height: 28px;
        position: absolute;
        top: -10px;
        left: 0;
        cursor: pointer;
        z-index: 3;
    }
    .separator {
        border-top: 1px solid #e2e2e2;
    }
    .titleBlock {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.39px;
        max-width: 500px;
        margin: 20px auto;
        @media(max-width: 520px) {
          margin: 18px 20px 10px;
        }
    }
    .otherData {
        max-width: 500px;
        margin: auto;
        width: 100%;
        .moredatacontent {
          div:nth-child(9) {
            padding-bottom: 12px;
            border-bottom: 1px solid #f2f2f2;
          }
          @media(max-width: 520px) {
            padding: 0 20px;
          }
        }
    }
    .fieldInput {
        font-family: "hn-medium", Arial;
        font-weight: 400;
        font-size: 14px;
        line-height: 23.4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 520px) {
            display: grid;
            grid-template-columns: 80px 160px 30px;
            justify-content: space-between;
            input {
                min-width: unset !important;
            }
            label.label {
                text-align: right;
            }
            .inputLabel {
                text-align: right;
                padding-left: 0;
            }
        }
        .label {
            width: 145px;
            margin-right: 8px;
            text-align: right;
            @media (max-width: 520px) {
              width: unset;
              text-align: right;
            }
        }
        .inputLabel {
            padding-left: 8px;
        }
        input {
            border: 1px solid #e8e9ea;
            border-radius: 2px;
            height: 45px;
            min-width: 270px;
            text-align: center;
            color: #000000;
            outline: none;
            &::placeholder {
                font-family: "hn-medium", Arial;
                font-weight: 400;
                font-size: 12px;
                color: #979da2;
            }
        }
    }

    .fieldInputFile {
        display: inline-block;
        width: 48%;
        margin-top: 12px;
        margin-bottom: 17px;
        &.frontal, &.lateral_izquierda {
          margin-right: 10px;
        }
        p {
          margin: 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .label {
          width: 100%;
          height: 105px;
          border: 1px dashed #e8e9ea;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #aeaeae;
          flex-direction: column;
          font-size: 12px;
          color: #979da2;
          text-align: center;
          @media (min-width: 520px) {
            padding-left: 20px;
            padding-right: 20px;
          }
          cursor: pointer;
          &::before {
            content: url(${camera});
            margin-bottom: 4px;
          }
          &.hide {
            border: none;
            visibility: hidden;
          }
          span {
            max-width: 116px;
          }
        }
        input[type="file"] {
          &.show {
            height: calc(100% - 25px);
          }
          height: 100%;
          opacity: 0;
          position: absolute;
          top: 0;
          z-index: 1;
          width: 100%;
          cursor: pointer;
        }
      
        .img-container {
          display: flex;
          position: absolute;
          top: 0;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        img {
          width: 100%;
          height: 100%;
          max-height: 105px;
          object-fit: contain;
          max-width: 95px;
        }
        .deleteImg {
          background: url(${deleteImg}) no-repeat center;
          width: 20px;
          height: 20px;
          margin-left: 5px;
          display: block;
          cursor: pointer;
        }
    }
    .buttonContainer {
        border-top: 1px solid #f7f7f7;
        padding: 30px 20px;
        display: flex;
        justify-content: center;
    }

`;

export const Button = styled.button`
  width: 100%;
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.34px;
  line-height: 20px;
  text-align: center;
  padding: 12px 20px;
  cursor: pointer;
  max-width: 345px;
  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }
`;