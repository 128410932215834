import React, { useEffect, useState } from "react";
import {
  PersonForm,
  PatientDataWrapper,
  PatientDataAdvice,
  DatePickerWrapper,
  PrivacyPolicyLabel,
  Title,
  ButtonSign,
} from "./style";
import { Label, ErrorBox } from "components/Input/style";
import PrivacyComponent from "components/Privacy";
import useOnclickOutside from "react-cool-onclickoutside";
import StringInput from "components/Input";
import { useInput } from "components/Input/input.hook";
import { useTranslation, Trans } from "react-i18next";
import { initTestAction } from "redux/actions/Patient";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import propTypes from "prop-types";
import TestBar from "components/antiaging/TestBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RequiredLegend } from "pages/transverse/Register/style";
import routes from "constants/routes";
import apps from "constants/apps";
import RedBar from "components/bodyshock/RedBar";
import axios from "axios";
import MaskedInput from 'react-text-mask';

import { completedPatient } from "mocks/bodyshock/patient";
const isDevelopmentMode = String(process.env.REACT_APP_DEVELOPER_MODE) === "active" ? true : false;
const getFormattedDOB = (dob) => {
  if (dob) {
    const dateParts = dob.toString().includes("-")
      ? dob.toString().split("-")
      : dob.toString().split("/");
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }
  return new Date();
};
const Home = function ({ initTest, selectedLang, patient, appSelected, token }) {
  const [t] = useTranslation("global");
  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();
  const [privacy, setPrivacy] = useState(false);
  const [dob, setDob] = useState((patient && getFormattedDOB(patient.date_of_birth)) || new Date());
  const [name, setName] = useInput(patient && patient.name);
  const [lastName, setLastName] = useInput(patient && patient.last_name);
  const [address, setAddress] = useInput(patient && patient.address);
  const [email, setEmail] = useInput(patient && patient.email);
  const [phoneNumber, setPhoneNumber] = useInput(patient && patient.phone_number);
  const [other, setOther] = useInput((patient && patient.considerations) || "");
  const [openPopup, setOpenPopup] = useState(false);
  const [signaturit, setSignaturit] = useState(false);
  const [openSignaturit, setOpenSignaturit] = useState(false);
  const [urlGenerated, setUrlGenerated] = useState(false);
  const [isSigned, setIsSigned] = useState(true);

  const referency = useOnclickOutside(() => {
    setOpenPopup(false);
  });
  document.title = "New patient ID test™";

  
  let dataSignaturit = {
    email: email,
    name: name,
    surname: lastName,
    city: address,
    application: appSelected,
    language: selectedLang
  }


  let inputs, index;

  const clearDataForm = () => {
    inputs = document.getElementsByTagName('input');
    for (index = 0; index < inputs.length; ++index) {
      inputs[index].value = "";
    }  
  }



  let idCheck = {
    id: signaturit.id
  }

  useEffect(() => {
    return () => {
      setUrlGenerated(false);

    };
  }, [email]);

  const sign =() => {
    setOpenSignaturit(true);

    if(!urlGenerated) {
        axios
        .post(`${process.env.REACT_APP_ANTIAGING_API}api/signaturit/create`, dataSignaturit, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setSignaturit(response.data);
          setUrlGenerated(true);
        })
        .catch(console.error);
    }

  }


  useEffect(() => {
    if (isDevelopmentMode) {
      initTest(completedPatient);
      history.push(`${routes.start}`);
    }
    clearDataForm();

  }, []);
  const onSubmit = () => {
    let formatted_date;
    if (dob) {
      const current_datetime = dob;
      formatted_date = `${current_datetime.getDate()}/${`0${current_datetime.getMonth() + 1}`.slice(
        -2
      )}/${current_datetime.getFullYear()}`;
    }
    initTest({
      name,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      address,
      date_of_birth: formatted_date,
      considerations: other,
    });
    history.push(`${routes.start}`);
  };
  return (
    <PatientDataWrapper
      className={appSelected === apps.ANTIAGING ? "wrapper-antiaging" : "wrapper-bodyshock"}
    >
      <PrivacyComponent
        openMenu={openPopup}
        onClick={() => setOpenPopup(!openPopup)}
        referency={referency}
      />
      {appSelected === apps.ANTIAGING ? (
        <TestBar history={history} width="20%" testForm />
      ) : (
        <RedBar />
      )}
      <Title> {appSelected === apps.ANTIAGING ? t("register.titlePage"): "bodyshock ID test™"}</Title>
      <PatientDataAdvice>{t("register.patientAdvice")}</PatientDataAdvice>
      {!isSigned && (
        <p style={{textAlign:"center"}}>No se ha firmado el documento</p>
      )}
      <PersonForm onSubmit={handleSubmit(onSubmit)}>
        <StringInput
          name="name"
          type="text"
          defaultValue={name}
          label={`${t("register.name")}*`}
          onChange={setName}
          ref={register({ required: true })}
          required
          error={errors.name}
        />
        <StringInput
          name="lastName"
          type="text"
          defaultValue={lastName}
          label={`${t("register.lastName")}*`}
          onChange={setLastName}
          ref={register({ required: true })}
          required
          error={errors.lastName}
        />
        <StringInput
          name="email"
          type="email"
          defaultValue={email}
          onChange={setEmail}
          label={`${t("register.email")}*`}
          ref={register({ required: true })}
          error={errors.email}
        />
        <StringInput
          name="phoneNumber"
          type="text"
          defaultValue={phoneNumber}
          onChange={setPhoneNumber}
          label={`${t("register.phoneNumber")}*`}
          ref={register({ required: true })}
          error={errors.phoneNumber}
        />
        <StringInput
          name="address"
          type="text"
          defaultValue={address}
          onChange={setAddress}
          label={`${t("register.address")}*`}
          ref={register({ required: true })}
          error={errors.address}
        />
        <DatePickerWrapper>
          <DatePicker
            name="dob"
            locale={selectedLang || "en"}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => setDob(date)}
            selected={dob}
            placeholderText="dd/mm/yyyy"
            customInput={<MaskedInput
              mask={[/\d/,/\d/,'/',/\d/,/\d/,'/',/\d/,/\d/,/\d/,/\d/]}
            />}
          />
          <Label htmlFor="dob">{`${t("register.dob")}*`}</Label>
        </DatePickerWrapper>
        <StringInput
          name="other"
          type="textarea"
          defaultValue={other}
          onChange={setOther}
          label={t("register.other")}
          ref={register({ required: false })}
          error={errors.other}
          limit={190}
        />
        <PrivacyPolicyLabel>
          <input
            type="checkbox"
            defaultValue={privacy}
            name="privacy"
            onChange={() => setPrivacy(!privacy)}
            ref={register({ required: true })}
          />
          <Trans t={t} i18nKey="register.privacyQuestionaire">
          <span>
              I read and accept the
              <span style={{ textDecoration: "underline" }} onClick={() => setOpenPopup(!openPopup)}>
                privacy policies
              </span>
          </span>
          </Trans>
        </PrivacyPolicyLabel>
        {errors.privacy && <ErrorBox>{t("errors.requiredField")}</ErrorBox>}

        <PrivacyPolicyLabel>
          <input
            type="checkbox"
            defaultValue={privacy}
            name="consent"
            ref={register({ required: true })}
          />
          <Trans t={t} i18nKey="register.consent">
            <span>Tengo consentimiento del paciente para introducir sus datos que serán únicamente utilizados para el funcionamiento de esta aplicación *</span>
          </Trans>
        </PrivacyPolicyLabel>
        {errors.consent && <ErrorBox>{t("errors.requiredField")}</ErrorBox>}

        <ButtonSign style={{ margin: "auto" }} type="submit">
          {t("register.submitButton")}
        </ButtonSign>

        <RequiredLegend>{t("register.required")}</RequiredLegend>
      </PersonForm>
    </PatientDataWrapper>
  );
};
Home.propTypes = {
  initTest: propTypes.func,
  patient: propTypes.object,
};
const mapDispatchToProps = (dispatch) => ({
  initTest: (patient) => dispatch(initTestAction(patient)),
});
const mapStateToProps = (state) => ({
  patient: state.patient.personalData,
  selectedLang: state.languages.selectedLanguage,
  appSelected: state.application,
  token: state.user.data.api_token,
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
