import { resetPatient, resetPatients, resetQuestionnaire } from "redux/actions/Patient"
import { resetProtocol } from "redux/actions/Protocols";
import { selectApplicationToStoreAction } from "redux/actions/transverse/Application";

export const changeApplicationUtil = (app) => (dispatch) => {
    dispatch(resetPatients())
    dispatch(resetPatient());
    dispatch(resetProtocol())
    dispatch(resetQuestionnaire());
    dispatch(selectApplicationToStoreAction(app))
}