import React from "react";
import { SwitcherWrapper, SwitcherBtn } from "./styles";
import pulsationsIcon from "./images/pulsations.svg";

export const QtySwitcher = ({ pulsations, setPulsations, t }) => {
  return (
    <SwitcherWrapper>
      <SwitcherBtn active={!pulsations} onClick={() => setPulsations(false)}>
        ml
      </SwitcherBtn>
      <SwitcherBtn
        active={pulsations}
        pulsation={true}
        onClick={() => setPulsations(true)}
      >
        {t("treatment.pulsations")}
      </SwitcherBtn>
      {pulsations && <img src={pulsationsIcon} alt="icon" />}
    </SwitcherWrapper>
  );
};
