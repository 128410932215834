import styled from "styled-components";
import closeIcon from "./images/ico-close.svg";
import boosterDelete from "./images/booster-delete.png";
import more from "./images/ico-more.svg";
import less from "./images/ico-less.svg";

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  z-index: 3;
  opacity: 0.4;
  top: 0;
  left: 0;
  cursor: pointer;
`;
export const PhaseEditWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  background: white;
  max-width: 464px;
  width: 90%;
  text-align: center;
  max-height: 95%;
  overflow-y: scroll;
  @media (max-width: 600px) {
    max-height: 90vh;
    overflow-y: scroll;
  }
  .errors {
    min-height: 20px;
    background-color: #ffe7e7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.26px;
    max-width: 400px;
    margin: 10px auto 0;
    p {
      margin: 0;
    }
  }
  .header {
    padding: 18px 20px 10px;
    background-color: white;
    border-bottom: 1px solid #e2e2e2;
  }
  h3 {
    margin: 0;
    text-align: center;
    font-size: 18px;
    font-family: hn-semibold, Arial;
    font-weight: 600;
    line-height: 22px;
    padding: 0 30px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    @media (max-width: 520px) {
      font-size: 12px;
      letter-spacing: 0.39px;
      line-height: 18px;
    }
  }
  .close {
    background: url(${closeIcon}) no-repeat center;
    display: inline-block;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 15px;
    left: 20px;
    cursor: pointer;
    z-index: 3;
  }
  .boosters-select {
    padding: 10px 10px 20px;
    border-bottom: 1px solid #f2f2f2;
    .info {
      margin: 0;
      text-align: center;
      font-size: 10px;
      line-height: 15px;
      margin-bottom: 15px;
    }
    .boosters {
      display: grid;
      max-width: 400px;
      grid-template-columns: repeat(4, 1fr);
      justify-content: center;
      column-gap: 12px;
      row-gap: 12px;
      margin: auto;
      @media (max-width: 520px) {
        grid-template-columns: repeat(2, 1fr);
      }
      .booster {
        border-radius: 5px;
        border: 1px solid #979da2;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;
        min-height: 70px;
        &.disabled {
          color: #d4d4d5;
          border: 1px solid #e8e9ea;
        }

        .booster-text {
          font-size: 12px;
          line-height: 15px;
          @media (max-width: 520px) {
            font-size: 10px;
            line-height: 12px;
          }
        }
      }
    }
  }
  .boosters-wrapper {
    padding: 10px 10px 12px 10px;
    border-bottom: 1px solid #f2f2f2;
    > div {
      display: grid;
      max-width: 400px;
      grid-template-columns: auto 20px;
      align-items: center;
      column-gap: 10px;
      margin: auto;
      margin-bottom: 8px;
      font-size: 14px;
      &:last-child {
        margin-bottom: 0px;
      }
      @media (max-width: 520px) {
        column-gap: 5px;
        font-size: 12px;
        letter-spacing: 0.39px;
        line-height: 14px;
      }
    }
    .booster-option {
      border-radius: 5px;
      border: 1px solid #e8e9ea;
      display: grid;
      grid-template-columns: auto 94px;
      column-gap: 15px;
      align-items: center;
      padding: 10px 10px;
      @media (max-width: 520px) {
        grid-template-columns: auto 80px;
        padding: 8px 7px;
        column-gap: 7px;
      }
      input {
        border: 1px solid #e8e9ea;
        width: 60px;
        height: 45px;
        text-align: center;
        font-family: hn-semibold;
        outline: none;
        color: #000;
        @media (max-width: 520px) {
          width: 50px;
          height: 40px;
        }
      }
      .booster-setting {
        display: grid;
        grid-template-columns: auto 60px 38px;
        align-items: center;
        column-gap: 6px;
        text-align: left;
        min-height: 45px;
        @media (max-width: 520px) {
          grid-template-columns: auto 50px 38px;
          column-gap: 5px;
        }
      }
    }
    .buttons {
      border: 1px solid #d25462;
      border-radius: 3px;
      display: flex;
      > div {
        width: 50%;
        height: 28px;
        cursor: pointer;
        @media (max-width: 520px) {
          height: 25px;
        }
      }
      .less {
        border-right: 1px solid #d25462;
        background: url(${less}) no-repeat center;
      }
      .more {
        background: url(${more}) no-repeat center;
      }
    }
  }
  .booster-detele {
    background: url(${boosterDelete}) no-repeat center;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;
export const Button = styled.div`
  background: black;
  color: white;
  max-width: 354px;
  height: 40px;
  font-family: hn-semibold;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.34px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
