import styled from "styled-components";
import antiwrinkle from "./images/antiwrinkle.svg";
import redensifying from "./images/redensyfing.svg";
import firming from "./images/firming.svg";
import brightening from "./images/brightening.svg";
import minus from "./images/minus-ico.svg";
const boostersIcons = {
  antiwrinkle: antiwrinkle,
  redensifying: redensifying,
  firming: firming,
  brightening: brightening,
};
export const MasksWrapper = styled.div`
  display: grid;
  grid-template-columns: 185px 185px;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
  grid-gap: 10px;
  margin: auto;
  margin-top: 20px;
`;
export const MaskUnit = styled.div`
  border: 1px solid #e2e2e2;
  ${({ active }) => active && "border: 1px solid black"};
  border-radius: 2px;
  text-align: center;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    font-size: 12px;
  }
`;
export const EditProfessionalProtocolWrapper = styled.section`
  @media print {
    display: none;
  }
  .inner {
    position: relative;
  }
  display: ${({ active }) => (active ? "block" : "none")};
  position: absolute;
  top: 15%;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  z-index: 10;
  width: 80%;
  max-width: 464px;
  background-color: #ffffff;
  padding-bottom: 25px;
  .header {
    margin-top: 10px;
    padding: 24px 35px;
    .advice {
      color: #323e48;
      font-family: "hn-medium", Arial;
      font-size: 10px;
      font-style: normal;
      letter-spacing: normal;
      line-height: 15px;
      text-align: center;
      span {
        display: block;
        font-family: "hn-semibold", Arial;
      }
    }
    .boosters-boxes {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      @media (max-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
      }
      grid-gap: 10px;
      > div {
        border: 1px solid #e2e2e2;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6px 5px;
        opacity: 0.3;
        cursor: pointer;
        &.active {
          opacity: 1;
        }
      }
      p {
        font-family: "hn-medium", Arial;
        font-size: 12px;
        font-style: normal;
        letter-spacing: normal;
        line-height: 23.4px;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
    .error {
      background-color: #ffe7e7;
      color: #000000;
      font-family: "hn-medium", Arial;
      font-size: 10px;
      font-style: normal;
      letter-spacing: 0.26px;
      line-height: 10px;
      text-align: center;
      margin: 10px auto;
      padding: 5px;
    }
  }
  .content {
    margin-bottom: 22px;
    padding: 0px 30px 22px 30px;
    border-bottom: 1px solid #e2e2e2;
  }
  h3 {
    color: #000000;
    font-family: "hn-light", Arial;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 25px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }
  .interline {
    background-color: #f2f2f2;
    color: #000000;
    font-family: "hn-medium", Arial;
    font-size: 10px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 23.4px;
    text-align: center;
  }
  .actions {
    width: 90%;
    max-width: 355px;
    margin: 0 auto;
    text-align: center;
    button {
      border: 1px solid #000000;
      padding: 10px;
      background-color: #000000;
      color: #ffffff;
      font-family: "hn-semibold", Arial;
      font-size: 14px;
      font-style: normal;
      letter-spacing: 0.34px;
      line-height: 20px;
      text-align: center;
      width: 100%;
      cursor: pointer;
      text-transform: lowercase;
    }
  }
  .proporciones {
    display: grid;
    grid-template-columns: 72% 16% 10%;
    grid-gap: 1%;
    .qtys {
      border-radius: 2px;
      border: 1px solid #000000;
      background-color: #ffffff;
      max-width: 300px;
      height: 45px;
      width: 100%;
      position: relative;
    }
  }
`;
export const Booster = styled.p`
  color: ${({ color }) => color};
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: flex;
  align-items: center;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: left;
  margin-bottom: 5px;
  &:before {
    content: "";
    background-image: url(${({ icon }) => boostersIcons[icon.toLowerCase()]});
    width: 20px;
    height: 15px;
    margin-right: 5px;
    background-position: left;
    background-repeat: no-repeat;
    display: inline-block;
  }
  @media (max-width: 650px) {
    display: block;
    &:before {
      display: block;
      margin: 3px 0;
    }
  }
`;
export const BoosterBox = styled.div`
  padding: 5px 0 10px;
  border-bottom: 1px solid #f2f2f2;
  .action {
    background-image: url(${minus});
    background-size: 15px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .qty-box {
    border-radius: 2px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      height: 100%;
      text-align: center;
      border: 0;
      cursor: default;
    }
  }
`;
export const BoosterQty = styled.input`
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  height: 41px;
  border-radius: 2px 0 0 2px;
  background-color: #ffffff;
  border: 1px solid #000000;
  max-width: 300px;
  position: relative;

  /*Chrome*/
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    & {
      overflow: hidden;
      -webkit-appearance: none;
      background-color: #fff;
    }

    &::-webkit-slider-runnable-track {
      height: 10px;
      -webkit-appearance: none;
      color: ${({ bgcolor }) => bgcolor};
      margin-top: -1px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: 1px solid #000000;
      height: 39px;
      width: 16px;
      border-radius: 3px;
      background: #ffffff;
      cursor: ew-resize;
      margin-top: -14px;
      box-shadow: -${({ proportions }) => proportions}px 0 0 ${({
          proportions,
        }) => proportions}px ${({ bgcolor }) => bgcolor};
    }
  }
  /** FF*/
  &::-moz-range-progress {
    background-color: ${({ bgcolor }) => bgcolor};
    height: 41px;
  }
  &::-moz-range-thumb {
    background-color: #fff;
    border: 1px solid #000000;
    height: 39px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: ew-resize;
  }
  /* IE*/
  &::-ms-fill-lower {
    background-color: ${({ bgcolor }) => bgcolor};
    height: 41px;
  }
  &::-ms-fill-upper {
    background-color: #fff;
    height: 41px;
  }
  &::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 39px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: ew-resize;
  }
`;
