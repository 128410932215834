import styled, {css} from 'styled-components';
import leftbg from './images/left-bg.png';
import download from './images/ico-download.svg';

/* Estilos para el protocolo domiciliario */
export const LoadingResource = styled.div`
  width: 100vw;
  height: 90vh;
  img {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
  }
`;

export const HomeTreatmentWrapper = styled.div`
  overflow-x: hidden;
  top: -36px;
  position: relative;
  z-index: 3;
  @media print {
    top: 0px !important;
    page-break-before: always;
    display: block;
    page-break-after: always;
    position: relative;
  }
  .home-treatment-wrapper {
    background-color: white;
    border-top: 1px solid #e2e2e2;
    > div {
      padding-bottom: 0;
      padding-top: 15px;
    }
  }

  .App-handle-container {
    width: 100%;
    height: 40px;
    top: 10px;
    position: absolute;
    z-index: 1;
    cursor: pointer;

  }
  .App-handle {
    margin: 0 auto;

    width: 36px;
    height: 5px;
    border-radius: 3px;
    background-color: #000000;
    opacity: 0.2;
    display: block;
    top: 6px;
  }
  .titleHomeProtocol {
    padding: 25px 20px 10px;
    margin: 0;
    text-align: center;
    font-size: 25px;
    font-family: hn-semibold;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
    border-bottom: 1px solid #F7F7F7;
    @media(max-width: 520px) {
      font-size: 18px;
      padding-top: 20px;
    }
  }
`;
export const BottomCTAsWrapper = styled.section`
  @media print {
    display: none;
  }
  background-color: white;
  width: 100%;
  display: grid;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const ProtocolsCTAs = styled.span`
  padding: 10px 15px;
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  border: 1px solid #000000;
  max-width: 250px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  text-transform: lowercase;
  max-width: 285px;
  width: 100%;
  ${({ protocolId }) =>
    protocolId &&
    css`
      border: 1px solid #000000;
      background-color: #000000;
      color: #ffffff;
      margin: 0px auto 40px;
      display: block;
    `}

  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const BackButton = styled.p `
  font-family: "hn-semibold", Arial;
  position: absolute;
  left: 60px;
  top: 15px;
  @media (max-width: 600px) {
    left: 20px;
    top: 5px;
  }
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
  padding-left: 0;
  cursor: pointer;
  color: #989ea3;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 23.4px;
  text-align: left;
  background: transparent;
  border: 0;
  z-index:2;
  margin: 0;
  padding: 10px 0 0;

  &::before {
    content: "";
    width: 7px;
    height: 7px;
    border-left: 1px solid #989ea3;
    border-top: 1px solid #989ea3;
    transform: rotate(-48deg);
    margin-right: 5px;
    display: inline-block;
  }
`;

export const ProtocolWrapper = styled.main`
    min-height: calc(100vh - 65px);
    @media print {
      min-height: initial;
      height: auto;
    }
    > header {
        border-bottom: 0;
    }
    .overlay-edit {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.4;
        z-index: 4;
        top: 0;
        cursor: pointer;
      }
    .professionalTreatmentWrapper {
        top: -35px;
        padding-top: 25px;
        padding-bottom: 10px;
        overflow: hidden;
        @media print {
          overflow: visible;
          position: relative;
          top: 0px !important;
          height: 100%;
          width: 100%;
        }
        @media(max-width: 520px) {
            top: -20px;
            padding-top: 8px;
        }
        .sessionsList {
          @media print {
            display: none;
          }
          @media(max-width: 520px) {
              > p {
                  font-size: 14px;
                  line-height: 18px;
                  letter-spacing: 0.39px;
              }
          }
          
          ul {
              height: 45vh;
              overflow-y: scroll;
          }
        } 
      }
`;

export const Header = styled.header`
    position: relative;
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
    @media print {
      display: none;
    }

    @media(max-width: 520px) {
        &:before {
            max-width: 320px;
            width: 100%;
        }
    }
    &:before {
        content: "";
        background: url(${leftbg}) no-repeat center;
        position: absolute;
        left: 0;
        top: -65px;
        z-index: -1;
        width: 375px;
        height: 175px;
        background-size: contain;
      }

    h1 {
        font-family: "hn-semibold", Arial;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0;
        @media(max-width: 520px) {
            line-height: 26px;
            font-size: 18px;
        }
    }
    .subtitle {
        font-family: hn-medium, Arial;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-top: 1px;
        margin-bottom: 0;
    }
    .Download {
        background: url(${download}) no-repeat center;
        display: inline-block;
        width: 16px;
        height: 17px;
        position: absolute;
        right: 47px;
        top: 40px;
    }
`;