import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { useHistory } from 'react-router';
import images from "./images/index";
import routes from "constants/routes";
import loadingIcon from "./images/loading.svg";


import {
  MiniBannerInner,
  MiniBannerSubtitle,
  MiniBannerTitle,
  MiniBannerWrapper,
  Wrapper,
  BottomCTAsWrapper,
  ProtocolsCTAs,
  LoadingResource
} from './style';
import {
  AdvicePageWrapper,  
} from './styleAdvices'

const AbdomenPage = ({ selectedLang, token }) => {
  const [t] = useTranslation('global');
  const history = useHistory();
  const [content, setContent] = useState("");
  const [openSteps, setOpenSteps] = useState(false);
  
  const openStepsfn = () => {
    setOpenSteps(!openSteps);
    document.getElementById('contentToggle').classList.toggle("active");
  }

  const openTab = (e) => {
    
    if(!e.target.classList.contains("active")) {
      let currentTabContent = document.getElementsByClassName(e.target.id)[0];
      let advices = document.querySelectorAll('.advice');
      let tabs = document.querySelectorAll('.tab');
      [].forEach.call(advices, function(advice) {
        advice.classList.remove("active");
      });
      [].forEach.call(tabs, function(tab) {
        tab.classList.remove("active");
      });
      e.target.classList.add("active");
      currentTabContent.classList.add("active");
    }
  }

  document.title = 'Legs bodyshock diagnosis solutions';
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/pages`, {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
        params: { identifier: "legs-bs" },
      })
      .then(({ data }) => setContent(data.results));
  }, [token, selectedLang]);
  if(content) {

    return (
      <Wrapper>
        <MiniBannerWrapper>
          <MiniBannerInner>
            <MiniBannerTitle>bodycare solutions</MiniBannerTitle>
            <MiniBannerSubtitle>
              {t('resourcesBodyshock.aboutPage.subtitle')}
            </MiniBannerSubtitle>
          </MiniBannerInner>
        </MiniBannerWrapper>
        <AdvicePageWrapper>
          
          {parse(content, {
            
            replace: function (domNode) {
              if (domNode.name === "img") {
                domNode.attribs.src =
                  images[domNode.attribs.src.replace("{", "").replace("}", "")];
              }

              if (domNode.attribs && domNode.attribs.id === "openManiobra") {
                return (
                    <p id="openManiobra" className={openSteps ? "active tab": "tab"}  onClick={() => openStepsfn()}>
                      {t('resourcesBodyshock.advicePage.openManiobraLegs')}
                    </p>
                );

              }

              if (domNode.attribs && domNode.attribs.id === "tab-first") {
                return (
                    <span id="tab-first" className="tab active"  onClick={(e) => openTab(e)}>
                      {t('resourcesBodyshock.advicePage.protocol')}
                    </span>
                );

              }

              if (domNode.attribs && domNode.attribs.id === "tab-last") {
                return (
                    <span id="tab-last" className="tab"  onClick={(e) => openTab(e)}>
                      {t('resourcesBodyshock.advicePage.indication')}
                    </span>
                );

              }

            }
          })}
        </AdvicePageWrapper>
        <BottomCTAsWrapper>
            <ProtocolsCTAs className='whiteButton'
              onClick={() => history.goBack()} 
            >
              {t('resourcesBodyshock.advicePage.seeTreatments')}
            </ProtocolsCTAs>
            <ProtocolsCTAs onClick={() => history.push(`${routes.homeBodyshock}`)} >
              home
            </ProtocolsCTAs>
        </BottomCTAsWrapper>
      </Wrapper>
    );
  }
  else {
    return(

      <LoadingResource>
        <img src={loadingIcon} alt="Loading Resource" />
      </LoadingResource>
    )
  }
};
const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});
export default connect(mapStateToProps, null)(AbdomenPage);
