import axios from "axios";
import { deletePatient, getPatients } from "../../actions/Patient";
import { errorProtocol, getProtocolsAction } from "../../actions/Protocols";

export const fetchPatientsUtil = (token) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => dispatch(getPatients(data.patients)))
    .catch(console.error);
};
export const deletePatientUtil = (token, userId) => (dispatch) => {
  axios
    .delete(
      `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(() => dispatch(deletePatient(userId)))
    .catch(console.error);
};
export const updatePatientUtil = (token, patient, id, selectedLang) => (dispatch) => {
  axios
    .put(
      `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${id}`,
      patient,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(() => {
      axios
        .get(
          `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${id}/protocol`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
          "Content-Language": selectedLang

            },
          }
        )
        .then(({ data }) => {
          dispatch(getProtocolsAction(data));
          dispatch(errorProtocol(null));
        });
    })
    .then(() =>
      axios
        .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => dispatch(getPatients(data.patients)))
    )
    .catch(console.error);
};
export const getPatientProtocolUtil = (patientId, token, selectedLang) => (dispatch) => {
  axios
    .get(
      `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${patientId}/protocol`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Language": selectedLang
        },
      }
    )
    .then(({ data }) => {
      dispatch(getProtocolsAction(data));
      dispatch(errorProtocol(null));
    });
};
export const savePatientProtocolUtil = (patientId, token, protocol, selectedLang) => (
  dispatch
) => {
  axios
    .put(
      `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${patientId}/protocol/${protocol.id}`,
      protocol,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Language": selectedLang
        },
      }
    )
    .then(({ data }) => {
      dispatch(getProtocolsAction(data));
      dispatch(errorProtocol(null));
    })
    .catch(console.error);
};
export const savePatientAppoinmentUtil = (
  patientId,
  token,
  fields,
  selectedLang
) => (dispatch) => {
  axios
    .post(
      `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${patientId}/appointment`,
      fields,
      {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      axios
        .get(
          `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${patientId}/protocol`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(({ data }) => {
          dispatch(getProtocolsAction(data));
          dispatch(errorProtocol(null));
        });
    })
    .catch(console.error);
};
export const updatePatientAppoinmentUtil = (
  patientId,
  token,
  fields,
  selectedLang
) => (dispatch) => {
  axios
    .put(
      `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${patientId}/appointment`,
      fields,
      {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      axios
        .get(
          `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/patient/${patientId}/protocol`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(({ data }) => {
          dispatch(getProtocolsAction(data));
          dispatch(errorProtocol(null));
        });
    })
    .catch(console.error);
};
