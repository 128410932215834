import React from "react";
import { 
    ApplianceWrapper,
} from './style';
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const Appliance = ({
    slideIndex,
    name,
    concern,
    description,
    image
}) => {
    const $urlServer = `${process.env.REACT_APP_ANTIAGING_API}storage/bodyshockimages/`;
    const [t] = useTranslation("global");
    return (
        <ApplianceWrapper className="swiper-Slide swiper-slide swiper-apparatus"  data-index={slideIndex} data-type={t("treatment.subtitleApparatus")}>
            <div className="content">
                <img src={`${$urlServer}${image}`} alt={`${name}`} />
                <h3>{name}</h3>
                <span className="concern">{concern}</span>
                <div className="description">
                    {parse(description)}
                </div>
            </div>

        </ApplianceWrapper>

    )

};

const mapStateToProps = (state) => ({
    allProducts: state.products.bodyshock,
});

export default connect(mapStateToProps, null)(Appliance);