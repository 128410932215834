import styled from "styled-components";

export const VideoWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  width: 80%;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  top: 15%;
  z-index: 100;
  &:before {
    background-color: black;
    width: 100%;
    height: 100%;
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.3;
    z-index: -1;
  }
  .iframe-wrapper {
    z-index: 10;
  }
  article {
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    > span {
        cursor: pointer;
    }
  }
`;
