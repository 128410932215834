import styled from 'styled-components';
import closeIcon from './images/ico-close.svg';
import check from './images/check.svg';
import search from './images/lupa.svg';


export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    position: fixed;
    z-index: 3;
    opacity: 0.3;
    top: 0;
    left: 0;
    cursor: pointer;
`;
export const ProductListWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 4;
    background: white;
    max-width: 464px;
    width: 90%;
    text-align: center;
    @media(max-width: 600px) {
        max-height: 620px;
        overflow-y: scroll;
    }

    .header {
        padding: 30px 20px 10px;
        background-color: white;
    }
    h3 {
        margin: 0;
        text-align: center;
        font-size: 25px;
        line-height: 30px;
        font-family: hn-light;
        font-weight: 300;
    }
    .close {
        background: url(${closeIcon}) no-repeat center;
        display: inline-block;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 15px;
        left: 20px;
        cursor: pointer;
        z-index: 3;
    }
    .header-content {
        border-bottom: 2px solid #f2f2f2;
        margin-top: 24px;
        > div {
            font-size: 10px;
            line-height: 18px;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 380px;
            margin: auto;
            span {
                text-align: left;
                &:first-child {
                    padding-left: 14px;
                }
            }
        }
    }
    .content-products {
        border-bottom: 1px solid #f2f2f2;
        font-size: 14px;
        line-height: 19px;
        padding-left: 20px;
        padding-right: 20px;

        > div {
            margin-top: 13px;
            margin-bottom: 20px;
        }
        .product-content {
            border-radius: 2px;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 380px;
            margin: auto;
            margin-bottom: 10px;
            align-items: center;
            text-align: left;
            padding: 0px 14px;
            cursor: pointer;
            border: 1px solid #000000;
            opacity: 0.27;
            position: relative;
            min-height: 45px;
            &.active {
                opacity: 1;
                cursor: pointer;
                pointer-events: unset;
                span:last-child {
                    padding-right: 14px;
                }
                &:after {
                    content: '';
                    display: inline-block;
                    background: url(${check}) no-repeat center;
                    position: absolute;
                    width: 12px;
                    height: 9px;
                    top: 18px;
                    right: 13px;
                }
            }
        }
    }
`;
export const Button = styled.div`
    background: black;
    color: white;
    max-width: 354px;
    height: 40px;
    font-family: hn-semibold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.34px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    @media (min-width: 1100px) {
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const Searcher = styled.div`
  text-align: center;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  input {
    position: relative;
    background-image: url(${search});
    background-position: right 14px;
    background-repeat: no-repeat;
    border-width: 0 0 1px 0;
    border-color: #4a4a4a;
    background-color: transparent;
    padding: 18px 40px 0px 0;
    width: 100%;
    max-width: 380px;
    font-size: 14px;
    line-height: 26px;
    &::placeholder {
      color: #979da2;
      opacity: 1;
    }
  }
  input:focus-within {
    border-width: 0 0 1px 0;
    outline: none;
  }
  &::before {
    content: "";
    background-color: #f2f2f2;
    width: 100%;
    position: absolute;
    left: 0;
    height: 60px;
    border-bottom: 1px solid #e2e2e2;
  }
`;