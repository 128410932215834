import React, { useState, useEffect } from "react";
import { Overlay, ProductAdvicesExerciseWrapper, Button } from "./style";
import { useTranslation } from "react-i18next";
import { ComponentCheck } from "components/EditHomeProtocol/styles";

export const ProductAdvicesExercise = ({
    closeEdit,
    allProductsAdvices,
    allProductsExercise,
    productsAdvices,
    productsExercise,
    handleAdvices
}) => {
    const [t] = useTranslation("global");
    const [productOptionsAdvices, setProductOptionsAdvices] = useState([]);
    const [productOptionsExercises, setProductOptionsExercises] = useState([]);

    const [loading, setLoading] = useState(false);
    const [advices, setAdvices] = useState(true);
    const [exercise, setExercise] = useState(false);

    useEffect(() => {
            const copyFromAllProductsAdvices = JSON.parse(JSON.stringify(allProductsAdvices));
            const copyFromAllProductsExercise = JSON.parse(JSON.stringify(allProductsExercise));
            const settingProductsAdvices = copyFromAllProductsAdvices.map((product) => {
                productsAdvices.forEach((activeProduct) => {
                    if (activeProduct.product_sku === product.product_sku) {
                        product.active = true;
                    }
                });
                return product;
            });
            setProductOptionsAdvices(settingProductsAdvices);

            const settingProductsExercise = copyFromAllProductsExercise.map((product) => {
                productsExercise.forEach((activeProduct) => {
                    if (activeProduct.product_sku === product.product_sku) {
                        product.active = true;
                    }
                });
                return product;
            });
            setProductOptionsExercises(settingProductsExercise);
        }, 
        [   productsExercise, 
            productsAdvices, 
            allProductsAdvices, 
            allProductsExercise, 
            setProductOptionsAdvices, 
            setProductOptionsExercises
        ]
    );

    const openAdvices = () => {
        setAdvices(true);
        setExercise(false);
    }
    const openExercise= () => {
        setAdvices(false);
        setExercise(true);
    }

    const handleClick = (product, type) => {
        if(type === "advices") {
            const copyFromProductOptionsAdvices = JSON.parse(JSON.stringify(productOptionsAdvices));
            copyFromProductOptionsAdvices.forEach((copyProduct) => {
                if(copyProduct.product_sku === product.product_sku) {
                    copyProduct.active = !copyProduct.active;
                }
            })
            setProductOptionsAdvices(copyFromProductOptionsAdvices);
        }
        else if(type === "exercise") {
            const copyFromProductOptionsExercises = JSON.parse(JSON.stringify(productOptionsExercises));
            copyFromProductOptionsExercises.forEach((copyProduct) => {
                if(copyProduct.product_sku === product.product_sku) {
                    copyProduct.active = !copyProduct.active;
                }
            })
            setProductOptionsExercises(copyFromProductOptionsExercises);
        }

    };

    const onSubmit = () => {
        setLoading(true);
        productsAdvices = productOptionsAdvices.filter(product => product.active);
        productsExercise = productOptionsExercises.filter(product => product.active);

        handleAdvices(productsAdvices, productsExercise);
        return setTimeout(() => {
            setLoading(false);
            closeEdit();
        }, 2500);
    };

    return (
        <>
            <Overlay onClick={() => closeEdit()}></Overlay>
            <ProductAdvicesExerciseWrapper>
                <div className="header">
                    <h3>{t("editHome.recommendations")}</h3>
                    <p className="subtitle">{t("editHome.editReasons")}</p>
                    <span className="close" onClick={() => closeEdit()}></span>
                </div>
                <div className="content-tabs">
                    <div className="tabs">
                        <span 
                            onClick={() => openAdvices()} 
                            className={`tab-advices tab ${advices ? "active" : ""}`}
                            >
                                {t("editHome.lifeHabits")}
                        </span>
                        <span 
                            onClick={() => openExercise()} 
                            className={`tab-exercise tab ${exercise ? "active" : ""}`}
                            >
                                {t("editHome.physicalExercise")}
                        </span>
                    </div>
                </div>
                <div className="content-products">
                    {advices &&
                        <div className="advices">
                            {productOptionsAdvices
                                .map((product) => {
                                    return (
                                        <div
                                            className={`product-content ${product.active ? "active" : ""}`}
                                            onClick={() => handleClick(product, "advices")}
                                            key={product.product_sku}
                                        >
                                            <span>{product.product_concern}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                    {exercise &&
                        <div className="exercise">
                            {productOptionsExercises
                                .map((product) => {
                                    return (
                                        <div
                                        className={`product-content ${product.active ? "active" : ""}`}
                                        onClick={() => handleClick(product,"exercise")}
                                            key={product.product_sku}
                                        >
                                            <span>{product.product_concern}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
                <div style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                    {loading ? (
                        <ComponentCheck size="40px" color="black" />
                    ) : (
                        <Button
                        onClick={onSubmit}
                        >   {advices && `${t("products.save")} ${t("editHome.lifeHabits")}`}
                           {exercise && `${t("products.save")} ${t("editHome.physicalExercise")}`}
                        </Button>
                    )}
                </div>
            </ProductAdvicesExerciseWrapper>

        </>
    );
};