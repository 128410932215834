import styled from "styled-components";

import img from "./images/bg-appselector.jpg";
export const AppSelectorWrapper = styled.main`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  moz-box-align: center;
  align-items: center;
  flex-direction: column;
  moz-box-pack: flex-start;
  justify-content: flex-start;
  min-height: calc(100vh - 65px);
`;
export const AntiagingSelected = styled.div`
  color: #000;
`;
export const BodyshockSelected = styled.div`
  color: #bd3d4b;
`;
export const PigmentControlSelected = styled.a`
  
`;

export const ContentSelector = styled.div`
  width:100%;
  max-width: 900px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px;
  .pigmentcontrol  {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: #B1B1B1;
    .title {
      font-family: "hn-bold",Arial;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 20px;
      margin-top: 0;
      margin-bottom: 4px;
      text-align: center;
    }
  }

  @media (max-width: 850px) {
    max-width: 590px;
    grid-template-columns: auto auto;
  }
  @media (max-width: 650px) {
    padding: 10px;
    gap: 20px;
    grid-template-columns: auto;
    justify-content: center;
  }

  .disabled {
    position: relative;
    border: none;
    cursor: inherit;
    pointer-events: none;
    @media(min-width: 521px) {

      &:hover {
        border: none;
  
      }
    }

    span {
      position: absolute;
      left: 15px;
      top: 15px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 10px;
      font-family: "hn-semibold", Arial;
      font-weight: 600;
      background-color: #323e48;
      color: white;
      padding: 4px 5px;
    }
    .title {
      color: #e8e9ea;
    }
  }
  > div {
    border: 1px solid #979DA2;
    background-color: white;
    display: flex;
    flex-direction: column;
    -moz-box-align: center;
    align-items: center;
    padding: 20px;
    justify-content: center;
    width: 280px;
    height: 300px;
    cursor: pointer;
    @media(min-width: 521px) {

      &:hover {
        border: 1px solid #000;
  
      }
    }
    @media(max-width: 520px) {
      height: 220px;
    }
    
    > div {
      text-align: center;
      .title {
        font-family: "hn-bold", Arial;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 20px;
        margin-top: 0;
        margin-bottom: 4px;
      }
    }
  }
`;
export const WelcomeText = styled.div`
  text-align: center;
  padding-top: 6%;
  padding-bottom: 5%;
  @media(min-width: 800px) {
    padding-top: 15%
  }
  @media(min-width: 1024px) {
    padding-top: 11%
  }
  @media(min-width: 1200px) {
    padding-top: 9%
  }
  h1 {
    font-family: "hn-light", Arial;
    font-weight: 300;
    font-size: 25px;
    line-height: 25px;
    margin-top: 0;
    margin-bottom: 3px;
    
    strong {
      font-family: "hn-semibold", Arial;
    }
  }

  p {
    font-family: "hn-semibold", Arial;
    letter-spacing: 0.36px;
    font-size: 14px;
    line-height: 26px;
    margin-top: 0;
  }
`;

export const IconImg = styled.img`
  margin-bottom: 15px;
`;