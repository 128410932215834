import {
    GETNOTIFICATION,
    RESETNOTIFICATION,
} from "../constants/Notification";

export default (state = { exist: false, title: "", image: false, content: false }, action) => {
    switch (action.type) {
        case GETNOTIFICATION:
            return Object.assign({}, { ...state, exist: true }, action.payload);
        // return Object.assign({ exist: true }, ...state, action.payload);
        case RESETNOTIFICATION:
            return Object.assign({}, { exist: false, title: "", image: false, content: false });
        default:
            return state;
    }
}