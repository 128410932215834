import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import global_pt from "./translations/pt/global.json";
import global_fr from "./translations/fr/global.json";
import global_pl from "./translations/pl/global.json";
import global_it from "./translations/it/global.json";
import global_de from "./translations/de/global.json";
import global_nl from "./translations/nl/global.json";
import global_ru from "./translations/ru/global.json";
import global_tr from "./translations/tr/global.json";
import privacy_es from "./translations/es/privacy.json";
import privacy_en from "./translations/en/privacy.json";
import privacy_fr from "./translations/fr/privacy.json";
import privacy_pt from "./translations/pt/privacy.json";
import privacy_ru from "./translations/ru/privacy.json";
import privacy_tr from "./translations/tr/privacy.json";
import centerMaterial_es from "./translations/es/pages/centermaterial.json";
import centerMaterial_de from "./translations/de/pages/centermaterial.json";
import centerMaterial_en from "./translations/en/pages/centermaterial.json";
import centerMaterial_fr from "./translations/fr/pages/centermaterial.json";
import centerMaterial_pl from "./translations/pl/pages/centermaterial.json";
import centerMaterial_pt from "./translations/pt/pages/centermaterial.json";
import centerMaterial_it from "./translations/it/pages/centermaterial.json";
import centerMaterial_nl from "./translations/nl/pages/centermaterial.json";
import centerMaterial_ru from "./translations/ru/pages/centermaterial.json";
import centerMaterial_tr from "./translations/tr/pages/centermaterial.json";
import { Provider } from "react-redux";
import store from "./redux/index";
import Geocode from "react-geocode";
import SwiperCore, {
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
} from "swiper";
SwiperCore.use([
  Virtual,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
  Parallax,
  Zoom,
  Lazy,
  Controller,
  A11y,
  History,
  HashNavigation,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectFlip,
  EffectCoverflow,
  Thumbs,
]);
Geocode.setApiKey("AIzaSyCJvmD2cNKHUryJQMp37prQBUzKPyyNDho");
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: "en",
    transSupportBasicHtmlNodes: true,
    resources: {
      es: {
        global: global_es,
        privacy: privacy_es,
        material: centerMaterial_es,
      },
      en: {
        global: global_en,
        privacy: privacy_en,
        material: centerMaterial_en,
      },
      pt: {
        global: global_pt,
        privacy: privacy_pt,
        material: centerMaterial_pt,
      },
      fr: {
        global: global_fr,
        privacy: privacy_fr,
        material: centerMaterial_fr,
      },
      pl: {
        global: global_pl,
        privacy: privacy_en,
        material: centerMaterial_pl,
      },
      it: {
        global: global_it,
        privacy: privacy_en,
        material: centerMaterial_it,
      },
      de: {
        global: global_de,
        privacy: privacy_en,
        material: centerMaterial_de,
      },
      nl: {
        global: global_nl,
        privacy: privacy_en,
        material: centerMaterial_nl,
      },
      ru: {
        global: global_ru,
        privacy: privacy_ru,
        material: centerMaterial_ru,
      },
      tr: {
        global: global_tr,
        privacy: privacy_tr,
        material: centerMaterial_tr,
      }
    },
    react: {
      useSuspense: true,
    },
  });
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
