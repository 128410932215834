import React, {useState } from "react";

export const ActionsComponent = function ({
  setEditablePatient,
  deletePatientAndResetList,
  patient,
  t,
}) {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open)
  }
  return (
    <>
      <span className={open? "active" : ""} onClick={toggle}>
      </span>
      <ul style={{ display: open ? "block" : "none" }} >
        <li
          className="icoedit"
          onClick={() => {
            setEditablePatient(patient);
            setOpen(false);
          }}
        >
          {t("patients.editPatient")}
        </li>
        <li
          className="icotrash"
          onClick={() => {
            deletePatientAndResetList(patient.id);
            setOpen(false);
          }}
        >
          {t("patients.removePatient")}
        </li>
      </ul>
    </>
  );
};
