import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { ProtocolPageWrapper } from "./styleProtocolPage";
import images from "./images/index";
import { Fragment } from "react";
import {
  ProtocolsCTAs,
  BottomCTAsWrapper,
} from "pages/antiaging/Patient/styles";
import {
  MiniBannerInner,
  MiniBannerSubtitle,
  MiniBannerTitle,
  MiniBannerWrapper,
} from "./style";
import { useHistory } from "react-router";

const ProtocolPage = ({ selectedLang, token }) => {
  const history = useHistory();
  const [t] = useTranslation("global");
  const [content, setContent] = useState("");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/pages`, {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
        params: { identifier: "protocol" },
      })
      .then(({ data }) => setContent(data.results));
  }, [token, selectedLang]);
  return (
    <Fragment>
      <MiniBannerWrapper>
        <MiniBannerInner>
          <MiniBannerTitle>age element</MiniBannerTitle>
          <MiniBannerSubtitle>
            {t("resources.aboutPage.subtitle")}
          </MiniBannerSubtitle>
        </MiniBannerInner>
      </MiniBannerWrapper>
      <ProtocolPageWrapper>
        {parse(content, {
          replace: function (domNode) {
            if (domNode.name === "img") {
              domNode.attribs.src =
                images[domNode.attribs.src.replace("{", "").replace("}", "")];
            }
          },
        })}
      </ProtocolPageWrapper>
      <BottomCTAsWrapper>
        <ProtocolsCTAs onClick={() => history.push(`/antiaging/resources`)}>
          {t("home.resourceAction")}
        </ProtocolsCTAs>
        <ProtocolsCTAs
          protocolId={true}
          onClick={() => history.push(`/antiaging`)}
        >
          {t("treatment.home")}
        </ProtocolsCTAs>
      </BottomCTAsWrapper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});

export default connect(mapStateToProps, null)(ProtocolPage);
