import styled from "styled-components";
import clock from "./images/clock.svg";
export const ProtocolHome = styled.div`
  max-width: 930px;
  margin: auto;
  border-bottom: 1px solid #e2e2e2;
  padding-top: 20px;
  @media print {
    border: 0;
    padding-top: 0;
  }
`;
export const RangeProductCard = styled.article`
  width: 387px;
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 640px) {
    width: 100%;
    max-width: 320px;
    margin: auto;
  }
  height: 218px;
  border-radius: 10px;
  border: 1px solid #e8e9ea;
  background-color: #ffffff;
  position: relative;
  @media print {
    page-break-inside: avoid;
  }
`;
export const RangeImg = styled.img`
  width: 120px;
`;
export const RangeInfoWrapper = styled.div`
  padding: 30px 10px;
`;
export const RangeTitle = styled.p`
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
  margin: 7px 0;
  margin-top: 0px;
`;
export const RangeDescription = styled.p`
  color: #979da2;
  font-family: "hn-light", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;
export const TimeToUse = styled.span`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.39px;
  line-height: 20px;
  text-align: left;
  background-image: url(${clock});
  background-position: left;
  background-repeat: no-repeat;
  padding: 5px 0;
  padding-left: 30px;
`;
export const Range = styled.h2`
  font-family: "hn-semibold", Arial;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;
export const HomeProductWrapper = styled.article`
  max-width: 380px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border: 1px solid #e8e9ea;
  margin-top: 20px;
  div {
    padding: 0 10px;
  }
  @media print {
    width: 45%;
    margin-right: 3%;
    float: left;
    page-break-inside: avoid;
  }
`;
export const HomeProductName = styled.h2`
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
  width: 85%;
`;
export const HomeProductDescription = styled.p`
  color: #979da2;
  font-family: "hn-light", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;
export const HomeProductImg = styled.img`
  float: left;
  width: 120px;
`;
export const RangeCardsWrapper = styled.section`
  max-width: 990px;
  margin: 35px auto;
  .swiper-container {
    padding: 0 20px 20px;
  }

  &::before {
    content: "";
    position: absolute;
    background-image: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
    width: 100%;
    height: 375px;
    margin-top: -110px;
    left: 0;
    z-index: 1;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: black;
    transform: rotate(45deg);
    &:after {
      content: "";
      width: 20px;
      height: 20px;
      border-top: 1px solid black;
      border-right: 1px solid black;
      display: block;
      font-family: unset;
    }
    @media print {
      display: none;
    }
  }
  .swiper-button-prev {
    transform: rotate(-135deg);
  }
  .swiper-pagination-bullet-active {
    background: #4a4a4a !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
  }
  .swiper-pagination-bullets {
    bottom: 0;
    @media print {
      display: none;
    }
  }
  @media print {
    margin: 0 auto;

    .swiper-wrapper {
      transform: translate3d(0px, 0px, 0px) !important;
      display: block !important;
      
      .swiper-slide {
        padding: 12px;
        display: block !important;
        float: left !important;
        width: 45% !important;
        margin-right: 3%;
      }
    }
    &::before {
      content: none;
    }
  }
`;
export const HomeProductsWrapper = styled.section`
  max-width: 790px;
  margin: 40px auto;
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  width: 100%;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  @media print {
    margin-top: 30px;
    display: block;
  }
`;
