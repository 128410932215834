import { GETNOTIFICATION, RESETNOTIFICATION } from "redux/constants/Notification";


export const getNotificationAction = (data) => ({
  type: GETNOTIFICATION,
  payload: data,
});

export const resetNotificationAction = () => ({
  type: RESETNOTIFICATION,
});
