import axios from "axios";
import { FieldSwitcher } from "pages/bodyshock/Questionnaire/utils";
import mocks from "./mocks";
function cleanSessionData(firstData, sessionData, selectedLang) {
  const mockedData = mocks[selectedLang];

  if (sessionData && sessionData.length) {
    sessionData = sessionData.filter((obj) => obj.name !== "weight" && obj.name !== "height");
    return sessionData;
  }
  if(firstData) {
    let dataForCleaning = JSON.parse(JSON.stringify(firstData));
    let cleanData = dataForCleaning.map((row) => {
      if (row.value) row.value = "0";
      if (row.input) row.input = "0";
      return row;
    });
    cleanData = cleanData.filter((obj) => obj.name !== "weight" && obj.name !== "height");
    return cleanData;
  }
  else {
    return mockedData;
  }
}

function getHeightAndWeightValuesObj(sessionData) {
  if (sessionData) {
    const height = sessionData.find((obj) => obj.name === "height") || 0;
    const weight = sessionData.find((obj) => obj.name === "weight") || 0;
    return { height, weight };
  }
  return {
    height: { name: "height", value: 0, type: "text" },
    weight: { name: "weight", value: 0, type: "text" },
  };
}

const DataPrinter = ({ finalData, saveImage, token }) => {
  return finalData?.map((field, index) => FieldSwitcher(field, index, saveImage, token));
};
const getPrivateImage = async (token, name) => {
  const fixedRoute = `${process.env.REACT_APP_ANTIAGING_API}bodyshock/images/users/${name}`;
  let response = await axios.get(fixedRoute, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "arraybuffer",
  });
  let data = Buffer.from(response.data, "binary").toString("base64");
  return data;
};
export { cleanSessionData, getHeightAndWeightValuesObj, DataPrinter, getPrivateImage };
