import { DELETEPATIENT, GETPATIENTS, RESETPATIENTS } from "../constants/Patient";

export default (state = [], action) => {
  switch (action.type) {
    case GETPATIENTS:
      return Object.assign([], state, action.payload);
    case DELETEPATIENT:
      return Object.assign(
        [],
        state.filter((patient) => patient.id !== action.payload)
      );
    case RESETPATIENTS:
      return [];
    default:
      return state;
  }
};
