import styled from "styled-components";
export const Select = styled.select`
  outline: 0;
  border: 0;
  border-bottom: 1px solid #000000;
  background: white;
  font-family: "hn-light", Arial;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 2px;
  width: 100%;
  line-height: 26px;
  text-transform: uppercase;
  padding: 4px 27px 4px 4px;
  option {
    padding: 10px 5px;
    border-bottom: 1px solid #c3c3c3;
    font-family: "hn-light", Arial;
  }
`;
export const Label = styled.label`
  position: absolute;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 300;
  transition: all 0.5s ease-in-out;
  top: -15px;
  left: 0;
  font-size: 10px;
`;
export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 25px;
`;
export const ErrorBox = styled.p`
  color: #e02b27;
  font-size: 12px;
  margin-top: 0;
`;
