import styled from 'styled-components';
import editIconBs from "./images/edit-icon-bs.svg";
import deleteIcon from "./images/ico-close-grey.png";
import clockIcon from "./images/clock.svg";
import arrow from './images/ico-arrow.svg';
import infoIcon from "./images/ico-info.svg";

export const HomeTreatmentWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 110px;
  overflow-x: hidden;
  .content {
    border-bottom: 1px solid #e2e2e2;
    background-image: linear-gradient(180deg, #fff 0%, #f2f2f2 150%);
    padding-bottom: 25px;
  }
 
  .topic-products {
    max-width: 970px;
    margin: auto;
    padding: 0 20px; 
    position: relative;
  }
  .swiper-wrapper {
    @media print {
      width: 100%;
      gap: 20px;
      flex-wrap: wrap;
    }
  }
  .complement-products-wrapper {
    overflow-x: hidden;
    border-bottom: 1px solid #e2e2e2;
    .contentToggle {
      position: relative;
      .content-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 930px;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .edit-icon-complements {
        display: flex;
        cursor: pointer;
        color: #d25462;
        font-family: hn-medium, Arial;
        letter-spacing: 0.31px;
        font-size: 12px;
        line-height: 20px;
        text-decoration: underline;
        justify-content: center;
        &::before {
          content: url(${editIconBs});
          margin-right: 10px;
        }
        @media print {
          display: none;
        }
      }
        
      .content-complements {
        max-width: 930px;
        margin: auto;
        padding-bottom: 20px;
      }
      @media print {
        display: block !important;
      }
    }
  }
  .subtitle {
    font-size: 12px;
    letter-spacing: 0.31px;
    line-height: 18px;
  }
  .title {
    font-family: hn-semibold, Arial;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    margin-bottom: 5px;
    @media(max-width: 520px) {
      font-size: 14px;
      letter-spacing: 0.39px;
    }
  }
  .edit-icon {
    display: flex;
    cursor: pointer;
    color: #d25462;
    font-family: hn-medium, Arial;
    letter-spacing: 0.31px;
    font-size: 12px;
    line-height: 20px;
    text-decoration: underline;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 0;
    &::before {
      content: url(${editIconBs});
      margin-right: 10px;
    }
    @media print {
      display: none;
    }
  }
  .edit-icon-advices {
    display: flex;
    cursor: pointer;
    color: #d25462;
    font-family: hn-medium, Arial;
    letter-spacing: 0.31px;
    font-size: 12px;
    text-decoration: underline;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 14px;
    align-items: center;
    &::before {
      content: url(${editIconBs});
      margin-right: 10px;
    }
    @media print {
      visibility: hidden;
    }
  }
  .product-name {
    font-size: 14px;
    font-family: hn-semibold, Arial;
    font-weight: 600;
    line-height: 15px;
    margin: 0;
    margin-bottom: 20px;
    @media(max-width: 520px) {
      font-size: 12px;
    }
  }
  .product-description {
    margin: 0;
    font-family: hn-light;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.39px;
    line-height: 17px;
    margin-bottom: 20px;
    @media(max-width: 520px) {
      font-size: 10px;
      letter-spacing: 0.33px;
      line-height: 15px;
    }
  }
  .swiper-container {
    overflow: visible;
  }
  .swiper-products {
    border: 1px solid #e8e9ea;
    border-radius: 10px;
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 130px 200px;
    align-items: center;
    background-color: white;
    max-width: 387px;
    width: 100% !important;
    grid-auto-rows: 1fr;
    img {
      width: 100%;
    }
    @media(max-width: 520px) {
      grid-template-columns: auto 180px;
      max-width: 297px;
    }
  }
  .products-advices {
    max-width: 970px;
    margin: 0px auto 40px;
    padding: 0 20px;
    position: relative;
    @media(max-width: 520px) {
      margin-bottom: 30px;
    }
    /* @media print {
      page-break-before:always;
    } */
    .swiper-pagination {
      @media print {
        display: none;
      }
    }
  }
  .swiper-advices {
    max-width: 150px;
    @media(max-width: 520px) {
      max-width: 140px;
    }
    cursor: pointer;
    img {
      border-radius: 6px;
      width: 100%;
    }
    p {
      margin: 0;
      margin-top: 6px;
      font-family: hn-semibold;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.39px;
      line-height: 15px;
      @media(max-width: 520px) {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: normal;
      }
    }
    .product-name {
      min-height: 30px;
      @media(max-width: 520px) {
        min-height: 24px;
      }
    }
    .subtitle {
      font-family: hn-medium;
      font-weight: 600;
    }
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    position: relative;
    top: 10px;
  }
  .swiper-pagination-bullet-active {
    background: #bd3d4b !important;
  }
  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    background: #f196a0;
    opacity: 1;
  }
  .delete-icon {
    position: absolute;
    cursor: pointer;
    right: 12px;
    top: 9px;
    &:before {
      content: url(${deleteIcon});
    }
    @media print {
      display: none;
    }
  }
  .clock {
    font-size: 12px;
    letter-spacing: 0.39px;
    line-height: 20px;
    display: flex;
    align-items: center;
    @media(max-width: 520px) {
      font-size: 10px;
      letter-spacing: 0.33px;
    }
    
    &:before {
      content: "";
      background: url(${clockIcon}) no-repeat center; 
      margin-right: 10px;
      width: 22px;
      height: 22px;
      @media(max-width: 520px) {
        width: 14px;
        height: 15px;
        background-size: contain;
        margin-right: 5px;
      }
    }
  }
  .titleToggle {
    padding-left: 20px;
    padding-right: 20px;
    @media print {
      display: none;
    }
    span {
        color: #d25462;
        font-family: hn-medium, Arial;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer;
        padding-bottom: 13px;
        padding-top: 13px;
        
        &:after {
            content: '';
            background: url(${arrow}) no-repeat center;
            width: 8px;
            height: 4px;
            display: inline-block;
            padding-left: 10px;
            transform: rotate(180deg);
        }
        &.active {
            &:after {
                transform: rotate(360deg);
                padding-right: 10px;
                padding-left: 0;
            }
        }
    }
  }
  .contentToggle {
      border-top: 1px solid #f7f7f7;
      padding: 0 20px;
      padding-bottom: 30px;
      @media(max-width: 520px) {
        padding-bottom: 0;
      }
  }
  .info-recomendation {
    max-width: 930px;
    margin: auto;
    padding: 16px 20px;
    padding-bottom: 0px;
    
    p {
      max-width: 684px;
      margin: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: start;
      color: #323e48;
      font-size: 10px;
      line-height: 15px;
      &:before {
        content: '';
        background: url(${infoIcon}) no-repeat center;
        display: block;
        margin-right: 10px;
        width: 22px;
        height: 23px;
        min-width: 22px;
      }
    }
  }
`;
