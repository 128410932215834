import styled from 'styled-components';
import clockBS from './images/clockBS.svg';
import sessionCheck from "./images/sessionCheck.svg";
import download from './images/ico-download.svg';
import leftbg from './images/left-bg.png';
import rightbg from './images/right-bg.png';
import calendarUpdate from './images/ico-updatecalendar.svg';
import calendar from './images/ico-calendar.svg';
import arrow from './images/ico-arrow.svg';
import arrowRed from './images/arrow_red.svg';
import arrowWhite from './images/arrow_white.svg';
import tracingActive from './images/ico-tracing-active.svg';
import tracing from './images/ico-tracing.svg';
import closeIcon from './images/ico-close.svg';
import editIconBs from "./images/edit-icon-bs.svg";
import infoIcon from "./images/ico-info.svg";
import tooltip from "./images/ico-use.svg";
import tooltipActive from "./images/ico-use-active.svg";


export const ProfessionalTreatmentWrapper = styled.div`
  position: relative;
  background-color: #f7f7f7;
  padding-bottom: 110px;
  border-bottom: 1px solid #e2e2e2;
  &:before {
    content: "";
    background-image: url(${leftbg});
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    left: 0;
    top: 0px;
    width: 274px;
    height: 797px;
    @media (max-width: 560px) {
      content: none;
    }
  }
  &:after {
    content: "";
    background-image: url(${rightbg});
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    width: 390px;
    height: 800px;
    right: 0;
    top: 0;
    @media (max-width: 768px) {
      content: none;
    }
  }
  @media print {
    &:before,
    &:after {
      content: none;
    }
  }
  .sessionsList {
    position: relative;
    z-index: 2;
    padding-top: 30px;
    @media print {
      display: none;
    }
    > p {
      margin: 0;
      margin-bottom: 20px;
      line-height: 20px; 
      text-align: center;
      padding: 0 20px;

      strong {
        font-family: hn-semibold;
        font-weight: 600;
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 436px;
      margin: auto;
    }
    li {
      display: grid;
      grid-template-columns: 64px 64px auto;
      column-gap: 10px;
      border-radius: 10px;
      @media(max-width: 520px) {
        grid-template-columns: 59px 59px auto;
        column-gap: 6px;
      }
    }

    .session {
      border-radius: 10px;
      border: 1px solid #e8e9ea;
      background-color: #ffffff;
      padding: 12px 15px 10px 23px;
      margin-bottom: 9px;
      cursor: pointer;
      position: relative;
      &.passed {
        .sessionTitle {
          text-decoration: line-through;
          color: black;
        }
        p {
          color: #c7c7cc;
        }
        .hour::before {
          background: url(${sessionCheck}) no-repeat center;
        }
      }
      @media(min-width: 600px) {
        &:hover {
          border: 1px solid #000;
        }
      }
      @media(max-width: 520px) {
        padding-top: 9px;
        padding-bottom: 8px;
        padding-left: 20px;
      }
      p {
        margin: 0;
      }
      .sessionTitle {
        font-size: 14px;
        letter-spacing: 0.39px;
        font-family: hn-semibold, Arial;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0px;
        color: #ced0d1;
        &.active {
          color: black;
        }
      }
      .date, .hour {
        font-family: hn-medium, Arial;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.39px;
        line-height: 20px;
      }
      .hour {
        display: flex;
        align-items: center;
        &:before {
          content: '';
          background: url(${clockBS}) no-repeat center;
          margin-right: 5px;
          display: inline-block;
          width: 16px;
          height: 17px;
        }
      }
      .arrow {
        position: absolute;
        top: 17px;
        right: 16px;
        width: 20px;
        height: 30px;
        background: url(${arrow}) no-repeat center;
      }

    }
    .sessionBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 84%;
      .scheduleSession {
        font-size: 12px;
        letter-spacing: 0.39px;
        line-height: 20px;
        color: #d25462;
        text-decoration: underline;
      }
    }
    .calendar {
      cursor: pointer;
      background: url(${calendar}) no-repeat center;
      border-radius: 10px;
      border: 1px solid #e8e9ea;
      background-color: #ffffff;
      width: 64px;
      height: 64px;
      cursor: pointer;
      @media(max-width: 520px) {
        width: 59px;
        height: 59px; 
      }
      @media(min-width: 600px) {
        &:hover {
          border: 1px solid #000;
        }
      }
    }
    .calendar-update {
      cursor: pointer;
      background: url(${calendarUpdate}) no-repeat center;
      border-radius: 10px;
      border: 1px solid #e8e9ea;
      background-color: #ffffff;
      width: 64px;
      height: 64px;
      cursor: pointer;
      @media(max-width: 520px) {
        width: 59px;
        height: 59px; 
      }
      @media(min-width: 600px) {
        &:hover {
          border: 1px solid #000;
        }
      }
    }
    .tracing-active {
      cursor: pointer;
      background: url(${tracingActive}) no-repeat center;
      border-radius: 10px;
      border: 1px solid #e8e9ea;
      background-color: #ffffff;
      width: 64px;
      height: 64px;
      cursor: pointer;
      @media(max-width: 520px) {
        width: 59px;
        height: 59px; 
        background-size: 28px 26px;
      }
      @media(min-width: 600px) {
        &:hover {
          border: 1px solid #000;
        }
      }
    }
  }
    .tracing {
      cursor: pointer;
      background: url(${tracing}) no-repeat center;
      border-radius: 10px;
      border: 1px solid #e8e9ea;
      background-color: #ffffff;
      width: 64px;
      height: 64px;
      cursor: default;
      pointer-events: none;
      @media(max-width: 520px) {
        width: 59px;
        height: 59px; 
        background-size: 28px 26px;
      }

  }
  .overlay-appointment {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
    z-index: 3;
    top: 0;
    cursor: pointer;
  }
  .overlay-professional {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
    z-index: 3;
    top: 0;
    cursor: pointer;
    @media print {
      display: none;
    }
  }
  .overlay-tracing {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
    z-index: 3;
    top: 0;
    cursor: pointer;
  }
  .App-handle-container {
    width: 100%;
    height: 30px;
    top: 10px;
    position: absolute;
    z-index: 1;
    cursor: pointer;

  }
  .App-handle {
    margin: 0 auto;

    width: 36px;
    height: 5px;
    border-radius: 3px;
    background-color: #000000;
    opacity: 0.2;
    display: block;
    top: 6px;
  }
  .download {
    background: url(${download}) no-repeat center;
    display: inline-block;
    width: 16px;
    height: 17px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 3;
    @media(max-width: 520px) {
      right: 20px;
    }
    @media print {
      display: none;
    }
  }
  .sessionsView {
    &.active {
      top: 5%;
    }
    @media print {
      position: relative !important;
      visibility: visible !important;
      width: 100% !important;
      top: 0 !important;
      left: 0;
      height: 100%;
      overflow: visible !important;
      box-shadow: none;
    }
    top: unset;
    bottom:0;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow-y: scroll;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.15);
    overflow-x: hidden;
    position: fixed;
    z-index: 3;

    header {
      padding: 24px 20px 13px 20px;
      border-bottom: 1px solid #e2e2e2;
      position: relative;
      background-color: white;
      border-radius: 10px 10px 0 0;
    }
    h2 {
      margin: 0;
      text-align: center;
      font-size: 18px;
      font-family: hn-semibold, Arial;
      font-weight: 600;
      line-height: 26px;

    }
    .subtitle {
      margin: 0;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }
    .close {
      background: url(${closeIcon}) no-repeat center;
      display: inline-block;
      width: 28px;
      height: 28px;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      z-index: 3;
      @media print {
        display: none;
      }
    }
    .edit {
      color: #d25462;
      letter-spacing: 0.31px;
      font-size: 12px;
      line-height: 20px;
      text-decoration: underline;
      display: flex;
      cursor: pointer;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;
      &::before {
        content: url(${editIconBs});
        margin-right: 10px;
      }
      @media(max-width: 520px) {
        right: 20px;
      }
    }


    .steps {
      max-width: 970px;
      margin: auto;
      margin-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
      &:first-child .step-title{
        border-top: 0;
      }
      .swiper-button-prev::after ,
      .swiper-button-next::after {
        content: none;
      }
      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          content: none;
        }
      }
      .swiper-button-prev {
        background: url(${arrow}) no-repeat center;
        display: block;
        transform: rotate(180deg);
        left: -40px;
        @media(max-width: 1023px) {
          display: none;
        }

      }
      .swiper-button-next {

          background: url(${arrow}) no-repeat center;
          display: block;
          right: -40px;
          @media(max-width: 1023px) {
            display: none;
          }
        
      }
      .swiper-button-disabled {
        opacity: 0;
      }
      @media(min-width: 1024px) {
        max-width: unset;
        padding-right: 10%;
        padding-left: 10%;
      }
      @media print {
        max-width: 100%;
      }
      .swiper-wrapper {
        @media print {
          transform: none !important;
          width: 100%;
          gap: 20px;
          flex-wrap: wrap;
        }
      }
    }
    .swiperWrapper {
      position: relative;
    }
    .steps-wrapper {
      @media print {
        width: 100% !important;
        overflow: visible !important;
        visibility: visible !important;
      }
      background-color: white;
      position: relative;
      z-index: 1;
      overflow-x: hidden;
      counter-reset: section;

      .swiper-container {
        overflow: visible;
        position: initial;

      }
      .swiper-slide {
        border: 1px solid #e8e9ea;
        border-radius: 10px;
        display: grid;
        grid-template-columns: auto 130px;
        align-items: center;
        padding: 12px 10px 12px 13px;
        max-width: 265px;
        width: 100%;
        background-color: white;
        min-height: 150px;

        img {
          height: 106px;
        }
        h3 {
          margin: 0;
          margin-bottom: 5px;
          font-family: hn-semibold, Arial;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.39px;
          line-height: 16px;
          height: 45px;
          max-width: 50px;
        }
        .quantity {
          display: flex;
          align-items: center;
          position: relative;
        }
        .product-qty {
          margin: 0;
          font-size: 18px;
          line-height: 22px;
          display: inline;
          margin-right: 5px;
        }
        .product-use {
          margin: 0;
          font-size: 10px;
          line-height: 15px;
        }
      }
    }
    .info-recomendation {
      max-width: 1534px;
      margin: auto;
      border-top: 1px solid #e2e2e2;
      padding: 16px;
      padding-bottom: 65px;
      @media print {
        display: none;
      }
      
      p {
        max-width: 900px;
        margin: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #323e48;
        font-size: 10px;
        line-height: 15px;
        &:before {
          content: '';
          background: url(${infoIcon}) no-repeat center;
          display: block;
          margin-right: 10px;
          width: 22px;
          height: 23px;
          min-width: 22px;
        }
      }
    }
    .step-title {
      max-width: 970px;
      margin: auto;
      margin-bottom: 10px;
      border-top: 1px solid #f7f7f7;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;

      @media(min-width: 1024px) {
        max-width: unset;
      }
      .step-title-text::before {
          counter-increment: section;
          content: counter(section) ".";
          font-family: hn-semibold;
          font-weight: 600;
          margin-right: 4px;
      }
      p {
        margin: 0;
        font-size: 14px;
        letter-spacing: 0.39px;
        line-height: 20px;
        strong {
          font-family: hn-semibold, Arial;
          font-weight: 600;
        }
      }
      .step-edit {
        color: #d25462;
        letter-spacing: 0.31px;
        font-size: 12px;
        line-height: 20px;
        text-decoration: underline;
        display: flex;
        cursor: pointer;
        &::before {
          content: url(${editIconBs});
          margin-right: 10px;
        }
        @media print {
          display: none;
        }
      }
    }
    .swiper-pagination {
      background-color: rgba(248, 248, 248);
      position: fixed;
      bottom: 0;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media print {
        display: none;
      }
    }
    .swiper-pagination-bullet-active {
      background: #bd3d4b !important;
    }
    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;
      background: #f196a0;
      opacity: 1;
      margin: 0 3px;
    }
    > .swiper-container {
      margin-bottom: 75px;
      @media print {
        overflow: visible !important;
      }
    }
  }
`;
export const TooltipIcon = styled.div`
  background-image: ${({activetooltip})=> (activetooltip ?  `url(${tooltipActive})` : `url(${tooltip})`)};
  background-position: center;
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  &:before {
    content: '';
    opacity: ${({activetooltip})=> (activetooltip ? '1' : '0')};
    width: 7px;
    height: 8px;
    transform: rotate(45deg);
    display: block;
    position: absolute;
    top: 26px;
    left: 6px;
    z-index: 1;
    border-left: 1px solid #ededed;
    border-top: 1px solid #ededed;
    border-bottom: none;
    border-right: none;
    background-color: rgba(248, 248, 248);
  }
  @media(min-width: 1024px) {
    &:hover {
      background: url(${tooltipActive}) no-repeat center;
      & ~ .content-tooltip {
        display: block;
      }
    }
  }

`;
export const TooltipContent = styled.div`
  display: ${({ activetooltip }) => (activetooltip? "block" : "none")};
  border: 1px solid #ededed;
  border-radius: 10px;
  background-color: rgba(248, 248, 248);
  padding: 24px 20px;
  position: absolute;
  width: 253px;
  right: -10px;
  top: 30px;

`;


export const Icon = styled.div`
  z-index: 99999;
  color: #d25462;
  bottom: 23px;
  position: fixed;
  cursor: pointer;
  font-size: 10px;
  letter-spacing: 0.39px;
  line-height: 12px;
  font-family: 'hn-semibold', Arial;

  &.prev {
    left: 10%;
    height: 12px;
    display: flex;
    align-items: center;
    &::before {
      content: url(${arrowRed});
      margin-right: 5px;
      display: inline-block;
      width: 5px;
      height: 8px;
    }
    @media(max-width: 520px) {
      left: 20px;
    }
    @media print {
      display: none;
    }
  }
  &.next {
    right: 10%;
    height: 30px;
    color: white;
    background: #D25462;
    min-width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 12px;
    padding-left: 5px;
    padding-right: 5px;
    &::after {
      content: url(${arrowWhite});
      margin-left: 5px;
      display: inline-block;
      width: 5px;
      height: 8px;
    }
    @media(max-width: 520px) {
      right: 20px;
      min-width: 100px;
    }
    @media print {
      display: none;
    }
  }
  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
`;

export const Actions = styled.div`
  text-align: right;
  button {
    width: 27px;
    height: 27px;
    border-radius: 14px;
    color: #a6a9aa;
    border: 1px solid #a6a9aa;
    background-color: #ffffff;
    cursor: pointer;
    padding: 0;
    position: relative;
    span {
      font-weight: bold;
      position: absolute;
      top: -2px;
      width: 100%;
      left: 0;
      font-size: 18px;
    }
    :hover,
    .active {
      border: 1px solid #323e48;
      background-color: #323e48;
      color: white;
    }
  }
  ul {
    display: "none";
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    padding: 15px 0px;
    background: #f2f2f2;
    border: 1px solid #e2e2e2;
    width: 190px;
    right: 0;
    margin-right: 20px;

    &::after,
    &::before {
      bottom: 100%;
      right: 45px;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-color: rgba(242, 242, 242, 0);
      border-bottom-color: #f2f2f2;
      border-width: 5px;
      margin-left: -5px;
    }
    &::before {
      border-color: rgba(226, 226, 226, 0);
      border-bottom-color: #e2e2e2;
      border-width: 6px;
      margin-left: -6px;
    }

  }
`;