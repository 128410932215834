import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ActivesLeft,
  ActivesRight,
  ActivesWrapper,
  Content,
  CustomizableWrapper,
  IngredientsContainer,
  IngredientsInfoWrapper,
  LogoContainer,
  MiniBannerInner,
  MiniBannerSubtitle,
  MiniBannerTitle,
  MiniBannerWrapper,
  Overlay,
  Popup,
  TextLogoContainer,
  VideoWrapper,
  CTAsWrapper,
  Wrapper,
} from "./style";
import useOnclickOutside from "react-cool-onclickoutside";
import { Line, Paragraph } from "../Home/helpPageStyle";
import logoinnovacion from "./images/innovador.svg";
import logopersonalizable from "./images/personalizable.svg";
import syringes from "./images/syringes.jpg";
import logointegral from "./images/integral.svg";
import shikimicologo from "./images/shikimico-logo.svg";
import maslinicologo from "./images/maslinico-logo.svg";
import isoquercetinalogo from "./images/isoquercetina-logo.svg";
import Button from "components/Button";
import { useHistory } from "react-router";
import useLocalStorage from "utils/transverse/useLocalStorage";
import localstoragekeys from "constants/localstoragekeys";
const getVideoId = (lang) => {
  switch (lang) {
    case "es":
      return "500791159";
    default:
      return "500790958";
  }
};
export default ({ ...props }) => {
  const [t] = useTranslation("global");
  const [appLanguage] = useLocalStorage(localstoragekeys.appLanguage);
  const history = useHistory();
  const [seeActives, setSeeActives] = useState(false);
  const referency = useOnclickOutside(() => {
    setSeeActives(false);
  });
  document.title = "About age element diagnosis system";
  return (
    <Wrapper>
      <Overlay active={seeActives} />
      <Popup active={seeActives} ref={referency}>
        <IngredientsContainer>
          <IngredientsInfoWrapper>
            <h2 className="title">
              {t("resources.aboutPage.popup.shikimico")}
            </h2>
            <p>{t("resources.aboutPage.popup.shikimico1")}</p>
            <p>{t("resources.aboutPage.popup.shikimico2")}</p>
          </IngredientsInfoWrapper>
          <img src={shikimicologo} alt="Icon" />
        </IngredientsContainer>
        <IngredientsContainer>
          <IngredientsInfoWrapper>
            <h2 className="title">
              {t("resources.aboutPage.popup.maslinico")}
            </h2>
            <p>{t("resources.aboutPage.popup.maslinico1")}</p>
            <p>{t("resources.aboutPage.popup.maslinico2")}</p>
          </IngredientsInfoWrapper>
          <img src={maslinicologo} alt="Icon" />
        </IngredientsContainer>
        <IngredientsContainer>
          <IngredientsInfoWrapper>
            <h2 className="title">
              {t("resources.aboutPage.popup.isoquercetina")}
            </h2>
            <p>{t("resources.aboutPage.popup.isoquercetina1")}</p>
            <p>{t("resources.aboutPage.popup.isoquercetina2")}</p>
          </IngredientsInfoWrapper>
          <img src={isoquercetinalogo} alt="Icon" />
        </IngredientsContainer>
      </Popup>
      <MiniBannerWrapper>
        <MiniBannerInner>
          <MiniBannerTitle>age element</MiniBannerTitle>
          <MiniBannerSubtitle>
            {t("resources.aboutPage.subtitle")}
          </MiniBannerSubtitle>
        </MiniBannerInner>
      </MiniBannerWrapper>
      <Content>
        <Paragraph>{t("resources.aboutPage.firstParagraph")}</Paragraph>
        <Paragraph>
          <Trans t={t} i18nKey="resources.aboutPage.secondParagraph">
            Un tratamiento
            <strong> innovador, personalizable e integral</strong> para los
            expertos en el cuidado de la piel más exigentes
          </Trans>
        </Paragraph>
      </Content>
      <Line />
      <Content style={{ paddingTop: 0 }}>
        <LogoContainer>
          <img src={logoinnovacion} alt="logo" />
          <TextLogoContainer>
            <p className="title">{t("resources.aboutPage.innovative")}</p>
            <p className="subtitle">meso[epigen] system™</p>
            <p className="text">{t("resources.aboutPage.innovativeText")}</p>
          </TextLogoContainer>
        </LogoContainer>
        <ActivesWrapper>
          <ActivesLeft>
            <span className="bigNumber">3</span>
            <div>
              <p>
                <Trans t={t} i18nKey="resources.aboutPage.activeText">
                  <strong>activos epigenéticos</strong> con acción sinérgica
                  antiedad
                </Trans>
              </p>
            </div>
          </ActivesLeft>
          <ActivesRight>
            <p>{t("resources.aboutPage.activeDescription")}</p>
            <p className="activeCTA" onClick={() => setSeeActives(!seeActives)}>
              {t("resources.aboutPage.viewActives")}
            </p>
          </ActivesRight>
        </ActivesWrapper>
        <LogoContainer>
          <img src={logopersonalizable} alt="logo" />
          <TextLogoContainer>
            <p className="title">{t("resources.aboutPage.customizable")}</p>
          </TextLogoContainer>
        </LogoContainer>
        <CustomizableWrapper>
          <h1>{t("resources.aboutPage.customizableTitle")}</h1>
          <div className="p__break-lines">
            <p>{t("resources.aboutPage.customizableFirst")}</p>
            <p>{t("resources.aboutPage.customizableSecond")}</p>
            <p>{t("resources.aboutPage.customizableThird")}</p>
          </div>
          <img src={syringes} alt="icon" />
        </CustomizableWrapper>
        <LogoContainer>
          <img src={logointegral} alt="logo" />
          <TextLogoContainer>
            <p className="title">{t("resources.aboutPage.integral")}</p>
            <p className="text">{t("resources.aboutPage.integralText")}</p>
          </TextLogoContainer>
        </LogoContainer>
      </Content>
      <VideoWrapper>
        <iframe
          title="Corporative video"
          src={`https://player.vimeo.com/video/${getVideoId(
            appLanguage
          )}`}
          width="100%"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
        ></iframe>

        <CTAsWrapper>
          <Button
            style={{ backgroundColor: "white", color: "black" }}
            type="button"
            onClick={() => history.push(`/antiaging/resources`)}
          >
            {t("home.resourceAction")}
          </Button>
          <Button type="button" onClick={() => history.push(`/antiaging`)}>
            {t("treatment.home")}
          </Button>
        </CTAsWrapper>
      </VideoWrapper>
    </Wrapper>
  );
};
