import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { useHistory } from 'react-router';
import images from './images/index';
import routes from "constants/routes";
import loadingIcon from "./images/loading.svg";


import {
  MiniBannerInner,
  MiniBannerSubtitle,
  MiniBannerTitle,
  MiniBannerWrapper,
  ProtocolsCTAs,
  BottomCTAsWrapper,
  ProtocolPageWrapper,
  Wrapper,
  LoadingResource
} from './style';

const ProtocolPage = function ({ selectedLang, token }) {
  const history = useHistory();
  const [t] = useTranslation('global');
  const [content, setContent] = useState('');
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/pages`, {
        headers: {
          'Content-Language': selectedLang,
          Authorization: `Bearer ${token}`,
        },
        params: { identifier: 'protocol-bs' },
      })
      .then(({ data }) => setContent(data.results));
  }, [token, selectedLang]);
  if(content) {

    return (
      <Wrapper>
        <MiniBannerWrapper>
          <MiniBannerInner>
            <MiniBannerTitle>bodycare solutions</MiniBannerTitle>
            <MiniBannerSubtitle>
            {t('resourcesBodyshock.aboutPage.subtitle')}
            </MiniBannerSubtitle>
          </MiniBannerInner>
        </MiniBannerWrapper>
        <ProtocolPageWrapper>
          {parse(content, {
            replace(domNode) {
              if (domNode.name === 'img') {
                domNode.attribs.src = images[domNode.attribs.src.replace('{', '').replace('}', '')];
              }
            },
          })}
        </ProtocolPageWrapper>
        <BottomCTAsWrapper>
          <ProtocolsCTAs className='whiteButton'
            onClick={() => history.goBack()}
          >
            bodyshock® resources
          </ProtocolsCTAs>
          <ProtocolsCTAs
            protocolId
            onClick={() => history.push(`${routes.homeBodyshock}`)}
          >
            home
          </ProtocolsCTAs>
        </BottomCTAsWrapper>
      </Wrapper>
    );
  }
  else {
    return(

      <LoadingResource>
        <img src={loadingIcon} alt="Loading Resource" />
      </LoadingResource>
    )
  }
};

const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});

export default connect(mapStateToProps, null)(ProtocolPage);
