import React, { useState } from "react";
import StringInput from "components/Input";
import { useInput } from "components/Input/input.hook";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { Wrapper, ContentWrapper, DatePickerWrapper, Button, CloseButton } from "./style";
import { Label } from "components/Input/style";
registerLocale("es", es);
const getFormattedDOB = (dob) => {
  if (dob.toString().includes("-") || dob.toString().includes("/")) {
    if (dob) {
      var dateParts = dob.toString().includes("-")
        ? dob.toString().split("-")
        : dob.toString().split("/");
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    }
  }
  return new Date();
};
const dateSubmit = (dob) => {
  let formatted_date;
  if (dob) {
    let current_datetime = dob;
    formatted_date =
      current_datetime.getDate() +
      "/" +
      ("0" + (current_datetime.getMonth() + 1)).slice(-2) +
      "/" +
      current_datetime.getFullYear();
  }
  return formatted_date;
};
export default ({ patient, selectedLang, onSubmit, close }) => {
  const [t] = useTranslation("global");
  const { handleSubmit, register, errors } = useForm();
  const [name, setName] = useInput(patient && patient.name);
  const [last_name, setLast_name] = useInput(patient && patient.last_name);
  const [date_of_birth, setDob] = useState(
    getFormattedDOB(patient && patient.date_of_birth)
  );
  const [email, setEmail] = useInput(patient && patient.email);
  const [phone_number, setPhoneNumber] = useInput(
    patient && patient.phone_number
  );
  const [address, setAddress] = useInput(
    patient && patient.address
  );
  const [considerations, setConsiderations] = useInput(
    (patient && patient.considerations) || ""
  );
  return (
    <Wrapper>
      <ContentWrapper>
      <CloseButton onClick={close} />
        <h2>{t("patients.editPatientTitle")}</h2>
        <form
          onSubmit={handleSubmit(() => {
            const fotmattedDOB = dateSubmit(date_of_birth);
            onSubmit({
              name,
              last_name: last_name,
              phone_number: phone_number,
              email,
              considerations: considerations,
              date_of_birth: fotmattedDOB,
              address: address,
            });
          })}
        >
          <StringInput
            name="name"
            type="text"
            defaultValue={name}
            label={t("register.name") + "*"}
            onChange={setName}
            ref={register({ required: true })}
            required
            error={errors.name}
          />
          <StringInput
            name="lastName"
            type="text"
            defaultValue={last_name}
            label={t("register.lastName") + "*"}
            onChange={setLast_name}
            ref={register({ required: true })}
            required
            error={errors.lastName}
          />
          <StringInput
            name="email"
            type="email"
            defaultValue={email}
            onChange={setEmail}
            label={t("register.email") + "*"}
            ref={register({ required: true })}
            error={errors.email}
          />
          <StringInput
            name="phoneNumber"
            type="text"
            defaultValue={phone_number}
            onChange={setPhoneNumber}
            label={t("register.phoneNumber") + "*"}
            ref={register({ required: true })}
            error={errors.phoneNumber}
          />
          <StringInput
            name="address"
            type="text"
            defaultValue={address}
            onChange={setAddress}
            label={t("register.address") + "*"}
            ref={register({ required: true })}
            error={errors.address}
          />
          <DatePickerWrapper>
            <DatePicker
              name="dob"
              dateFormat="P"
              locale={selectedLang ? selectedLang : "en"}
              onChange={(date) => setDob(date)}
              selected={date_of_birth}
            />
            <Label htmlFor={"dob"}>{t("register.dob") + "*"}</Label>
          </DatePickerWrapper>
          <StringInput
            name="other"
            type="textarea"
            defaultValue={considerations}
            onChange={setConsiderations}
            label={t("register.other")}
            ref={register({ required: false })}
            error={errors.other}
            limit={190}
          />
          <Button style={{ margin: "auto" }} type="submit">
            {t("editProfessional.save")}
          </Button>
        </form>
      </ContentWrapper>
    </Wrapper>
  );
};
