import axios from "axios";
import { dateFormatter, timeFormatter } from "components/bodyshock/Appointment/utils";
import { getQuestionnaireAction, setQuestionnaireAction } from "../../actions/Patient";
import { errorProtocol, getProtocolsAction } from "../../actions/Protocols";
import { verifyToken } from "../transverse/Token";
import { LogOut } from "../transverse/User";
import { saveAppointmentUtil } from "./Appointments";
function formatDataForRedux(objSession) {
  return {
    appointment_date: objSession.sessionDate,
    appointment_time:
      objSession.sessionHour &&
      objSession.sessionMinutes &&
      `${objSession.sessionHour}:${objSession.sessionMinutes}`,
    data: [...objSession.data],
  };
}
function getPerimetersFromQuestionnaire (question) {
  const result = question.choices.filter(choice => choice.input !== undefined && choice.selected);
  return result;
}
export function cleanBlobs(data) {
  if(data) {

    return [...data].map(element => {
      if(element.blob) delete element.blob;
      return element;
    });
  }
  return data;
}
function putDataFromAppointmentInReduxSession(dataFromServer, objectForRedux) {
  const mixedData = JSON.parse(JSON.stringify(dataFromServer));
  mixedData.protocol.professional[0] = { ...mixedData.protocol.professional[0], ...objectForRedux };
  return mixedData;
}
export const getQuestionnaire = (language) => (dispatch) => {
  const lang = language.split("-");
  axios.get(`/questionnaire/bodyshock/bodyshock-${lang[0]}.json`).then(({ data }) => {
    dispatch(getQuestionnaireAction(data));
  });
};
export const sendQuestionnaire = (questionnaire, token, history) => (dispatch) => {
  dispatch(setQuestionnaireAction(questionnaire));
  verifyToken(token)
    .then(() => {
      const newData = cleanBlobs(questionnaire.test.questions[21].data);
      questionnaire.test.questions[21].data = null;
      questionnaire.test.questions[21].data = [...newData];
      axios
        .post(`${process.env.REACT_APP_ANTIAGING_API}api/bodyshock/protocol`, questionnaire, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          const today = new Date();
          const sessionTime = timeFormatter(today).split(":");
          const perimeters = getPerimetersFromQuestionnaire(questionnaire.test.questions[22]);
          const sessionData = {
            protocolId: data.protocol_id,
            sessionNumber: 1,
            sessionDate: dateFormatter(today),
            sessionHour: sessionTime[0],
            sessionMinutes: sessionTime[1],
            data: [
              ...questionnaire.test.questions[21]?.data,
              { name: "height", value: questionnaire.test.questions[21]?.height, type: "text" },
              { name: "weight", value: questionnaire.test.questions[21]?.weight, type: "text" },
              ...perimeters,
            ],
          };
          dispatch(
            saveAppointmentUtil(data.patient.id, token, sessionData, questionnaire.test.lang)
          );
          const dataForRedux = formatDataForRedux(sessionData);
          const dataForStore = putDataFromAppointmentInReduxSession(data, dataForRedux);
          dispatch(getProtocolsAction(dataForStore));
          dispatch(errorProtocol(null));
        })
        .catch(({ response }) => dispatch(errorProtocol(response.data)));
    })
    .catch(() => dispatch(LogOut(history)));
};
