import React, { useCallback, useEffect, useState } from "react";
import { Fragment } from "react";
import { connect } from "react-redux";
import EditPatient from "components/EditPatient";
import { useInput } from "components/Input/input.hook";
import {
  deletePatientUtil,
  fetchPatientsUtil,
  updatePatientUtil,
} from "redux/utils/antiaging/Patients";
import {
  Wrapper,
  AddPatient,
  ResultsContainer,
  Header,
  Searcher,
  Results,
  Patient,
  Actions,
  BottomCTAsWrapper,
  ProtocolsCTAs,
} from "./style";
import useOnclickOutside from "react-cool-onclickoutside";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import routes from "constants/routes";

const ActionsComponent = ({
  setEditablePatient,
  deletePatientAndResetList,
  patient,
  t,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), [setOpen]);
  const referencia = useOnclickOutside(() => {
    setOpen(false);
  });
  return (
    <Fragment>
      <button onClick={toggle}>
        <span>...</span>
      </button>
      <ul style={{ display: open ? "block" : "none" }} ref={referencia}>
        <li
          className="icoedit"
          onClick={() => {
            setEditablePatient(patient);
            setOpen(false);
          }}
        >
          {t("patients.editPatient")}
        </li>
        <li
          className="icotrash"
          onClick={() => {
            deletePatientAndResetList(patient.id);
            setOpen(false);
          }}
        >
          {t("patients.removePatient")}
        </li>
      </ul>
    </Fragment>
  );
};
const Patients = ({
  patients,
  token,
  getPatients,
  selectedLang,
  deletePatient,
  updatePatient,
}) => {
  const [t] = useTranslation("global");
  const [editablePatient, setEditablePatient] = useState(false);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [searchValue, setSearchValue] = useInput("");
  const history = useHistory();
  useEffect(() => {
    getPatients(token);
    if (patients) setFilteredPatients(patients);
    // eslint-disable-next-line
  }, [getPatients, token]);
  useEffect(() => {
    setFilteredPatients(patients);
  }, [patients]);
  useEffect(() => {
    const result = patients.filter((patient) => {
      const patientCombination =
        patient.name + patient.last_name + patient.email;
      return patientCombination
        .toLowerCase()
        .includes(searchValue.toLowerCase().replace(" ", ""));
    });
    setFilteredPatients(result);
  }, [searchValue, patients]);
  const deletePatientAndResetList = (patientId) => {
    if (window.confirm("quiere eliminar?")) {
      deletePatient(token, patientId);
    }
  };
  const onSubmit = (patient) => {
    updatePatient(token, patient, editablePatient.id);
    setEditablePatient(false);
    setFilteredPatients(patients);
  };
  return (
    <Wrapper>
      <Header>
        <AddPatient
          onClick={() => history.push(`/antiaging/newpatient`)}
        ></AddPatient>
        <h1>{t("patientList.patientList")}</h1>
      </Header>
      <Searcher>
        <input
          name="search"
          type="text"
          placeholder={t("patientList.searchPlaceholder")}
          defaultValue={searchValue}
          onChange={setSearchValue}
        />
      </Searcher>
      <ResultsContainer>
        <Results>
          <tbody>
            {filteredPatients &&
              filteredPatients.map((patient) => (
                <Patient key={patient.id}>
                  <td
                    onClick={() =>
                      history.push(`/antiaging/patient/${patient.id}`)
                    }
                    style={{ textDecoration: "underline" }}
                  >
                    {patient.last_name}, {patient.name}
                  </td>
                  <td
                    onClick={() =>
                      history.push(`/antiaging/patient/${patient.id}`)
                    }
                  >
                    {patient.date_of_birth}
                  </td>
                  <td
                    onClick={() =>
                      history.push(`/antiaging/patient/${patient.id}`)
                    }
                  >
                    {patient.email}
                  </td>
                  <td
                    onClick={() =>
                      history.push(`/antiaging/patient/${patient.id}`)
                    }
                  >
                    {patient.phone_number || "-"}
                  </td>
                  <td>
                    <Actions>
                      {
                        <ActionsComponent
                          setEditablePatient={setEditablePatient}
                          deletePatientAndResetList={deletePatientAndResetList}
                          patient={patient}
                          t={t}
                        />
                      }
                    </Actions>
                  </td>
                </Patient>
              ))}
          </tbody>
        </Results>
      </ResultsContainer>
      {editablePatient && (
        <>
          <div className="overlay" onClick={() => setEditablePatient(false)} ></div>

          <EditPatient
            key={editablePatient.id}
            patient={editablePatient}
            selectedLang={selectedLang}
            onSubmit={onSubmit}
            close={() => setEditablePatient(false)}
            
          />
        </>
      )}
      <BottomCTAsWrapper>
        <ProtocolsCTAs onClick={() => history.push(`${routes.homeAntiaging}`)} >{t("treatment.home")}</ProtocolsCTAs>
      </BottomCTAsWrapper>

    </Wrapper>
  );
};
const mapStateToProps = (state) => ({
  patients: state.patients,
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  getPatients: (token) => dispatch(fetchPatientsUtil(token)),
  deletePatient: (token, patientId) =>
    dispatch(deletePatientUtil(token, patientId)),
  updatePatient: (token, patient, patientId) =>
    dispatch(updatePatientUtil(token, patient, patientId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Patients);
