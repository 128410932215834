import React from "react";
import { useTranslation } from "react-i18next";
import {
  BannerWrapper,
  BannerInnerWrapper,
  Banner,
  BannerLogo,
  BannerTextWrapper,
  H1,
  Exclusive,
  HomeContentWrapper,
  ButtonsWrapper,
  ActionWrapper,
  IconImg,
  HomeButton,
  ButtonText,
} from "../Home/style";
import { CTAsWrapper, Wrapper } from "./style";
import miniLogo from "../Home/images/mini-logo.png";
import about from "./images/about.svg";
import material from "./images/material.svg";
import protocol from "./images/protocol.svg";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Button from "components/Button";

const Resources = ({ ...props }) => {
  const [t] = useTranslation("global");
  const history = useHistory();
  document.title = "Resource area age element";
  return (
    <Wrapper>
      <BannerWrapper>
        <BannerInnerWrapper>
          <Banner />
          <BannerLogo src={miniLogo} />
          <BannerTextWrapper>
            <H1>{t("home.welcome")}</H1>
            <Exclusive>{t("home.exclusive")}</Exclusive>
          </BannerTextWrapper>
        </BannerInnerWrapper>
      </BannerWrapper>
      <HomeContentWrapper>
        <ButtonsWrapper resources={true}>
          <ActionWrapper onClick={() => history.push(`/antiaging/about`)}>
            <IconImg src={about} alt="Icon" />
            <div>
              <HomeButton primary={false}>
                {t("resources.aboutAgeElement")}
              </HomeButton>
              <ButtonText>{t("resources.aboutAgeElementSub")}</ButtonText>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`/antiaging/protocol`)}>
            <IconImg src={protocol} alt="Icon" />
            <div>
              <HomeButton primary={false}>{t("resources.protocol")}</HomeButton>
              <ButtonText>{t("resources.protocolSub")}</ButtonText>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`/antiaging/material`)}>
            <IconImg src={material} alt="Icon" />
            <div>
              <HomeButton primary={false}>
                {t("resources.centerMaterial")}
              </HomeButton>
              <ButtonText>{t("resources.centerMaterialSub")}</ButtonText>
            </div>
          </ActionWrapper>
        </ButtonsWrapper>
        <CTAsWrapper>
          <Button
            style={{
              backgroundColor: "white",
              color: "black",
              fontSize: "14px",
            }}
            type="button"
            onClick={() => history.push(`/antiaging/newpatient`)}
          >
            {t("treatment.new")} age element id test™
          </Button>
          <Button
            type="button"
            onClick={() => history.push(`/antiaging`)}
            style={{ fontSize: "14px" }}
          >
            {t("treatment.home")}
          </Button>
        </CTAsWrapper>
      </HomeContentWrapper>
    </Wrapper>
  );
};
export default connect()(Resources);
