import React from "react";

function ErrorsHandler({ errors }) {
  return (
    <div>
      <p style={{textAlign: "center"}}>{errors.message}</p>
    </div>
  );
}

export default ErrorsHandler;
