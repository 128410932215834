import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { TracingWrapper, Button } from "./style.js";
import IMC from "../IMC/index.js";
import { updateAppointmentUtil } from "redux/utils/bodyshock/Appointments.js";
import useLocalStorage from "utils/transverse/useLocalStorage.js";
import localstoragekeys from "constants/localstoragekeys.js";
import { uploadImagesUtil } from "pages/bodyshock/Questionnaire/utils.js";
import { DataPrinter, getHeightAndWeightValuesObj, cleanSessionData } from "./utils.js";
import { ComponentCheck } from "components/EditHomeProtocol/styles.js";
const Tracing = ({ closeTracing, patient, session, token, updateAppointment, firstSession }) => {
  const [t] = useTranslation("global");
  const [selectedLang] = useLocalStorage(localstoragekeys.appLanguage);
  const [calculatedValue, setCalculatedValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const SCREEN_HEIGHT = window.innerHeight - 30;
  const handlePos = useSpring({ y: 0 });
  const [finalData, setFinalData] = useState([]);
  const [imcValues, setImcValues] = useState(null);
  useEffect(() => {
    const finalArray = cleanSessionData(firstSession.data, session.data, selectedLang);
    const finalImcvalues = getHeightAndWeightValuesObj(session.data);
    setImcValues({ ...finalImcvalues });
    return setFinalData(finalArray);
  }, [session, firstSession.data]);
  useEffect(() => {
    if (imcValues) {
      const { weight, height } = imcValues;
      const fixedHeight = height.value / 100;
      const result = +weight.value / (+fixedHeight * +fixedHeight);
      if (Number.isNaN(result)) {
        return setCalculatedValue(0);
      } else {
        return setCalculatedValue(result);
      }
    }
  }, [imcValues]);
  const bindHandlePos = useDrag((params) => {
    const movement = params.movement[1];
    if (params.dragging) {
      if (movement >= 0 && movement <= SCREEN_HEIGHT) {
        handlePos.y.set(movement);
      }
    } else {
      if (movement > 180) {
        closeTracing();
      } else {
        handlePos.y.start(0);
      }
    }
  });

  const handleBlur = () => {
    const weight = document.getElementById("weight")?.value;
    const height = document.getElementById("height")?.value / 100;
    if (weight && height) {
      const imcObj = {
        height: {
          value: height * 100,
          name: "height",
        },
        weight: {
          value: weight,
          name: "weight",
        },
      };
      const result = weight / (height * height);
      setImcValues(imcObj);
      setCalculatedValue(result);
    }
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const { height, weight } = imcValues;
    const sessionData = {
      id: session.appointment_id,
      patientId: patient.id,
      data: [...finalData, height, weight],
    };

    updateAppointment(patient.id, token, sessionData, selectedLang);
    return setTimeout(() => {
      setLoading(false);
      closeTracing();
    }, 2000);
  };
  const saveImage = async (formData) => {
    const image = await uploadImagesUtil(formData, token);
    return image;
  };
  const sessionDataExistsAndItsAnArray = finalData && Array.isArray(finalData);

  return (
    <TracingWrapper>
      <animated.section style={{ y: handlePos.y, touchAction: "pan-y" }}>
        <div className="App-handle-container" {...bindHandlePos()} style={{ touchAction: "none" }}>
          <div className="App-handle" />
        </div>
        <div className="header">
          <div style={{maxWidth:"540px", margin: "auto", position: "relative"}}>
            <h2>
              {t("treatment.sessionTitle")} {session.session}
            </h2>
            <p className="subtitle">{t("tracing.IMC")}</p>
            <span className="close" onClick={() => closeTracing()}></span>
          </div>
        </div>
        <form onSubmit={(event) => handleSubmit(event)}>
          <p className="titleBlock">{t("tracing.subtitle")}</p>
          <IMC calculatedValue={calculatedValue} handleBlur={handleBlur} values={imcValues} />
          <div className="separator"></div>
          <p className="titleBlock">{t("tracing.data")}</p>
          <div className="otherData">
            <div className="moredatacontent">
              {sessionDataExistsAndItsAnArray && (
                <DataPrinter finalData={finalData} saveImage={saveImage} token={token} />
              )}
            </div>
          </div>
          <div className="buttonContainer">
            {loading ? (
              <ComponentCheck size="40px" color="black" />
            ) : (
              <Button>{t("tracing.button")}</Button>
            )}
          </div>
        </form>
      </animated.section>
    </TracingWrapper>
  );
};
const mapDispatchToProps = (dispatch) => ({
  updateAppointment: (patientId, token, sessionData, selectedLang) =>
    dispatch(updateAppointmentUtil(patientId, token, sessionData, selectedLang)),
});
export default connect(null, mapDispatchToProps)(Tracing);
