import styled, { css } from "styled-components";

export const PolicyWrapper = styled.section`
  position: relative;
`;
export const PolicyContent = styled.section`
  position: relative;
  color: #000000;
  max-width: 930px;
  margin: 0 auto;
  z-index: 1;
  padding: 50px 20px;

  @media (max-width: 930px) {
    margin: 0
  }
  h1 {
    font-family: "hn-light", Arial;
    font-size: 25px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 0;
  }
  article {
    margin-top: 40px;
    div {
      margin: 10px 0;
    }
  }
  .title {
    font-family: "hn-semibold", Arial;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 15px;
  }
  ul {
    font-family: "hn-medium", Arial;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.36px;
    line-height: 18px;
    text-align: left;
    padding-left: 10px;
  }
  li {
    margin-bottom: 10px;
    strong {
      font-family: "hn-semibold", Arial;
      font-weight: 600;
    }
  }
  p {
    font-family: "hn-medium", Arial;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.36px;
    line-height: 18px;
    text-align: left;
    strong {
      font-family: "hn-semibold", Arial;
      font-weight: 600;
    }
    a {
      color: #000000;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-decoration: underline;
    }
  }
`;

export const BottomCTAsWrapper = styled.section`
  @media print {
    display: none;
  }
  max-width: 530px;
  margin: 0 auto;
  width: 95%;
  display: grid;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const ProtocolsCTAs = styled.span`
  padding: 15px;
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  border: 1px solid #000000;
  max-width: 250px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  text-transform: lowercase;
  width: 100%;
  ${({ protocolId }) =>
    protocolId &&
    css`
      border: 1px solid #000000;
      background-color: #000000;
      color: #ffffff;
      margin: 30px auto;
      display: block;
    `}
`;
export const BackButton = styled.p `
  font-family: "hn-semibold", Arial;
  position: absolute;
  left: 60px;
  top: 15px;
  @media (max-width: 600px) {
    left: 20px;
    top: 5px;
  }
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
  padding-left: 0;
  cursor: pointer;
  color: #989ea3;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 23.4px;
  text-align: left;
  background: transparent;
  border: 0;
  z-index:2;

  &::before {
    content: "";
    width: 7px;
    height: 7px;
    border-left: 1px solid #737373;
    border-top: 1px solid #737373;
    transform: rotate(-48deg);
    margin-right: 5px;
    display: inline-block;
  }
`;
