import React from "react";
import { RecommendedProductWrapper, Button, BackButton } from './style';
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";


export const RecommendedProduct = ({
    back,
    name,
    description,
    image,
    concern
}) => {
    const [t] = useTranslation("global");
    const $urlServer = `${process.env.REACT_APP_ANTIAGING_API}storage/bodyshockimages/`;


    return (
        <RecommendedProductWrapper>
            <div className="header">
                <BackButton onClick={()=> back()}>{t("productsBodyshock.back")}</BackButton>
                <img src={`${$urlServer}${image}`} alt={`${name}`} />
                <h3>{name}</h3>
                <p className="subtitle">{concern}</p>
            </div>
            <div className="content">
                {parse(description)}
            </div>

            <div className="content-button" style={{marginTop: 20, paddingLeft: 20, paddingRight: 20}}>
                <Button onClick={()=> back()}>{t("productsBodyshock.ok")}</Button>
            </div>

        </RecommendedProductWrapper>
            
    )

}