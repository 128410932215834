import styled, { css } from "styled-components";
import leftbg from "./images/left-bg.png";
import rightbg from "./images/right-bg.png";
import arrow from "./images/arrow.svg";
import camera from "./images/camera-icon.svg";
import deleteImg from "./images/deleteImg.png";
import help from "./images/professional.svg";
import helpActive from "./images/professional-active.svg";

export const Wrapper = styled.main`
  position: relative;
  z-index: 0;
  width: 100%;
  padding-bottom: 50px;
  .IMCselected {
    border: 1px solid black;
  }
  .imagescontent {
    max-width: 500px;
    margin: auto;
    width: 100%;
  }
  .IMCwrapper {
    max-width: 500px;
    margin: auto;
    width: 100%;
    > div {
      display: flex;
      justify-content: space-around;
      align-items: center;
      p:first-child {
        background-color: lightblue;
      }
      p:nth-child(2) {
        background-color: lightgreen;
      }
      p:nth-child(3) {
        background-color: yellow;
      }
      p:nth-child(4) {
        background-color: orange;
      }
      p:last-child {
        background-color: red;
      }
    }
    > p {
      text-align: center;
    }
  }
  &:before {
    content: url(${leftbg});
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    @media (max-width: 560px) {
      content: none;
    }
  }
  &:after {
    content: url(${rightbg});
    position: absolute;
    right: 0;
    top: 0px;
    z-index: -2;
    @media (max-width: 768px) {
      content: none;
    }
  }
  @media print {
    &:before,
    &:after {
      content: none;
    }
  }
`;

export const QuestionText = styled.p`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
  margin-bottom: 0;
  @media(max-width: 520px) {
    padding: 0 20px;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 15px;
    letter-spacing: 0.31px;
  }
`;

export const Choice = styled.li`
  margin: 10px 0;
  padding: 3px 30px 3px 0;
  list-style: none;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 45px;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  color: #000;
  border-radius: 2px;
  border: 1px solid #e8e9ea;
  background-color: #ffffff;
  font-family: "hn-medium";
  font-weight: 400;
  font-size: 14px;
  span {
    border-radius: 2px;
    background-color: #efefef;
    padding: 3px 7px;
    margin: 0 15px;
  }
  @media(min-width: 1101px) {
    &:hover {
    border: 1px solid #000;
      &:after {
        content: "";
        width: 12px;
        height: 5px;
        transform: rotate(-49deg);
        display: inline-block;
        right: 15px;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
        position: absolute;
      }
      span {
        color: #ffffff;
        background-color: #000;
      }
    } 
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid #000;
      &:after {
        content: "";
        width: 12px;
        height: 5px;
        transform: rotate(-49deg);
        display: inline-block;
        right: 15px;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
        position: absolute;
      }
      span {
        color: #ffffff;
        background-color: #000;
      }
    `}
`;

export const QuestionnaireTitle = styled.h1`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 25px;
  @media (max-width: 540px) {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 26px;
  }
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  text-align: center;
  max-width: 300px;
  margin: 0 auto 35px;
  position: relative;
`;
export const Counter = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 20px;
  margin-bottom: 5px;
  text-align: center;
  span {
    font-family: "hn-semibold", Arial;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
  }
`;
export const QuestionWrapper = styled.section`
  margin: 40px auto;
  padding: 0 20px;
  @media(max-width: 520px) {
    margin: 20px 0 0 0;
    padding: 0;
  }
`;
export const PrevNextButtonsWrapper = styled.div`
  max-width: 355px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  @media(max-width: 520px) {
    margin-top:15px;
    padding: 0 20px;
  }

`;
export const PrevNextButton = styled.button`
  &:focus {
    outline: 0;
  }
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
  padding-left: 0;
  cursor: pointer;
  color: #989ea3;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 23.4px;
  text-align: left;
  text-transform: lowercase;
  background: transparent;
  border: 0;
  &::before {
    content: ${({ prev }) => (prev ? "'';" : "none;")};
    width: 7px;
    height: 7px;
    border-left: 1px solid #737373;
    border-top: 1px solid #737373;
    transform: rotate(-48deg);
    margin-right: 5px;
    display: inline-block;
  }
  ${({ nextBtn }) =>
    nextBtn &&
    css`
      border: 1px solid #000000;
      background-color: #000000;
      color: white;
      padding: 12px 25px;
    `}
  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const QuestionnaireBarWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  height: 3px;
  max-width: 260px;
`;
export const QuestionnaireBarBg = styled.div`
  height: 3px;
  width: 100%;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 0;
`;
export const QuestionnaireBar = styled.div`
  height: 3px;
  border-radius: 3px;
  background-color: #000000;
  width: ${({ position, totalQty }) => `${(position * 100) / totalQty}%`};
  position: absolute;
  top: 0;
  left: 0;
`;

export const FinishTest = styled.p`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
`;
export const FinishButton = styled.p`
  border: 1px solid #000000;
  background-color: #000000;
  width: 100%;
  max-width: 300px;
  margin: 15px auto;
  color: #ffffff;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const GoToFirst = styled.p`
  border: 1px solid #000000;
  width: 100%;
  max-width: 300px;
  margin: 15px auto;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const AnswerError = styled.p`
  background-color: #ffe7e7;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.26px;
  line-height: 26px;
  text-align: center;
  max-width: 384px;
  margin: 5px auto 25px auto;
`;

export const ProfessionalIconWrapper = styled.div`
  position: absolute;
  right: 0;
  @media (min-width: 600px) {
    right: -30px;
    left: unset;
  }
`;
export const ProfessionalIcon = styled.span`
  &.active {
    background: url(${helpActive}) no-repeat center;
  }
  background: url(${help}) no-repeat center;
  width: 51px;
  height: 47px;
  display: inline-block;

  @media (min-width: 1100px) {
    &:hover {
      background: url(${helpActive}) no-repeat center;
      & ~ p {
        display: block;
      }
    }
  }
`;

export const ProfessionalAdvice = styled.p`
  display: ${({ active }) => (active ? "block" : "none")};
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
  z-index: 2;
  position: absolute;
  right: 0;
  border: 1px solid #ededed;
  padding: 30px 20px;
  border-radius: 30px;
  background-color: rgba(248, 248, 248, 1);

  width: 270px;
  @media (min-width: 600px) {
    position: absolute;
    left: -25px;
    right: unset;
  }
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    top: -4px;
    left: 40px;
    @media (max-width: 600px) {
      left: unset;
      right: 40px;
    }
    border-left: 1px solid #ededed;
    border-top: 1px solid #ededed;
    transform: rotate(45deg);
    background-color: rgba(248, 248, 248, 0.82);
  }
`;
export const Notes = styled.div`
  text-align: center;
  textarea {
    border: 1px solid #ededed;
    max-width: 300px;
    width: 100%;
    font-family: "hn-medium", Arial;
    font-weight: 400;
    font-size: 14px;
    border-radius: 2px;
    padding: 15px;
    outline: none;
    &::placeholder {
      font-family: "hn-medium", Arial;
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

export const OtherData = styled.div`
  max-width: 500px;
  margin: auto;
  width: 100%;
  .moredatacontent {
    border-bottom: 1px solid #f2f2f2;
    div:nth-child(5) {
      padding-bottom: 12px;
      border-bottom: 1px solid #f2f2f2;
    }
    p {
      font-size: 12px;
      line-height: 15px;
    }
    @media(max-width: 520px) {
      padding: 0 20px;
    }
  }
`;

export const MoreData = styled.div`
  margin: 0 auto;
  padding: 3px 10px;
  background-color: #f2f2f2;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  max-width: 464px;
  @media(max-width: 520px) {
    font-size: 10px;
  }
  &:after {
    content: "";
    background: url(${arrow}) no-repeat center;
    width: 10px;
    height: 6px;
    display: inline-block;
    padding-left: 5px;
    ${({ open }) => (!open ? `transform: rotate(180deg);` : `transform: rotate(0deg);`)};
  }
`;

export const FieldInput = styled.p`
  font-family: "hn-medium", Arial;
  font-weight: 400;
  font-size: 14px;
  line-height: 23.4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 520px) {
    display: grid;
    grid-template-columns: 80px 160px 30px;
    justify-content: space-between;
    font-size: 12px;
    line-height: 15px;
    input {
      min-width: unset !important;
    }
    label.label {
      text-align: right;
    }
    .inputLabel {
      text-align: right;
      padding-left: 0;
    }
  }
  .label {
    width: 145px;
    margin-right: 8px;
    text-align: right;
    @media (max-width: 520px) {
      width: unset;
      text-align: right;
    }
  }
  .inputLabel {
    padding-left: 8px;
  }
  input {
    border: 1px solid #e8e9ea;
    border-radius: 2px;
    height: 45px;
    min-width: 270px;
    text-align: center;
    color: #000000;
    outline: none;
    &::placeholder {
      font-family: "hn-medium", Arial;
      font-weight: 400;
      font-size: 12px;
      color: #979da2;
    }
  }
`;

export const FieldInputFile = styled.div`
  display: inline-block;
  width: 48%;
  margin-top: 12px;
  margin-bottom: 17px;
  &.frontal, &.lateral_izquierda {
    margin-right: 10px;
  }
  p {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .label {
    width: 100%;
    height: 105px;
    border: 1px dashed #e8e9ea;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aeaeae;
    flex-direction: column;
    font-size: 12px;
    color: #979da2;
    text-align: center;
    @media (min-width: 520px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    cursor: pointer;
    &::before {
      content: url(${camera});
      margin-bottom: 4px;
    }
    &.hide {
      border: none;
      visibility: hidden;
    }
    span {
      max-width: 116px;
    }
  }
  input[type="file"] {
    &.show {
      height: calc(100% - 25px);
    }
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    cursor: pointer;
  }

  .img-container {
    display: flex;
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 105px;
    object-fit: contain;
    max-width: 95px;
  }
  .deleteImg {
    background: url(${deleteImg}) no-repeat center;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    display: block;
    cursor: pointer;
  }
`;

export const MultipleSelect = styled.div`
  .zonaTratamiento {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 10px 0;
    display: grid;
    grid-template-columns: 218px 120px;

    > div {
      display: flex;
      align-items: center;
      span {
        padding-left: 5px;
      }
    }
    @media (max-width: 520px) {
      display: grid;
      grid-template-columns: 180px 120px;
    }
  }
  li {
    margin: 0;
    max-width: 256px;
  }
  input {
    max-width: 89px;
    border-radius: 2px;
    border: 1px solid #e8e9ea;
    background-color: #ffffff;
    text-align: center;
    height: 45px;
  }
  .infoExtra {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;
    .infoZona {
      flex-basis: auto;
    }
    .infoPerimetro {
      flex-basis: 117px;
    }
  }
`;
export const MultipleAnswer = styled.div`
  .subtitle {
    margin-bottom: 20px;
    font-size: 14px;
  }
`;