import styled from "styled-components";

export const HeaderWrapper = styled.header`
  background-color: #fff;
  padding: 12px 50px;
  height: 65px;
  border-bottom: 1px solid #323e48;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 520px) {
    padding: 12px 20px;
  }
  @media print {
    display: block;
    margin: 0 auto;
    padding: 12px;
    text-align: center;
  }
  .logo-box {
    position: relative;
    cursor: pointer;
    display: flex;
    @media print {
      display: none;
    }
    p {
      color: #ffffff;
      font-family: "hn-medium", Arial;
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.36px;
      line-height: 26px;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }
`;
export const Img = styled.img``;
export const UserLogo = styled.img`
  cursor: pointer;
  border-radius: 50%;
  @media print {
    display: none;
  }
`;
export const UserMenuWrapper = styled.section`
  @media print {
    display: none;
  }
  display: ${({ active }) => (active ? "block" : "none")};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  background-color: #f2f2f2;
`;
export const UserMenuTitle = styled.h2`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  text-align: left;
  padding-left: 25px;
  margin-bottom: 25px;
`;
export const UserMenuOkBtn = styled.span`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
export const UserMenuInfo = styled.article`
  border: 1px solid #e2e2e2;
  background-color: #ffffff;
  padding: 10px 25px;
  display: flex;
  align-items: center;
`;
export const UserMenuInfoInnerWrapper = styled.div`
  margin-left: 10px;
`;
export const UserMenuClinicName = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.39px;
  line-height: 20px;
  text-align: left;
  margin: 0;
`;
export const UserMenuClinicEmail = styled.p`
  color: #323e48;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  margin: 0;
  text-align: left;
`;
export const UserMenuLegals = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f2f2f2;
  background-color: white;
`;
export const UserMenuLegalsTabs = styled.div`
  padding: 10px 25px;
  flex-basis: 50%;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
`;
export const UserMenu = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 12px;
`;
export const UserMenuOptions = styled.li`
  border-bottom: 1px solid #e2e2e2;
  background-color: #ffffff;
  padding: 15px 10px 15px 25px;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
  position: relative;

  &.submenu::before {
    content: "";
    width: 5px;
    height: 5px;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    transform: rotate(225deg);
    display: block;
    top: 22px;
    position: absolute;
    right: 20px;
  }
`;
export const UserMenuLanguagesWrapper = styled.section`
  @media print {
    display: none;
  }
  border-radius: 10px;
  background-color: #f2f2f2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  background-color: #f2f2f2;
`;
export const UserMenuLanguagesInnerWrapper = styled.div`
  position: relative;
`;
export const UserMenuLanguageBack = styled.span`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: left;
  position: absolute;
  top: 3px;
  left: 20px;
  cursor: pointer;
  &::before {
    content: "";
    width: 4px;
    height: 4px;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    transform: rotate(45deg);
    margin-right: 3px;
    display: block;
  }
`;
export const UserMenuLanguageTitle = styled.h2`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 26px;
  text-align: center;
`;
export const UserMenuLanguageLabel = styled.p`
  border: 1px solid #e2e2e2;
  background-color: #ffffff;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
  margin: 0;
  padding: 10px 35px;
  &:last-child {
    border-radius: 0 0 10px 10px;
  }
`;
