import axios from "axios";
import IMC from "components/bodyshock/IMC";
import { getPrivateImage } from "components/bodyshock/Tracing/utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AnswerError,
  Choice,
  QuestionText,
  Notes,
  FieldInputFile,
  OtherData,
  MoreData,
  FieldInput,
  MultipleSelect,
  PrevNextButton,
  PrevNextButtonsWrapper,
  MultipleAnswer,
} from "./style";

const letters = ["A", "B", "C", "D", "E", "F"];
const choicesSort = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};
export var RenderSelectQuestions = function ({
  question,
  position,
  handleClick,
  answerError,
  handleChange,
  backQuestion,
  nextQuestion,
  saveImage,
}) {
  const [t] = useTranslation("global");
  const [answerSelected, setAnswerSelected] = useState(false);
  const [choiceIndex, setChoiceIndex] = useState(false);
  const [subQuotingPosition, setSubquotingPosition] = useState(0);
  const [subQuotingError, setSubquotingError] = useState(false);
  const subQuotingBack = () => {
    if (subQuotingPosition === 0) {
      return setAnswerSelected(false);
    }
    return setSubquotingPosition(subQuotingPosition - 1);
  };
  const checkAnswer = (choice, question) => {
    if (!choice.subQuotingArray) {
      return handleClick(choice, question);
    }
    const choiceID = +question.choices.findIndex((selection) => selection.id === choice.id);
    setAnswerSelected(true);
    setChoiceIndex(choiceID);
  };
  const handleSubClick = (choice, question) => {
    question.choices.map((choice) => (choice.selected = false));
    choice.selected = true;
    nextSubquoting();
  };
  const nextSubquoting = () => {
    let correct = false;
    question.choices[choiceIndex].subQuotingArray[subQuotingPosition].choices.map((choice) => {
      if (choice.selected) return (correct = true);
      return false;
    });
    if (!correct) return setSubquotingError(true);
    if (subQuotingPosition < question.choices[choiceIndex].subQuotingArray.length - 1) {
      setSubquotingPosition(() => subQuotingPosition + 1);
      return setSubquotingError(false);
    }
    if (subQuotingPosition === question.choices[choiceIndex].subQuotingArray.length - 1) {
      setAnswerSelected(false);
      return handleClick(question.choices[choiceIndex], question);
    }
  };
  if (!answerSelected) {
    return RenderQuestion({
      question,
      answerError,
      checkAnswer,
      handleChange,
      t,
      index: position,
      position,
      backQuestion,
      nextQuestion,
      saveImage,
    });
  }
  if (answerSelected) {
    const questions = [...question.choices[choiceIndex].subQuotingArray];
    return RenderQuestion({
      question: questions[subQuotingPosition],
      answerError: subQuotingError,
      checkAnswer: handleSubClick,
      handleChange,
      t,
      index: `${position + 1}.${subQuotingPosition + 1}`,
      position: subQuotingPosition,
      backQuestion: subQuotingBack,
      nextQuestion: nextSubquoting,
      answerSelected,
      saveImage,
    });
  }
};
const RenderQuestion = ({
  question,
  answerError,
  checkAnswer,
  handleChange,
  t,
  index,
  backQuestion,
  nextQuestion,
  answerSelected,
  saveImage,
}) => {
  const [calculatedValue, setCalculatedValue] = useState(0);
  const [someSelected, setSomeSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const handleSelection = (choice) => {
    let counter;
    if (question.type === "multipleSelect" || question.type === "multipleAnswer") {
      counter = 1;
      question.choices.forEach((choice) => {
        if (choice.selected) counter++;
      });
      if (counter <= question.maxSelection || choice.selected) {
        choice.selected = !choice.selected;
      }
    } else {
      choice.selected = !choice.selected;
    }
    setSomeSelected(!someSelected);
  };
  const handleBlur = (e, choice, questionParam) => {
    const weight = document.getElementById("weight")?.value;
    const height = document.getElementById("height")?.value / 100;
    if (weight && height) {
      const result = weight / (height * height);
      question.value = result;
      question.weight = weight * 1;
      question.height = height * 100;
      setCalculatedValue(result);
    }
    if (choice && questionParam) {
      if (!e.target.value) {
        return (choice.selected = false);
      }
      choice.input = e.target.value;
    }
  };
  switch (question.type) {
    case "valueInsert":
      return (
        <div key={question.text}>
          <QuestionText>{`${answerSelected ? index + " - " : ""} ${question.text}`}</QuestionText>
          {answerError && <AnswerError>{t("questionnaire.errorBMI")}</AnswerError>}

          {question.calculator && <IMC calculatedValue={calculatedValue} handleBlur={handleBlur} values={question} />}
          <div className="imagescontent">
            {question.data.filter(field => field.type === "image").map((field, index) => FieldSwitcher(field, index, saveImage))}
          </div>
          {question.data && (
            <OtherData>
              <MoreData open={open} onClick={() => setOpen(!open)}>
                {t("questionnaire.otherData")}
              </MoreData>
              <div className="moredatacontent" style={{ display: open ? "block" : "none" }}>
                {question.data.filter(field => field.type === "text").map((field, index) => FieldSwitcher(field, index, saveImage))}
              </div>
            </OtherData>
          )}

          {question.withNote && (
            <Notes>
              <textarea
                placeholder={question.withNote}
                onChange={(e) => handleChange(e, question)}
              ></textarea>
            </Notes>
          )}

          {backNextButtons({ backQuestion, nextQuestion, t })}
        </div>
      );
    case "multipleSelect":
      return (
        <MultipleSelect key={question.text}>
          <QuestionText>{`${answerSelected ? index + " - " : ""} ${question.text}`}</QuestionText>
          {answerError && <AnswerError>{t("questionnaire.error")}</AnswerError>}

          <ul
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              maxWidth: 385,
              margin: "auto",
              marginTop: 25,
            }}
          >
            <div className="infoExtra">
              <span className="infoZona">{question.subText}</span>
              <span className="infoPerimetro">{`${question.param} ${question.symbol}`}</span>
            </div>
            {question.choices.sort(choicesSort).map((choice, index) => {
              return (
                <div className="zonaTratamiento" key={choice.text}>
                  <Choice
                    selected={choice.selected}
                    onClick={() => {
                      handleSelection(choice);
                      if (choice.selected && document.getElementById(choice.id))
                        document.getElementById(choice.id).focus();
                    }}
                  >
                    <span>{letters[index]}</span> {choice.text}
                  </Choice>
                  <div>
                    {choice.input && (
                      <input
                        id={choice.id}
                        type="text"
                        defaultValue={choice.input}
                        onBlur={(e) => handleBlur(e, choice, question)}
                        onClick={() => {
                          if (!choice.selected) return handleSelection(choice);
                        }}
                      />
                    )}
                    {choice.symbol && <span className="inputLabel">{choice.symbol}</span>}
                  </div>
                </div>
              );
            })}
          </ul>
          {question.withNote && (
            <div>
              <textarea
                placeholder={question.withNote}
                onChange={(e) => handleChange(e, question)}
                defaultValue={question.noteValue}
              ></textarea>
            </div>
          )}
          {question.calculator && (
            <div>
              <p>
                {t("questionnaire.weight")}
                <input type="text" id="weight" name="weight" onBlur={handleBlur} />
                Kg
              </p>
              <p>
                {t("questionnaire.height")}{" "}
                <input
                  type="text"
                  name="height"
                  id="height"
                  onBlur={handleBlur}
                  onChange={handleBlur}
                />
                cm
              </p>
              <div>
                <p>
                  <strong>Cálculo automático del (IMC)</strong>
                  <input type="text" value={calculatedValue.toFixed(2)} disabled />
                </p>
              </div>
            </div>
          )}
          {question.data && (
            <div>
              <p onClick={() => setOpen(!open)}>Añadir otros datos: </p>
              <div style={{ display: open ? "block" : "none" }}>
                {question.data.map((field, index) => FieldSwitcher(field, index, saveImage))}
              </div>
            </div>
          )}
          {backNextButtons({ backQuestion, nextQuestion, t })}
        </MultipleSelect>
      );
    case "multipleAnswer":
      return (
        <MultipleAnswer key={question.text}>
          <QuestionText>{`${answerSelected ? index + " - " : ""} ${question.text}`}</QuestionText>
          {answerError && <AnswerError>{t("questionnaire.error")}</AnswerError>}

          <ul
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              maxWidth: 340,
              margin: "auto",
              marginTop: 25,
            }}
          >
            <div className="subtitle">
              <span>{question.subText}</span>
            </div>
            {question.choices.sort(choicesSort).map((choice, index) => {
              return (
                <div key={choice.text}>
                  <Choice
                    selected={choice.selected}
                    onClick={() => {
                      handleSelection(choice);
                    }}
                  >
                    <span>{letters[index]}</span> {choice.text}
                  </Choice>
                </div>
              );
            })}
          </ul>
          {question.withNote && (
            <Notes>
              <textarea
                placeholder={question.withNote}
                onChange={(e) => handleChange(e, question)}
                defaultValue={question.noteValue}
              ></textarea>
            </Notes>
          )}
          {backNextButtons({ backQuestion, nextQuestion, t })}
        </MultipleAnswer>
      );

    default:
      return (
        <div key={question.text} style={{ marginBottom: 30 }}>
          <QuestionText>{`${question.text}`}</QuestionText>
          {answerError && <AnswerError>{t("questionnaire.error")}</AnswerError>}

          <ul
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              maxWidth: 340,
              margin: "auto",
              marginTop: 25,
            }}
          >
            {question.choices.sort(choicesSort).map((choice, index) => {
              return (
                <div key={choice.text}>
                  <Choice selected={choice.selected} onClick={() => checkAnswer(choice, question)}>
                    <span>{letters[index]}</span> {choice.text}
                  </Choice>
                  {choice.input && (
                    <input
                      type="text"
                      defaultValue={choice.input}
                      onBlur={(e) => handleBlur(e, choice, question)}
                    />
                  )}
                </div>
              );
            })}
          </ul>
          {question.withNote && (
            <Notes>
              <textarea
                placeholder={question.withNote}
                onChange={(e) => handleChange(e, question)}
                defaultValue={question.noteValue}
              ></textarea>
            </Notes>
          )}
          {question.calculator && (
            <IMC question={question} calculatedValue={calculatedValue} handleBlur={handleBlur} />
          )}
          {question.data && (
            <OtherData>
              <MoreData open={open} onClick={() => setOpen(!open)}>
                {t("questionnaire.otherData")}
              </MoreData>
              <div className="moredatacontent" style={{ display: open ? "block" : "none" }}>
                {question.data.map((field, index) => FieldSwitcher(field, index, saveImage))}
              </div>
            </OtherData>
          )}
          {backNextButtons({ backQuestion, nextQuestion, t })}
        </div>
      );
  }
};
export const FieldSwitcher = (field, index, saveImage, token) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    const itsImageOrFile = field.type === "image" || field.type === "file";
    if (itsImageOrFile && field.value === "0") {
      field.value = null;
    }
    if (field.value) setValue(field.value);
    if(field.blob) setValue(field.blob);
    if (Boolean(Number(field.input))) setValue(field.input);
    if (itsImageOrFile && token && field.value && !field.blob) {
      handleAsync();
    }
  }, [field, token]);
  let handleFiles = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append(e.target.name, file);
    const url = getUrlFromFile(file);
    const imgName = await saveImage(formData);
    field.value = imgName;
    field.blob = url;
    setValue(url);

  };
  let deleteFile = (e) => {
    field.value = null;
    field.blob = null;
    setValue("");
  };
  let handleText = (e) => {
    setValue(e.target.value);
    field.value = e.target.value;
  };
  const handleAsync = async () => {
    let result = await getPrivateImage(token, field.value);
    setValue("data:image/gif;base64, " + result);
  };
  switch (field.type) {
    case "image":
    case "file":
      return (
        <FieldInputFile key={index} className={field.name}>
          <p>
            <label className={`label ${value ? "hide" : ""}`}>
              <span>{field.text}</span>
            </label>
            <input
              className={`${value ? "show" : ""}`}
              type="file"
              name={field.name}
              onChange={(e) => handleFiles(e)}
              accept="image/*"
              capture
            />

            {value ? (
              <span className="img-container">
                <img src={value} alt={field.name} />
                <span onClick={(e) => deleteFile(e)} className="deleteImg"></span>
              </span>
            ) : (
              ""
            )}
          </p>
        </FieldInputFile>
      );
    default:
      return (
        <div key={index}>
          <FieldInput>
            <label className="label">{field.text}</label>
            <input
              type="text"
              defaultValue={value}
              name={field.name}
              onChange={(e) => handleText(e)}
              placeholder="0"
            />
            <span className="inputLabel">{field.symbol}</span>
          </FieldInput>
        </div>
      );
  }
};
const backNextButtons = ({ backQuestion, nextQuestion, t }) => {
  return (
    <PrevNextButtonsWrapper>
      <PrevNextButton prev={true} onClick={backQuestion}>
        {t("questionnaire.back")}
      </PrevNextButton>
      <PrevNextButton nextBtn={true} onClick={nextQuestion}>
        {t("questionnaire.next")}
      </PrevNextButton>
    </PrevNextButtonsWrapper>
  );
};
const getUrlFromFile = (file) => {
  const url = URL.createObjectURL(file);
  return url;
};
export const uploadImagesUtil = async (formData, token) => {
  return axios
    .post(`${process.env.REACT_APP_ANTIAGING_API}api/bodyshock/protocol/images`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.image;
    })
    .catch(console.error);
};
