import { GETLANGUAGES, SETLANGUAGE } from "../constants/CountryLang";

export default (
  state = { languagesAvailables: [], selectedLanguage: navigator.language },
  action
) => {
  switch (action.type) {
    case GETLANGUAGES:
      return Object.assign({}, state, {
        languagesAvailables: [...action.payload],
      });
    case SETLANGUAGE:
      return Object.assign({}, state, { selectedLanguage: action.payload });
    default:
      return state;
  }
};
