import fase0 from './protocol/fase0.jpg';
import fase1 from './protocol/fase1.jpg';
import fase2_1 from './protocol/fase2_1.jpg';
import fase2_2 from './protocol/fase2_2.jpg';
import fase3 from './protocol/fase3.jpg';
import fase4 from './protocol/fase4.jpg';
import sculpting_mask from './protocol/sculpting_mask.jpg';
import tightening_mask from './protocol/tightening_mask.jpg';

import adiposidad_localizada from './about/adiposidad_localizada.jpg';
import celulitis from './about/celulitis.jpg';
import estrias from './about/estrias.jpg';
import flacidez from './about/flacidez.jpg';
import ico_adiposidad from './about/ico_adiposidad.svg';
import ico_celulitis from './about/ico_celulitis.svg';
import ico_flacidez from './about/ico_flacidez.svg';
import ico_estrias from './about/ico_estrias.svg';
import innovacion from './about/innovacion.svg';
import sinergia from './about/sinergia.svg';
import protocolo from './about/protocolo.svg';
import tecnologia from './about/tecnologia.svg';
import cartinina from './about/cartinina.svg';


export default {
  fase0,
  fase1,
  fase2_1,
  fase2_2,
  fase3,
  fase4,
  adiposidad_localizada,
  celulitis,
  estrias,
  flacidez,
  ico_adiposidad,
  ico_celulitis,
  ico_estrias,
  ico_flacidez,
  sinergia,
  innovacion,
  protocolo,
  tecnologia,
  cartinina,
  sculpting_mask,
  tightening_mask
};
