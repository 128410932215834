import axios from "axios";
import {
  getQuestionnaireAction,
  setQuestionnaireAction,
} from "../../actions/Patient";
import { errorProtocol, getProtocolsAction } from "../../actions/Protocols";
import { LogOut } from "../transverse/User";
export const getQuestionnaire = (token, language) => (dispatch) => {
  const lang = language.split("-");
  axios
    .get(`/questionnaire/questionnaire-${lang[0]}.json`)
    .then(({ data }) => {
      dispatch(getQuestionnaireAction(data));
    })
};
export const sendQuestionnaire = (questionnaire, token, history) => (
  dispatch
) => {
  dispatch(setQuestionnaireAction(questionnaire));
  axios
    .post(`${process.env.REACT_APP_ANTIAGING_API}api/token`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => {
  
      axios
        .post(
          `${process.env.REACT_APP_ANTIAGING_API}api/antiaging/protocol`,
          questionnaire,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(({ data }) => {
          dispatch(getProtocolsAction(data));
          dispatch(errorProtocol(null));
        })
        .catch(({ response }) => dispatch(errorProtocol(response.data)));
    })
    .catch(() => dispatch(LogOut(history)));
};
