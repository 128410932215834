import { combineReducers } from "redux";
import user from "./User";
import patient from "./Patient";
import languages from "./Languages";
import questionnaire from "./Questionnaire";
import protocols from "./Protocols";
import patients from "./Patients";
import application from "./transverse/Application";
import notification from "./Notification";
import products from "./transverse/Products";
export default combineReducers({
  user,
  patient,
  languages,
  questionnaire,
  protocols,
  patients,
  application,
  notification,
  products,
});
