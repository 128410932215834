import React from "react";
import { MassageWrapper } from './style';
import { useTranslation } from "react-i18next";

export const Massage = ({
    slideIndex,
    name,
    url
}) => {
    const [t] = useTranslation("global");

    return (
        <MassageWrapper className="swiper-Slide swiper-slide swiper-massage" data-index={slideIndex} data-type={t("phase.massages")}>
            <div className="content">
                <iframe 
                    src={`https://player.vimeo.com/video/${url}`}
                    // width="560" height="315"
                    width="100%"
                    height="360"
                    frameBorder="0" 
                    allow="autoplay; fullscreen; picture-in-picture"
                    title="vimeo-player"
                ></iframe>
                <h3>{name}</h3>
            </div>

        </MassageWrapper>

    )

}
