import styled from "styled-components";
import icoProfessional from './images/ico-professional.svg';
import icoProfessionalActive from './images/ico-professionalActive.svg';
import icoDomiciliario from './images/ico-dom.svg';
import icoDomiciliarioActive from './images/ico-domActive.svg';
import icoIDtest from './images/ico-IDtest.svg';
import icoResources from './images/ico-resources.svg';
import icoHome from './images/ico-home.svg';


export const NavigatonBarWrapper = styled.div`
  background-color: rgba(248, 248, 248);
  box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.3);
  padding-top: 6px;
  padding-bottom: 20px;
  width: 100%;
  z-index: 2;
  bottom: 0;
  position: fixed;

  .navigation-bar__innerbox {
    max-width: 260px;
    margin: 0 auto;
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    
    a {
      color: #323e48;
      font-family: hn-medium, Arial;
      font-size: 10px;
      letter-spacing: 0.3px;
      line-height: 12px;
      text-align: center;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      &:before {
        content: '';
        display: block;
        margin-bottom: 5px;
      }

      &:not(.active) {
        color: #8e8e93;
      }
      &.professional {
        &:before {
          background: url(${icoProfessional}) no-repeat top center;
          width: 31px;
          height: 25px;
        }
        &.active {
          &:before {
            background: url(${icoProfessionalActive}) no-repeat top center;
          }
        }
      }
      &.domiciliario {
        &:before {
          background: url(${icoDomiciliario}) no-repeat top center;
          width: 26px;
          height: 25px;
        }
        &.active {
          &:before {
            background: url(${icoDomiciliarioActive}) no-repeat top center;
          }
        }
      }
      &.home {
        &:before {
          background: url(${icoHome}) no-repeat top center;
          width: 25px;
          height: 25px;
        }
      }
      &.IDtest {
        &:before {
          background: url(${icoIDtest}) no-repeat top center;
          width: 21px;
          height: 21px;
        }
      }
      &.resources {
        &:before {
          background: url(${icoResources}) no-repeat top center;
          width: 26px;
          height: 26px;
        }
      }
    }
  }

`;
