import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientDataWrapper } from "./styles";
import { animated, useSpring } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import icoUser from "./images/ico-user.svg";
import { disableBodyScroll, enableBodyScroll } from "../HomeTreatment/utils";

export default ({ patient, handleEdit, edit, application }) => {
  const [t] = useTranslation("global");
  const [seePatient, setSeePatient] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const openPatientView = () => {
    setSeePatient(!seePatient);
    setScrollPosition(window.scrollY);
    disableBodyScroll();
    document.getElementsByClassName(`${application}`)[0].style.zIndex = 4;
  }
  const closePatientView = () => {
    setSeePatient(!seePatient);
    enableBodyScroll(scrollPosition);
    document.getElementsByClassName(`${application}`)[0].style.zIndex = 3;
  }
  const handleEditView = () => {
    handleEdit(!edit);
  }
  const SCREEN_HEIGHT = window.innerHeight - 30;
  const handlePos = useSpring({ y: 0 });
  const bindHandlePos = useDrag((params) => {
    const movement = params.movement[1];
    if (params.dragging) {
      if (movement >= 0 && movement <= SCREEN_HEIGHT) {
        handlePos.y.set(movement);
      }
    } else {
      if (movement > 180) {
        closePatientView();

      } else {
        handlePos.y.start(0);
      }
    }
  });
  return (
    <PatientDataWrapper className={application}>
      <>
        {seePatient && (
          <div className="overlay-editpatient" onClick={() => closePatientView()} >

          </div>
        )}
        <div style={{position: "relative"}}>
          <div className="line"></div>
          <div className="toggle" onClick={() => openPatientView()}>
            <img src={icoUser} alt="icon user" />
            <div>
              <p className="name">
                {patient.name} {patient.last_name}
              </p>
              <span>{t("patients.patientData")}</span>
            </div>
          </div>

          <animated.div
            className={seePatient ? "contentToggle active" : "contentToggle"}
            style={{ y: handlePos.y, touchAction: "pan-y" }}
          >
            <div className='App-handle-container' {...bindHandlePos()} style={{ touchAction: "none" }}>
              <div className='App-handle' />
            </div>
            <div className="header">
              <div className="contentName">
                <span className="close" onClick={() => closePatientView()}></span>
                <span className="editButton" onClick={() => handleEditView()}>{t("patients.edit")}</span>
                <p className="name">{patient.name} {patient.last_name}</p>
              </div>

            </div>
            <div className="content">
              <div>
                <p>
                  {t("register.name")}:{" "}
                  <span>
                    {patient.name}, {patient.last_name}
                  </span>
                </p>
                <p>
                  {t("register.email")}: <span>{patient.email}</span>
                </p>
                <p>
                  {t("register.phoneNumber")}: <span>{patient.phone_number}</span>
                </p>
                <p>
                  {t("register.address")}: <span>{patient.address || "-"}</span>
                </p>
                <p>
                  {t("register.dob")}: <span>{patient.date_of_birth || "-"}</span>
                </p>
              </div>

            </div>
            <div className="otherData">
              <div>
                <p className="title">
                  {t("register.other")}:
                </p>
                <div className="otherData-content">
                  <p>{patient.considerations || "-"}</p>
                </div>
              </div>
            </div>
          </animated.div>

        </div>
      </>

    </PatientDataWrapper>
  );
};
