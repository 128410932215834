import styled from "styled-components";

export const IMCWrapper = styled.div`
  max-width: 500px;
  margin: auto;
  width: 100%;
  @media(max-width: 520px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .imcContainer {
    border-top: 1px solid #f2f2f2;
    padding-top: 20px;
    @media(max-width: 520px) {
      padding-top: 15px;
      padding-bottom: 20px;
    }
    .imcValues {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      max-width: 465px;
      width: 100%;
      height: 26px;
      color: #000;
      font-size: 12px;
      font-family: hn-regular;
      font-weight: 400;
      margin: auto;

      @media(max-width: 520px) {
        font-size: 10px;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > .active {
        border: 1px solid #000;
      }
    }
    .imcResults {
      margin-top: 15px;
      @media(max-width: 520px) {
        margin-top: 10px;
      }
      p {
        display: block;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        @media(max-width: 520px) {
          font-size: 12px;
          letter-spacing: 0.31px;
        }

        span {
          font-family: hn-medium;
          font-weight: 400;
        }
        strong {
          font-family: hn-semibold;
          font-weight: 600;
        }
      }
    }
    .underweight {
      background-color: #b5efed;
    }
    .healthy {
      background-color: #c1f0d1;
    }
    .overweight {
      background-color: #fdeab6;
    }
    .obesity1 {
      background-color: #fdd2b6;
    }
    .obesity2 {
      background-color: #fdb6b6;
    }
  }
  p {
    font-family: "hn-medium", Arial;
    font-weight: 400;
    font-size: 14px;
    line-height: 23.4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 520px) {
      justify-content: space-between;
      display: grid;
      grid-template-columns: 80px 160px 30px;
      justify-content: space-between;
      font-size: 12px;
      input {
        min-width: unset;
      }
      label.label {
        text-align: right;
      }
      .inputLabel {
        text-align: right;
        padding-left: 0;
      }
    }

    .label {
      width: 145px;
      margin-right: 8px;
      text-align: right;
      @media (max-width: 520px) {
        width: unset;
      }
    }
    .inputLabel {
      padding-left: 8px;
    }
  }
  input {
    border: 1px solid #e8e9ea;
    border-radius: 2px;
    height: 45px;
    min-width: 270px;
    text-align: center;
    color: #000000;
    outline: none;
    &::placeholder {
      font-family: "hn-medium", Arial;
      font-weight: 400;
      font-size: 12px;
      color: #979da2;
    }
  }
  > div {
    padding-top: 15px;
    border-top: 1px solid #f2f2f2;
    padding-bottom: 30px;
    @media(max-width: 520px) {
      padding-bottom: 15px;
    }
    p {
      font-family: "hn-medium", Arial;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      flex-wrap: nowrap;
      justify-content: center;
      margin: 0;
      @media (max-width: 520px) {
        display: grid;
        grid-template-columns: 150px 100px 30px;
        justify-content: space-between;
        label.label {
          width: unset;
          font-size: 10px;
          text-align: left;
        }
      }

      .label {
        text-align: left;
        width: 246px;
        strong {
          font-family: "hn-semibold", Arial;
          font-weight: 600;
        }
      }
      input {
        min-width: unset;
        max-width: 115px;
        color: #000;
        font-family: "hn-semibold", Arial;
        font-weight: 600;
        border: 1px solid #000000;
      }
    }
  }
`;