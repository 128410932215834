export default {
  home: "home",
  newpatient: "newpatient",
  start: "start",
  treatment: "treatment",
  faq: "faq",
  legalNotice: "legal-notice",
  resources: "resources",
  patient: "patient",
  patients: "patients",
  about: "about",
  protocol: "protocol",
  material: "material",
  advices: "advices",
  abdomen: "abdomen",
  arms: "arms",
  buttocks: "buttocks",
  legs: "legs",
  breasts: "breasts",
  chin: "chin",
  login: "login",
  register: "register",
  selector: "selector",
  professionalTreatment: "treatment/professional",
  homeTreatment: "treatment/home",
  homeAntiaging: "/antiaging",
  homeBodyshock: "/bodyshock",
  helpPage: "help-page"
};
