import styled from 'styled-components';
import closeIcon from './images/ico-close.svg';
import check from './images/check.svg';

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    position: fixed;
    z-index: 3;
    opacity: 0.3;
    top: 0;
    left: 0;
    cursor: pointer;
`;

export const MassageEditWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 4;
    background: white;
    max-width: 464px;
    width: 90%;
    text-align: center;
    @media(max-width: 600px) {
        max-height: 95%;
        overflow-y: scroll;
    }

    .header {
        padding: 20px 20px 35px;
        background-color: white;
    }
    h3 {
        margin: 0;
        text-align: center;
        font-size: 18px;
        font-family: hn-semibold;
        font-weight: 600;
        line-height: 30px;
    }
    .subtitle {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
    }
    .close {
        background: url(${closeIcon}) no-repeat center;
        display: inline-block;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 15px;
        left: 20px;
        cursor: pointer;
        z-index: 3;
    }
    .content-products {
        border-bottom: 1px solid #f2f2f2;
        font-size: 12px;
        letter-spacing: 0.39px;
        line-height: 18px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        .product-content {
            border-radius: 2px;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 380px;
            margin: auto;
            margin-bottom: 10px;
            align-items: center;
            text-align: left;
            padding: 12px 14px;
            cursor: pointer;
            border: 1px solid #e8e9ea;
            position: relative;
            span:first-child {
                padding-right: 5px
            }
            &.active {
                cursor: pointer;
                pointer-events: unset;
                border: 1px solid #000000;
                span:last-child {
                    padding-right: 14px;
                }
                &:after {
                    content: '';
                    display: inline-block;
                    background: url(${check}) no-repeat center;
                    position: absolute;
                    width: 12px;
                    height: 9px;
                    top: 18px;
                    right: 13px;
                }
            }
        }
    }
`;

export const Button = styled.div`
    background: black;
    color: white;
    max-width: 354px;
    height: 40px;
    font-family: hn-semibold;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.34px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    @media (min-width: 1100px) {
        &:hover {
        text-decoration: underline;
        }
    }
`;
