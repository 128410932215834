import axios from "axios";
import apps from "constants/apps";
import { getLanguagesAction, setLanguageAction } from "../../actions/CountryLang";

export const getLanguages = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_ANTIAGING_API}api/languages`)
    .then(({ data }) => dispatch(getLanguagesAction(data.results)))
    .catch(console.error);
};
export const getAllowedLanguagesUtil = (app) => dispatch => {
 if(app === apps.BODYSHOCK) {
  axios
  .get(`${process.env.REACT_APP_ANTIAGING_API}api/languages-bodyshock`)
  .then(({ data }) => dispatch(getLanguagesAction(data.results)))
  .catch(console.error);
 }else {
  axios
  .get(`${process.env.REACT_APP_ANTIAGING_API}api/languages`)
  .then(({ data }) => dispatch(getLanguagesAction(data.results)))
  .catch(console.error);
 }
}
export const setLanguage = (language) => (dispatch) => {
  dispatch(setLanguageAction(language));
};