import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import useOnclickOutside from "react-cool-onclickoutside";
import { getQuestionnaire, sendQuestionnaire } from "redux/utils/bodyshock/Questionnaire";
import {
  QuestionnaireTitle,
  Wrapper,
  Counter,
  QuestionWrapper,
  QuestionnaireBarWrapper,
  QuestionnaireBarBg,
  QuestionnaireBar,
  FinishTest,
  FinishButton,
  GoToFirst,
  ProfessionalIconWrapper,
  ProfessionalIcon,
  ProfessionalAdvice,
} from "./style";
import { RenderSelectQuestions, uploadImagesUtil } from "./utils";
import { resetQuestionnaire } from "redux/actions/Patient";
import routes from "constants/routes";
import RedBar from "components/bodyshock/RedBar";
import Loading from "components/bodyshock/Loading";
import { completedQuestionaire } from "mocks/bodyshock/questionnaire";
const isDevelopmentMode = String(process.env.REACT_APP_DEVELOPER_MODE) === "active" ? true : false;

const Questionnaire = ({
  fetchQuestionnaire,
  questionnaire,
  sendCompleteQuestionnaire,
  patient,
  token,
  language,
  resetQuestionnaireUtil,
}) => {
  const [t] = useTranslation("global");
  const [position, setPosition] = useState(0);
  const [answerError, setAnswerError] = useState(false);
  const history = useHistory();
  // const [iconProfessional, setIconProfesional] = useState(false);
  useEffect(() => {
    if (isDevelopmentMode) {
      patient.test = completedQuestionaire;
      sendCompleteQuestionnaire(patient, token, history);
      resetQuestionnaireUtil();
      history.push(`${routes.professionalTreatment}`);
    }
  }, []);
  // const toggleClass = () => {
  //   setIconProfesional(!iconProfessional);
  // };

  const saveImage = async (formData) => {
    const image = await uploadImagesUtil(formData, token);
    return image;
  };
  useEffect(() => {
    document.title = "bodyshock ID test™";
    fetchQuestionnaire(language);
  }, [fetchQuestionnaire, language, token]);

  const backQuestion = () => {
    if (position > 0) setPosition(() => position - 1);
    if (position === 0) {
      resetQuestionnaireUtil();
      history.goBack();
    }
  };
  const nextQuestion = () => {
    let correct = false;
    let counter;
    switch (questionnaire.questions[position].type) {
      case "valueInsert":
        correct = Boolean(questionnaire.questions[position].value);
        break;
      case "multipleSelect":
        counter = 0;
        questionnaire.questions[position].choices.forEach((choice) => {
          if (choice.selected) counter++;
        });
        if (
          counter >= questionnaire.questions[position].minSelection &&
          counter <= questionnaire.questions[position].maxSelection
        ) {
          correct = true;
        } else {
          correct = false;
        }
        break;
      case "multipleAnswer":
        counter = 0;
        questionnaire.questions[position].choices.forEach((choice) => {
          if (choice.selected) counter++;
        });
        if (
          counter >= questionnaire.questions[position].minSelection &&
          counter <= questionnaire.questions[position].maxSelection
        ) {
          correct = true;
        } else {
          correct = false;
        }
        break;
      default:
        questionnaire.questions[position].choices.map((choice) => {
          if (choice.selected) return (correct = true);
          return false;
        });
    }

    if (!correct) return setAnswerError(true);
    if (position <= questionnaire.questions.length - 1) {
      setPosition(() => position + 1);
      return setAnswerError(false);
    }
  };
  const handleClick = (choice, question) => {
    question.choices.map((choice) => (choice.selected = false));
    choice.selected = true;
    nextQuestion();
  };
  const handleChange = (e, question) => {
    question.noteValue = e.target.value;
  };
  const handleSubmit = () => {
    patient.test = questionnaire;
    sendCompleteQuestionnaire(patient, token, history);
    resetQuestionnaireUtil();
    history.push(`${routes.professionalTreatment}`);
  };
  const questionnaireSetted = !!questionnaire.questions;

  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => 
    setOpen((o) => !o),
    [setOpen],
  );
  const referency = useOnclickOutside(() => {
    setOpen(false);
  });

  if (questionnaireSetted) {
    return (
      <Wrapper>
        <RedBar
          question={questionnaire?.questions[position]}
          finish={position > questionnaire.questions.length - 1}
        />
        <QuestionnaireTitle>
          bodyshock ID test™
          {questionnaire.questions[position]?.professional && (
            <ProfessionalIconWrapper>
              <ProfessionalIcon
                className={open ? "active" : ""}
                onClick={toggle}
              />
              <ProfessionalAdvice 
                style={{ display: open ? "block" : "none" }} 
                ref={referency} 
                // active={iconProfessional}
              >
                {t("questionnaire.professionalAdvice")}
              </ProfessionalAdvice>
            </ProfessionalIconWrapper>
          )}
        </QuestionnaireTitle>
        <Counter>
          <span>{Math.round(((position + 1) * 100) / (questionnaire.questions.length + 1))}%</span>
        </Counter>
        <QuestionnaireBarWrapper>
          <QuestionnaireBarBg />
          <QuestionnaireBar position={position + 1} totalQty={questionnaire.questions.length + 1} />
        </QuestionnaireBarWrapper>
        <QuestionWrapper>
          {position <= questionnaire.questions.length - 1 && (
            <RenderSelectQuestions
              key={position}
              question={questionnaire.questions[position]}
              position={position}
              handleClick={handleClick}
              answerError={answerError}
              handleChange={handleChange}
              nextQuestion={nextQuestion}
              backQuestion={backQuestion}
              saveImage={saveImage}
            />
          )}
          {position > questionnaire.questions.length - 1 && (
            <div>
              <FinishTest>{t("questionnaire.finishText")}</FinishTest>
              <FinishButton onClick={handleSubmit}>{t("questionnaire.send")}</FinishButton>
              <GoToFirst onClick={backQuestion}>{t("questionnaire.goBack")}</GoToFirst>
            </div>
          )}
        </QuestionWrapper>
      </Wrapper>
    );
  } else {
    return <Loading />;
  }
};
const mapStateToProps = (state) => ({
  questionnaire: state.questionnaire,
  patient: state.patient,
  token: state.user.data.api_token,
  language: state.languages.selectedLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  fetchQuestionnaire: (language) => dispatch(getQuestionnaire(language)),
  sendCompleteQuestionnaire: (questionnaire, token, history) =>
    dispatch(sendQuestionnaire(questionnaire, token, history)),
  resetQuestionnaireUtil: () => dispatch(resetQuestionnaire()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
