const completedQuestionaire = {
  lang: "es",
  questions: [
    {
      id: 1,
      type: "select",
      text: "Indique a continuación el motivo de la consulta",
      choices_qty: 4,
      category: "historial",
      professional: true,
      choices: [
        {
          id: 1,
          order: 1,
          text: "Celulitis",
          subQuotingArray: [
            {
              id: 1.2,
              order: 1,
              text: "¿Se aprecia a simple vista la celulitis?",
              professional: true,
              choices: [
                { id: 1, order: 1, text: "Sí" },
                { id: 2, order: 2, text: "No, es visible únicamente  al pellizcar" },
              ],
            },
            {
              id: 1.3,
              order: 2,
              text: "Si presiona en la zona con celulitis…",
              professional: true,
              choices: [
                {
                  id: 1,
                  order: 1,
                  text: "Mis dedos se quedan marcados durante un tiempo, la piel se aprecia acorchada",
                },
                {
                  id: 2,
                  order: 2,
                  text: "La piel es dura al tacto y difícil de pellizcar, el tejido está firme y tonificado",
                },
                { id: 3, order: 3, text: "El tejido está blando y con poco tono muscular" },
              ],
            },
            {
              id: 1.4,
              order: 3,
              text: "Al analizar la zona donde hay celulitis...",
              professional: true,
              choices: [
                { id: 1, order: 1, text: "Se aprecia enseguida la piel de naranja" },
                {
                  id: 2,
                  order: 2,
                  text: "El relieve cutáneo es liso. Al tumbarse o caminar, no cambia de aspecto",
                },
                {
                  id: 3,
                  order: 3,
                  text: "Se aprecia la piel de naranja a simple vista, se deforma al caminar",
                },
              ],
            },
            {
              id: 1.5,
              order: 4,
              text: "La zona o zonas en las cuales se aprecia la celulitis son…",
              professional: true,
              choices: [
                {
                  id: 1,
                  order: 1,
                  text: "Tobillos, rodillas o muslos: efecto 'columna' visible en la parte inferior de las piernas.  Se aprecian varices",
                },
                {
                  id: 2,
                  order: 2,
                  text: "Rodillas (cara interna) o cara externa de los muslos (cartucheras) ",
                },
                { id: 3, order: 3, text: "Brazos, espalda, abdomen, glúteos o muslos" },
              ],
            },
            {
              id: 1.6,
              order: 5,
              text: "Ha sufrido o sufre...",
              professional: true,
              choices: [
                { id: 1, order: 1, text: "Problemas circulatorios" },
                {
                  id: 2,
                  order: 2,
                  text: "Alteraciones de peso debidas a cambios en la dieta o factores hormonales",
                },
                { id: 3, order: 3, text: "Ninguna de las anteriores" },
              ],
            },
          ],
          selected: false,
        },
        { id: 2, order: 2, text: "Adiposidad localizada", selected: false },
        { id: 3, order: 3, text: "Flacidez", selected: false },
        { id: 4, order: 4, text: "Estrías", selected: true },
        { id: 5, order: 5, text: "Pérdida de peso", selected: false },
      ],
    },
    {
      id: 1.1,
      category: "historial",
      order: 1,
      text: "Indique a continuación otra preocupación del paciente o motivo adicional de consulta",
      choices: [
        { id: 1, order: 1, text: "Celulitis", selected: false },
        { id: 2, order: 2, text: "Adiposidad localizada", selected: false },
        { id: 3, order: 3, text: "Flacidez", selected: true },
        { id: 4, order: 4, text: "Estrías", selected: false },
        { id: 5, order: 5, text: "Pérdida de peso", selected: false },
      ],
    },
    {
      id: 2,
      type: "select",
      category: "historial",
      text: "¿Desde cuando padece la alteración?",
      choices_qty: 4,
      professional: false,
      choices: [
        { id: 1, order: 1, text: "Hace pocos meses", formula: "all", selected: false },
        { id: 2, order: 2, text: "Hace más de 1 año", formula: "all", selected: false },
        { id: 3, order: 3, text: "Hace más de 3 años", formula: "all", selected: true },
      ],
    },
    {
      id: 3,
      type: "multipleAnswer",
      category: "historial",
      text: "¿Existen antecedentes familiares relacionados con las siguientes patologías?",
      subText: "Puede marcar más de una opción",
      choices_qty: 4,
      professional: false,
      minSelection: 1,
      maxSelection: 5,
      choices: [
        { id: 1, order: 1, text: "Obesidad" },
        { id: 2, order: 2, text: "Diabetes", selected: false },
        { id: 3, order: 3, text: "Retención de líquidos" },
        { id: 4, order: 4, text: "Varices", selected: true },
        { id: 5, order: 5, text: "Ninguna de las anteriores" },
      ],
    },
    {
      id: 4,
      category: "historial",
      type: "select",
      text: "¿Ha estado embarazada? ",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Sí", selected: false },
        { id: 2, order: 2, text: "No", selected: true },
      ],
    },
    {
      id: 5,
      type: "multipleAnswer",
      text: "Padece transtornos menstruales?",
      choices_qty: 4,
      category: "historial",
      minSelection: 1,
      maxSelection: 1,
      withNote: "Especifique a continuación:",
      choices: [
        { id: 1, order: 1, text: "Sí", selected: true },
        { id: 2, order: 2, text: "No" },
      ],
    },
    {
      id: 6,
      type: "multipleAnswer",
      text: "¿Utiliza métodos anticonceptivos?",
      withNote: "Especifique a continuación:",
      category: "historial",
      minSelection: 1,
      maxSelection: 1,
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Sí" },
        { id: 2, order: 2, text: "No", selected: true },
      ],
    },
    {
      id: 7,
      type: "multipleAnswer",
      text: "¿Padece alguna enfermedad?",
      category: "historial",
      withNote: "Especifique a continuación:",
      choices_qty: 4,
      minSelection: 1,
      maxSelection: 1,
      choices: [
        { id: 1, order: 1, text: "Sí" },
        { id: 2, order: 2, text: "No", selected: true },
      ],
    },
    {
      id: 8,
      category: "historial",
      type: "multipleAnswer",
      text: "¿Se ha sometido a alguna intervención quirúrgica?",
      withNote: "Especifique a continuación:",
      choices_qty: 4,
      minSelection: 1,
      maxSelection: 1,
      choices: [
        { id: 1, order: 1, text: "Sí" },
        { id: 2, order: 2, text: "No", selected: true },
      ],
    },
    {
      id: 9,
      type: "multipleAnswer",
      text: "¿Padece alguna alergia? ",
      category: "historial",
      withNote: "Especifique a continuación:",
      choices_qty: 4,
      minSelection: 1,
      maxSelection: 1,
      choices: [
        { id: 1, order: 1, text: "Sí" },
        { id: 2, order: 2, text: "No", selected: true },
      ],
    },
    {
      id: 10,
      type: "select",
      text: "¿Con qué frecuencia realiza actividad física?",
      category: "lifehabits",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Diaria", selected: false },
        { id: 2, order: 2, text: "Semanal", selected: true },
        { id: 3, order: 3, text: "Sedentaria", selected: false },
      ],
    },
    {
      id: 11,
      type: "select",
      text: "¿Qué tipo de actividad física realiza?",
      choices_qty: 4,
      category: "lifehabits",
      choices: [
        { id: 1, order: 1, text: "Ejercicio postural: Yoga, pilates...", selected: false },
        { id: 2, order: 2, text: "Ejercicio funcional: senderismo, aérobic", selected: false },
        { id: 3, order: 3, text: "Ejercicios de impacto: crossfit, pesas", selected: true },
        { id: 4, order: 4, text: "Ninguno de los anteriores", selected: false },
      ],
    },
    {
      id: 12,
      type: "select",
      text: "¿Cuántas horas duerme al día de forma habitual?",
      category: "lifehabits",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Más de 8h diarias", selected: false },
        { id: 2, order: 2, text: "Entre 6h y 8h diarias ", selected: false },
        { id: 3, order: 3, text: "Menos de 6h diarias", selected: true },
      ],
    },
    {
      id: 13,
      type: "select",
      text: "¿Es usted fumador/a?",
      category: "lifehabits",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Sí", selected: false },
        { id: 2, order: 2, text: "No", selected: false },
        { id: 3, order: 3, text: "Era fumador pero en la actualidad no", selected: true },
      ],
    },
    {
      id: 14,
      type: "select",
      text: "¿Suele tener problemas digestivos o estreñimiento?",
      category: "lifehabits",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Sí", selected: true },
        { id: 2, order: 2, text: "No", selected: false },
      ],
    },
    {
      id: 15,
      type: "select",
      text: "¿Qué tipo de dieta sigue habitualmente?",
      choices_qty: 4,
      category: "lifehabits",
      choices: [
        { id: 1, order: 1, text: "Vegetariana", selected: false },
        { id: 2, order: 2, text: "Vegana", selected: true },
        { id: 3, order: 3, text: "Hiperproteica", selected: false },
        { id: 4, order: 4, text: "Equilibrada", selected: false },
        { id: 5, order: 5, text: "Rica en grasas y procesados", selected: false },
        { id: 6, order: 6, text: "Ninguna de las anteriores", selected: false },
      ],
    },
    {
      id: 16,
      type: "select",
      text: "¿Utiliza regularmente fajas o prendas oclusivas?",
      category: "lifehabits",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Sí", selected: false },
        { id: 2, order: 2, text: "No", selected: true },
      ],
    },
    {
      id: 17,
      type: "multipleAnswer",
      text: "¿Toma algún medicamento?",
      category: "lifehabits",
      choices_qty: 4,
      minSelection: 1,
      maxSelection: 1,
      withNote: "Indique cuál:",
      choices: [
        { id: 1, order: 1, text: "Sí" },
        { id: 2, order: 2, text: "No", selected: true },
      ],
    },
    {
      id: 18,
      type: "select",
      text: "¿Con qué frecuencia se somete a la exposición solar?",
      category: "lifehabits",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Habitualmente", selected: false },
        { id: 2, order: 2, text: "Solo en verano", selected: true },
        { id: 3, order: 3, text: "Nunca", selected: false },
      ],
    },
    {
      id: 19,
      type: "select",
      category: "lifehabits",
      text: "¿Cuál es su ingesta habitual de agua?",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Más de 1,5l diarios", selected: false },
        { id: 2, order: 2, text: "Menos de 1,5l diarios", selected: false },
        { id: 3, order: 3, text: "Apenas bebo agua", selected: true },
      ],
    },
    {
      id: 20,
      type: "select",
      category: "lifehabits",
      text: "¿Con qué frecuencia consume alcohol?",
      choices_qty: 4,
      choices: [
        { id: 1, order: 1, text: "Diariamente", selected: false },
        { id: 2, order: 2, text: "Una o dos veces por semana", selected: true },
        { id: 3, order: 3, text: "De forma puntual", selected: false },
        { id: 4, order: 4, text: "Nunca", selected: false },
      ],
    },
    {
      id: 21,
      type: "valueInsert",
      text: "Índice de masa corporal (IMC)  del paciente:",
      category: "physicalanalysis",
      choices_qty: 4,
      calculator: "IMC",
      data: [
        {
          id: 1,
          text: "Masa ósea (Kg):",
          type: "text",
          symbol: "Kg",
          name: "masaOsea",
          value: "222",
        },
        {
          id: 2,
          text: "Masa grasa (%):",
          type: "text",
          symbol: "%",
          name: "masaGrasa",
          value: "222",
        },
        { id: 3, text: "Agua (%):", type: "text", symbol: "%", name: "agua", value: "22" },
        {
          id: 4,
          text: "Masa muscular (%):",
          type: "text",
          symbol: "%",
          name: "masaMuscular",
          value: "2",
        },
        {
          id: 5,
          text: "Grasa abdominal (%):",
          type: "text",
          symbol: "%",
          name: "grasaAbdominal",
          value: "2,3",
        },
        {
          id: 6,
          text: "Añadir fotografía lateral izquierda",
          type: "image",
          name: "lateral_izquierda",
        },
        {
          id: 7,
          text: "Añadir fotografía lateral derecha",
          type: "image",
          name: "lateral_derecha",
        },
        { id: 8, text: "Añadir fotografía frontal", type: "image", name: "frontal" },
        { id: 9, text: "Añadir fotografía trasera", type: "image", name: "trasera" },
      ],
      value: 23.888440980620498,
      weight: 80,
      height: 183,
    },
    {
      id: 22,
      type: "multipleSelect",
      text: "Zona de tratamiento",
      subText: "Seleccione las zonas",
      category: "physicalanalysis",
      param: "Perímetro",
      symbol: "cm",
      minSelection: 1,
      maxSelection: 2,
      choices_qty: 4,
      choices: [
        {
          id: 1,
          order: 1,
          text: "Abdomen y flancos",
          input: "213",
          symbol: "cm",
          selected: true,
        },
        { id: 2, order: 2, text: "Piernas", input: "0", symbol: "cm" },
        { id: 3, order: 3, text: "Glúteos", input: "0", symbol: "cm" },
        { id: 4, order: 4, text: "Brazos", input: "0", symbol: "cm" },
        { id: 5, order: 5, text: "Senos", selected: true },
        { id: 6, order: 6, text: "Doble mentón" },
      ],
    },
  ],
};
export {completedQuestionaire}