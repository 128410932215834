import styled from "styled-components";
import tooltipIcon from "./images/tooltip-icon.svg";
import tooltipIconActive from "./images/tooltip-icon-active.svg";

export const Input = styled.input`
  border: none;
  margin: 7px 0px;
  border-bottom: 1px solid #4a4a4a;
  background: transparent;
  width: ${({ child }) => (child ? "80%" : "100%")};
  padding: 6px 0;
  font-size: 14px;
  &::placeholder {
    color: #979da2;
    font-family: hn-medium, Arial;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;
  }
  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
    border-bottom: 1px solid #4a4a4a;
  }
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
`;
export const Label = styled.label`
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "hn-medium", Arial;
  color: #000000;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 18px;
  text-align: left;
  top: -12px;
`;
export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  text-align: left;
  .limit {
    margin: 0;
    font-size: 12px;
    color: gray;
  }
`;
export const ErrorBox = styled.p`
  color: #e02b27;
  font-size: 12px;
  margin-top: 0;
  &::before {
    display: inline;
    content: "⚠ ";
  }
`;
export const TooltipTextBox = styled.div`
  border: 1px solid #ededed;
  background-color: rgba(248, 248, 248);
  position: absolute;
  @media (max-width: 560px) {
    position: static;
  }
  display: ${({ tooltipActive }) => (tooltipActive ? "block" : "none")};
  z-index: 10;
  width: 95%;
  max-width: 270px;
  bottom: 44px;
  left: 80%;
  border-radius: 20px;
  padding: 30px 38px;
  &:before {
    content: '';
    width: 7px;
    height: 8px;
    transform: rotate(45deg);
    display: block;
    position: absolute;
    bottom: -5px;
    left: 42px;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    border-left: none;
    border-top: none;
    background-color: rgba(248, 248, 248);
  }
  p {
    color: #000000;
    font-family: hn-medium, Arial;
    font-size: 10px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 15px;
    text-align: left;
  }
`;
export const TooltipIcon = styled.p`
background-image:  ${({ tooltipActive }) => (!tooltipActive ? `url(${tooltipIcon})` : `url(${tooltipIconActive})`)};
  background-position: center;
  background-size: cover;
  width: 22px;
  height: 22px;
  display: block;
  position: absolute;
  top: -7px;
  right: 0;
`;
