import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { CenterMaterialWrapper, ActionsBtn } from "./CenterMaterialStyles";
import { useTranslation, Trans } from "react-i18next";
import VideoModal from "components/VideoModal";
import { CTAsWrapper } from "./style";
import Button from "components/Button";
import { useHistory } from "react-router";
const dateSortFunction = (a, b) => {
  if (a.iso_date < b.iso_date) {
    return -1;
  }
  if (a.iso_date > b.iso_date) {
    return 1;
  }
  return 0;
};
const CenterMaterial = ({ selectedLang, token }) => {
  const history = useHistory();
  const [t] = useTranslation("material");
  const [t2] = useTranslation("global");
  const [material, setMaterial] = useState({});
  const [section, setSection] = useState("commercial");
  const [video, setVideo] = useState("");

  const refToVideo = useRef();
  const refToVideoModal = () => {
    setTimeout(() => {
      refToVideo.current.scrollIntoView({
        behavior: "smooth",
      });
    });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/resources`, {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => setMaterial({ ...data.results }));
  }, [token, selectedLang]);
  return (
    <CenterMaterialWrapper>
      <section className="header">
        <h1>age element</h1>
        <p className="subtitle">{t("subtitle")}</p>
      </section>
      <section className="content">
        <header>
          <p>
            <Trans t={t} i18nKey="mainMessage">
              Descargue todo el material comercial y formativo
              <strong>age element</strong> para su centro:
            </Trans>
          </p>
          <div className="actions">
            <ActionsBtn
              active={section === "commercial"}
              onClick={() => setSection("commercial")}
            >
              {t("commercial")}
            </ActionsBtn>
            <ActionsBtn
              active={section === "protocol"}
              onClick={() => setSection("protocol")}
            >
              {t("protocol")}
            </ActionsBtn>
            <ActionsBtn
              active={section === "guides"}
              onClick={() => setSection("guides")}
            >
              {t("guide")}
            </ActionsBtn>
          </div>
        </header>
        <article>
          {material[section] && material[section].pdf && (
            <div className="pdf-block">
              <div className="inner-block">
                <h2 className="section-title">{t(section + "Pdf")}</h2>
                <div className="material-grid">
                  {material[section].pdf.sort(dateSortFunction).map((pdf) => (
                    <div key={pdf.id} className="card">
                      <img src={pdf.thumbnail} alt={`${section} pdf`} />
                      <p
                        onClick={() => window.open(pdf.url)}
                        className="download"
                      >{`${t("download")} ${pdf.iso_language}`}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {material[section] && material[section].video && (
            <div>
              <div className="inner-block">
                <h2 className="section-title">{t(section + "Video")}</h2>
                <div className="material-grid">
                  {material[section].video
                    .sort(dateSortFunction)
                    .map((video) => (
                      <div key={video.id} className="card video">
                        <div
                          className="video-wrapper"
                          onClick={() => {
                            video.url &&
                            video.url.includes("https://cloud.mesoestetic.com")
                              ? window.open(video.url)
                              : setVideo(video.url) && refToVideoModal();
                          }}
                        >
                          <img src={video.thumbnail} alt={`${section} video`} />
                        </div>
                        <div className="video-info">
                          <p>{`age element ${video.iso_language}`}</p>
                          <p>{`${t("subtitlesLabel")} ${
                            video.iso_language
                          }`}</p>
                        </div>
                        {video.download_url && (
                          <p
                            onClick={() => window.open(video.download_url)}
                            className="download"
                          >
                            {`${t("download")}`}
                          </p>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </article>
      </section>
      {video && (
        <VideoModal
          refProp={refToVideo}
          videoId={video}
          close={() => setVideo("")}
        />
      )}
      <CTAsWrapper>
        <Button
          style={{ backgroundColor: "white", color: "black" }}
          type="button"
          onClick={() => history.push(`/antiaging/resources`)}
        >
          {t2("home.resourceAction")}
        </Button>
        <Button type="button" onClick={() => history.push(`/antiaging`)}>
          {t2("treatment.home")}
        </Button>
      </CTAsWrapper>
    </CenterMaterialWrapper>
  );
};

CenterMaterial.propTypes = {
  selectedLang: propTypes.string,
  token: propTypes.string,
};
const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});

export default connect(mapStateToProps, null)(CenterMaterial);
