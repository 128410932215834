import p1_product from "./protocol/p1_product.png";
import p2_product from "./protocol/p2_product.png";
import p3_product from "./protocol/p3_product.png";
import p4_product from "./protocol/p4_product.png";
import p5_product1 from "./protocol/p5_product1.png";
import p5_product2 from "./protocol/p5_product2.png";
import p6_product from "./protocol/p6_product.png";
import home_product from "./protocol/home_product.png";
import p1_face from "./protocol/p1_face.png";
import p2_face from "./protocol/p2_face.png";
import p3_face from "./protocol/p3_face.png";
import p4_face from "./protocol/p4_face.png";
import p5_face1 from "./protocol/p5_face1.png";
import p5_face2 from "./protocol/p5_face2.png";
import p6_face from "./protocol/p6_face.png";
import home_face from "./protocol/home_face.png";
export default {
  p1_product,
  p2_product,
  p3_product,
  p4_product,
  p5_product1,
  p5_product2,
  p6_product,
  home_product,
  p1_face,
  p2_face,
  p3_face,
  p4_face,
  p5_face1,
  p6_face,
  p5_face2,
  home_face,
};
