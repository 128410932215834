import styled from "styled-components";
export const LoadingResource = styled.div`
  width: 100%;
  height: 90vh;
  img {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
  }
`;
