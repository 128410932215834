import apps from "constants/apps";
import routes from "constants/routes";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavigatonBarWrapper } from "./styles";

function NavigationBar() {

  let url = window.location.href;
  const [t_global] = useTranslation("global");

  return (
    <NavigatonBarWrapper>
      <div className="navigation-bar__innerbox">
        <Link className="home" to={`/${apps.BODYSHOCK}`}>{t_global("navigationBar.home")}</Link>
        <Link className={url.includes("professional") ? "professional active" : "professional"} to={`/${apps.BODYSHOCK}/${routes.professionalTreatment}`}>{t_global("navigationBar.professional")}</Link>
        <Link className={url.includes("home") ? "domiciliario active" : "domiciliario"} to={`/${apps.BODYSHOCK}/${routes.homeTreatment}`}>{t_global("navigationBar.domiciliary")}</Link>
      </div>
    </NavigatonBarWrapper>
  );
}
export default NavigationBar;
