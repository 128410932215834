import React, { useEffect, useState } from "react";
import { SessionDateWrapper } from "./styles";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerWrapper } from "pages/transverse/NewPatient/style";
import { connect } from "react-redux";
import {
  savePatientAppoinmentUtil,
  updatePatientAppoinmentUtil,
} from "redux/utils/antiaging/Patients";
import { CloseButton, ComponentCheck } from "../EditHomeProtocol/styles";
const generateOptions = (hourOrMinutes) => {
  const html = [];
  let qty = hourOrMinutes === "hours" ? 24 : 60;
  for (let i = 0; i < qty; i++) {
    html.push(i);
  }
  return html;
};
const dateFormatter = (date) => {
  let formatted_date;
  if (date) {
    let current_datetime = date;
    formatted_date =
      current_datetime.getDate() +
      "/" +
      ("0" + (current_datetime.getMonth() + 1)).slice(-2) +
      "/" +
      current_datetime.getFullYear();
  }
  return formatted_date;
};
const boostersUtilities = {
  BRIGHTENING: "illuminator",
  "ANTI-WRINKLE": "anti-wrinkle",
  FIRMING: "firming",
  REDENSIFYING: "redensifying",
};
const SessionDate = ({
  protocol,
  close,
  selectedLang,
  sessionNumber,
  patient,
  protocolId,
  token,
  updateAppoinment,
  saveAppoinment,
  clinic,
  refProp,
}) => {
  const [t] = useTranslation("global");
  const [sessionDate, setSessionDate] = useState("");
  const [hoursOptions, setHoursOptions] = useState([]);
  const [minutesOptions, setMinutesOptions] = useState([]);
  const [sessionHour, setSessionHour] = useState("00");
  const [sessionMinutes, setSessionMinutes] = useState("00");
  const [check, setCheck] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailBody, setEmailBody] = useState("");
  useEffect(() => {
    if (!hoursOptions.length) {
      setHoursOptions(generateOptions("hours"));
      setMinutesOptions(generateOptions());
    } else {
      setSessionHour(hoursOptions[0]);
      setSessionMinutes(minutesOptions[0]);
    }
    const emailOptions = {
      clientName: patient.name,
      appointment_date: dateFormatter(sessionDate),
      appointment_time: `${sessionHour}:${sessionMinutes}`,
      clinic_name: clinic.first_name,
    };
    protocol &&
      protocol[sessionNumber - 1].boosters.map(
        (boost, index) => (emailOptions[`boost${index + 1}`] = boost.boost_name)
      );
    const emailContent =
      protocol && protocol[sessionNumber - 1].boosters.length === 3
        ? t("appointment.emailContentThreeBoosters", emailOptions)
        : t("appointment.emailContent", emailOptions);
    setEmailBody(emailContent);
    // eslint-disable-next-line
  }, []);
  const saveSessionDate = () => {
    const fields = {
      id_results_jsons: protocolId,
      session: sessionNumber,
      appointment_date: dateFormatter(sessionDate),
      appointment_time: `${sessionHour}:${sessionMinutes}`,
      email: check && emailBody,
    };
    saveAppoinment(patient.id, token, fields, selectedLang);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
      close(false);
    }, 2000);
  };
  const updateSessionDate = () => {
    const fields = {
      patient_id: patient.id,
      id: protocol[sessionNumber - 1].appointment_id,
      appointment_date: dateFormatter(sessionDate),
      appointment_time: `${sessionHour}:${sessionMinutes}`,
      email: check && emailBody,
      id_results_jsons: protocolId,
    };
    updateAppoinment(patient.id, token, fields, selectedLang);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
      close(false);
    }, 2000);
  };
  useEffect(() => {
    const emailOptions = {
      clientName: patient.name,
      appointment_date: dateFormatter(sessionDate),
      appointment_time: `${sessionHour}:${sessionMinutes}`,
      clinic_name: clinic.first_name,
    };
    protocol &&
      protocol[sessionNumber - 1].boosters.map(
        (boost, index) =>
          (emailOptions[`boost${index + 1}`] = t(
            "boostersUtilities." +
              boostersUtilities[boost.boost_name.toUpperCase()]
          ))
      );
    const emailContent =
      protocol && protocol[sessionNumber - 1].boosters.length === 3
        ? t("appointment.emailContentThreeBoosters", emailOptions)
        : t("appointment.emailContent", emailOptions);
    setEmailBody(emailContent);
    // eslint-disable-next-line
  }, [sessionDate, sessionHour, sessionMinutes]);
  return (
    <SessionDateWrapper active={protocol} ref={refProp}>
      <CloseButton onClick={close} style={{ top: "-15px" }} />
      <section className="content">
        <h3>
          {" "}
          {t("appointment.title")} {sessionNumber}
        </h3>
        <form>
          <DatePickerWrapper>
            <label htmlFor="sessionDate">{t("appointment.dateLabel")}</label>
            <DatePicker
              name="sessionDate"
              locale={selectedLang ? selectedLang : "en"}
              dateFormat="P"
              onChange={(date) => setSessionDate(date)}
              selected={sessionDate}
            />
          </DatePickerWrapper>
          <div>
            <p>{t("appointment.timeLabel")}</p>
            <select
              name="hour"
              value={sessionHour}
              onChange={(e) => setSessionHour(e.target.value)}
            >
              {hoursOptions &&
                hoursOptions.map((hour, index) => (
                  <option key={index} value={hour > 9 ? hour : `0${hour}`}>
                    {hour > 9 ? hour : `0${hour}`}
                  </option>
                ))}
            </select>
            <span className="separator">:</span>
            <select
              name="minutes"
              value={sessionMinutes}
              onChange={(e) => setSessionMinutes(e.target.value)}
            >
              {minutesOptions &&
                minutesOptions.map((min, index) => (
                  <option key={index} value={min > 9 ? min : `0${min}`}>
                    {min > 9 ? min : `0${min}`}
                  </option>
                ))}
            </select>
          </div>
          <div className="email__box">
            <input
              type="checkbox"
              name="email"
              id="appointment-email"
              value={check}
              onChange={() => setCheck(!check)}
            />
            <div className="checkmark"></div>

            <label htmlFor="appointment-email">
              {t("appointment.inputLabel")}
            </label>
          </div>
          <div>
            {check && (
              <textarea
                name="email-content"
                onChange={(e) => setEmailBody(e.target.value)}
                cols="24"
                rows="16"
                value={emailBody}
              />
            )}
          </div>
          <div className="actions">
            {success && <ComponentCheck size="40px" color="black" />}
            {!success && (
              <button
                onClick={
                  protocol[sessionNumber - 1].appointment_id
                    ? updateSessionDate
                    : saveSessionDate
                }
              >
                {check ? t("appointment.saveSend") : t("appointment.send")}
              </button>
            )}
          </div>
        </form>
      </section>
    </SessionDateWrapper>
  );
};
const mapStateToProps = (state) => ({
  selectedLang: state.languages.selectedLanguage,
  token: state.user.data.api_token,
  patient: state.protocols.patient,
  clinic: state.user.data,
});
const mapDispatchToProps = (dispatch) => ({
  saveAppoinment: (patientId, token, fields, selectedLang) =>
    dispatch(savePatientAppoinmentUtil(patientId, token, fields, selectedLang)),
  updateAppoinment: (patientId, token, fields, selectedLang) =>
    dispatch(
      updatePatientAppoinmentUtil(patientId, token, fields, selectedLang)
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(SessionDate);
