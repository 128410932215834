import {
  AnswerError,
  Choice,
  ChoiceRanking,
  QuestionGroup,
  QuestionText,
  RankingLegend,
  RankingOptions,
} from "./style";
import React from "react";
import { useTranslation } from "react-i18next";
const letters = ["A", "B", "C", "D", "E", "F"];
const choicesSort = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};
export const RenderSelectQuestions = ({
  question,
  index,
  position,
  handleClick,
  answerError,
  age,
}) => {
  const [t] = useTranslation("global");
  const [firstTime, setFirstTime] = React.useState(false);

  if (question.id === 1 && position < 1 && !firstTime) {
    question.choices
      .sort(choicesSort)
      .map((choice) => (choice.selected = false));
    if (age <= 30) {
      question.choices[0].selected = true;
      setFirstTime(true);
    } else if (age > 30 && age < 45) {
      question.choices[1].selected = true;
      setFirstTime(true);
    } else if (age > 45 && age < 60) {
      question.choices[2].selected = true;
      setFirstTime(true);
    } else {
      question.choices[3].selected = true;
      setFirstTime(true);
    }
  }
  return (
    <QuestionGroup active={position === index} behind={position > index}>
      <QuestionText>{`${question.text}`}</QuestionText>
      {answerError && <AnswerError>{t("questionnaire.error")}</AnswerError>}

      <ul style={{ paddingLeft: 0 }}>
        {question.choices.sort(choicesSort).map((choice, index) => (
          <Choice
            key={choice.text}
            selected={choice.selected}
            onClick={() => handleClick(choice, question)}
          >
            <span>{letters[index]}</span> {choice.text}
          </Choice>
        ))}
      </ul>
    </QuestionGroup>
  );
};
export const RenderRankingQuestions = ({
  question,
  index,
  position,
  handleRankingChoice,
  answerError,
}) => {
  const [t] = useTranslation("global");
  return (
    <QuestionGroup active={position === index} behind={position > index}>
      <QuestionText>{`${question.text}`}</QuestionText>
      <RankingLegend style={{ fontWeight: 600 }}>
        {t("questionnaire.rankingLegend")}
      </RankingLegend>
      <RankingLegend
        style={{
          marginTop: "10px",
          lineHeight: "17px",
        }}
      >
        {t("questionnaire.rankingAdvice")}
      </RankingLegend>
      {answerError && <AnswerError>{t("questionnaire.error")}</AnswerError>}
      <ul style={{ paddingLeft: 0 }}>
        {question.choices.sort(choicesSort).map((choice, index) => (
          <RankingOptions key={choice.text}>
            <Choice value={choice.value} ranking>
              <span>{letters[index]}</span> {choice.text}
            </Choice>
            <ChoiceRanking
              onClick={() => handleRankingChoice(choice, question)}
            >
              {choice.value || 0}
            </ChoiceRanking>
          </RankingOptions>
        ))}
      </ul>
    </QuestionGroup>
  );
};

export const choicesSortRanking = (a, b) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
};
