import React, { useEffect } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import {
  DownloadCTA,
  Subtitle,
  Title,
  TopElementsWrapper,
  TreatmentPageWrapper,
  BottomCTAsWrapper,
  ProtocolsCTAs,
  Advice,
  HomeLegend,
  AddProductWrapper,
  LoadingResource,
  PatientBarWrapper,
} from "./styles";
import { useTranslation } from "react-i18next";
import "swiper/swiper-bundle.css";
import { useState } from "react";
import { useHistory } from "react-router";
import loadingIcon from "./images/loading.svg";
import { useParams } from "react-router-dom";
import {
  getPatientProtocolUtil,
  updatePatientUtil,
  savePatientProtocolUtil,
} from "redux/utils/antiaging/Patients";
import PatientData from "components/PatientData";
import EditPatient from "components/EditPatient";
import { QtySwitcher } from "components/QtySwitcher";
import { ProfessionalProtocolViewer } from "components/ProfessionalProtocol";
import { AddProductComponent } from "./utils";
import axios from "axios";
import { Overlay } from "components/LangSelector/style";
import EditHomeProtocol from "components/EditHomeProtocol";
import HomeProtocol from "components/HomeProtocol";
const Patient = ({
  protocol,
  errors,
  token,
  getPatientProtocol,
  patient,
  selectedLang,
  updatePatient,
  saveProtocol,
}) => {
  const { id } = useParams();
  const [homeProducts, setHomeProducts] = useState({});
  const [coadyuvantes, setCoadyuvantes] = useState([]);
  const [gammaProducts, setGammaProducts] = useState([]);
  const [productsToPopup, setProductsToPopup] = useState(false);
  useEffect(() => {
    document.title = "Professional protocol age element";
    if (id && token) {
      getPatientProtocol(id, token, selectedLang);
    }
  }, [id, token, getPatientProtocol, selectedLang]);
  useEffect(() => {
    setHomeProducts(protocol.home);
  }, [protocol]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/products`, {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        const results = data.results;
        const coad = [];
        const gamma = [];
        results.map((product) => {
          if (product.gamma) return gamma.push(product);
          return coad.push(product);
        });
        setCoadyuvantes([...coad]);
        setGammaProducts([...gamma]);
      })
      .catch(console.error);
  }, [token, selectedLang]);
  const [pulsations, setPulsations] = useState(false);
  const [edit, setEdit] = useState(false);
  const [t] = useTranslation("global");
  const history = useHistory();
  const handleEditSubmit = (modPatient) => {
    updatePatient(token, modPatient, patient.id, selectedLang);
    setEdit(false);
    getPatientProtocol(id, token, selectedLang);
  };
  const onSaveProtocol = () => {
    saveProtocol(id, token, protocol, selectedLang);
  };
  const removeProduct = (productId, gamma) => {
    if (gamma) {
      const newGammaProducts = homeProducts.gamma.filter(
        (prod) => prod.id !== productId
      );
      const newHomeProducts = { ...homeProducts };
      newHomeProducts.gamma = [...newGammaProducts];
      protocol.home = { ...newHomeProducts };
      saveProtocol(id, token, protocol, selectedLang);
    }
    if (!gamma) {
      const newProducts = homeProducts.products.filter(
        (prod) => prod.id !== productId
      );
      const newHomeProducts = { ...homeProducts };
      newHomeProducts.products = [...newProducts];
      protocol.home = { ...newHomeProducts };
      saveProtocol(id, token, protocol, selectedLang);
    }
  };
  const addProducts = (products, gamma) => {
    if (gamma) {
      const newHomeProducts = { ...homeProducts };
      products = products.filter(
        (x) => !newHomeProducts.gamma.find((y) => y.id === x.id)
      );
      newHomeProducts.gamma = [...homeProducts.gamma, ...products];
      setHomeProducts({ ...newHomeProducts });
      protocol.home = { ...newHomeProducts };
      saveProtocol(id, token, protocol, selectedLang);
    }
    if (!gamma) {
      const newHomeProducts = { ...homeProducts };
      products = products.filter(
        (x) => !newHomeProducts.products.find((y) => y.id === x.id)
      );
      newHomeProducts.products = [...homeProducts.products, ...products];
      setHomeProducts({ ...newHomeProducts });
      protocol.home = { ...newHomeProducts };
      saveProtocol(id, token, protocol, selectedLang);
    }
  };
  if (protocol.professional && patient) {
    return (
      <div>
        <TreatmentPageWrapper>
          <PatientBarWrapper>
            <span onClick={() => history.goBack()}>{t("testBar.back")}</span>
            <p> {`${patient.name} ${patient.last_name}`}</p>
          </PatientBarWrapper>
          <PatientData patient={patient} handleEdit={setEdit} edit={edit} />
          {edit && (
            <>
              <div className="overlay" onClick={() => setEdit(false)} ></div>

              <EditPatient
                patient={patient}
                selectedLang={selectedLang}
                onSubmit={handleEditSubmit}
                close={() => setEdit(false)}
              />
            </>
          )}
          <TopElementsWrapper>
            <Title>age element ID profile™</Title>
            <QtySwitcher
              pulsations={pulsations}
              setPulsations={setPulsations}
              t={t}
            />
            <DownloadCTA onClick={() => window.print()}>
              {t("treatment.download")}
            </DownloadCTA>
          </TopElementsWrapper>
          <ProfessionalProtocolViewer
            protocol={protocol.professional}
            pulsations={pulsations}
            patient={patient}
            onSaveProtocol={onSaveProtocol}
            protocolId={protocol.id}
          />
        </TreatmentPageWrapper>
        {homeProducts && (
          <TreatmentPageWrapper>
            <TopElementsWrapper>
              <AddProductWrapper>
                <Subtitle>{t("treatment.homeProtocol")}</Subtitle>
                <HomeLegend>{t("treatment.homeLegend")}</HomeLegend>
                <AddProductComponent
                  handleClick={setProductsToPopup}
                  coadyuvantes={coadyuvantes}
                  gammaProducts={gammaProducts}
                  t={t}
                />
              </AddProductWrapper>
            </TopElementsWrapper>
            <HomeProtocol
              homeProducts={homeProducts}
              removeProduct={removeProduct}
              t={t}
            />
            <BottomCTAsWrapper>
              <Advice>{t("treatment.advice")}</Advice>
              <ProtocolsCTAs onClick={() => history.push(`/antiaging/resources`)}>
                {t("home.resourceAction")}
              </ProtocolsCTAs>
              <ProtocolsCTAs
                protocolId={true}
                onClick={() => history.push(`/antiaging`)}
              >
                {t("treatment.home")}
              </ProtocolsCTAs>
            </BottomCTAsWrapper>
            <Overlay active={productsToPopup} />
            {productsToPopup && (
              <EditHomeProtocol
                products={productsToPopup}
                handleClick={addProducts}
                close={() => setProductsToPopup(false)}
              />
            )}
          </TreatmentPageWrapper>
        )}
      </div>
    );
  } else {
    return (
      (errors && (
        <div>
          <p>{errors.message}</p>
          <ul>
            {errors.error_info &&
              errors.error_info.map((error) => (
                <p>
                  {error.name} = {error.accumulator}
                </p>
              ))}
          </ul>
        </div>
      )) || (
        <LoadingResource>
          <img src={loadingIcon} alt="Loading Resource" />
        </LoadingResource>
      )
    );
  }
};

Patient.propTypes = {
  protocol: propTypes.object,
  homeProducts: propTypes.object,
  errors: propTypes.object,
  patient: propTypes.object,
};

const mapStateToProps = (state) => ({
  protocol: state.protocols,
  homeProducts: state.protocols.home,
  errors: state.protocols.error,
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
  patient: state.protocols.patient,
});
const mapDispatchToProps = (dispatch) => ({
  getPatientProtocol: (id, token, selectedLang) =>
    dispatch(getPatientProtocolUtil(id, token, selectedLang)),
  updatePatient: (token, patient, patientId, selectedLang) =>
    dispatch(updatePatientUtil(token, patient, patientId, selectedLang)),
  saveProtocol: (patientId, token, protocol, selectedLang) =>
    dispatch(savePatientProtocolUtil(patientId, token, protocol, selectedLang)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Patient);
