import styled from "styled-components";

export const PrivacyWrapper = styled.section`
  @media print {
    display: none;
  }
  display: ${({ active }) => (active ? "block" : "none")};
  background-color: #fff;
  position: absolute;
  right: 0;
  left: 0;
  width: 90%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  top: 15%;
  &:before {
    background-color: black;
    width: 100%;
    height: 100%;
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.3;
  }
`;

export const PrivacyInside = styled.div`
  padding: 20px;
  text-align: left;
  font-size: 12px;
  position: absolute;
  background: white;
  z-index: 2;
  .subtitle-politics {
    padding: 20px 0;
  }
  .subtitle2-politics {
    padding: 15px 0;
  }
  a {
    color: black;
  }
`;
