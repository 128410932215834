import styled from "styled-components";
import closeIcon from "./images/ico-close.svg";


export const Wrapper = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  width: 80%;
  max-width: 464px;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
  overflow-y: scroll;
  top: 50%;
  transform: translateY(-50%);
  max-height: 90vh;
`;
export const ContentWrapper = styled.div`
  position: relative;
  background-color: white;
  padding: 40px 64px;
  text-align: center;
  @media(max-width: 520px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  h2 {
    font-family: "hn-light", Arial;
    padding-bottom: 20px;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: normal;
    margin: 0 0 15px 0;
  }
  form {
    padding: 0 25px;
  }
  button {
    text-transform: lowercase;
  }
`;
export const DatePickerWrapper = styled.div`
  position: relative;
  margin-bottom: 25px;
  input {
    border: 0;
    margin: 7px 0px;
    border-bottom: 1px solid #4a4a4a;
    background: #fff;
    width: 100%;
    padding: 6px 0;
    font-size: 14px;
  }
  .react-datepicker-wrapper {
    display: block;
  }
`;

export const Button = styled.button`
  width: 100%;
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.34px;
  line-height: 20px;
  text-align: center;
  padding: 12px 20px;
  cursor: pointer;
  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
export const CloseButton = styled.span`
  background-image: url(${closeIcon});
  display: block;
  width: 33px;
  height: 33px;
  background-repeat: no-repeat;
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
`;