import styled from 'styled-components';


export const ApplianceWrapper = styled.div`

    @media print {
        display: none;
    }

    .content {
        max-width:970px;
        padding: 20px 20px 30px;
        margin: auto;
        @media(max-width: 520px) {
            padding: 0;
        }
    }
    img {
        margin-bottom: 5px;
        float: left;
        margin-right: 30px;
        margin-bottom: 30px;
        @media(max-width: 520px) {
            margin-right: 0;
            margin-bottom: 0;

            width: 100%;
            float: none;
        }
    }
    h3 {
        margin: 0;
        font-size: 14px;
        letter-spacing: 0.36px;
        line-height: 22px;
        font-family: hn-semibold;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
        @media(max-width: 520px) {
            margin-top: 22px;
            margin-bottom: 4px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .concern {
        margin-bottom: 27px;
        font-size: 14px;
        letter-spacing: 0.36px;
        line-height: 22px;
        display: block;
        @media(max-width: 520px) {
            margin-bottom: 19px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .description {
        font-size: 14px;
        letter-spacing: 0.36px;
        line-height: 20px;

        @media(max-width: 520px) {
            font-size: 12px;
            letter-spacing: 0.31px;
            line-height: 18px;
            padding-left: 20px;
            padding-right: 20px;
        }
        p {
            margin-top: 0;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            strong {
                font-family: hn-semibold;
                font-weight: 600;
            }
        }
    }
`;
