import axios from "axios";
import { getNotificationAction, resetNotificationAction } from "../../actions/Notification";

export const getNotificationUtil = (selectedLanguage, appFromStore) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_ANTIAGING_API}api/popup?language=${selectedLanguage}&application=${appFromStore}`)
    .then(({ data }) => {
      if (data.content) {
        dispatch(getNotificationAction(data));
      }
    })
    .catch(console.error);
};

export const resetNotificationUtil = () => (dispatch) => {
  dispatch(resetNotificationAction());
}
