import styled, { css } from "styled-components";
import leftbg from "./images/left-bg.png";
import rightbg from "./images/right-bg.png";
export const Wrapper = styled.main`
  position: relative;
  z-index: 0;
  width: 100%;
  padding-bottom: 50px;
  &:before {
    content: url(${leftbg});
    position: absolute;
    left: 0;
    top: 48px;
    z-index: -1;
  }
  &:after {
    content: url(${rightbg});
    position: absolute;
    right: 0;
    top: 48px;
    z-index: -1;
  }
`;
export const QuestionGroup = styled.article`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 360px;
  margin: 0 auto;
  padding-top: 0px;
  transition: transform 900ms ease 0s, opacity 200ms ease 0s,
    display 300ms ease 0s position 300ms ease 0s;
  opacity: 0;
  position: absolute;
  display: none;
  transform: translateY(100vh);
  ${({ behind }) =>
    behind ? "transform: translateY(-100vh); opacity:0; display:none" : ""}
  ${({ active }) =>
    active
      ? "transform: translateY(0); opacity: 1; position:relative; display:block"
      : ""}
`;
export const QuestionText = styled.p`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
`;
export const Choice = styled.li`
  margin: 10px 0;
  list-style: none;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 45px;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  border-radius: 2px;
  background-color: #ffffff;
  color: #000;
  border-radius: 2px;
  border: 1px solid #e8e9ea;
  background-color: #ffffff;
  span {
    border-radius: 2px;
    background-color: #efefef;
    padding: 3px 5px;
    margin: 0 15px;
  }
  ${({ ranking }) =>
    ranking
      ? ""
      : css`
          &:hover {
            border: 1px solid #000;
            &:after {
              content: "";
              width: 12px;
              height: 5px;
              transform: rotate(-49deg);
              display: inline-block;
              right: 15px;
              border-bottom: 1px solid #000;
              border-left: 1px solid #000;
              position: absolute;
            }
            span {
              color: #ffffff;
              background-color: #000;
            }
          }
        `}
  ${({ selected }) =>
    selected &&
    css`
      &:after {
        content: "";
        width: 12px;
        height: 5px;
        transform: rotate(-49deg);
        display: inline-block;
        right: 15px;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
        position: absolute;
      }
      span {
        color: #ffffff;
        background-color: #000;
      }
    `}
`;
export const ChoiceRanking = styled.span`
  margin-left: 10px;
  cursor: pointer;
  opacity: 1;
  border-radius: 2px;
  background-color: #ffffff;
  color: #000;
  border-radius: 2px;
  border: 1px solid #e8e9ea;
  display: flex;
  align-items: center;
  min-height: 45px;
  margin: 10px 0 10px 10px;
  cursor: pointer;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const QuestionnaireTitle = styled.h1`
  color: #000000;
  font-family: "hn-light", Arial;
  font-size: 25px;
  @media (max-width: 540px) {
    font-size: 18px;
  }
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  text-align: center;
  max-width: 300px;
  margin: 0 auto 35px;
  position: relative;
`;
export const Counter = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 26px;
  text-align: center;
  span {
    font-family: "hn-semibold", Arial;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
  }
`;
export const QuestionWrapper = styled.section`
  margin: 40px auto;
  padding: 0 20px;
`;
export const PrevNextButtonsWrapper = styled.div`
  max-width: 355px;
  margin: auto;
  display: flex;
  justify-content: space-between;
`;
export const PrevNextButton = styled.button`
  &:focus {
    outline: 0;
  }
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
  padding-left: 0;
  cursor: pointer;
  color: #989ea3;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 23.4px;
  text-align: left;
  text-transform: lowercase;
  background: transparent;
  border: 0;
  &::before {
    content: ${({ prev }) => (prev ? "'';" : "none;")};
    width: 7px;
    height: 7px;
    border-left: 1px solid #737373;
    border-top: 1px solid #737373;
    transform: rotate(-48deg);
    margin-right: 5px;
    display: inline-block;
  }
  ${({ nextBtn }) =>
    nextBtn &&
    css`
      border: 1px solid #000000;
      background-color: #000000;
      color: white;
      padding: 12px 25px;
    `}
`;
export const QuestionnaireBarWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  height: 3px;
  max-width: 260px;
`;
export const QuestionnaireBarBg = styled.div`
  height: 3px;
  width: 100%;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  left: 0;
`;
export const QuestionnaireBar = styled.div`
  height: 3px;
  border-radius: 3px;
  background-color: #000000;
  width: ${({ position }) => `${(position * 100) / 17}%`};
  position: absolute;
  top: 0;
  left: 0;
`;
export const RankingOptions = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
export const FinishTest = styled.p`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
`;
export const FinishButton = styled.p`
  border: 1px solid #000000;
  background-color: #000000;
  width: 100%;
  max-width: 300px;
  margin: 15px auto;
  color: #ffffff;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
`;
export const GoToFirst = styled.p`
  border: 1px solid #000000;
  width: 100%;
  max-width: 300px;
  margin: 15px auto;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
`;
export const LastButtonsWrapper = styled.div``;
export const AnswerError = styled.p`
  background-color: #ffe7e7;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.26px;
  line-height: 26px;
  text-align: center;
`;
export const RankingLegend = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 26px;
  text-align: center;
  margin: 0;
`;
export const ProfessionalIconWrapper = styled.div`
  position: absolute;
  right: 0;
  @media (min-width: 600px) {
    right: -30px;
    left: unset;
  }
`;
export const ProfessionalIcon = styled.img``;
export const ProfessionalAdvice = styled.p`
  display: ${({ active }) => (active ? "block" : "none")};
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
  z-index: 2;
  position: absolute;
  right: 0;
  border: 1px solid #ededed;
  padding: 30px 20px;
  border-radius: 30px;
  background-color: rgba(248, 248, 248, 0.82);

  width: 270px;
  @media (min-width: 600px) {
    position: absolute;
    left: -25px;
    right: unset;
  }
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    top: -4px;
    left: 40px;
    @media (max-width: 600px) {
      left: unset;
      right: 40px;
    }
    border-left: 1px solid #ededed;
    border-top: 1px solid #ededed;
    transform: rotate(45deg);
    background-color: rgba(248, 248, 248, 0.82);
  }
`;
