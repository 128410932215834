import styled from "styled-components";
import editIcon from "./images/edit-icon.svg";
import editIconBs from "./images/edit-icon-bs.svg";
export const PatientDataWrapper = styled.section`
  // Estilos para Bodyshock
  &.bodyshock {
    max-width: 930px;
    border-bottom: none;
    width: 100%;
    margin-bottom: 0;
    @media print {
      visibility: visible;
      top: 0;
    }

    > div:last-child {
      span {
        color: #d25462;
        font-family: hn-medium, Arial;
        letter-spacing: 0.31px;
        font-size: 12px;
        line-height: 20px;
        text-decoration: underline;
        justify-content: center;
        &::before {
          content: url(${editIconBs});
          margin-right: 10px;
        }
      }
    }
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e2e2e2;
  margin: 30px auto;
  width: 90%;
  max-width: 1000px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  > div:first-child {
    p {
      color: #000000;
      font-family: "hn-semibold", Arial;
      font-size: 14px;
      font-style: normal;
      letter-spacing: 0.36px;
      line-height: 22px;
      text-align: left;
      margin: 0;
    }
    span {
      font-family: "hn-medium", Arial;
    }
  }
  > div:last-child {
    line-break: anywhere;
    > p {
      color: #000000;
      font-family: "hn-medium", Arial;
      font-size: 10px;
      font-style: normal;
      letter-spacing: 1.5px;
      line-height: 26px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      margin-top: 0;
    }
    span {
      float: right;
      display: flex;
      cursor: pointer;
      &::before {
        content: url(${editIcon});
        margin-right: 5px;
        margin-top: 2px;
      }
    }
    div {
      background-color: #f2f2f2;
      padding: 8px 20px;

      p {
        color: #000000;
        font-family: "hn-medium", Arial;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.31px;
        line-height: 18px;
        text-align: left;
      }
    }
    @media print {
      display: content;
      overflow: visible;
      span {
        display: none;
      }
      div {
        padding: 0px;
      }
    }
  }
`;
