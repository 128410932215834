import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function PrintProductQtys({ product, onDeleteProduct, index }) {
  const [quantity, setQuantity] = useState(product.product_qty || 0);
  function handleClick(action, product) {

    if(product.product_unit === "gr") {

      if (action === "add") {
        product.product_qty = quantity + 25;
        return setQuantity(Number(quantity) + 25);
      }
      if (quantity > 1 && action === "less") {
        product.product_qty = quantity - 25;
        return setQuantity(Number(quantity) - 25);
      }
    }
    else {
      if (action === "add") {
        product.product_qty = quantity + 1;
        return setQuantity(Number(quantity) + 1);
      }
      if (quantity > 1 && action === "less") {
        product.product_qty = quantity - 1;
        return setQuantity(Number(quantity) - 1);
      }
    }
  }

  if (product) {
    const isFixed = fixedReferencies.some((name) => product.product_name.includes(name));
        return (

      <div>

        <div className="booster-option">

          <div className="booster-setting">

            <label className="booster-name">{product.product_name}</label>

            {product.product_sku !== "T-PBOD0071" && (
              <>
                <input value={quantity} disabled />
                <span>{product.product_unit}</span>
              </>
            )}

          </div>

          {product.product_sku !== "T-PBOD0071" && (

            <div className="buttons">

              <div className="less" onClick={() => handleClick("less", product)}></div>

              <div className="more" onClick={() => handleClick("add", product)}></div>

            </div>

          )}

        </div>

        {!isFixed && <div className="booster-detele" onClick={() => {onDeleteProduct(index)}}></div>}

      </div>

    );

  }
}
function PrintProductCards({ products, activeProducts, setActiveProducts, haveBoosters, setHaveBoosters }) {
  const [t_global] = useTranslation("global");
  const [productOptions, setProductOptions] = useState([]);
 
  useEffect(() => {
    if (products?.length) {
      const copyFromProducts = JSON.parse(JSON.stringify(products));
      const filteredProductsByActives = copyFromProducts.map((product) => {
        activeProducts.forEach((activeProduct) => {
          if (activeProduct.product_name?.includes(product.product_name)) product.exist = true;
        });
        return product;
      });
      const definitiveProducts = filteredProductsByActives.filter((product) => !product.exist);
      const boosters = activeProducts.map((product) => isABooster(product));
      if (boosters.length) {
        setHaveBoosters(boosters.length - 1);
      }
      setProductOptions(definitiveProducts);
    }
  }, [products, activeProducts, setHaveBoosters]);

  function handleClick(product) {

    if (isABooster(product)) {
      setHaveBoosters(haveBoosters + 1);
    }
    if(product.product_phase !== "final") {
      product.product_qty = 1;
    } 

    const newProducts = [...activeProducts, product];
    return setActiveProducts([...newProducts]);
  }

  if (productOptions.length) {
    return (
      <div className="boosters-select">
        <p className="info">{t_global("phase.info")}</p>
        <div className="boosters">
          {productOptions.map((product, index) => {
            return (
              <div
                className={`booster ${product.disabled ? "disabled" : ""}`}
                key={`${product.id}_${index}`}
                onClick={() => handleClick(product, index)}
              >
                <p className="booster-text">{product?.product_name}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
}

function isABooster(product) {
  return product.product_name.toLowerCase().includes("booster");
}
const fixedReferencies = ["exfoliator gel", "active solution", "intensifying cream"];

export { PrintProductQtys, PrintProductCards, isABooster };
