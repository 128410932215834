import routes from "constants/routes";
import React from "react";
import { Redirect, Route } from "react-router";

export const IsUserAllowed = ({ path, Component, exact, isUserLogged }) => {
  return isUserLogged ? (
    <Route path={path} exact={exact}>
      <Component />
    </Route>
  ) : (
    <Redirect to={{ pathname: `/${routes.login}` }} />
  );
};
