
const body = document.querySelector('body');
let scrollPosition = 0;

function disableBodyScroll() {
    scrollPosition = window.scrollY;
    body.style.overflow = 'hidden';
    body.style.position = 'fixed';
    body.style.top = `-${scrollPosition}px`;
    body.style.width = '100%';
}

function enableBodyScroll(position) {
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('width');
    window.scrollTo(0, position);
}

export { enableBodyScroll, disableBodyScroll };