import {
  GETPROTOCOLS,
  ERRORPROTOCOL,
  RESETPROTOCOL,
} from "../constants/Protocols";
export default (state = { error: null }, action) => {
  switch (action.type) {
    case GETPROTOCOLS:
      return Object.assign({}, state, action.payload);
    case ERRORPROTOCOL:
      return Object.assign({}, state, { error: action.payload });
    case RESETPROTOCOL:
      return {};
    default:
      return state;
  }
};
