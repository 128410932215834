import React, { useCallback,  useState } from "react";
import { TooltipContent, TooltipIcon } from "./styles";

export const TooltipComponent = ({ productUse, productName }) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), [setOpen]);

  //I'm using "click" but it works with any event
  document.addEventListener("click", function (event) {
    if (event.target.closest(`#${productName}`)) return 
    setOpen(false)
  });
  return (
    <div id={productName}>
      <TooltipIcon onClick={toggle} activetooltip={open} />
      <TooltipContent   activetooltip={open} className="content-tooltip">
        <p className="product-use">{productUse}</p>
      </TooltipContent>
    </div>
  );
};
