import React from "react";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

import "swiper/swiper-bundle.css";
import Loading from "../Loading";

import {
  ProfessionalTreatmentWrapper,
  Icon,
} from './styles';
import Swiper from "react-id-swiper";
import ErrorsHandler from "components/transverse/ErrorsHandler";
import Appointment from "../Appointment";
import Tracing from "../Tracing";
import { isSessionExpired } from "../Appointment/utils";
import { Massage } from "../Massage";
import PhaseEdit from "../PhaseEdit";
import { ApparatusEdit } from "../ApparatusEdit";
import Appliance from "../Appliance";
import { MassageEdit } from "../MassageEdit";
import { TooltipComponent } from "./utils";
import { disableBodyScroll, enableBodyScroll } from "../HomeTreatment/utils";

const $urlServer = `${process.env.REACT_APP_ANTIAGING_API}storage/bodyshockimages/`;

const SCREEN_HEIGHT = window.innerHeight - 30;

const params = {
  slidesPerView: "auto",
  spaceBetween: 20,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  shouldSwiperUpdate: true,

};

const ProfessionalTreatment = ({
  onSaveProtocol,
  treatmentZone,
  sessions,
  protocolId,
  patient,
  user,
  errors,
  allProducts
}) => {
  const [openedSession, setOpenedSession] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [tracing, setTracing] = useState(null);
  const [phase, setPhase] = useState(null);
  const [t_global] = useTranslation("global");

  const preparation = "preparation";
  const activation = "activation";
  const final = "final";
  const intensification = "intensification";

  const swiperRef = useRef(null);
  const [editApparatus, setEditApparatus] = useState(false);
  const [editMassage, setEditMassage] = useState(false);
  const [apparatus, setApparatus] = useState(false);
  const [massage, setMassage] = useState(false);

  const subtitleSessions = t_global("treatment.subtitleProfessional");
  const [subtitleSession, setSubtitleSession] = useState(subtitleSessions);
  const [showDownload, setShowDownload] = useState(true);

  const [showComplementaryProducts, setshowComplementaryProducts] = useState(true);


  useEffect(() => {
    if(user.country_id === 13 || user.country_id === 171) {
      setshowComplementaryProducts(false);
    }
  }, []);

  const setSubtitleSessions = (subtitle, index) => {
    subtitle = subtitle + " " + index;
    setSubtitleSession(subtitle);
  }

  const [scrollPosition, setScrollPosition] = useState(0);

  const paramsProfessionalProtocol = {
    slidesPerView: 1,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
    },
    navigation: {
      nextEl: ".next",
      prevEl: ".prev",
    },
    renderPrevButton: () => (
      <Icon
        className="prev"
        width={50}
        height={50}
        strokeWidth={1}
      >{t_global("productsBodyshock.prev")}</Icon>

    ),
    renderNextButton: () => (

      <Icon
        className="next"
        width={50}
        height={50}
        strokeWidth={1}
      >{t_global("productsBodyshock.next")}</Icon>
    ),

    on: {
      init: function () {
        const activeIndex = swiperRef.current.swiper.activeIndex;
        const slideType = swiperRef.current.swiper.slides[activeIndex+1].getAttribute("data-type");
        const index = parseInt(swiperRef.current.swiper.slides[activeIndex+1].getAttribute("data-index"))+1;

        setSubtitleSessions(subtitleSessions, "");

        setShowDownload(true);
        if(index > 1) {

          document.querySelector(".next").textContent = `${slideType}${" "}${index}`;
        } else {
          document.querySelector(".next").textContent = `${slideType}`;
        }
      },
      slideChange: function () {
        const activeIndex = swiperRef.current.swiper.activeIndex;
        const currentSlideType = swiperRef.current.swiper.slides[activeIndex].getAttribute("data-type");
        const currentIndex = parseInt(swiperRef.current.swiper.slides[activeIndex].getAttribute("data-index"))+1;
        setSubtitleSessions(currentSlideType, currentIndex);

        if (swiperRef.current.swiper.isEnd) {
          document.getElementsByClassName("next")[0].style.opacity = 0;
        }
        else {
          document.getElementsByClassName("next")[0].style.opacity = 1;
          const slideType = swiperRef.current.swiper.slides[activeIndex+1].getAttribute("data-type");
          const index = parseInt(swiperRef.current.swiper.slides[activeIndex+1].getAttribute("data-index"))+1;
          if(index > 1) {
            document.querySelector(".next").textContent = `${slideType}${" "}${index}`;
          } else {
            document.querySelector(".next").textContent = `${slideType}`;
          }
        }
        if (swiperRef.current.swiper.slides[activeIndex].className.includes("swiper-apparatus")) {
          setEditApparatus(true);
          setShowDownload(false);
        }
        else {
          setEditApparatus(false);
        }
        if (swiperRef.current.swiper.slides[activeIndex].className.includes("swiper-massage")) {
          setEditMassage(true);
          setShowDownload(false);
        }
        else {
          setEditMassage(false);
        }
        if (swiperRef.current.swiper.slides[activeIndex].className.includes("steps-wrapper")) {
          setSubtitleSession(subtitleSessions);
          setShowDownload(true);
        }
      }
    },
    shouldSwiperUpdate: true,

  };

  const openSessions = (session) => {
    setOpenedSession(session);
    setScrollPosition(window.scrollY);
    disableBodyScroll();
    document.getElementsByClassName("professionalTreatmentWrapper")[0].style.zIndex = 4;
  };

  const closeSessions = () => {
    setOpenedSession();
    setEditApparatus(false);
    setEditMassage(false);
    enableBodyScroll(scrollPosition);
    document.getElementsByClassName("professionalTreatmentWrapper")[0].style.zIndex = "unset";
  };
  const openAppointment = (e, session) => {
    e.stopPropagation();
    setAppointment(session);
    setScrollPosition(window.scrollY);
    disableBodyScroll();

    document.getElementsByClassName("professionalTreatmentWrapper")[0].style.zIndex = 4;

  };
  const closeAppointments = () => {
    setAppointment(null);
    enableBodyScroll(scrollPosition);
    document.getElementsByClassName("professionalTreatmentWrapper")[0].style.zIndex = "unset";
  };

  const openTracing = (session) => {
    setTracing(session);
    setScrollPosition(window.scrollY);
    disableBodyScroll();
    document.getElementsByClassName("professionalTreatmentWrapper")[0].style.zIndex = 4;

  };
  const closeTracing = () => {
    setTracing(null);
    enableBodyScroll(scrollPosition);
    document.getElementsByClassName("professionalTreatmentWrapper")[0].style.zIndex = "unset";

  };
  const closeApparatusEdit = () => {
    setApparatus(false);
  }
  const closeMassageEdit = () => {
    setMassage(false);
  }

  const handlePos = useSpring({ y: 0 });
  const bindHandlePos = useDrag((params) => {
    const movement = params.movement[1];
    if (params.dragging) {
      if (movement >= 0 && movement <= SCREEN_HEIGHT) {
        handlePos.y.set(movement);
      }
    } else {
      if (movement > 180) {
        closeSessions();
      } else {
        handlePos.y.start(0);
      }
    }
  });
  const handleSubmit = () => {
    if (onSaveProtocol) {
      sessions[openedSession.session - 1][phase.name] = [...phase.phase];
      onSaveProtocol();
    }
  };
  const handleApparatus = (newProducts) => {
    if (onSaveProtocol) {
      sessions[openedSession.session - 1]["apparatus"] = [...newProducts];
      onSaveProtocol();
    }
  };
  const handleMassage = (newProducts) => {
    if (onSaveProtocol) {
      sessions[openedSession.session - 1]["massage"] = [...newProducts];
      onSaveProtocol();
    }
  };
  if (treatmentZone && sessions && patient) {
    return (
      <ProfessionalTreatmentWrapper className="professionalTreatmentWrapper">
        {openedSession && <div className="overlay-professional" onClick={() => closeSessions()}></div>}
        <div className="sessionsList">
          <p>
            {t_global("treatment.treatmentZone")}{" "}
            <strong style={{ textTransform: "lowercase" }}>{`${treatmentZone}`}</strong>
          </p>
          <ul>
            {sessions.map((session) => {
              const isExpired = isSessionExpired(
                session.appointment_date,
                session.appointment_time
              );
              return (
                <li key={session.session}>
                  <div
                    onClick={(e) => openAppointment(e, session)}
                    className={session.appointment_date ? "calendar-update" : "calendar"}
                  ></div>
                  <div
                    onClick={() => openTracing(session)}
                    className={session.appointment_date ? "tracing-active" : "tracing"}
                  ></div>
                  <div
                    className={`session ${isExpired ? "passed" : ""}`}
                    onClick={() => openSessions(session)}
                  >
                    <p
                      className={session.appointment_date ? "sessionTitle active" : "sessionTitle"}
                    >
                      {t_global("treatment.sessionTitle")} {session.session}
                    </p>
                    <p className="sessionBlock">
                      {session.appointment_date ? (
                        <>
                          <span className="date">{session.appointment_date}</span>
                          <span className="hour">{session.appointment_time}</span>
                        </>
                      ) : (
                        <span
                          className="scheduleSession"
                          onClick={(e) => openAppointment(e, session)}
                        >
                          {t_global("treatment.scheduleSession")}
                        </span>
                      )}
                    </p>
                    <span className="arrow" onClick={() => openSessions(session)}></span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {openedSession && (
          <>
            <animated.div
              className={openedSession ? "sessionsView active" : "sessionsView"}
              style={{ y: handlePos.y, touchAction: "pan-y" }}
            >
              <div
                className="App-handle-container"
                {...bindHandlePos()}
                style={{ touchAction: "none" }}
              >
                <div className="App-handle" />
              </div>
              <header>
                <div style={{maxWidth:"1534px", margin: "auto", position: "relative"}}>

                  <h2>
                    {t_global("treatment.sessionTitle")} {`${openedSession.session}`}
                  </h2>
                  <p className="subtitle">{subtitleSession}</p>

                  {showDownload && <span className="download" onClick={() => window.print()}></span>}
                  <span className="close" onClick={() => closeSessions()}></span>
                  {editApparatus && <span className="edit" onClick={() => setApparatus(openedSession.apparatus)}>{t_global("treatment.edit")}</span>}
                  {editMassage && <span className="edit" onClick={() => setMassage(openedSession.massage)}>{t_global("treatment.edit")}</span>}
                </div>


              </header>
              {showComplementaryProducts ? 
              
                <Swiper
                  ref={swiperRef}
                  {...paramsProfessionalProtocol}
                >
                  <div className="swiper-Slide steps-wrapper">
                    {openedSession.preparation && (
                      <div className="steps">
                        <div className="step-title">
                          <p className="step-title-text">{t_global("treatment.preparation")}</p>
                          <span
                            onClick={() =>
                              setPhase({ name: preparation, phase: openedSession.preparation })
                            }
                            className="step-edit"
                          >
                            {t_global("treatment.edit")}
                          </span>
                        </div>
                        <div className="swiperWrapper">
                          <Swiper {...params}>
                            {openedSession.preparation.map((item) => (
                              <div key={item.id} className="swiper-slide">
                                <img
                                  src={`${$urlServer}${item.product_image}`}
                                  alt={`${item.product_name}`}
                                />
                                <div>
                                  {
                                    (item.product_name.includes("bodyshock"))
                                      ? <h3>{item.product_name}</h3>
                                      : <h3>{item.product_brand} {item.product_name}</h3>

                                  }
                                  <p>{`${item.product_qty} ${item.product_unit}`}</p>
                                </div>
                              </div>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    )}
                    {openedSession.activation && (
                      <div className="steps">
                        <div className="step-title">
                          <p className="step-title-text">{t_global("treatment.activation")}</p>
                          <span
                            onClick={() =>
                              setPhase({ name: activation, phase: openedSession.activation })
                            }
                            className="step-edit"
                          >
                            {t_global("treatment.edit")}
                          </span>
                        </div>
                        <div className="swiperWrapper">
                          <Swiper {...params}>
                            {openedSession.activation.map((item) => (
                              <div key={item.id} className="swiper-slide">
                                <img
                                  src={`${$urlServer}${item.product_image}`}
                                  alt={`${item.product_name}`}
                                />
                                <div>
                                  {
                                    (item.product_name.includes("bodyshock"))
                                      ? <h3>{item.product_name}</h3>
                                      : <h3>{item.product_brand} {item.product_name}</h3>

                                  }
                                  <div className="quantity">
                                    {item.product_qty &&
                                      <p className="product-qty">{`${item.product_qty} ${item.product_unit}`}</p>
                                    }
                                    {item.product_use &&
                                      <TooltipComponent productUse={item.product_use} productName={item.product_name.split(" ").join("")} />
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    )}
                    {openedSession.final && (
                      <div style={{ zIndex: "-1", position: "relative" }} className="steps">
                        <div className="step-title">
                          <p className="step-title-text">{t_global("treatment.final")}</p>
                          <span
                            onClick={() => {
                              openedSession.final.forEach(product => {
                                if(product.product_sku === "T-PBOD0071") {
                                  product.product_qty = ""; // sculpting mask
                                } 
                                if(product.product_sku === "T-PBOD0011"){
                                  product.product_qty = product.product_qty;  //tightening mask
                                } 
                              });
                              // const productQty = openedSession.final?.[0] ? openedSession.final[0].product_qty : 150;
                              setPhase({
                                name: final,
                                phase: openedSession.final,
                                sessionNumber: openedSession.session
                                // productQty
                              })
                            }
                            }
                            className="step-edit"
                          >
                            {t_global("treatment.edit")}
                          </span>
                        </div>
                        <div className="swiperWrapper">
                          <Swiper {...params}>
                            {openedSession.final.map((item) => (
                              <div key={item.id} className="swiper-slide">
                                <img
                                  src={`${$urlServer}${item.product_image}`}
                                  alt={`${item.product_name}`}
                                />
                                <div>
                                  {
                                    (item.product_name.includes("bodyshock"))
                                      ? <h3>{item.product_name}</h3>
                                      : <h3>{item.product_brand} {item.product_name}</h3>

                                  }
                                  <div className="quantity">
                                    {item.product_qty &&
                                      <p className="product-qty">{`${item.product_qty} ${item.product_unit}`}</p>
                                    }
                                    {item.product_use &&
                                      <TooltipComponent productUse={item.product_use} productName={item.product_name.split(" ").join("")} />
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    )}

                    {openedSession.intensification && (
                      <div style={{ zIndex: "-2", position: "relative" }} className="steps">
                        <div className="step-title">
                          <p className="step-title-text">{t_global("treatment.intensification")}</p>
                          <span
                            onClick={() =>
                              setPhase({
                                name: intensification,
                                phase: openedSession.intensification,
                              })
                            }
                            className="step-edit"
                          >
                            {t_global("treatment.edit")}
                          </span>
                        </div>
                        <div className="swiperWrapper">
                          <Swiper {...params}>
                            {openedSession.intensification.map((item) => (
                              <div key={item.id} className="swiper-slide">
                                <img
                                  src={`${$urlServer}${item.product_image}`}
                                  alt={`${item.product_name}`}
                                />
                                <div>
                                  {
                                    (item.product_name.includes("bodyshock"))
                                      ? <h3>{item.product_name}</h3>
                                      : <h3>{item.product_brand} {item.product_name}</h3>

                                  }
                                  <p>{`${item.product_qty} ${item.product_unit}`}</p>
                                </div>
                              </div>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    )}
                    <div className="info-recomendation">
                      <p>{t_global("treatment.recomendation")}</p>
                    </div>
                  </div>
                  {openedSession.apparatus ?
                    (openedSession.apparatus.map((item, index) => (

                      <Appliance
                        key={item.id}
                        slideIndex={index}
                        name={item.product_name}
                        concern={item.product_concern}
                        description={item.product_description}
                        image={item.product_image}
                        products={openedSession.apparatus}
                      />
                    )))
                    : null
                  }
                  {openedSession.massage ?
                    (openedSession.massage.map((item, index) => (
                      <Massage
                        key={item.id}
                        slideIndex={index}
                        name={item.product_name}
                        url={item.product_description}
                      />
                    ))
                    )
                    : null
                  }
                </Swiper>
                :
                <Swiper
                  ref={swiperRef}
                  {...paramsProfessionalProtocol}
                >
                  <div className="swiper-Slide steps-wrapper">
                    {openedSession.preparation && (
                      <div className="steps">
                        <div className="step-title">
                          <p className="step-title-text">{t_global("treatment.preparation")}</p>
                          <span
                            onClick={() =>
                              setPhase({ name: preparation, phase: openedSession.preparation })
                            }
                            className="step-edit"
                          >
                            {t_global("treatment.edit")}
                          </span>
                        </div>
                        <div className="swiperWrapper">
                          <Swiper {...params}>
                            {openedSession.preparation.map((item) => (
                              <div key={item.id} className="swiper-slide">
                                <img
                                  src={`${$urlServer}${item.product_image}`}
                                  alt={`${item.product_name}`}
                                />
                                <div>
                                  {
                                    (item.product_name.includes("bodyshock"))
                                      ? <h3>{item.product_name}</h3>
                                      : <h3>{item.product_brand} {item.product_name}</h3>

                                  }
                                  <p>{`${item.product_qty} ${item.product_unit}`}</p>
                                </div>
                              </div>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    )}
                    {openedSession.activation && (
                      <div className="steps">
                        <div className="step-title">
                          <p className="step-title-text">{t_global("treatment.activation")}</p>
                          <span
                            onClick={() =>
                              setPhase({ name: activation, phase: openedSession.activation })
                            }
                            className="step-edit"
                          >
                            {t_global("treatment.edit")}
                          </span>
                        </div>
                        <div className="swiperWrapper">
                          <Swiper {...params}>
                            {openedSession.activation.map((item) => (
                              <div key={item.id} className="swiper-slide">
                                <img
                                  src={`${$urlServer}${item.product_image}`}
                                  alt={`${item.product_name}`}
                                />
                                <div>
                                  {
                                    (item.product_name.includes("bodyshock"))
                                      ? <h3>{item.product_name}</h3>
                                      : <h3>{item.product_brand} {item.product_name}</h3>

                                  }
                                  <div className="quantity">
                                    {item.product_qty &&
                                      <p className="product-qty">{`${item.product_qty} ${item.product_unit}`}</p>
                                    }
                                    {item.product_use &&
                                      <TooltipComponent productUse={item.product_use} productName={item.product_name.split(" ").join("")} />
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    )}
                    {openedSession.final && (
                      <div style={{ zIndex: "-1", position: "relative" }} className="steps">
                        <div className="step-title">
                          <p className="step-title-text">{t_global("treatment.final")}</p>
                          <span
                            onClick={() => {
                              openedSession.final.forEach(product => {
                                if(product.product_sku === "T-PBOD0071") {
                                  product.product_qty = ""; // sculpting mask
                                } 
                                if(product.product_sku === "T-PBOD0011"){
                                  product.product_qty = product.product_qty;  //tightening mask
                                } 
                              });
                              // const productQty = openedSession.final?.[0] ? openedSession.final[0].product_qty : 150;
                              setPhase({
                                name: final,
                                phase: openedSession.final,
                                sessionNumber: openedSession.session
                                // productQty
                              })
                            }
                            }
                            className="step-edit"
                          >
                            {t_global("treatment.edit")}
                          </span>
                        </div>
                        <div className="swiperWrapper">
                          <Swiper {...params}>
                            {openedSession.final.map((item) => (
                              <div key={item.id} className="swiper-slide">
                                <img
                                  src={`${$urlServer}${item.product_image}`}
                                  alt={`${item.product_name}`}
                                />
                                <div>
                                  {
                                    (item.product_name.includes("bodyshock"))
                                      ? <h3>{item.product_name}</h3>
                                      : <h3>{item.product_brand} {item.product_name}</h3>

                                  }
                                  <div className="quantity">
                                    {item.product_qty &&
                                      <p className="product-qty">{`${item.product_qty} ${item.product_unit}`}</p>
                                    }
                                    {item.product_use &&
                                      <TooltipComponent productUse={item.product_use} productName={item.product_name.split(" ").join("")} />
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    )}

                    {openedSession.intensification && (
                      <div style={{ zIndex: "-2", position: "relative" }} className="steps">
                        <div className="step-title">
                          <p className="step-title-text">{t_global("treatment.intensification")}</p>
                          <span
                            onClick={() =>
                              setPhase({
                                name: intensification,
                                phase: openedSession.intensification,
                              })
                            }
                            className="step-edit"
                          >
                            {t_global("treatment.edit")}
                          </span>
                        </div>
                        <div className="swiperWrapper">
                          <Swiper {...params}>
                            {openedSession.intensification.map((item) => (
                              <div key={item.id} className="swiper-slide">
                                <img
                                  src={`${$urlServer}${item.product_image}`}
                                  alt={`${item.product_name}`}
                                />
                                <div>
                                  {
                                    (item.product_name.includes("bodyshock"))
                                      ? <h3>{item.product_name}</h3>
                                      : <h3>{item.product_brand} {item.product_name}</h3>

                                  }
                                  <p>{`${item.product_qty} ${item.product_unit}`}</p>
                                </div>
                              </div>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    )}
                    <div className="info-recomendation">
                      <p>{t_global("treatment.recomendation")}</p>
                    </div>
                  </div>
                  {openedSession.massage ?

                    (openedSession.massage.map((item, index) => (
                      <Massage
                        key={item.id}
                        slideIndex={index}
                        name={item.product_name}
                        url={item.product_description}
                      />
                    ))
                    )
                    : null
                  }
                </Swiper>
              }

            </animated.div>
          </>
        )}
        {appointment && (
          <>
            <div className="overlay-appointment" onClick={() => closeAppointments()}></div>
            <Appointment
              closeAppointments={closeAppointments}
              session={appointment}
              patient={patient}
              user={user}
              protocolId={protocolId}
              treatmentZone={treatmentZone}
              productCategory
            />
          </>
        )}
        {tracing && (
          <>
            <div className="overlay-tracing" onClick={() => closeTracing()}></div>
            <Tracing
              closeTracing={closeTracing}
              patient={patient}
              token={user.api_token}
              protocolId={protocolId}
              session={tracing}
              firstSession={sessions[0]}
            />
          </>
        )}
        {phase && (
          <>
            <PhaseEdit
              closePhase={() => setPhase(false)}
              phaseData={phase}
              handleSubmit={handleSubmit}
            />
          </>
        )}
        {apparatus && (
          <>
            <ApparatusEdit
              closeEdit={closeApparatusEdit}
              allProducts={allProducts["apparatus"]}
              apparatus={apparatus}
              handleApparatus={handleApparatus}
            />
          </>
        )}
        {massage && (
          <>
            <MassageEdit
              closeEdit={closeMassageEdit}
              allProducts={allProducts["massage"]}
              massage={massage}
              handleMassage={handleMassage}
            />
          </>
        )}

      </ProfessionalTreatmentWrapper>
    );
  }
  return errors ? <ErrorsHandler errors={errors} /> : <Loading />;
};

const mapStateToProps = (state) => ({
  user: state.user.data,
  errors: state.protocols.error,
  patient: state.protocols.patient,
  allProducts: state.products.bodyshock,
});

export default connect(mapStateToProps, null)(ProfessionalTreatment);
