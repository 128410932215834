import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { HomeTreatmentWrapper } from "./style";

import "swiper/swiper-bundle.css";

import Swiper from "react-id-swiper";
import ErrorsHandler from "components/transverse/ErrorsHandler";
import { ProductList } from "../ProductList";
import { RecommendedProduct } from "../RecommendedProduct";
import { ProductAdvicesExercise } from "../ProductAdvicesExercise";
import { disableBodyScroll, enableBodyScroll } from "./utils";

const $urlServer = `${process.env.REACT_APP_ANTIAGING_API}storage/bodyshockimages/`;

const params = {
  slidesPerView: "auto",
  spaceBetween: 30,
  rebuildOnUpdate: true,
  breakpoints: {
    640: {
      spaceBetween: 30,
    },
    320: {
      spaceBetween: 10,
    },
  },
};
const paramsAdvices = {
  slidesPerView: "auto",
  spaceBetween: 30,
  rebuildOnUpdate: true,
  centerInsufficientSlides: true,
  watchOverflow: true,
  breakpoints: {
    640: {
      spaceBetween: 30,
    },
    320: {
      spaceBetween: 15,
    },
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: false
  }
};

const HomeTreatment = ({ products, errors, onSaveProtocol, allProducts, user }) => {
  const [t_global] = useTranslation("global");
  const [seeComplements, setSeeComplements] = useState(false);
  const [edit, setEdit] = useState(false);
  const [seeProduct, setSeeProduct] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [concern, setConcern] = useState('');
  const [editAdvices, setEditAdvices] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showComplementaryProducts, setshowComplementaryProducts] = useState(true);


  useEffect(() => {
    if(user.country_id === 13 || user.country_id === 171) {
      setshowComplementaryProducts(false);
    }
  }, []);

  const openEdit = (selector) => {
    if (selector === 'advices') {
      setEditAdvices(true);
    }
    else {
      setEdit(selector);
    }
    setScrollPosition(window.scrollY);
    disableBodyScroll();
  };

  const closeEdit = () => {
    setEdit(null);
    setEditAdvices(null);
    enableBodyScroll(scrollPosition);
  }

  const openProducts = () => {
    setSeeProduct(true);
    disableBodyScroll();
  }
  const closeProducts = () => {
    setSeeProduct(null);
    enableBodyScroll(scrollPosition);
  }

  const handleSeeProduct = (name, description, image, concern) => {
    setScrollPosition(window.scrollY);
    disableBodyScroll();
    openProducts();
    setName(name);
    setDescription(description);
    setImage(image);
    setConcern(concern);
  }
  const handleSubmit = (newProducts, productCategory) => {
    if (onSaveProtocol) {
      products[productCategory] = [...newProducts];
      onSaveProtocol();
    }
  };
  const handleDelete = (index, key) => {
    const newProducts = JSON.parse(JSON.stringify(products[key]));
    newProducts.splice(index, 1);
    products[key] = JSON.parse(JSON.stringify(newProducts));
    onSaveProtocol();
  };
  const handleAdvices = (newProductsAdvices, newProductsExercise) => {
    if (onSaveProtocol) {
      products["advices"] = [...newProductsAdvices];
      products["exercise"] = [...newProductsExercise];
      onSaveProtocol();
    }
  };

  if (errors) {
    return <ErrorsHandler errors={errors} />;
  }
  if (seeProduct) {
    return (

      <RecommendedProduct
        back={closeProducts}
        name={name}
        description={description}
        image={image}
        concern={concern}
      />
    )

  }

  return (
    <HomeTreatmentWrapper>
      <div className="content">
        <div className="topic-products">
          <h2 className="title">{t_global("treatment.topicProducts")}</h2>
          <p className="subtitle">{t_global("treatment.topicProductsSubtitle")}</p>
          <span onClick={() => openEdit("topical_products")} className="edit-icon">
            {t_global("patients.edit")}
          </span>
          <Swiper {...params}>
            {products.topical_products.map((item, index) => (
              <div key={item.product_name} className="swiper-products">
                <span
                  className="delete-icon"
                  onClick={() => handleDelete(index, "topical_products")}
                ></span>
                <img src={`${$urlServer}${item.product_image}`} alt={`${item.product_name}`} />
                <div>
                  <h3 className="product-name">
                    {" "}
                    {`${item.product_brand}`} <br />
                    {`${item.product_name}`}
                  </h3>
                  <p className="product-description">{item.product_description}</p>
                  <span className="clock">{item.using_frequency}</span>
                </div>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
      {showComplementaryProducts && 
      
      <div className="complement-products-wrapper">
        <div className="titleToggle">
          <span
            className={seeComplements ? "active " : ""}
            onClick={() => setSeeComplements(!seeComplements)}
          >
            {t_global("treatment.complementaryProducts")}
          </span>
        </div>
        <div className="contentToggle" style={{ display: seeComplements ? "block" : "none" }}>
          <div className="content-title">
            <h2 className="title">{t_global("treatment.complementaryfood")}</h2>
            <span
              onClick={() => openEdit("complementary_products")}
              className="edit-icon-complements"
            >
              {t_global("patients.edit")}
            </span>
          </div>
          <div className="content-complements">
            <Swiper {...params}>
              {products.complementary_products.map((item, index) => (
                <div key={item.product_name} className="swiper-products">
                  <span
                    className="delete-icon"
                    onClick={() => handleDelete(index, "complementary_products")}
                  ></span>
                  <img src={`${$urlServer}${item.product_image}`} alt={`${item.product_name}`} />
                  <div>
                    <h3 className="product-name">
                      {`${item.product_brand}`} <br />
                      {`${item.product_name}`}
                    </h3>
                    <p className="product-description">{item.product_description}</p>
                    <span className="clock">{item.using_frequency}</span>
                  </div>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      }
      {(products.advices || products.exercise) ?

        <div className="products-advices">
          <span
            onClick={() => openEdit("advices")}
            className="edit-icon-advices"
          >
            {t_global("patients.edit")}
          </span>

          <Swiper {...paramsAdvices}>
            {products.advices.map((item) => (
              <div
                onClick={() => handleSeeProduct(
                  item.product_name,
                  item.product_description,
                  item.product_image,
                  item.product_concern
                )}
                key={item.id}
                className="swiper-advices"
              >
                <img src={`${$urlServer}${item.product_image}`} alt={`${item.product_name}`} />
                <p className="product-name"> {item.product_name} </p>
                <p className="subtitle">{item.product_concern}</p>
              </div>

            ))}


            {products.exercise.map((item) => (
              <div
                onClick={() => handleSeeProduct(
                  item.product_name,
                  item.product_description,
                  item.product_image,
                  item.product_concern
                )}
                key={item.id}
                className="swiper-advices"
              >
                <img src={`${$urlServer}${item.product_image}`} alt={`${item.product_name}`} />
                <p className="product-name"> {item.product_name} </p>
                <p className="subtitle">{item.product_concern}</p>
              </div>
            ))}

          </Swiper>
        </div>
        : null
      }
      <div className="info-recomendation">
        <p>{t_global("treatment.recomendation")}</p>
      </div>
      {edit && (
        <>
          <ProductList
            closeEdit={closeEdit}
            allProducts={allProducts[edit]}
            products={products[edit]}
            handleSubmit={handleSubmit}
            productCategory={edit}
          />
        </>
      )}
      {editAdvices && (
        <>
          <ProductAdvicesExercise
            closeEdit={closeEdit}
            allProductsAdvices={allProducts["advices"]}
            allProductsExercise={allProducts["exercise"]}
            productsAdvices={products["advices"]}
            productsExercise={products["exercise"]}
            handleAdvices={handleAdvices}
          />
        </>
      )}
    </HomeTreatmentWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
  protocols: state.protocols,
  allProducts: state.products.bodyshock,
});
export default connect(mapStateToProps, null)(HomeTreatment);
