import styled, { css } from "styled-components";
import bg_right from "./images/bg-home-top-right.png";
import bg_left from "./images/bg-home-bottom-left.png";
import leftgirl from "./images/left-girl.png";
import littleleftgirl from "./images/littleleft-girl.png";
import rightgirl from "./images/right-girl.png";

export const MainWrapper = styled.main`
  padding-bottom: 50px;
  position: relative;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
  }
  &::before {
    height: 336px;
    width: 298px;
    bottom: 0;
    left: 0;
    background: url(${bg_left}) no-repeat;
  }

  &::after {
    width: 248px;
    height: 280px;
    top: 196px;
    @media (max-width: 560px) {
      top: 156px;
    }
    right: 0;
    background: url(${bg_right}) no-repeat;
  }
`;
export const BannerWrapper = styled.section`
  background-image: linear-gradient(to right, #b9b9ba 0%, #f5f5f5 100%);
  height: 196px;
  @media (max-width: 560px) {
    height: 156px;
  }
  text-align: center;
`;
export const BannerInnerWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
`;
export const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 196px;
  @media (max-width: 560px) {
    height: 156px;
  }
  display: block;
  &:before {
    content: "";
    background-image: url(${leftgirl});
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    height: 196px;
    width: 235px;
    left: 0;
    top: 0;
    @media (max-width: 560px) {
      height: 156px;
      width: 125px;
      background-image: url(${littleleftgirl});
    }
  }
  &:after {
    content: "";
    background-image: url(${rightgirl});
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    right: 0;
    height: 196px;
    width: 235px;
    top: 0;
    @media (max-width: 768px) {
      content: none;
    }
  }
`;
export const BannerTextWrapper = styled.div`
  position: absolute;
  margin: 0;
  top: 50%;
  @media (max-width: 767px) {
    top: 38%;
  }
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  @media (max-width: 560px) {
    text-align: left;
    transform: translate(0, 0);
    top: 15%;
    left: 40%;
    max-width: 185px;
  }
`;
export const BannerLogo = styled.img`
  position: absolute;
  left: 50px;
  bottom: 20px;
  @media (max-width: 560px) {
    width: 45px;
    left: 13px;
    bottom: 15px;
  }
`;
export const H1 = styled.h1`
  color: #000000;
  font-family: "hn-light", Arial;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  text-align: center;
  margin-bottom: 0;
  @media (max-width: 560px) {
    text-align: left;
    font-size: 18px;
    line-height: 19.8px;
  }
`;
export const Exclusive = styled.p`
  margin-top: 5px;
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 26px;
  text-align: center;
  @media (max-width: 560px) {
    text-align: left;
    font-size: 12px;
    line-height: 19.8px;
  }
`;
export const HomeContentWrapper = styled.section`
  width: 100%;
  height: 100%;
`;
export const ButtonsWrapper = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: ${({ resources }) =>
    resources ? "1fr 1fr 1fr" : "1fr 1fr 1fr"};

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  width: 90%;
  max-width: 930px;
  grid-gap: 30px;
  margin: 6% auto 50px;
`;
export const ActionWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e8e9ea;
  &:hover {
    border: 1px solid #979da2;
  }
  justify-content: top;
  padding: 40px 20px 20px;
  position: relative;
  background-color: white;
  z-index: 2;
  ${({ soon }) =>
    soon &&
    css`
      &:hover {
        border: 1px solid #e8e9ea;
        cursor: auto;
      }
    `}
  .soon {
    background-color: #323e48;
    color: #ffffff;
    font-family: "hn-semibold", Arial;
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1px;
    line-height: 23.4px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    padding: 0 6px;
    left: 20px;
  }
  ${({ soon }) =>
    soon &&
    css`
      p {
        color: #d4d4d5 !important;
      }
    `}
  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: center;
    padding: 20px 20px;
  }
`;
export const HomeButton = styled.p`
  color: #000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 20px;
  text-align: center;
  margin: 30px auto 10px;
  cursor: pointer;
  text-transform: lowercase;
  @media (max-width: 600px) {
    font-size: 12px;
    text-align: left;
    margin: 0px;
  }
`;
export const ButtonText = styled.p`
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.39px;
  line-height: 20px;
  text-align: center;
  max-width: 260px;
  @media (max-width: 600px) {
    font-size: 10px;
    text-align: left;
  }
`;
export const IconImg = styled.img`
  height: 102px;
  @media (max-width: 600px) {
    margin-right: 20px;
  }
`;
