import styled, { css } from "styled-components";
export const SwitcherWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 300px;
  margin: 15px auto;
  position: relative;
  @media (max-width: 500px) {
    margin-bottom: 60px;
    max-width: 240px;
  }
  @media print {
    display: none;
  }
  img {
    position: absolute;
    right: -70px;
    @media (max-width: 500px) {
      right: -60px;
    }
  }
`;
export const SwitcherBtn = styled.p`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 18px;
  text-align: center;
  border-radius: ${({ pulsation }) =>
    pulsation ? "0 4px 4px 0" : "4px 0 0 4px;"};
  border: 1px solid #323e48;
  padding: 4px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background-color: #323e48;
      color: white;
    `}
`;
