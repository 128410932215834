import styled from "styled-components";

export const VideoWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  width: 80%;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  top: 15%;
  z-index: 100;

  .iframe-wrapper {
    position: fixed;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 694px;
    max-height: 387px;
    width: 90%;
    overflow-y: scroll;

  }
  article {
    z-index: 10;
    background-color: #fff;
    padding: 20px;
    > span {
        cursor: pointer;
    }
  }
`;
export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: black;
    position: fixed;
    z-index: 3;
    opacity: 0.3;
    top: 0;
    left: 0;
    cursor: pointer;
`;