export const getNavigatorLanguage = () => {
  const language = navigator.language.split("-")[0];
  switch (language) {
    case "es":
      return language;
    case "en":
      return language;
    case "it":
      return language;
    case "pl":
      return language;
    case "pt":
      return language;
    case "de":
      return language;
    case "fr":
      return language;
    default:
      return "en";
  }
};
