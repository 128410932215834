import styled, { css } from "styled-components";
import syringe from "./images/syringe.svg";
import antiwrinkle from "./images/antiwrinkle.svg";
import redensifying from "./images/redensyfing.svg";
import firming from "./images/firming.svg";
import brightening from "./images/brightening.svg";
import downloadcta from "./images/downloadcta.svg";
import clock from "./images/clock.svg";
import tooltip from "./images/tooltip.svg";
import leftbg from "../Patient/images/bg-superior-derecha.jpg";
import rightbg from "../Patient/images/bg-inferior-derecho.svg";
const boostersIcons = {
  antiwrinkle: antiwrinkle,
  redensifying: redensifying,
  firming: firming,
  brightening: brightening,
};
export const TreatmentPageWrapper = styled.main`
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  &:before {
    content: "";
    background-image: url(${leftbg});
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    height: 250px;
    max-width: 421px;
    width: 100%;
    left: 0;
    top: 48px;
    z-index: -1;
  }
  &:after {
    content: "";
    background-image: url(${rightbg});
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    height: 280px;
    width: 248px;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  @media print {
    &:before,
    &:after {
      content: none;
    }
  }
`;
export const TopElementsWrapper = styled.section`
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
  width: 95%;
  z-index: 2;
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
    z-index: 3;
    top: 0;
    left: 0;
    cursor: pointer;
  }
`;

export const Title = styled.h1`
  color: #000000;
  font-family: "hn-light", Arial;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  text-align: center;
`;
export const Subtitle = styled.p`
  @media print {
    display: none;
  }
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 26px;
  text-align: center;
  ${({ firstPage }) =>
    firstPage &&
    css`
      font-family: hn-medium, Arial;
      font-weight: 400;
      max-width: 640px;
      margin: 0 auto;
    `}
`;
export const DownloadCTA = styled.button`
  @media print {
    display: none;
  }
  border: 1px solid #5b646c;
  color: #323e48;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  background-position: 5px;
  background-repeat: no-repeat;
  right: 20px;
  top: 0;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: left;
  background-color: white;
  padding: 5px;
  padding-left: 27px;
  background-image: url(${downloadcta});
  margin: auto;
  display: block;
  position: absolute;
  cursor: pointer;
  @media (max-width: 650px) {
    top: -50px;
  }
`;
export const TreatmentWrapper = styled.section`
  max-width: 930px;
  margin: 0 auto;
  width: 95%;
  border-bottom: 1px solid #e2e2e2;
`;
export const TopTable = styled.div`
  display: grid;
  grid-template-columns: 29% 40% 8% 23%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f8f8f8;
  background-color: #f2f2f2;
  -webkit-print-color-adjust: exact;
  padding-bottom: 5px;
  @media (max-width: 650px) {
    grid-template-columns: 20% 47% 4% 29%;
  }
`;
export const TopTableText = styled.span`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
`;
export const Session = styled.article`
  display: grid;
  grid-template-columns: 29% 40% 8% 23%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 20px;
  @media (max-width: 650px) {
    grid-template-columns: 20% 47% 4% 29%;
  }
`;
export const SessionNumber = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: center;
`;
export const SessionQtys = styled.div`
  justify-self: center;
  ${({ full }) =>
    full
      ? css`
          width: 100%;
        `
      : ""}
`;
export const Plus = styled.span`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.31px;
  line-height: 23.4px;
  text-align: center;
`;
export const MaskWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #000000;
    font-family: "hn-medium", Arial;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 23.4px;
    text-align: center;
    margin: 0;
  }
`;
export const MaskIcon = styled.img`
  margin-top: 10px;
  -webkit-print-color-adjust: exact;
`;
export const BottomCTAsWrapper = styled.section`
  @media print {
    display: none;
  }
  max-width: 530px;
  margin: 0 auto;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
export const BoostersNames = styled.div`
  display: flex;
  justify-content: space-evenly;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;
export const Booster = styled.p`
  color: ${({ color }) => color};
  -webkit-print-color-adjust: exact;
  display: flex;
  float: left;
  align-items: center;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23.4px;
  text-align: left;
  &:before {
    content: "";
    background-image: url(${({ icon }) => boostersIcons[icon.toLowerCase()]});
    width: 20px;
    height: 15px;
    margin-right: 5px;
    background-position: left;
    background-repeat: no-repeat;
    display: inline-block;
  }
  @media (max-width: 650px) {
    display: block;
    &:before {
      display: block;
      margin: 3px auto;
    }
  }
`;
export const BoostersProportions = styled.div`
  background-image: url(${syringe});
  -webkit-print-color-adjust: exact;
  position: relative;
  width: 293px;
  height: 44px;
  background-repeat: no-repeat;
  margin: auto;
  @media (max-width: 650px) {
    width: 176px;
    height: 26px;
    background-size: contain;
    background-position: center;
  }
`;
export const BoostersQtys = styled.div`
  position: absolute;
  width: 142px;
  height: 30px;
  left: 76px;
  top: 6px;
  display: flex;
  @media (max-width: 650px) {
    width: 87px;
    height: 22px;
    left: 43px;
    top: 3px;
  }
`;
export const Mls = styled.span`
  height: 32px;
  border: 1px solid white;
  width: ${({ proportion }) => proportion}%;
  background-color: ${({ bgcolor }) => bgcolor};
  display: flex;
  -webkit-print-color-adjust: exact;
  color: #ffffff;
  font-family: "hn-medium", Arial;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.29px;
  line-height: 17.5px;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    height: 19px;
  }
`;
export const ProtocolsCTAs = styled.span`
  padding: 15px;
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  border: 1px solid #000000;
  max-width: 250px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  text-transform: lowercase;
  width: 100%;
  ${({ protocolId }) =>
    protocolId &&
    css`
      border: 1px solid #000000;
      background-color: #000000;
      color: #ffffff;
      margin: 30px auto;
      display: block;
    `}
`;
export const Advice = styled.p`
  color: #979da2;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
  padding-left: 50px;
  background-image: url(${tooltip});
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 25px;
  max-width: 600px;
  margin: 20px auto;
  @media (min-width: 501px) {
    grid-column: 1/3;
  }
`;
export const HomeLegend = styled.p`
  @media print {
    display: none;
  }
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: center;
`;
/* Estilos para el protocolo domiciliario */
export const RangeProductCard = styled.article`
  width: 387px;
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 640px) {
    width: 100%;
    max-width: 320px;
    margin: auto;
  }
  height: 218px;
  border-radius: 10px;
  border: 1px solid #e8e9ea;
  background-color: #ffffff;
`;
export const RangeImg = styled.img`
  width: 120px;
`;
export const RangeInfoWrapper = styled.div`
  padding: 30px 10px;
`;
export const RangeTitle = styled.p`
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
  margin: 7px 0;
`;
export const RangeDescription = styled.p`
  color: #979da2;
  font-family: "hn-light", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;
export const TimeToUse = styled.span`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.39px;
  line-height: 20px;
  text-align: left;
  background-image: url(${clock});
  background-position: left;
  background-repeat: no-repeat;
  padding: 5px 0;
  padding-left: 30px;
`;
export const Range = styled.h2`
  font-family: "hn-semibold", Arial;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;
export const HomeProductWrapper = styled.article`
  max-width: 325px;
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HomeProductName = styled.h2`
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;
export const HomeProductDescription = styled.p`
  color: #979da2;
  font-family: "hn-light", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
`;
export const HomeProductImg = styled.img`
  float: left;
  width: 120px;
`;
export const RangeCardsWrapper = styled.section`
  max-width: 990px;
  margin: 35px auto;
  @media print {
    .swiper-wrapper {
      transform: translate3d(45px, 0px, 0px) !important;
    }
  }
  &::before {
    content: "";
    position: absolute;
    background-image: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
    width: 100%;
    height: 375px;
    margin-top: -110px;
    left: 0;
    z-index: 1;
  }
`;
export const HomeProductsWrapper = styled.section`
  max-width: 715px;
  margin: 40px auto;
  display: grid;
  padding: 0 20px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  width: 100%;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
export const FirstPageGraphics = styled.section`
  max-width: 560px;
  margin: 50px auto;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
  display: grid;
`;
export const ColumnsWrapper = styled.div`
  height: 175px;
  width: 194px;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  align-items: flex-end;
  grid-gap: 10px;
  margin-top: 10px;
  @media (max-width: 500px) {
    justify-self: center;
  }
`;
export const Column = styled.div`
  background-color: ${({ bgcolor }) => bgcolor};
  height: ${({ colheight }) => `${colheight}%`};
  width: 41px;
`;
export const Percents = styled.p`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 22px;
  text-align: center;
`;
export const ReferencesWrapper = styled.div`
  width: 100%;
  max-width: 275px;
  @media (max-width: 500px) {
    justify-self: center;
  }
`;
export const References = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 22px;
  text-align: left;
  display: flex;
  align-items: center;
`;
export const ReferenceColor = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-right: 10px;
  background-color: ${({ bgcolor }) => bgcolor};
`;
export const ReferenceLegend = styled.span``;
export const ColumnsInnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const LowerLegendBar = styled.div`
  background-color: #f2f2f2;
  max-width: 930px;
  margin: auto;
  width: 100%;
  text-align: center;
`;
export const LowerLegend = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 22px;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  max-width: 650px;
`;
export const PopupTitle = styled.h2`
  color: #000000;
  font-size: 14px;
  text-align: left;
  font-family: "hn-semibold", Arial;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
`;
export const PopupContent = styled.p`
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 25px;
  text-align: left;
`;
