import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import "swiper/swiper-bundle.css";
import { useParams } from "react-router-dom";
import {
  getPatientProtocolUtil,
  savePatientProtocolUtil,
  updatePatientUtil,
} from "redux/utils/bodyshock/Patients";
import PatientData from "components/bodyshock/PatientData";
import EditPatient from "components/EditPatient";
import {
  LoadingResource,
  HomeTreatmentWrapper,
  BottomCTAsWrapper,
  ProtocolsCTAs,
  BackButton,
  Header,
  ProtocolWrapper,
} from "./styles";
import loadingIcon from "./images/loading.svg";
import routes from "constants/routes";
import ProfessionalTreatment from "components/bodyshock/ProfessionalTreatment";
import HomeTreatment from "components/bodyshock/HomeTreatment";
const Patient = function ({
  protocols,
  errors,
  token,
  getPatientProtocol,
  patient,
  selectedLang,
  application,
  updatePatient,
  protocolId,
  saveProtocol,
}) {
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [t_global] = useTranslation("global");
  const history = useHistory();
  const handleEditSubmit = (newPatientData) => {
    updatePatient(token, newPatientData, patient.id, selectedLang);
    setEdit(false);
    getPatientProtocol(id, token, selectedLang);
  };
  const handleEditClose = () => {
    setEdit(false);
  };
  useEffect(() => {
    document.title = "Professional protocol bodyshock";
    if (id && token) {
      getPatientProtocol(id, token, selectedLang);
    }
  }, [id, token, getPatientProtocol, selectedLang]);
  const onSaveProtocol = () => {
    saveProtocol(patient.id, token, { ...protocols, id: protocolId }, selectedLang);
  };
  if (protocols) {
    return (
      <ProtocolWrapper>
        <Header>
          <BackButton onClick={() => history.goBack()}>{t_global("productsBodyshock.back")}</BackButton>
          <h1>{t_global("patients.titleProfessionalProtocol")}</h1>

        </Header>
        <PatientData application={application} patient={patient} handleEdit={setEdit} edit={edit} />
        {edit && (
          <>
            <div className="overlay-edit" onClick={() => handleEditClose()}></div>
            <EditPatient
              patient={patient}
              onSubmit={handleEditSubmit}
              close={() => handleEditClose()}
            />
          </>
        )}
        <ProfessionalTreatment
          treatmentZone={protocols.treatment_zones}
          sessions={protocols.professional}
          patientId={patient.id}
          token={token}
          protocolId={protocolId}
          onSaveProtocol={onSaveProtocol}
        />
        <HomeTreatmentWrapper className="homeTreatmentWrapper">
          <div className="home-treatment-wrapper">
            <section className="App-handle-container">
              <div className="App-handle" />
            </section>
            <p className="titleHomeProtocol">{t_global("patients.titleHomeProtocol")}</p>
            <HomeTreatment products={protocols.home} onSaveProtocol={onSaveProtocol} />
          </div>
        </HomeTreatmentWrapper>
        <BottomCTAsWrapper>
          <ProtocolsCTAs 
            protocolId={protocolId} 
            onClick={() => history.push(`${routes.homeBodyshock}`)}
          >
            home
          </ProtocolsCTAs>
        </BottomCTAsWrapper>
      </ProtocolWrapper>
    );
  }
  return (
    (errors && (
      <div>
        <p>{errors.message}</p>
        <ul>
          {errors.error_info &&
            errors.error_info.map((error) => (
              <p>
                {error.name} ={error.accumulator}
              </p>
            ))}
        </ul>
      </div>
    )) || (
      <LoadingResource>
        <img src={loadingIcon} alt="Loading Resource" />
      </LoadingResource>
    )
  );
};
const mapStateToProps = (state) => ({
  protocols: state.protocols.protocol,
  protocolId: state.protocols.id,
  patient: state.protocols.patient,
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
  errors: state.protocols.error,
  application: state.application,
});
const mapDispatchToProps = (dispatch) => ({
  getPatientProtocol: (id, token, selectedLang) =>
    dispatch(getPatientProtocolUtil(id, token, selectedLang)),
  updatePatient: (token, patient, patientId, selectedLang) =>
    dispatch(updatePatientUtil(token, patient, patientId, selectedLang)),
  saveProtocol: (patientId, token, protocol, selectedLang) =>
    dispatch(savePatientProtocolUtil(patientId, token, protocol, selectedLang)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Patient);
