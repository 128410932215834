import React, { useCallback, useRef, useState } from "react";
import { Fragment } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useTranslation } from "react-i18next";
import EditProfessionalProtocol from "../EditProfessionalProtocol";
import crystalfiber from "./images/crystalfiber.svg";
import firmingmask from "./images/instantfirming.svg";
import {
  TreatmentWrapper,
  TopTable,
  TopTableText,
  Session,
  SessionNumber,
  SessionQtys,
  BoostersNames,
  BoostersProportions,
  BoostersQtys,
  Mls,
  Plus,
  MaskWrapper,
  MaskIcon,
  Booster,
  DateWrapper,
  Actions,
  PatientSessionWrapper,
} from "./styles";
import { Overlay } from "../LangSelector/style";
import SessionDate from "../SessionDate";

const boosterColors = {
  BRIGHTENING: "#ae9b89",
  ANTIWRINKLE: "#b4918f",
  FIRMING: "#a6a9aa",
  REDENSIFYING: "#8C7A4F",
};
const maskIcons = {
  crystalfiber,
  instantfirming: firmingmask,
};
const ActionsComponent = ({
  setEditableSession,
  setEditableMask,
  session,
  setSessionDate,
  t,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), [setOpen]);
  const referencia = useOnclickOutside(() => {
    setOpen(false);
  });
  return (
    <Fragment>
      <button onClick={toggle}>
        <span>...</span>
      </button>
      <ul style={{ display: open ? "block" : "none" }} ref={referencia}>
        <li
          className="icoedit"
          onClick={() => {
            setEditableSession(session);
            setOpen(false);
          }}
        >
          {t("treatment.editActivation")}
        </li>
        <li
          className="icoedit"
          onClick={() => {
            setEditableMask(session);
            setOpen(false);
          }}
        >
          {t("treatment.editBoosting")}
        </li>
        <li
          className="icocalendar"
          onClick={() => {
            setSessionDate(true);
            setOpen(false);
          }}
        >
          {t("treatment.scheduleSession")}
        </li>
      </ul>
    </Fragment>
  );
};

export const ProfessionalProtocolViewer = ({
  protocol,
  pulsations,
  patient,
  onSaveProtocol,
  protocolId,
}) => {
  const [t] = useTranslation("global");
  const [editableSession, setEditableSession] = useState(false);
  const [editableMask, setEditableMask] = useState(false);
  const [sessionDatePopup, setSessionDatePopup] = useState(false);
  const expiredSessionDate = (session) => {
    if (session.appointment_date && session.appointment_time) {
      let today = new Date();
      var appointment = session.appointment_date.includes("-")
        ? session.appointment_date.split("-")
        : session.appointment_date.split("/");
      let appointmentTime = session.appointment_time.split(":");
      const appointmentDate = new Date(
        appointment[2],
        appointment[1] - 1,
        appointment[0],
        appointmentTime[0],
        appointmentTime[1]
      );

      if (today.getTime() > appointmentDate.getTime()) {
        return true;
      }
      return false;
    }
  };
  const editableSessionReferency = useRef();
  const editableMaskReferency = useRef();
  const sessionDatePopupReferency = useRef();
  return (
    <TreatmentWrapper id="protocol">
      <TopTable>
        <TopTableText>{t("treatment.sessions")}</TopTableText>
        <TopTableText>{t("treatment.activationPhase")}</TopTableText>
        <TopTableText></TopTableText>
        <TopTableText>{t("treatment.contributionPhase")}</TopTableText>
        <TopTableText>{t("treatment.scheduledDate")}</TopTableText>
      </TopTable>
      {protocol.length &&
        protocol.map((session, index) => (
          <PatientSessionWrapper key={index} className="sessions">
            <Session expired={expiredSessionDate(session)}>
              <SessionNumber>
                {t("treatment.session")} {session.session}
              </SessionNumber>
              <SessionQtys full={session.boosters.length > 2}>
                <BoostersNames>
                  {session.boosters.map((booster, boostIndex) => (
                    <Booster
                      key={boostIndex}
                      booster={booster.boost_name.toLowerCase()}
                      color={
                        boosterColors[booster.boost_name.split("-").join("")]
                      }
                      icon={booster.boost_name.split("-").join("")}
                    >
                      {booster.boost_name.toLowerCase()}
                    </Booster>
                  ))}
                </BoostersNames>
                <BoostersProportions>
                  <BoostersQtys>
                    {session.boosters.map((booster, boostIndex) => (
                      <Mls
                        key={boostIndex}
                        proportion={
                          (booster.product_qty * 100) / session.total_qty
                        }
                        bgcolor={
                          boosterColors[booster.boost_name.split("-").join("")]
                        }
                      >
                        {!pulsations &&
                          `${Number(booster.product_qty).toString()}ml`}
                        {pulsations &&
                          `${(
                            Number(booster.product_qty) * 10 +
                            5
                          ).toString()}`}
                      </Mls>
                    ))}
                  </BoostersQtys>
                </BoostersProportions>
              </SessionQtys>
              <Plus>+</Plus>
              <MaskWrapper>
                <p>{session.mask[0].mask.toLowerCase()} mask</p>
                <MaskIcon
                  src={
                    maskIcons[
                      session.mask[0].mask.split(" ").join("").toLowerCase()
                    ]
                  }
                  alt="Mask icon"
                />
              </MaskWrapper>
              <DateWrapper>
                <p>{session.appointment_date || "-"}</p>
                <p>{session.appointment_time || ""}</p>
              </DateWrapper>
            </Session>
            <Actions>
              <ActionsComponent
                setEditableSession={() => {
                  setEditableSession(session);
                  setTimeout(() => {
                    editableSessionReferency.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  });
                }}
                setEditableMask={() => {
                  setEditableMask(session);
                  setTimeout(() => {
                    editableMaskReferency.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  });
                }}
                setSessionDate={() => {
                  setSessionDatePopup(session.session);
                  setTimeout(() => {
                    sessionDatePopupReferency.current.scrollIntoView({
                      behavior: "smooth",
                    });
                  });
                }}
                patient={patient}
                t={t}
              />
            </Actions>
          </PatientSessionWrapper>
        ))}
      {editableSession && (
        <EditProfessionalProtocol
          session={editableSession}
          boosters
          onSaveProtocol={onSaveProtocol}
          close={() => setEditableSession(false)}
          protocol={protocol}
          refProp={editableSessionReferency}
        />
      )}
      {editableMask && (
        <EditProfessionalProtocol
          session={editableMask}
          mask
          onSaveProtocol={onSaveProtocol}
          close={() => setEditableMask(false)}
          protocol={protocol}
          refProp={editableMaskReferency}
        />
      )}
      {sessionDatePopup && (
        <SessionDate
          protocol={protocol}
          close={() => setSessionDatePopup(false)}
          sessionNumber={sessionDatePopup}
          protocolId={protocolId}
          refProp={sessionDatePopupReferency}
        />
      )}
      <Overlay active={editableMask || editableSession || sessionDatePopup} />
    </TreatmentWrapper>
  );
};
