import styled, { css } from 'styled-components';
import bg_left from './images/bg-home-bottom-left.png';
import bodyleft from './images/body-left.png';
import bofyrigth from './images/body-rigth.png';

export const MainWrapper = styled.main`
  padding-bottom: 50px;
  position: relative;
  min-height: calc(100vh - 65px);

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 230px;
    width: 493px;
    bottom: 0;
    left: 0;
    background: url(${bg_left}) no-repeat;
    @media (max-width: 560px) {
      background-size: contain;
      height: 130px;
      width: 300px;
    }
  }

`;
export const BannerWrapper = styled.section`
  background-image: linear-gradient(95deg, #e8e9ea 0%, #f5f5f5 100%);
  height: 196px;
  @media (max-width: 560px) {
    height: 156px;
  }
  text-align: center;
`;
export const BannerInnerWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
`;
export const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 196px;
  @media (max-width: 560px) {
    height: 156px;
  }
  display: block;
  &:before {
    content: "";
    background-image: url(${bodyleft});
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    height: 196px;
    width: 134px;
    left: 0;
    top: 0;
    @media (max-width: 560px) {
      height: 100%;
      width: 106px;
      background-size: contain;
      left: -30px;
    }
  }
  &:after {
    content: "";
    background-image: url(${bofyrigth});
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    right: 0;
    height: 196px;
    width: 172px;
    top: 0;
    @media (max-width: 768px) {
      content: none;
    }
  }
`;
export const BannerTextWrapper = styled.div`
  position: absolute;
  margin: 0;
  top: 50%;
  @media (max-width: 767px) {
    top: 38%;
  }
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  @media (max-width: 560px) {
    text-align: left;
    transform: translate(-50%, -50%);
    margin-right:unset;
    top: 50%;
    left: 50%;
    max-width: 225px;
    width: 100%;
  }
`;
export const BannerLogo = styled.img`
  position: absolute;
  left: 50px;
  bottom: 20px;
  @media (max-width: 560px) {
    width: 45px;
    left: 13px;
    bottom: 15px;
  }
`;
export const H1 = styled.h1`
  color: #000000;
  font-family: "hn-light", Arial;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  text-align: center;
  margin-bottom: 0;
  span {
    color: #000000;
    font-family: "hn-semibold", Arial;
    font-weight: 600;

  }
  @media (max-width: 560px) {
    text-align: center;
    font-size: 18px;
    line-height: 19.8px;
    span {
      text-align: center;

    }
  }
`;
export const Exclusive = styled.p`
  margin-top: 4px;
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 26px;
  text-align: center;
  @media (max-width: 560px) {
    text-align: center;
    font-size: 12px;
    line-height: 19.8px;
  }
`;
export const HomeContentWrapper = styled.section`
  width: 100%;
`;
export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ resources }) => (resources ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr')};

  @media (max-width: 820px) {
    grid-template-columns: ${({ resources }) => (resources ? '1fr 1fr' : '1fr 1fr 1fr')};
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  width: 90%;
  max-width: 930px;
  grid-gap: ${({ resources }) => (resources ? '20px' : '30px')};;
  row-gap: 20px;
  margin: 6% auto 50px;
`;
export const ActionWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e8e9ea;
  cursor: pointer;
  
  &:hover {
    border: 1px solid #979da2;
  }
  justify-content: top;
  padding: 40px 20px 20px;
  position: relative;
  background-color: white;
  z-index: 2;
  ${({ soon }) => soon
    && css`
      &:hover {
        border: 1px solid #e8e9ea;
        cursor: auto;
      }
    `}
  .soon {
    background-color: #d25462;
    color: #ffffff;
    font-family: "hn-semibold", Arial;
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1px;
    line-height: 23.4px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    padding: 0 6px;
    left: 15px;
    margin-top: 0;
    color: #fff !important;
  }
  ${({ soon }) => soon
    && css`
      &:before {
        content:'';
        width: 100%;
        height: 100%;
        background: #fff;
        opacity: 0.8;
        top: 0;
        left: 0;
        position: absolute;
      }
    `}
  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: center;
    padding: 20px 20px;
    display: grid;
    grid-template-columns: 40% 55%;
    column-gap: 5%;
  }
`;
export const HomeButton = styled.p`
  color: #000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 20px;
  text-align: center;
  margin: 30px auto 10px;
  cursor: pointer;
  text-transform: lowercase;
  @media (max-width: 600px) {
    font-size: 12px;
    text-align: left;
    margin: 0px;
  }
`;
export const ButtonText = styled.p`
  font-family: "hn-medium", Arial;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.39px;
  line-height: 20px;
  text-align: center;
  max-width: 260px;
  @media (max-width: 600px) {
    font-size: 10px;
    text-align: left;
  }
`;
export const IconImg = styled.img`
  height: 102px;
  ${({ soon }) => soon
    && css`
    img::before {
      content:'';
      width: 100%;
      height: 102px;
      background: #fff;
      opacity: 0.7;
    }
  `}
  @media (max-width: 600px) {
    width: 100%;
  }
`;
