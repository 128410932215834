import styled from "styled-components";
import checkmark from "./images/Checkmark.svg";

export const Img = styled.img`
    margin-right: 7px;
`;

export const AppSelectorWrapper = styled.div`
    ul {
        padding: 0;
        list-style: none;
        margin: 0;
    }
    li {
        border: 1px solid #e2e2e2;
        background-color: #ffffff;
        color: #000000;
        font-family: "hn-medium", Arial;
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.31px;
        line-height: 20px;
        text-align: left;
        cursor: pointer;
        padding: 12px 35px;
        display: flex;
        align-items: center;

        &:last-child {
            border-radius: 0 0 10px 10px;
            border-top: 0;
        }
        &.selected {

            position: relative;
            span {
                font-family: "hn-semibold", Arial;
                font-weight: 600;
            }
            &::after {
                content: '';
                background: url(${checkmark});
                width: 13px;
                height: 10px;
                right: 23px;
                position: absolute;
            }
        }
        img {
            max-height: 15px;
        }
        
    }
`;