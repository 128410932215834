import apps from "constants/apps";
import { Trans, useTranslation } from "react-i18next";
import localstoragekeys from "constants/localstoragekeys";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import useLocalStorageState from "utils/transverse/useLocalStorage";
import {
  AppSelectorWrapper,
  WelcomeText,
  ContentSelector,
  IconImg,
  AntiagingSelected,
  BodyshockSelected,
} from "./style";
import bodyshockIcon from "./images/bodyshock-icon.svg";
import bodyshockIconDis from "./images/bodyshock-icon-dis.svg";
import ageIcon from "./images/age-icon.svg";
import ageIconDis from "./images/age-icon-dis.svg";
import pigmentIcon from "./images/pigment-icon.svg";
import pigmentIconDis from "./images/pigment-icon-dis.svg";
import { changeApplicationUtil } from "redux/utils/transverse/Application";

function AppSelector({ user, changeApp }) {
  const history = useHistory();
  const [t] = useTranslation("global");
  const [, setApp] = useLocalStorageState(localstoragekeys.app, apps.NONE);
  function handleClick(selectedApp) {
    const appToUpper = selectedApp.toUpperCase();

    if (apps[appToUpper] && appToUpper !== 'PIGMENTCONTROL') {
      changeApp(apps[appToUpper])
      setApp(apps[appToUpper]);
      history.push(`${selectedApp}`);
    }
    
  }
  return (
    <AppSelectorWrapper>
      <WelcomeText>
        <h1>
          <Trans t={t} i18nKey="appSelector.title">
            welcome to <strong>mesoestetic diagnosis solutions™</strong>
          </Trans>
        </h1>
        <p>{t("appSelector.subtitle")}</p>
      </WelcomeText>

      <ContentSelector>
        {Object.entries(apps).map(([key, value], index) => {
          if (key !== "NONE") {
            const itsIncluded = user.permission.includes(value);
            const onClickFn = itsIncluded ? () => handleClick(value) : null;
            const disabledClass = itsIncluded ? "" : "disabled";
            return (
              <div key={index} onClick={onClickFn} className={disabledClass} >
                {!itsIncluded && <span>{t("appSelector.notAvailable")}</span>}

                {value === apps.BODYSHOCK && (
                  <BodyshockSelected>
                    <IconImg src={itsIncluded ? bodyshockIcon : bodyshockIconDis} alt="Bodyshock" />
                    <p className="title">{t(`appSelector.${value}`)}<br/>solutions</p>
                  </BodyshockSelected>
                )}

                {value === apps.ANTIAGING && (
                  <AntiagingSelected>
                    <IconImg src={itsIncluded ? ageIcon : ageIconDis} alt="Antiaging" />
                    <p className="title">{value} <br/>solutions</p>
                  </AntiagingSelected>
                )}
                {value === apps.PIGMENTCONTROL && (
                  <a className="pigmentcontrol" href="https://pc.mesoesteticds.com/">
                    <IconImg src={itsIncluded ? pigmentIcon : pigmentIconDis} alt="Pigment Control" />
                    <p className="title">pigment control <br/>solutions</p>
                  </a>
                )}
              </div>
            );
          }
          return "";
        })}
      </ContentSelector>
    </AppSelectorWrapper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.data,
});
const mapDispatchToProps = (dispatch) => ({
  changeApp: (selectedApp) => dispatch(changeApplicationUtil(selectedApp)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppSelector);
