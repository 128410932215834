import React, { useState } from "react";
import {
    FormWrapper,
    BlockWrapper,
    Wrapper,
    ErrorPass,
    SuccessMessage
} from "./style";
import Button from "components/Button";
import { useForm } from "react-hook-form";
import { Form } from "components/Form";
import StringInput from "components/Input";
import { useLocation } from 'react-router';
import { useInput } from "components/Input/input.hook";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const ResetPassword = () => {
    const [t] = useTranslation("global");
    const { handleSubmit, register, errors } = useForm();
    let query = useQuery();
    const history = useHistory();

    
    const [email, setEmail] = useInput(query.get("email"));
    const [token] = useState(query.get("token"));
    const [passError, setPassError] = useState(false);

    const [password, setPassword] = useInput("");
    const [password_confirmation, setPasswordConfirm] = useInput("");
    const [successChanged, setSuccessChanged] = useState(false);
    const [successMessage, setSuccessMessage] = useState("Password changed successfully");


    const handleSubmitResetPassword = () => {
        if (password !== password_confirmation) {
            setPassError(true);
            setTimeout(() => {
                setPassError(false);
              }, 3000);
        }
        else {
            
            axios
                .put(
                `${process.env.REACT_APP_ANTIAGING_API}api/accounts/update/password`,
                {
                    email,
                    token,
                    password,
                    password_confirmation
                }
                )
                .then(() => {
                    setSuccessChanged(true);
                    setSuccessMessage("Password reset successfully");
                    setTimeout(() => {
                        setPassError(false);
                        history.push("/login");
                      }, 3000);
                })
                .catch(console.error);
            };

        }
      
    document.title = "Reset password mesoestetic diagnosis solutions™";

    return (
        <Wrapper>
            <BlockWrapper>
                <FormWrapper>
                    <>
                        <p>Reset Password</p>
                        <Form onSubmit={handleSubmit(handleSubmitResetPassword)}>
                            <StringInput
                            name="email"
                            type="email"
                            defaultValue={email}
                            // require
                            label={t("register.email") + "*"}
                            onChange={setEmail}
                            error={errors.email}
                            ref={register({ required: true })}
                            />
                            <StringInput
                                name="password"
                                type="password"
                                // required
                                defaultValue={password}
                                onChange={setPassword}
                                minLength={6}
                                error={errors.password}
                                label={t("register.password") + "*"}
                                ref={register({ required: true })}

                            />
                            <StringInput
                                name="password_confirmation"
                                // required
                                type="password"
                                defaultValue={password_confirmation}
                                onChange={setPasswordConfirm}
                                label={t("register.confirmPassword") + "*"}
                                minLength={6}
                                error={errors.password_confirmation}
                                ref={register({ required: true })}                      
                            />
                            {passError && 
                            <ErrorPass>{t("register.errorPass")}</ErrorPass>
                            }
                            {successChanged && <SuccessMessage>{successMessage}</SuccessMessage>}
                            <Button style={{ margin: "auto" }} type="submit">
                                {t("products.save")}
                            </Button>

                        </Form>
                    
                    </>
                </FormWrapper>
            </BlockWrapper>
        </Wrapper>
    );
}

export default ResetPassword;