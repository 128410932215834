import React from "react";
import propTypes from "prop-types";

import { Button as StyledButton } from "./style";

const Button = ({ type, onClick, value, children, style, ...props }) => {
  return (
    <StyledButton style={style} type={type} value={value} onClick={onClick}>
      {children}
    </StyledButton>
  );
};
Button.propTypes = {
  type: propTypes.string,
  value: propTypes.string,
  className: propTypes.string,
  onClick: propTypes.func,
  children: propTypes.any,
  style: propTypes.object,
};
export default Button;
