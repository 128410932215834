import styled, { css } from "styled-components";
import bg_top from "./images/bg_top.svg";
import bg_bottom from "./images/bg_bottom.svg";
import icon_pdf from "./images/pdf.svg";
import icon_download from "./images/download.svg";
import icon_share from "./images/share.svg";
import icon_edit from "./images/icon-more-grey.svg";

export const FAQWrapper= styled.main`
position: relative;
  
`;

export const FAQContent = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 50px 20px;

  section {
    color: #000000;
    max-width: 930px;
    margin: 0 auto;
    z-index: 1;
  }
  &::before {
    content: "";
    height: 336px;
    width: 298px;
    position: absolute;
    top: 0;
    right: 0;
    background: url(${bg_top}) no-repeat right top;
    z-index: -1;
  }
  &::after {
    content: "";
    height: 336px;
    width: 298px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(${bg_bottom}) no-repeat left;
    z-index: -1;
  }
  .ico-share { 
    background: url(${icon_share}) no-repeat center;
    width: 24px;
    height: 16px;
    display: inline-block;
  }
  .ico-pdf {
    background: url(${icon_pdf}) bottom no-repeat;
    background-size: 16px 16px;
    width: 23px;
    height: 17px;
    display: inline-block;
  }
  .ico-edit {
    background: url(${icon_edit}) bottom no-repeat;
    width: 23px;
    height: 17px;
    display: inline-block;
    background-size: 16px 16px;
  }
  .ico-download {
    background: url(${icon_download}) bottom no-repeat;
    width: 16px;
    height: 17px;
    display: inline-block;
    background-size: 16px 16px;
  }
  ul {
    font-family: "hn-medium", Arial;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.36px;
    line-height: 18px;
    text-align: left;
    padding-left: 10px;
  }
  li {
    margin-bottom: 10px;
    a, a:visited {
      color: #000;
    }
  }

  @media (max-width: 930px) {
    margin: 0;
  }
  h1 {
    font-family: "hn-light", Arial;
    font-size: 25px;
    font-weight: 300;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 0;
  }
  h2 {
    font-family: "hn-semibold", Arial;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 20px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;
  }
  h3 {
    font-family: "hn-semibold", Arial;
    font-size: 12px;
  }
  article {
    margin-top: 40px;
    div {
      margin: 10px 0;
    }
  }
  .title {
    font-family: "hn-semibold", Arial;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: 15px;
  }
  p {
    font-family: "hn-medium", Arial;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.36px;
    line-height: 18px;
    text-align: left;
    strong {
      font-family: "hn-semibold", Arial;
      font-weight: 600;
    }
    a {
      color: #000000;
      font-style: normal;
      letter-spacing: normal;
      line-height: normal;
      text-decoration: underline;
    }
  }

`;
export const ProtocolsCTAs = styled.span`
  padding: 15px;
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: center;
  border: 1px solid #000000;
  max-width: 250px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  text-transform: lowercase;
  width: 100%;
  ${({ protocolId }) =>
    protocolId &&
    css`
      border: 1px solid #000000;
      background-color: #000000;
      color: #ffffff;
      margin: 30px auto;
      display: block;
    `}
`;

export const BottomCTAsWrapper = styled.section`
  @media print {
    display: none;
  }
  max-width: 530px;
  margin: 0 auto;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr;
`;
export const BackButton = styled.p `
  font-family: "hn-semibold", Arial;
  position: absolute;
  left: 60px;
  top: 15px;
  z-index: 1;
  @media (max-width: 600px) {
    left: 20px;
    top: 5px;
  }
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
  padding-left: 0;
  cursor: pointer;
  color: #989ea3;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 23.4px;
  text-align: left;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  &::before {
    content: "";
    width: 7px;
    height: 7px;
    border-left: 1px solid #737373;
    border-top: 1px solid #737373;
    transform: rotate(-48deg);
    margin-right: 5px;
    display: inline-block;
  }
`;