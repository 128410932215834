import styled, { css } from "styled-components";
import img from "./images/login-background.jpg";
import qrIcon from "./images/qr-reader-icon.png";
export const Wrapper = styled.main`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const BlockWrapper = styled.div`
  @media (max-width: 500px) {
    padding: 10px;
  }
  .help-links {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #000000;
      font-family: hn-medium, Arial;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
      cursor: pointer;
      margin-right: 20px;
      text-decoration: underline;
    }
  }
`;
export const FormWrapper = styled.section`
  margin: 0 auto;
  display: inline-block;
  max-width: 465px;
  background-color: #fff;
  height: fit-content;
  padding: 30px 15%;
  text-align: center;
  @media (max-width: 500px) {
    padding: 30px 10px;
  }
`;
export const ToogleFormBtnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
export const ToggleBtn = styled.span`
  color: #000000;
  font-family: hn-medium, Arial;
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  padding: 25px;
  background-color: ${({ active }) => (active ? "" : "#F2F2F2")};
  ${({ active }) =>
    !active &&
    css`
      border: 1px solid #e2e2e2;
    `}
`;
export const TextB4Form = styled.p`
  color: #000000;
  font-family: hn-medium, Arial;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.31px;
  line-height: 20px;
  text-align: center;
  padding: 0 20px;
  strong {
    font-family: "hn-semibold", Arial;
      font-weight: 600;
  }
`;
export const TextAfterForm = styled.p`
  color: #000000;
  font-family: hn-medium, Arial;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  @media(max-width:520px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  a, a:visited {
    color: black;
    cursor: pointer;
  }
`;
export const QrIcon = styled.span`
  background-image: url(${qrIcon});
  width: 37px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: cover;
  float: right;
`;

export const ForgotPassword = styled.p`
  color: #000000;
  font-family: hn-semibold, Arial;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;
export const BackButton = styled.p`
  font-family: "hn-semibold", Arial;
  padding:0;
  margin: 0;
  margin-top: 15px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: underline;
  line-height: 15px;
  text-align: center;
  background: transparent;
  border: 0;
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    transform: rotate(-48deg);
    margin-right: 5px;
    display: inline-block;
  }
`;
export const LoadingResource = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.5;
  img {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
  }
`;
export const SuccessMessage = styled.p`
  background-color: #DAEEE2;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-style: normal;
  letter-spacing: 0.26px;
  line-height: 10px;
  text-align: center;
  margin: 10px auto;
  padding: 5px;
`;