import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EditPatient from "components/EditPatient";
import { useInput } from "components/Input/input.hook";
import {
  deletePatientUtil,
  fetchPatientsUtil,
  updatePatientUtil,
} from "redux/utils/bodyshock/Patients";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Wrapper,
  AddPatient,
  ResultsContainer,
  Header,
  Searcher,
  Results,
  Patient,
  Actions,
  BottomCTAsWrapper,
  ProtocolsCTAs,
} from "./style";
import routes from "constants/routes";
import { ActionsComponent } from "./utils";
const Patients = function ({
  patients,
  token,
  getPatients,
  selectedLang,
  deletePatient,
  updatePatient,
}) {
  const [t] = useTranslation("global");
  const [editablePatient, setEditablePatient] = useState(false);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [searchValue, setSearchValue] = useInput("");
  const history = useHistory();

  useEffect(() => {
    getPatients(token);
  }, [getPatients, token]);
  useEffect(() => {
    setFilteredPatients(patients);
  }, [patients]);
  useEffect(() => {
    const result = patients.filter((patient) => {
      const patientCombination = patient.name + patient.last_name + patient.email;
      return patientCombination.toLowerCase().includes(searchValue.toLowerCase().replace(" ", ""));
    });
    setFilteredPatients(result);
  }, [searchValue, patients]);

  const deletePatientAndResetList = (patientId) => {

    if (selectedLang === 'es') {
      if (window.confirm('¿está seguro que desea eliminar?')) {
        deletePatient(token, patientId);
      }
    } 
    else if(selectedLang === 'pt') {
      if (window.confirm('tem a certeza de que quer eliminar?')) {
        deletePatient(token, patientId);
      }
    }
    else if(selectedLang === 'fr') {
      if (window.confirm('Vous êtes sûr de vouloir supprimer?')) {
        deletePatient(token, patientId);
      }
    }
    else if(selectedLang === 'pl') {
      if (window.confirm('Czy na pewno chcesz usunąć?')) {
        deletePatient(token, patientId);
      }
    }
    else if(selectedLang === 'nl') {
      if (window.confirm('weet u zeker dat u wilt verwijderen?')) {
        deletePatient(token, patientId);
      }
    }
    else if(selectedLang === 'de') {
      if (window.confirm('Sind Sie sicher, dass Sie das löschen möchten?')) {
        deletePatient(token, patientId);
      }
    }
    else {
      if (window.confirm('are you sure you want to delete?')) {
        deletePatient(token, patientId);
      }
    }

  };
  const onSubmit = (patient) => {
    updatePatient(token, patient, editablePatient.id);
    setEditablePatient(false);
    setFilteredPatients(patients);
  };
  return (
    <Wrapper>
      <Header>
        <AddPatient onClick={() => history.push(`${routes.newpatient}`)} />
        <h1>{t("patientList.patientListTitle")}</h1>
      </Header>
      <Searcher>
        <input
          name="search"
          type="text"
          placeholder={t("patientList.searchPlaceholder")}
          defaultValue={searchValue}
          onChange={setSearchValue}
        />
      </Searcher>
      <ResultsContainer>
        <Results>
          <tbody>
            {filteredPatients &&
              filteredPatients.map((patient) => (
                <Patient key={patient.id}>
                  <td style={{ cursor: "default", width: "30px" }}>
                    <Actions>
                        <ActionsComponent
                          setEditablePatient={setEditablePatient}
                          deletePatientAndResetList={deletePatientAndResetList}
                          patient={patient}
                          t={t}
                        />
                      </Actions>
                  </td>
                  <td
                    onClick={() => history.push(`${routes.patient}/${patient.id}`)}
                  >
                    {patient.name}{" "}{patient.last_name}
                  </td>
                  <td onClick={() => history.push(`${routes.patient}/${patient.id}`)}>
                    {patient.date_of_birth}
                  </td>
                  <td onClick={() => history.push(`${routes.patient}/${patient.id}`)}>
                    {patient.email}
                  </td>
                  <td onClick={() => history.push(`${routes.patient}/${patient.id}`)}>
                    {patient.phone_number || "-"}
                  </td>
                </Patient>
              ))}
          </tbody>
        </Results>
      </ResultsContainer>
      {editablePatient && (
        <>
          <div className="overlay" onClick={() => setEditablePatient(false)} >

          </div>
          <EditPatient
            key={editablePatient.id}
            patient={editablePatient}
            selectedLang={selectedLang}
            onSubmit={onSubmit}
            close={() => setEditablePatient(false)}
          />
        </>
      )}
      <BottomCTAsWrapper>
        <ProtocolsCTAs 
          onClick={() => history.push(`${routes.homeBodyshock}`)}
          >
          {t("treatment.home")}
        </ProtocolsCTAs>
      </BottomCTAsWrapper>
    </Wrapper>
  );
};
const mapStateToProps = (state) => ({
  patients: state.patients,
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  getPatients: (token) => dispatch(fetchPatientsUtil(token)),
  deletePatient: (token, patientId) => dispatch(deletePatientUtil(token, patientId)),
  updatePatient: (token, patient, patientId) =>
    dispatch(updatePatientUtil(token, patient, patientId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Patients);
