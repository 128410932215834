function generateArrayFrom0toNMinus1(number) {
  return Array.from(Array(number), (_, index) => index);
}
function dateFormatter(date) {
  let fixedDate;
  if (date instanceof Date) {
    fixedDate = date;
  } else {
    fixedDate = new Date();
  }
  const day = ("0" + (fixedDate.getDate())).slice(-2);;
  const monthWith0InFrontAndNumberCorrect = ("0" + (fixedDate.getMonth() + 1)).slice(-2);
  const fourDigitYear = fixedDate.getFullYear();
  return `${day}/${monthWith0InFrontAndNumberCorrect}/${fourDigitYear}`;
}
function timeFormatter(date) {
  if (date instanceof Date) {
    const hour = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${hour}:${minutes}`;
  }
  throw new Error("date must be an instance of Date");
}
const isSessionExpired = (appointment_date) => {
  if (appointment_date) {
    let today = new Date().setHours(0, 0, 0, 0);
    var appointment = appointment_date.includes("-")
      ? appointment_date.split("-")
      : appointment_date.split("/");
    const appointmentDate = new Date(appointment[2], appointment[1] - 1, appointment[0]).setHours(
      0,
      0,
      0,
      0
    );
    if (today > appointmentDate) {
      return true;
    }
    return false;
  }
};
export { generateArrayFrom0toNMinus1, dateFormatter, timeFormatter, isSessionExpired };
