import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { initTestAction } from "redux/actions/Patient";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import propTypes from "prop-types";
import { getNotificationUtil } from "redux/utils/transverse/Notification";

import {
  ActionWrapper,
  ButtonsWrapper,
  BannerWrapper,
  MainWrapper,
  Banner,
  BannerInnerWrapper,
  BannerTextWrapper,
  Exclusive,
  H1,
  BannerLogo,
  HomeContentWrapper,
  ButtonText,
  HomeButton,
  IconImg,
} from "./style";
import miniLogo from "./images/mini-logo.svg";
import testIcon from "./images/test-icon.svg";
import patientsIcon from "./images/patients-icon.svg";
import resourceIcon from "./images/resource-icon.svg";
import "react-datepicker/dist/react-datepicker.css";
import routes from "constants/routes";
import localstoragekeys from "constants/localstoragekeys";
import useLocalStorage from "utils/transverse/useLocalStorage";
import { Notification } from "components/transverse/Notification";

const Home = function ({
  initTest,
  selectedLang,
  appSelected,
  getNotifications,
  notification,
}) {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [t] = useTranslation("global");
  const [showNotification, setShowNotification] = useState(false);
  const [saveNotification, setSaveNotification] = useLocalStorage(localstoragekeys.notification);

  const closeNotification = () => {
    setShowNotification(false);
    setSaveNotification(true);
  }

  useEffect(() => {
    getNotifications(selectedLang, appSelected);

    if (!saveNotification) {
      setShowNotification(true);
    }
  }, [getNotifications, setShowNotification, selectedLang, appSelected, saveNotification]);

  document.title = "bodyshock diagnosis system";

  return (
    <MainWrapper>
      <BannerWrapper>
        <BannerInnerWrapper>
          <Banner />
          <BannerLogo src={miniLogo} />
          <BannerTextWrapper>
            <H1><Trans t={t} i18nKey="home.welcomeBodyshock">welcome to <span>bodyshock diagnosis solutions™</span></Trans></H1>
            <Exclusive>{t("home.exclusive")}</Exclusive>
          </BannerTextWrapper>
        </BannerInnerWrapper>
      </BannerWrapper>
      <HomeContentWrapper>
        <ButtonsWrapper>
          <ActionWrapper onClick={() => history.push(`${path}/${routes.helpPage}`)}>
            <IconImg src={testIcon} alt="Icon" />
            <div>
              <HomeButton primary>{t("home.testActionBody")}</HomeButton>
              <ButtonText>{t("home.testHelpText")}</ButtonText>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${path}/${routes.patients}`)}>
            <IconImg src={patientsIcon} alt="Icon" />
            <div>
              <HomeButton primary>{t("home.patientsActionBody")}</HomeButton>
              <ButtonText>{t("home.patientsHelpTextBody")}</ButtonText>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${path}/${routes.resources}`)}>
            <IconImg src={resourceIcon} alt="Icon" />
            <div>
              <HomeButton primary={false}>{t("home.resourceActionBody")}</HomeButton>
              <ButtonText>{t("home.resourceHelpText")}</ButtonText>
            </div>
          </ActionWrapper>
        </ButtonsWrapper>
        {showNotification && notification.exist && (
          <Notification notification={notification} closeNotification={closeNotification} />
        )}
      </HomeContentWrapper>
    </MainWrapper>
  );
};
Home.propTypes = {
  initTest: propTypes.func,
  patient: propTypes.object,
};
const mapDispatchToProps = (dispatch) => ({
  initTest: (patient) => dispatch(initTestAction(patient)),
  getNotifications: (selectedLang, appSelected) => dispatch(getNotificationUtil(selectedLang, appSelected)),
});
const mapStateToProps = (state) => ({
  patient: state.patient.personalData,
  selectedLang: state.languages.selectedLanguage,
  appSelected: state.application,
  notification: state.notification,
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
