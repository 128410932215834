import React, { Fragment, useState, useCallback } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {
  RangeCardsWrapper,
  Range,
  RangeProductCard,
  RangeImg,
  RangeTitle,
  RangeInfoWrapper,
  RangeDescription,
  HomeProductWrapper,
  HomeProductsWrapper,
  HomeProductDescription,
  HomeProductImg,
  HomeProductName,
  ProtocolHome,
} from "./styles";
import { Actions } from "components/ProfessionalProtocol/styles";
import {
  hadensimatrix,
  antiagingflashampoules,
  aoxferulic,
  brighteningpeelbooster,
  collagen360ºElixir,
  collagen360ºEssence,
  collagen360ºEyeContour,
  collagen360ºIntensiveCream,
  couperendmaintenancecream,
  energyccomplex,
  energyceyecontour,
  energycintensivecream,
  glycolicampoules,
  glycorepair,
  melatoninampoules,
  pollutiondefenseampoules,
  proteoglycansampoules,
  radiancednaelixir,
  radiancednaessence,
  radiancednaeyecontour,
  radiancednaintensivecream,
  radiancednanightcream,
  resurfacingpeelbooster,
  stemcellnanofillerlipcontour,
  stemcellserumrestructuractive,
  stemcellactivegrowthfactor,
  brighteningcomplexplus,
  firmingelixir,
  antiwrinklelipandcontour,
  ageelementbrighteningcream,
  ageelementbrighteningconcentrate,
  ageelementbrighteningeyecontour,
  ageelementfirmingcream,
  ageelementfirmingconcentrate,
  ageelementfirmingeyecontour,
  ageelementantiwrinklecream,
  ageelementantiwrinkleconcentrate,
  ageelementantiwrinkleeyecontour,
  ageelementantiwrinklenightcream
} from "./images/products/index";
import Swiper, { Navigation, Pagination } from "react-id-swiper";
const params = {
  slidesPerView: 1.1,
  spaceBetween: 20,
  // centeredSlides: true,
  modules: [Navigation, Pagination],
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    640: {
      width: 640,
      slidesPerView: 2,
    },
  },
  rebuildOnUpdate: true,
};

const ProductImgs = {
  hadensimatrix,
  antiagingflashampoules,
  aoxferulic,
  brighteningpeelbooster,
  collagen360ºelixir: collagen360ºElixir,
  collagen360ºessence: collagen360ºEssence,
  collagen360ºeyecontour: collagen360ºEyeContour,
  collagen360ºintensivecream: collagen360ºIntensiveCream,
  energycintensivecream,
  couperendmaintenancecream,
  energyccomplex,
  energyceyecontour,
  collagen360ºIntensiveCream,
  "glycolic+e+fampoules": glycolicampoules,
  glycorepair,
  melatoninampoules,
  pollutiondefenseampoules,
  proteoglycansampoules,
  radiancednaelixir,
  radiancednaessence,
  radiancednaeyecontour,
  radiancednaintensivecream,
  radiancednanightcream,
  resurfacingpeelbooster,
  stemcellnanofillerlipcontour,
  stemcellserumrestructuractive,
  stemcellactivegrowthfactor,
  brighteningcomplexplus,
  firmingelixir,
  "anti-wrinklelipandcontour": antiwrinklelipandcontour,
  ageelementbrighteningcream,
  ageelementbrighteningconcentrate,
  ageelementbrighteningeyecontour,
  ageelementfirmingcream,
  ageelementfirmingconcentrate,
  ageelementfirmingeyecontour,
  "ageelementanti-wrinklecream": ageelementantiwrinklecream,
  "ageelementanti-wrinkleconcentrate": ageelementantiwrinkleconcentrate,
  "ageelementanti-wrinkleeyecontour": ageelementantiwrinkleeyecontour,
  "ageelementanti-wrinklenightcream": ageelementantiwrinklenightcream
};
const ActionsComponent = ({ removeProduct, productId, gamma, t }) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), [setOpen]);
  const referencia = useOnclickOutside(() => {
    setOpen(false);
  });
  return (
    <Fragment>
      <button onClick={toggle}>
        <span>...</span>
      </button>
      <ul style={{ display: open ? "block" : "none" }} ref={referencia}>
        <li
          className="icoedit"
          onClick={() => {
            removeProduct(productId, gamma);
            setOpen(false);
          }}
        >
          {t("treatment.deleteProduct")}
        </li>
      </ul>
    </Fragment>
  );
};

const HomeProtocol = ({ homeProducts, removeProduct, t }) => {
  return (
    <ProtocolHome
      id="homeprotocol"
    >
      {homeProducts && homeProducts.gamma && homeProducts.gamma.length > 0 && (
        <RangeCardsWrapper>
          <Swiper {...params}>
            {homeProducts.gamma.map((product, index) => (
              <div key={product.id} className="swiper-slide">
                <RangeProductCard>
                  <Actions>
                    <ActionsComponent
                      productId={product.id}
                      removeProduct={removeProduct}
                      gamma={true}
                      t={t}
                    />
                  </Actions>
                  <RangeImg
                    src={
                      ProductImgs[
                      product.gamma.split(" ").join("").toLowerCase() +
                      product.name.split(" ").join("").toLowerCase()
                      ]
                    }
                    alt="icon"
                  />
                  <RangeInfoWrapper>
                    <Range>{product.gamma}</Range>
                    <RangeTitle>{product.name}</RangeTitle>
                    <RangeDescription>{product.description}</RangeDescription>
                  </RangeInfoWrapper>
                </RangeProductCard>
              </div>
            ))}
          </Swiper>
        </RangeCardsWrapper>
      )}
      <HomeProductsWrapper>
        {homeProducts &&
          homeProducts.products &&
          homeProducts.products.map((product) => (
            <HomeProductWrapper key={product.id}>
              <Actions>
                <ActionsComponent
                  productId={product.id}
                  removeProduct={removeProduct}
                  t={t}
                />
              </Actions>
              <HomeProductImg
                src={
                  ProductImgs[product.name.split(" ").join("").toLowerCase()]
                }
              />
              <div>
                <HomeProductName>{product.name.toLowerCase()}</HomeProductName>
                <HomeProductDescription>
                  {product.description}
                </HomeProductDescription>
              </div>
            </HomeProductWrapper>
          ))}
      </HomeProductsWrapper>
    </ProtocolHome>
  );
};
export default HomeProtocol;
