import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import {
  BackButton,
  Bar,
  BarBg,
  BarContainer,
  BarTitle,
  BarTitles,
  BarWrapper,
  TestBarWrapper,
} from "./style";
const TestBar = ({
  history,
  width,
  test,
  homeProtocol,
  professionalProtocol,
  handleClick,
  testForm,
  country,
  patient,
  protocol,
}) => {
  const [t] = useTranslation("global");
  const ownHistory = useHistory();
  return (
    <TestBarWrapper>
      {history && (
        <BackButton onClick={() => history.goBack()}>
          {t("testBar.back")}
        </BackButton>
      )}
      {handleClick && (
        <BackButton onClick={handleClick}>{t("testBar.back")}</BackButton>
      )}

      <BarWrapper
        test={test}
        homeProtocol={homeProtocol}
        professionalProtocol={professionalProtocol}
        testForm={testForm}
      >
        <BarTitles>
          <BarTitle
            homeProtocol={homeProtocol}
            professionalProtocol={professionalProtocol}
            onClick={() => ownHistory.push("/newpatient")}
          >
            {t("testBar.patientData")}
          </BarTitle>
          <BarTitle
            homeProtocol={homeProtocol}
            professionalProtocol={professionalProtocol}
            onClick={() =>
              patient && patient.date_of_birth && ownHistory.push("/start")
            }
          >
            {t("testBar.ageElementID")}
          </BarTitle>
          <BarTitle
            onClick={() =>
              (handleClick && handleClick()) ||
              (protocol.id && ownHistory.push("/treatment"))
            }
          >
            {t("testBar.professionalProtocol")}
          </BarTitle>
            <BarTitle
            >
              {t("testBar.homeProtocol")}
            </BarTitle>
        </BarTitles>
        <BarContainer>
          <BarBg />
          <Bar
            width={width}
            test={test}
            homeProtocol={homeProtocol}
            professionalProtocol={professionalProtocol}
            testForm={testForm}
          />
        </BarContainer>
      </BarWrapper>
    </TestBarWrapper>
  );
};
const mapStateToProps = (state) => ({
  country: state.user.data.country_id,
  patient: state.patient.personalData,
  protocol: state.protocols,
});
export default connect(mapStateToProps, null)(TestBar);
