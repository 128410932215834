import {
  INITTEST,
  RESETPATIENT,
  SENDQUESTIONNAIRE,
} from "../constants/Patient";

export default (state = { test: null, personalData: null }, action) => {
  switch (action.type) {
    case INITTEST:
      return Object.assign({}, state, action.payload);
    case SENDQUESTIONNAIRE:
      return Object.assign({}, state, action.payload);
    case RESETPATIENT:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};
