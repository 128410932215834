import styled, { css } from "styled-components";
import lens from "./images/lens.svg";
import closeIcon from "./images/close.svg";
import SuccessIndicator from "react-success-indicator";

export const CloseButton = styled.span`
  background-image: url(${closeIcon});
  display: block;
  width: 33px;
  height: 33px;
  background-repeat: no-repeat;
  position: absolute;
  top: -40px;
  right: -14px;
  cursor: pointer;
`;
export const ProductListWrapper = styled.section`
  .inner {
    position: relative;
  }
  position: absolute;
  right: 0;
  left: 0;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  top: 15%;
  z-index: 100;
  height: auto;
  background: white;
  max-width: 465px;
  @media (max-width: 520px) {
    width: 90%;
  }
  h3 {
    color: #000000;
    font-family: "hn-light", Arial;
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
    text-align: center;
    text-transform: lowercase;
  }
  .search {
    width: 100%;
    text-align: center;
    background-color: #f2f2f2;
    padding: 14px 8px;
    @media (max-width: 520px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    input {
      width: 75%;
      border: none;
      border-bottom: solid 1px #4a4a4a;
      height: 35px;
      outline: none;
      background: #f2f2f2 url(${lens}) no-repeat center right;
      @media (max-width: 520px) {
        width: 100%;
      }
    }
  }

  ul {
    max-width: 380px;
    width: 98%;
    margin: 10px auto;
    padding-left: 0;
    max-height: 390px;
    overflow-y: scroll;
    @media (max-width: 520px) {
      width: 100%;
      padding: 0 20px;
    }
    &::webkit-scrollbar {
      background: white;
      border: 3px solid #4a4a4a;
    }
    li {
      display: flex;
      width: 98%;
      justify-content: space-around;
      span {
        width: 45%;
        font-family: "hn-light", Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 23.4px;
        text-align: left;
      }
    }
  }

  .product-labels {
    border-bottom: 2px solid #f2f2f2;
    @media (max-width: 520px) {
      padding: 0 20px;
    }
    article {
      display: flex;
      margin: 26px auto 0;
      max-width: 380px;
    }
    span {
      font-family: "hn-light", Arial;
      font-weight: 400;
      line-height: 23.4px;
      text-align: left;
      font-size: 10px;
      width: 43%;
      color: #000000;
    }
  }

  .actions {
    max-width: 350px;
    margin: 20px auto 20px;
    text-align: center;
    @media (max-width: 520px) {
      padding: 0 20px;
    }
    button {
      border: 1px solid #000000;
      padding: 10px;
      background-color: #000000;
      color: #ffffff;
      font-family: "hn-semibold", Arial;
      font-size: 14px;
      letter-spacing: 0.34px;
      line-height: 20px;
      text-align: center;
      text-transform: lowercase;
      width: 100%;
      margin: 15px 0;
      cursor: pointer;
    }
  }
`;

export const Product = styled.li`
  border: 1px solid #e8e9ea;
  border-radius: 2px;
  padding: 12px 10px;
  margin: 8px 0px;
  list-style: none;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      border: solid 1px black;
      &:after {
        content: "";
        width: 4px;
        height: 11px;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        transform: rotate(45deg);
        z-index: 1;
        top: 0;
        left: 5px;
      }
    `}
  ${({unique}) => unique && css`
    span {
      width: 100% !important;
    }
  `}
`;
export const ComponentCheck = styled(SuccessIndicator)`
  margin: 20px 0;
`;
