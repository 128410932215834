import axios from "axios";
import {
  resetPatient,
  resetPatients,
  resetQuestionnaire,
} from "../../actions/Patient";
import { resetProtocol } from "../../actions/Protocols";
import {
  loginAction,
  validateCodeAction,
  errorsUserAction,
  errorsCode,
  logoutAction,
} from "../../actions/User";
export const LoginUser = (email, password) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_ANTIAGING_API}api/login`, {
      email,
      password,
    })
    .then(({ data }) => {
      dispatch(loginAction(data.user));
      dispatch(errorsUserAction(null));
      dispatch(errorsCode(null));
    })
    .catch(({ response }) => {
      dispatch(errorsUserAction(response.data.message));
      dispatch(errorsCode(null));
    });
};
export const RecoveryPasswordUser = (email, selectedLang) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_ANTIAGING_API}api/accounts/reset/password`, {
        email,
      },
      { headers: { "Content-Language": selectedLang } }
    )
    .then(() => {
      dispatch(errorsUserAction('Send email recovery pass'));
      dispatch(errorsCode(null));
    })
    .catch(({ response }) => {
      dispatch(errorsUserAction(response.data.message));
      dispatch(errorsCode(null));
    });
};

export const RegisterUser = (user) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_ANTIAGING_API}api/register`, user)
    .then(({ data }) => {
      dispatch(loginAction(data.user));
      dispatch(errorsUserAction(null));
      dispatch(errorsCode(null));
    })
    .catch(({ response }) => {
      dispatch(errorsUserAction(response.data.message));
      dispatch(errorsCode(null));
    });
};

export const validateCode = (code, history) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_ANTIAGING_API}api/code`, { code })
    .then(({ data }) => {
      dispatch(validateCodeAction(data.code));
      dispatch(errorsCode(null));
      dispatch(errorsUserAction(null));
      if (data.code) {
        history.push("/register");
      }
    })
    .catch(({ response }) => {
      dispatch(errorsCode(response.data.message));
      errorsUserAction(null);
    });
};
export const LogOut = (history) => (dispatch) => {
  dispatch(resetPatient());
  dispatch(resetQuestionnaire());
  dispatch(resetProtocol());
  dispatch(resetPatients());
  dispatch(logoutAction());
  history.push("/");
};
export const verifyUserToken = (token) => () => {
  axios
    .post(`${process.env.REACT_APP_ANTIAGING_API}api/token`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => true)
    .catch(() => false);
};
export const registerNewApp = (email, password, code) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_ANTIAGING_API}api/registerupdate`, {
      email,
      password,
      register_code: code,
    })
    .then(({ data }) => {
      dispatch(loginAction(data.user));
      dispatch(errorsUserAction(null));
      dispatch(errorsCode(null));
    })
    .catch(({ response }) => {
      dispatch(errorsUserAction(response?.data.message));
      dispatch(errorsCode(null));
    });
};
