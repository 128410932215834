import axios from "axios";
import { getPatientProtocolUtil } from "./Patients";
import { cleanBlobs } from "./Questionnaire";
const appointmentFormatter = (fields, patientId) => ({
  id_results_jsons: fields?.protocolId,
  session: fields?.sessionNumber,
  appointment_date: fields?.sessionDate,
  appointment_time:
    fields?.sessionHour &&
    fields.sessionMinutes &&
    `${fields.sessionHour}:${fields.sessionMinutes}`,
  data: (fields.patientData ? fields.patientData : cleanBlobs(fields.data)),
  patient_id: patientId,
  ...(fields.email && { "email": fields.emailContent }),
});
const appointmentUpdateData = (fields, patientId) => ({
  id: fields.id,
  patient_id: patientId,
  appointment_date: fields?.sessionDate,
  appointment_time:
    fields?.sessionHour &&
    fields.sessionMinutes &&
    `${fields.sessionHour}:${fields.sessionMinutes}`,
  data: cleanBlobs(fields.data),
});
const saveAppointmentUtil = (patientId, token, sessionData, selectedLang) => (dispatch) => {
  const formattedData = appointmentFormatter(sessionData, patientId);
  axios
    .post(
      `${process.env.REACT_APP_ANTIAGING_API}api/bodyshock/patient/${patientId}/appointment`,
      formattedData,
      {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(() => {
      dispatch(getPatientProtocolUtil(patientId, token, selectedLang));
    });
};
const updateAppointmentUtil = (patientId, token, sessionData, selectedLang) => (dispatch) => {
  const formattedData = appointmentUpdateData(sessionData, patientId);
  axios
    .put(
      `${process.env.REACT_APP_ANTIAGING_API}api/bodyshock/patient/${patientId}/appointment`,
      formattedData,
      {
        headers: {
          "Content-Language": selectedLang,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(() => {
      dispatch(getPatientProtocolUtil(patientId, token, selectedLang));
    });
};
export { saveAppointmentUtil, updateAppointmentUtil };
