import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from "react-redux";
import { useHistory } from 'react-router';
import routes from "constants/routes";
import abdomen from './images/abdomen.svg';
import brazos from './images/brazos.svg';
import gluteos from './images/gluteos.svg';
import piernas from './images/piernas.svg';
import pecho from './images/pecho.svg';
import menton from './images/menton.svg';


import {
  MiniBannerInner,
  MiniBannerSubtitle,
  MiniBannerTitle,
  MiniBannerWrapper,
  Wrapper,
  BottomCTAsWrapper,
  ProtocolsCTAs,
} from './style';
import {
    ActionWrapper,
    IconImg,
    HomeButton,
} from '../Home/style';

import {
  AdvicesWrapper,  
  Subtitle
} from './styleAdvices'


const AdvicePage = () => {
  const [t] = useTranslation('global');
  const history = useHistory();

  document.title = 'Advices bodyshock diagnosis solutions';


    return (
      <Wrapper>
        <MiniBannerWrapper>
          <MiniBannerInner>
            <MiniBannerTitle>bodycare solutions</MiniBannerTitle>
            <MiniBannerSubtitle>
              {t('resourcesBodyshock.aboutPage.subtitle')}
            </MiniBannerSubtitle>
          </MiniBannerInner>
        </MiniBannerWrapper>
        <Subtitle>
            <p className="title"><Trans t={t} i18nKey="resourcesBodyshock.advicePage.titleAdvices"><strong>maniobras</strong> y <strong>consejos</strong> del experto</Trans></p>
            <p className='subtitle'><Trans t={t} i18nKey="resourcesBodyshock.advicePage.subTitleAdvices">La técnica es una parte esencial del tratamiento, descubre los <strong>consejos de nuestros expertos</strong> en base a la zona de tratamiento.</Trans></p>
        </Subtitle>
        <AdvicesWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.abdomen}`)}>
            <IconImg src={abdomen} alt="Icon" />
            <div>
            <HomeButton primary={false}>
                {t('resourcesBodyshock.advicePage.abdomen')}
              </HomeButton>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.arms}`)}>
            <IconImg src={brazos} alt="Icon" />
            <div>
            <HomeButton primary={false}>
                {t('resourcesBodyshock.advicePage.arms')}
              </HomeButton>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.buttocks}`)}>
            <IconImg src={gluteos} alt="Icon" />
            <div>
            <HomeButton primary={false}>
                {t('resourcesBodyshock.advicePage.buttocks')}
              </HomeButton>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.legs}`)}>
            <IconImg src={piernas} alt="Icon" />
            <div>
            <HomeButton primary={false}>
                {t('resourcesBodyshock.advicePage.legs')}
              </HomeButton>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.breasts}`)}>
            <IconImg src={pecho} alt="Icon" />
            <div>
            <HomeButton primary={false}>
                {t('resourcesBodyshock.advicePage.breasts')}
              </HomeButton>
            </div>
          </ActionWrapper>
          <ActionWrapper onClick={() => history.push(`${routes.chin}`)}>
            <IconImg src={menton} alt="Icon" />
            <div>
            <HomeButton primary={false}>
                {t('resourcesBodyshock.advicePage.chin')}
              </HomeButton>
            </div>
          </ActionWrapper>
        </AdvicesWrapper>
        <BottomCTAsWrapper>
            <ProtocolsCTAs className='whiteButton'
              onClick={() => history.goBack()} 
            >
              bodyshock® resources
            </ProtocolsCTAs>
            <ProtocolsCTAs onClick={() => history.push(`${routes.homeBodyshock}`)} >
              home
            </ProtocolsCTAs>
        </BottomCTAsWrapper>
      </Wrapper>
    );

};
const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});
export default connect(mapStateToProps, null)(AdvicePage);
