import {
  ERRORCODE,
  LOGINUSER,
  LOGOUTUSER,
  REGISTERERRORS,
  VALIDATECODE,
} from "../constants/User";

export const loginAction = (userData) => ({
  type: LOGINUSER,
  payload: userData,
});
export const errorsUserAction = (errors) => ({
  type: REGISTERERRORS,
  payload: errors,
});
export const validateCodeAction = (code = false) => ({
  type: VALIDATECODE,
  payload: code,
});
export const errorsCode = (error) => ({
  type: ERRORCODE,
  payload: error,
});
export const logoutAction = () => ({
  type: LOGOUTUSER,
  payload: {},
});
