import React from "react";
import { PrivacyWrapper, PrivacyInside } from "./style";
import { Trans, useTranslation } from "react-i18next";
import { CloseButton } from "../EditHomeProtocol/styles";

export default ({ openMenu, referency, onClick }) => {
  const [t] = useTranslation("privacy");

  return (
    <PrivacyWrapper active={openMenu}>
      <CloseButton onClick={onClick} style={{ top: "-15px", zIndex: 5 }} />
      <PrivacyInside>
        <div>
          <h1>{t("title")}</h1>
        </div>
        <div>
          <p>
            <strong>1. {t("section1title")}</strong>
          </p>
          <ul>
            <li>{t("responsable")}</li>
            <li>{t("purpose")}</li>
            <li>{t("legitimate")}</li>
            <li>{t("recipients")}</li>
            <li>{t("rights")}</li>
            <li>
              <Trans t={t} i18nKey="additionalInfo">
                Información adicional: Puede consultar la información adicional
                y detallada sobre Protección de Datos en nuestra página web:
                <a href="https://www.mesoestetic.com/es/politica-de-privacidad">
                  https://www.mesoestetic.com/es/politica-de-privacidad
                </a>
              </Trans>
            </li>
          </ul>
          <p>
            <strong>2. {t("section2title")}</strong>
          </p>
          <p>{t("dataProtection")}</p>
          <p>{t("userGuarantees")}</p>
          <p>{t("thirdParty")}</p>
          <p>{t("thirdParty2")}</p>
          <p>{t("geolocation")}</p>
          <p>{t("webPerformance")}</p>
          <p>
            <strong>2.1 {t("section21title")}</strong>
          </p>
          <p>{t("identity")}</p>
          <p>{t("postalAddress")}</p>
          <p>{t("telephone")}</p>
          <p>
            <Trans t={t} i18nKey="email">
              CORREO ELECTRÓNICO:
              <a href="mailto:politicaprivacidad@mesoestetic.com">
                politicaprivacidad@mesoestetic.com
              </a>
            </Trans>
          </p>
          <div>
            <p>
              <strong>2.1.1 {t("section211title")}</strong>
            </p>
            <p>{t("section211content")}</p>
            <p>
              <strong>2.1.2 {t("section212title")}</strong>
            </p>
            <p>{t("manageList")}</p>
            <ul>
              <li>{t("manageList1")}</li>
              <li>{t("manageList2")}</li>
              <li>{t("manageList3")}</li>
              <li>{t("manageList4")}</li>
              <li>{t("manageList5")}</li>
            </ul>
            <p>{t("commercialProfile")}</p>
            <p>{t("socialLogin")}</p>
          </div>
          <p>
            <strong>2.2 {t("section22title")}</strong>
          </p>
          <p>{t("commercialPurpose")}</p>
          <p>{t("revokeConsent")}</p>
          <p>
            <Trans t={t} i18nKey="dataCriteria">
              El criterio de conservación de los datos tendrá base en la
              manifestación contraria al tratamiento por su parte. En todo caso,
              Podrá ejercer los derechos de acceso, rectificación, limitación,
              supresión, portabilidad y oposición, dirigiendo su petición a la
              dirección postal indicada más arriba o bien a través de correo
              electrónico
              <a href="mailto:politicaprivacidad@mesoestetic.com">
                politicaprivacidad@mesoestetic.com
              </a>
            </Trans>
          </p>
          <p>
            <strong>2.3 {t("section23title")}</strong>
          </p>
          <p>{t("section23content")}</p>
          <p></p>
          <p>
            <strong>2.4 {t("section24title")}</strong>
          </p>
          <p>{t("section24content")}</p>
          <p></p>
          <p>
            <strong>2.5 {t("section25title")}</strong>
          </p>
          <p>{t("section25content1")}</p>
          <p>{t("section25content2")}</p>
          <p></p>
          <p>
            <strong>2.6 {t("section26title")}</strong>
          </p>
          <p>
            <Trans t={t} i18nKey="section26content">
              El Usuario tiene derecho a obtener confirmación sobre si
              MESOESTETIC E-COMMERCE, SL trata datos personales que le
              conciernen, así como a acceder a sus datos personales, solicitar
              la rectificación de los datos inexactos o, en su caso, solicitar
              su supresión cuando, entre otros motivos, los datos ya no sean
              necesarios para los fines que fueron recogidos.En determinadas
              circunstancias, el Usuario podrá solicitar la limitación del
              tratamiento de sus datos, en cuyo caso únicamente se conservarán
              para el ejercicio o la defensa de reclamaciones.En determinadas
              circunstancias y por motivos relacionados con su situación
              particular, el Usuario podrá oponerse al tratamiento de sus datos.
              MESOESTETIC E-COMMERCE, SL cesará en el tratamiento de los datos,
              salvo por motivos legítimos imperiosos, o el ejercicio o la
              defensa de posibles reclamaciones.El Usuario podrá plantear las
              cuestiones que considere en relación a la presente Política así
              como ejercer sus derechos en los términos legalmente previstos
              debiendo para ello dirigir una comunicación mediante correo postal
              a: MESOESTETIC E-COMMERCE, SL, Carrer de la Tecnologia, 25, 08840,
              Viladecans (Barcelona), o correo electrónico a:
              <a href="mailto:politicaprivacidad@mesoestetic.com">
                politicaprivacidad@mesoestetic.com
              </a>
              con indicación de la solicitud correspondiente y acompañado de
              copia del DNI o documento acreditativo de la identidad.
            </Trans>
          </p>
        </div>
      </PrivacyInside>
    </PrivacyWrapper>
  );
};
