import styled from "styled-components";

export const Button = styled.button`
  background-color: #000000;
  padding: 7px 51px;
  color: #fff;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 26px;
  text-transform: lowercase;
  border: 1px solid #000;
  display: block;
  cursor: pointer;
  z-index: 5;
  width: 100%;
`;
