import {
  GETPATIENTS,
  GETQUESTIONNAIRE,
  INITTEST,
  SENDQUESTIONNAIRE,
  DELETEPATIENT,
  RESETPATIENT,
  RESETQUESTIONNAIRE,
  RESETPATIENTS,
} from "../constants/Patient";

export const initTestAction = (patient) => ({
  type: INITTEST,
  payload: { personalData: patient },
});

export const getQuestionnaireAction = (questionnarire) => ({
  type: GETQUESTIONNAIRE,
  payload: questionnarire,
});
export const setQuestionnaireAction = (patient) => ({
  type: SENDQUESTIONNAIRE,
  payload: patient,
});
export const getPatients = (patients) => ({
  type: GETPATIENTS,
  payload: patients,
});
export const deletePatient = (id) => ({
  type: DELETEPATIENT,
  payload: id,
});
export const resetPatient = () => ({
  type: RESETPATIENT,
  payload: { test: null, personalData: null },
});
export const resetQuestionnaire = () => ({
  type: RESETQUESTIONNAIRE,
});
export const resetPatients = () => ({
  type: RESETPATIENTS,
});
