import React, { useCallback, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { AddProductWrapper } from "./styles";
export const AddProductComponent = ({
  handleClick,
  coadyuvantes,
  gammaProducts,
  t,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((o) => !o), [setOpen]);
  const referencia = useOnclickOutside(() => {
    setOpen(false);
  });
  return (
    <AddProductWrapper>
      <button onClick={toggle}>
        <span>{t("treatment.addProduct")}</span>
      </button>
      <ul style={{ display: open ? "block" : "none" }} ref={referencia}>
        <li
          className="icoadd"
          onClick={() => {
            handleClick(gammaProducts);
          }}
        >
          {t("editHome.addRange")}
        </li>
        <li
          className="icoadd"
          onClick={() => {
            handleClick(coadyuvantes);
          }}
        >
          {t("editHome.addAdjuvants")}
        </li>
      </ul>
    </AddProductWrapper>
  );
};
