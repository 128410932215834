import React, { useCallback, useEffect, useState } from "react";
import TestBar from "components/antiaging/TestBar";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { getQuestionnaire, sendQuestionnaire } from "redux/utils/antiaging/Questionnaire";
import {
  QuestionnaireTitle,
  Wrapper,
  Counter,
  QuestionWrapper,
  PrevNextButton,
  PrevNextButtonsWrapper,
  QuestionnaireBarWrapper,
  QuestionnaireBarBg,
  QuestionnaireBar,
  FinishTest,
  FinishButton,
  GoToFirst,
  ProfessionalIconWrapper,
  ProfessionalIcon,
  ProfessionalAdvice,
} from "./style";
import {
  RenderSelectQuestions,
  RenderRankingQuestions,
  choicesSortRanking,
} from "./utils";
import help from "./images/professional.svg";
import helpActive from "./images/professional-active.svg";
import { resetQuestionnaire } from "redux/actions/Patient";
const Questionnaire = ({
  fetchQuestionnaire,
  questionnaire,
  sendCompleteQuestionnaire,
  patient,
  token,
  language,
  resetQuestionnaireUtil,
}) => {
  const [t] = useTranslation("global");
  const [position, setPosition] = useState(0);
  const [boostersPoints, setBoostersPoints] = useState([]);
  const [rangeProducts, setRangeProducts] = useState([]);
  const [homePoints, setHomePoints] = useState([]);
  const [answerError, setAnswerError] = useState(false);
  const [choiceOrder, setChoiceOrder] = useState(1);
  const history = useHistory();
  const [iconProfessional, setIconProfesional] = useState(false);
  const [age, setAge] = useState(0);
  const CalculateAge = useCallback(() => {
    let today = new Date();
    let dob = patient.personalData.date_of_birth.includes("-")
      ? patient.personalData.date_of_birth.split("-")
      : patient.personalData.date_of_birth.split("/");
    let difference = today - new Date(dob[2], dob[1] - 1, dob[0]);
    let totalDays = difference / (1000 * 60 * 60 * 24);
    let totalYears = totalDays / 365;
    setAge(parseInt(totalYears));
  }, [patient.personalData.date_of_birth]);
  useEffect(() => {
    document.title = "age element ID test™";
    fetchQuestionnaire(token, language);
    CalculateAge();
  }, [fetchQuestionnaire, language, token, CalculateAge]);
  useEffect(() => {
    if (questionnaire.professionalMatrix) {
      setBoostersPoints([...questionnaire.professionalMatrix]);
      setHomePoints([...questionnaire.homeMatrix]);
      setRangeProducts([...questionnaire.rangeOfProducts]);
    }
  }, [
    questionnaire.professionalMatrix,
    questionnaire.homeMatrix,
    questionnaire.rangeOfProducts,
  ]);
  const backQuestion = () => {
    if (position > 0) setPosition(() => position - 1);
    if (position === 0) {
      resetQuestionnaireUtil();
      history.goBack();
    }
  };
  const nextQuestion = () => {
    if (questionnaire.questions[position].type.toLowerCase() === "ranking") {
      const rankingValues = [];
      questionnaire.questions[position].choices.forEach((choice) =>
        rankingValues.push(choice.value)
      );
      if (
        new Set(rankingValues).size !==
        questionnaire.questions[position].choices.length
      ) {
        return setAnswerError(true);
      } else {
        if (
          rankingValues.indexOf(0) !== -1 ||
          rankingValues.indexOf(undefined) !== -1
        ) {
          return setAnswerError(true);
        }
      }
    } else {
      let correct = false;
      // eslint-disable-next-line
      questionnaire.questions[position].choices.map((choice) => {
        if (choice.selected) correct = true;
      });
      if (!correct) return setAnswerError(true);
    }
    if (position < questionnaire.questions.length - 1) {
      setPosition(() => position + 1);
      setAnswerError(false);
    }
    if (position === questionnaire.questions.length - 1) {
      setPosition(() => position + 1);
      setAnswerError(false);
    }
  };
  const handleClick = (choice, question) => {
    question.choices.map((choice) => (choice.selected = false));
    choice.selected = true;
    nextQuestion();
  };
  const CalculateResults = (questionnaire) => {
    let newBoosterObj = [...boostersPoints];
    let newHomeObj = [...homePoints];
    let newRange = [...rangeProducts];
    // eslint-disable-next-line
    questionnaire.questions.map((question) => {
      if (question.type.toLowerCase() === "select") {
        // eslint-disable-next-line
        question.choices.map((choice) => {
          if (choice.selected) {
            choice.boosters.map(
              (booster, index) => (newBoosterObj[index].accumulator += booster)
            );
            choice.homeProducts.map(
              (product, index) => (newHomeObj[index].accumulator += product)
            );
            if (choice.range) {
              newRange[choice.range.indexOf(1)].selected = true;
            }
          }
        });
      } else if (question.type.toLowerCase() === "ranking") {
        let orderedChoices = question.choices.sort(choicesSortRanking);
        let firstChoice = orderedChoices.filter(
          (choice) => +choice.value === 1
        );
        // eslint-disable-next-line
        orderedChoices.map((choice, index) => {
          let indexDeBooster = choice.boosters.indexOf(1);
          newBoosterObj[indexDeBooster].accumulator +=
            question.points_matriz_professional[index];
        });
        // eslint-disable-next-line
        newHomeObj.map((homeProduct, indice) => {
          homeProduct.accumulator += firstChoice[0].homeProducts[indice];
        });
      }
    });
  };
  const handleRankingChoice = (choice) => {
    choice.value = choiceOrder;
    if (choiceOrder >= 4) return setChoiceOrder(1);
    setChoiceOrder(choiceOrder + 1);
  };
  const handleSubmit = () => {
    CalculateResults(questionnaire);
    questionnaire.professionalMatrix = [...boostersPoints];
    questionnaire.homeMatrix = [...homePoints];
    patient.test = questionnaire;
    sendCompleteQuestionnaire(patient, token, history);
    resetQuestionnaireUtil();
    history.push(`/antiaging/treatment`);
  };

  return (
    <Wrapper>
      <TestBar width="50%" test />
      <QuestionnaireTitle>
        age element ID test™
        {questionnaire.questions &&
          questionnaire.questions[position]?.professional && (
            <ProfessionalIconWrapper>
              <ProfessionalIcon
                onMouseEnter={() => setIconProfesional(true)}
                onMouseLeave={() => setIconProfesional(false)}
                src={iconProfessional ? helpActive : help}
                alt="icon"
              />
              <ProfessionalAdvice active={iconProfessional}>
                {t("questionnaire.professionalAdvice")}
              </ProfessionalAdvice>
            </ProfessionalIconWrapper>
          )}
      </QuestionnaireTitle>
      <Counter>
        <span>{position + 1}</span>/
        {questionnaire.questions && questionnaire.questions.length + 1}
      </Counter>

      <QuestionnaireBarWrapper>
        <QuestionnaireBarBg />
        <QuestionnaireBar position={position + 1} />
      </QuestionnaireBarWrapper>
      <QuestionWrapper>
        {questionnaire.questions &&
          // eslint-disable-next-line
          questionnaire.questions.map((question, index) => {
            if (question.type.toLowerCase() === "select") {
              return (
                <RenderSelectQuestions
                  key={index}
                  question={question}
                  index={index}
                  position={position}
                  handleClick={handleClick}
                  answerError={answerError}
                  age={age}
                />
              );
            } else if (question.type.toLowerCase() === "ranking") {
              return (
                <RenderRankingQuestions
                  question={question}
                  key={index}
                  index={index}
                  position={position}
                  handleRankingChoice={handleRankingChoice}
                  answerError={answerError}
                />
              );
            }
          })}
        {questionnaire.questions &&
          position <= questionnaire.questions.length - 1 && (
            <PrevNextButtonsWrapper>
              <PrevNextButton prev={true} onClick={backQuestion}>
                {t("questionnaire.back")}
              </PrevNextButton>
              <PrevNextButton nextBtn={true} onClick={nextQuestion}>
                {t("questionnaire.next")}
              </PrevNextButton>
            </PrevNextButtonsWrapper>
          )}
        {questionnaire.questions &&
          position > questionnaire.questions.length - 1 && (
            <div>
              <FinishTest>{t("questionnaire.finishText")}</FinishTest>
              <FinishButton onClick={handleSubmit}>
                {t("questionnaire.send")}
              </FinishButton>
              <GoToFirst onClick={backQuestion}>
                {t("questionnaire.goBack")}
              </GoToFirst>
            </div>
          )}
      </QuestionWrapper>
    </Wrapper>
  );
};
const mapStateToProps = (state) => ({
  questionnaire: state.questionnaire,
  patient: state.patient,
  token: state.user.data.api_token,
  language: state.languages.selectedLanguage,
});
const mapDispatchToProps = (dispatch) => ({
  fetchQuestionnaire: (token, language) =>
    dispatch(getQuestionnaire(token, language)),
  sendCompleteQuestionnaire: (questionnaire, token, history) =>
    dispatch(sendQuestionnaire(questionnaire, token, history)),
  resetQuestionnaireUtil: () => dispatch(resetQuestionnaire()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
