import styled from "styled-components";
import bg from "./images/helpPage-bg.jpg";
import professionalIcon from "./images/professional-icon.svg";
import tooltip from "../Treatment/images/tooltip.svg";
export const HelpPageWrapper = styled.main `
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
`;
export const Title = styled.h2 `
  color: #000000;
  font-family: "hn-light", Arial;
  font-size: 25px;
  font-weight: 100;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  text-align: left;
  margin-bottom:30px;
`;
export const Paragraph = styled.p `
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 20px;
  text-align: left;
  strong {
    font-family: "hn-semibold", Arial;
  }
`;
export const BackButton = styled.p `
  font-family: "hn-semibold", Arial;
  position: absolute;
  left: 60px;
  top: 15px;
  @media (max-width: 600px) {
    left: 20px;
    top: 5px;
  }
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 10px 0;
  padding-left: 0;
  cursor: pointer;
  color: #989ea3;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 23.4px;
  text-align: left;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  &::before {
    content: "";
    width: 7px;
    height: 7px;
    border-left: 1px solid #737373;
    border-top: 1px solid #737373;
    transform: rotate(-48deg);
    margin-right: 5px;
    display: inline-block;
  }
`;
export const Content = styled.section `
  max-width: 665px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
`;
export const Line = styled.div `
  height: 1px;
  background-color: #e2e2e2;
  width: 90%;
  margin: 50px auto 40px;
`;
export const GrayWrapper = styled.div `
  border-radius: 2px;
  background-color: #f3f3f3;
  padding: 25px 35px;
  max-width: 615px;
  margin: 30px auto;
  h3 {
    color: #000000;
    font-family: "hn-semibold", Arial;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0.36px;
    line-height: 20px;
    text-align: left;
  }
  p {
    color: #000000;
    font-family: "hn-medium", Arial;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.39px;
    line-height: 17px;
    text-align: left;
    position: relative;
    &:nth-child(3) {
      max-width: 490px;
      @media (max-width: 500px) {
        max-width: 210px;
      }
    }
  }
`;
export const ProfessionalIcon = styled.span `
  width: 40px;
  height: 37px;
  background-image: url(${professionalIcon});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  position: absolute;
  right: -40px;
  top: -5px;
`;
export const BottomAlert = styled.p `
  color: #323e48;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 15px;
  text-align: left;
  background-image: url(${tooltip});
  background-repeat: no-repeat;
  background-position: left;
  padding: 3px 0 3px 40px;
`;
export const CTAtoPatientForm = styled.p `
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: 20px;
  text-align: center;
  margin: 20px auto;
  padding: 13px;
  cursor:pointer;
  width: 285px;
`;