import React from "react";
import loadingIcon from "./images/loading.svg";
import { LoadingResource } from "./styles";

const Loading = () => {
  return (
    <LoadingResource>
      <img src={loadingIcon} alt="Loading Resource" />
    </LoadingResource>
  );
};

export default Loading;
