import styled, { css } from "styled-components";
import img from "./images/register-background.jpg";
import locationicon from "./images/location.svg";
export const RegisteredInfo = styled.div`
  display: ${({ isRegistered }) => (isRegistered ? "none" : "block")};
  background-color: #DAEEE2; 
  padding: 10px 20px;
  margin: 10px 0;
  span {
    color: #000;
    font-family: hn-light, Arial;
    font-size: 12px;
  }

`;
export const Wrapper = styled.div`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 65px);
  @media (max-width: 500px) {
    padding: 30px 10px 50px;
  }
`;
export const FormWrapper = styled.section`
  background-color: #fff;
  width: 100%;
  max-width: 465px;
  text-align: center;
  padding: 25px 60px;
  margin-top: 35px;
  @media (max-width: 500px) {
    padding: 20px 10px;
  }
`;
export const LocationWrapper = styled.div`
  position: relative;
  margin-bottom: 25px;
`;
export const ErrorPass = styled.p`
  background-color: #ffe7e7;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-style: normal;
  letter-spacing: 0.26px;
  line-height: 10px;
  text-align: center;
  margin: 10px auto;
  padding: 5px;
`;
export const LocationLabel = styled.label`
  position: absolute;
  top: 0px;
  left: 0px;
  color: #000000;
  font-family: "hn-medium", Arial;
  letter-spacing: 2px;
  font-weight: 300;
  top: -12px;
  font-size: 10px;
  &::placeholder {
    color: #979da2;
    font-family: hn-medium, Arial;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: 26px;
    text-align: left;
  }
`;
export const SuggestionsBox = styled.ul`
  margin: 0px;
  padding: 0px;
  max-height: 60%;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-image: initial;
  border-top: none;
  border-color: #ededed;
  list-style-type: none;
  text-align: left;
  overflow-y: auto;
  margin-top: -30px;
`;
export const SuggestionsItem = styled.li`
  padding: 7px 15px;
  cursor: pointer;
  border-bottom: 1px solid #ededed;
  color: #000000;
  font-family: hn-medium, Arial;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  text-align: left;
  &::last-child {
    border-bottom: 0;
  }
  ${({ location }) =>
    location
      ? css`
          background-image: url(${locationicon});
          background-position: 10px center;
          background-repeat: no-repeat;
          padding-left: 40px;
        `
      : ""}
`;

export const ErrorBox = styled.p`
  color: #e02b27;
  font-size: 12px;
  margin-top: 0;
  &::before {
    display: inline;
    content: "⚠ ";
  }
`;
export const Title = styled.h1`
  color: #000000;
  font-family: hn-light, Arial;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 0;
`;
export const Subtitle = styled.p`
  color: #000000;
  font-family: hn-semibold, Arial;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: 26px;
  margin-top: 5px;
  margin-bottom: 0px;
`;
export const RequiredLegend = styled.span`
  color: #000000;
  font-family: hn-medium, Arial;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  display: block;
  margin-top: 10px;
`;
export const PrivacyPolicyLabel = styled.label`
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.39px;
  line-height: 14px;
  text-align: left;
  margin: 0px 0px 20px;
  display: flex;
  align-items: flex-start;
  input {
    margin: 2px 10px 0px 0px;
    min-width: 14px;
  }
  .label {
    strong {
      font-family: hn-semibold, Arial;
      font-weight: 600;
    }
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  a {
    color: black;
  }
  strong {
    font-family: hn-semibold, Arial;
    font-weight: 600;
  }
  .label {
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
