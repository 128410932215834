import styled, { css } from "styled-components";
import img from "./images/login-background.jpg";
import qrIcon from "./images/qr-reader-icon.png";
export const Wrapper = styled.main`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
export const BlockWrapper = styled.div`
  @media (max-width: 500px) {
    padding: 10px;
  }
  .help-links {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #000000;
      font-family: hn-medium, Arial;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
      cursor: pointer;
      margin-right: 20px;
      text-decoration: underline;
    }
  }
`;
export const FormWrapper = styled.section`
  margin: 0 auto;
  display: inline-block;
  max-width: 465px;
  background-color: #fff;
  height: fit-content;
  padding: 30px 15%;
  text-align: center;
  width: 400px;
  @media (max-width: 500px) {
    padding: 30px 10px;
  }
`;
export const ErrorPass = styled.p`
  background-color: #ffe7e7;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-style: normal;
  letter-spacing: 0.26px;
  line-height: 10px;
  text-align: center;
  margin: 10px auto;
  padding: 5px;
`;

export const SuccessMessage = styled.p`
  background-color: #DAEEE2;
  color: #000000;
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-style: normal;
  letter-spacing: 0.26px;
  line-height: 10px;
  text-align: center;
  margin: 10px auto;
  padding: 5px;
`;