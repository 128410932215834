import React from 'react'
import { NotificationWrapper, Button, Overlay } from './style';
import { useTranslation } from "react-i18next";

export const Notification = ({ notification, closeNotification }) => {

    const [t] = useTranslation("global");
    const $urlServer = `${process.env.REACT_APP_ANTIAGING_API}`;
    const { content, image, title } = notification;

    return (
        <>
            <Overlay className='leo' onClick={closeNotification}></Overlay>

            <NotificationWrapper>
                <img src={$urlServer + image} alt={title} />
                <h2>{title}</h2>
                <p>{content}</p>
                <Button onClick={closeNotification}>{t("home.closePopup")}</Button>
            </NotificationWrapper>
        </>

    )
}
