import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { dateFormatter, generateArrayFrom0toNMinus1 } from "./utils";
import { saveAppointmentUtil, updateAppointmentUtil } from "redux/utils/bodyshock/Appointments";
import useLocalStorage from "utils/transverse/useLocalStorage";
import localstoragekeys from "constants/localstoragekeys";
import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { SessionSetupWrapper, Button } from "./style.js";
import { ComponentCheck } from "components/EditHomeProtocol/styles";

const Appointment = ({
  user,
  session,
  protocolId,
  closeAppointments,
  saveAppointment,
  updateAppointment,
  patient,
  treatmentZone,
}) => {
  const [selectedLang] = useLocalStorage(localstoragekeys.appLanguage);
  const SCREEN_HEIGHT = window.innerHeight - 30;
  const handlePos = useSpring({ y: 0 });
  const hoursOptions = generateArrayFrom0toNMinus1(24);
  const minutesOptions = generateArrayFrom0toNMinus1(60);
  const [sessionDate, setSessionDate] = useState(new Date());
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [t_global] = useTranslation("global");
  const createEmail = () => {
    const textForTreatmentZone = `${
      treatmentZone.length > 1 ? `${treatmentZone[0]},${" "} ${treatmentZone[1]}` : treatmentZone[0]
    }`;
    const emailOptions = {
      patientName: patient.name,
      appointmentDate: dateFormatter(sessionDate),
      appointmentTime: `${hours}:${minutes}`,
      clinicName: user.first_name,
      product0: "ha deEEEnsimaXXX", //Hay que ver que productos se agregan
      product1: "LLLuralifirm XX",
      treatmentZone: textForTreatmentZone,
    };
    let emailContent = t_global("appointment.bodyshockEmailContent", emailOptions);
    setEmailContent(emailContent);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const sessionData = {
      protocolId,
      sessionNumber: session.session,
    };
    for (var element of e.target) {
      sessionData[element.name] = element.value;
    }
    if(session.appointment_date ) 
    { 
      sessionData.id = session.appointment_id

      updateAppointment(patient.id, user.api_token, sessionData, selectedLang)
    } else{
      saveAppointment(patient.id, user.api_token, sessionData, selectedLang)
    }
    return setTimeout(() => {
      setLoading(false);
      closeAppointments();
    }, 2500);
  };
  const bindHandlePos = useDrag((params) => {
    const movement = params.movement[1];
    if (params.dragging) {
      if (movement >= 0 && movement <= SCREEN_HEIGHT) {
        handlePos.y.set(movement);
      }
    } else {
      if (movement > 180) {
        closeAppointments();
      } else {
        handlePos.y.start(0);
      }
    }
  });
  return (
    <SessionSetupWrapper>
      <animated.section className="sessionSetup" style={{ y: handlePos.y, touchAction: "pan-y" }}>
        <div className="App-handle-container" {...bindHandlePos()} style={{ touchAction: "none" }}>
          <div className="App-handle" />
        </div>
        <div className="header">
          <div style={{maxWidth:"540px", margin: "auto", position: "relative"}}>
            <h3>{`${t_global("appointment.title")} ${session.session}`}</h3>
            <span className="close" onClick={() => closeAppointments()}></span>
          </div>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="calendar">
            <label htmlFor="sessionDate">{t_global("appointment.dateLabel")}</label>
            <DatePicker
              name="sessionDate"
              dateFormat="dd/MM/yyyy"
              locale={selectedLang ? selectedLang : "en"}
              onChange={(date) => setSessionDate(date)}
              selected={sessionDate}
            />
          </div>
          <div className="hourwrapper">
            <p>{t_global("appointment.timeLabel")}</p>
            <select name="sessionHour" onChange={(e) => setHours(e.target.value)}>
              {hoursOptions &&
                hoursOptions.map((hour, index) => (
                  <option key={index} value={hour > 9 ? hour : `0${hour}`}>
                    {hour > 9 ? hour : `0${hour}`}
                  </option>
                ))}
            </select>
            <span className="separator">:</span>
            <select name="sessionMinutes" onChange={(e) => setMinutes(e.target.value)}>
              {minutesOptions &&
                minutesOptions.map((min, index) => (
                  <option key={index} value={min > 9 ? min : `0${min}`}>
                    {min > 9 ? min : `0${min}`}
                  </option>
                ))}
            </select>
          </div>
          <div className="emailwrapper">
            <div>
              <input
                type="checkbox"
                name="email"
                id="appointment-email"
                value={check}
                onChange={() => {
                  if (!check) {
                    createEmail();
                  }
                  setCheck(!check);
                }}
              />
              <label htmlFor="appointment-email">{t_global("appointment.inputLabel")}</label>
            </div>
            {check && (
              <textarea name="emailContent" cols="24" rows="16" defaultValue={emailContent} />
            )}
          </div>
          {loading ? (
            <ComponentCheck size="40px" color="black" />
          ) : (
            <Button>
              {check ? t_global("appointment.saveSend") : t_global("appointment.send")}
            </Button>
          )}
        </form>
      </animated.section>
    </SessionSetupWrapper>
  );
};
const mapDispatchToProps = (dispatch) => ({
  saveAppointment: (patientId, token, sessionData, selectedLang) =>
    dispatch(saveAppointmentUtil(patientId, token, sessionData, selectedLang)),
    updateAppointment: (patientId, token, sessionData, selectedLang) =>
    dispatch(updateAppointmentUtil(patientId, token, sessionData, selectedLang)),
});
export default connect(null, mapDispatchToProps)(Appointment);
