import styled from "styled-components";


export const RedBarWrapper = styled.div`
    max-width: 600px;
    margin: auto;
    padding: 20px 0 37px 0;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    @media(max-width: 600px) {
        grid-template-columns: repeat(4,33%);
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        
        .patientdata.active ~ .physicalAnalysis {
            display: none;
        }
        .history.active ~ .physicalAnalysis {
            display: none;
        }
        &.activeLife {
            .patientdata.passed {
                display: none;
            }
        }
        &.passedLife {
            .patientdata.passed {
                display: block;
            }
        }
        &.activeHistory {
            .patientdata.passed {
                display: flex;
            }
        }
        &.activephysicalAnalysis {
            .patientdata.passed {
                display: none;
            }
        }
        &.passedphysicalAnalysis {
            .patientdata.passed {
                display: none;
            }
        }
    }
`;
export const RedBarItem = styled.div`
    position: relative;
    letter-spacing: 0.5px;
    font-size: 10px;
    color: #ffffff;
    font-family: "hn-bold", Arial;
    font-weight: 700;
    text-align: center;
    line-height: 10px;
    display: flex; 
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-right: -5px;
    @media(max-width: 520px) {
        margin-right: -3px;
    }
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        clip-path: polygon(0 0,92% 0,100% 50%,92% 100%,0 100%,8% 50%);
        background-color: #bd3d4b;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    span {
        font-family: "hn-bold",Arial;
        font-weight: 700;
        padding-left: 12px;
        padding-right: 12px;
        letter-spacing: inherit;
    }
    &.active span {
        text-decoration: underline;
    }
    &.active, &.passed {
        ::before {
            background-color: #bd3d4b;
    
        }
    }
    &.history::before {
        background-color: #d17781;
    }
    &.history.active::before {
        background-color: #bd3d4b;
    }

    &.lifeHabits::before {
        background-color: #de9da5;
    }
    &.physicalAnalysis::before {
        background-color: #ebc4c9;
    }
`;

