import React, { useEffect, useState } from "react";
import { Overlay, MassageEditWrapper, Button } from "./style";
import { useTranslation } from "react-i18next";
import { ComponentCheck } from "components/EditHomeProtocol/styles";


export const MassageEdit = ({
    allProducts,
    closeEdit,
    massage,
    handleMassage
}) => {
    const [t] = useTranslation("global");
    const [productOptions, setProductOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const copyFromAllProducts = JSON.parse(JSON.stringify(allProducts));
        const settingProducts = copyFromAllProducts.map((product) => {
            massage.forEach((activeProduct) => {
                if (activeProduct.product_sku === product.product_sku) {
                    product.active = true;
                }
            });
            return product;
        });
        setProductOptions(settingProducts);
    }, [massage, allProducts, setProductOptions])
    
    const handleClick = (index) => {
        const copyFromProductOptions = JSON.parse(JSON.stringify(productOptions));
        copyFromProductOptions[index].active = !copyFromProductOptions[index].active;

        setProductOptions(copyFromProductOptions);
    };
    const onSubmit = () => {
        setLoading(true);
        massage = productOptions.filter(product => product.active);

        handleMassage(massage);
        return setTimeout(() => {
            setLoading(false);
            closeEdit();
        }, 2500);
    };

    return (
        <>
            <Overlay onClick={() => closeEdit()}></Overlay>
            <MassageEditWrapper>
                <div className="header">
                    <h3>{t("phase.massages")}</h3>
                    <p className="subtitle">{t("phase.editTreatmentZone")}</p>
                    <span className="close" onClick={() => closeEdit()}></span>
                </div>

                <div className="content">
                    <div className="content-products">
                        {productOptions
                            .map((product, index) => {
                                return (
                                    <div
                                        className={`product-content ${product.active ? "active" : ""}`}
                                        onClick={() => handleClick(index)}
                                        key={product.product_sku}
                                    >
                                        <span>{product.product_concern}</span>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                    {loading ? (
                        <ComponentCheck size="40px" color="black" />
                    ) : (
                        <Button
                            onClick={onSubmit}
                        >
                            {t("products.save")}
                        </Button>
                    )}
                </div>
            </MassageEditWrapper>
        </>
    );
};
