import React from 'react';
import { useHistory } from "react-router";
import routes from "constants/routes";
import { Trans, useTranslation } from 'react-i18next';
import {
  BackButton,
  BottomAlert,
  Content,
  CTAtoPatientForm,
  GrayWrapper,
  HelpPageWrapper,
  Line,
  Paragraph,
  ProfessionalIcon,
  Title,
} from './style';

export var HelpPage = function ({ handleClick, backButton }) {
  const [t] = useTranslation('global');
  const history = useHistory();

  document.title = 'What is bodyshock ID test™';
  return (
    <HelpPageWrapper>
      <BackButton onClick={() => history.goBack()}>{t('productsBodyshock.back')}</BackButton>
      <Content>
        <Title>
          <Trans t={t} i18nKey='helpPageBody.topTitle'>
          ¿qué es <strong>bodyshock ID test™</strong>?
          </Trans>
        </Title>
        <Paragraph>
          <Trans t={t} i18nKey="helpPageBody.firstParagraph">
            <strong>bodyshock diagnosis system™</strong> es la innovadora herramienta de diagnóstico de <strong>bodyshock</strong>
            , la línea
            profesional antiedad que combina la personalización y la tecnología
            estética más avanzada
          </Trans>
        </Paragraph>
        <Paragraph>
          <Trans t={t} i18nKey="helpPageBody.secondParagraph">
            Un exclusivo test analiza la predisposición a expresar los
            principales signos del envejecimiento y las necesidades específicas
            para cada cliente, definiendo su
            <strong> age element ID profile™</strong>
            : un tratamiento
            profesional y domiciliario personalizado para prevenir y corregir
            los signos del envejecimiento cutáneo y obtener los máximos
            resultados
          </Trans>
        </Paragraph>
        <Line />
        <Title>
          <Trans t={t} i18nKey="helpPageBody.bottomTitle">
            ¿cómo funciona <strong>bodyshock ID test™</strong>?
          </Trans>
        </Title>
        <Paragraph>
          <Trans t={t} i18nKey="helpPageBody.thirdParagraph">
            <strong>bodyshock ID test™</strong>
            {' '}
            consta de un set de preguntas
            relativas a las necesidades de la piel, los hábitos de vida y las
            condiciones que el cliente desea mejorar con el fin de detectar las
            variables epigenéticas que pueden afectar su proceso de
            envejecimiento
          </Trans>
        </Paragraph>
        <GrayWrapper>
          <h3>{t('helpPageBody.important')}</h3>
          <p>{t('helpPageBody.grayFirst')}</p>
          <p>
            {t('helpPageBody.graySecond')}
            {' '}
            <ProfessionalIcon />
          </p>
          <p>{t('helpPageBody.grayThird')}</p>
        </GrayWrapper>
        <Paragraph>
          <Trans t={t} i18nKey="helpPageBody.fourthParagraph">
            Como resultado de las respuestas obtenidas se genera el
            <strong> bodyshock ID profile™</strong>
            {' '}
            , que incluye el
            <strong> tratamiento profesional y domiciliario</strong>
            personalizado para obtener la máxima respuesta terapéutica para el
            paciente
          </Trans>
        </Paragraph>
        <BottomAlert>{t('helpPageBody.bottomAlert')}</BottomAlert>
        <CTAtoPatientForm onClick={() => history.push(`${routes.newpatient}`)} >
          {t('helpPageBody.CTAtoPatientForm')}
        </CTAtoPatientForm>
      </Content>
    </HelpPageWrapper>
  );
};
