import apps from "constants/apps";
import logoAntiaging from "./images/logo-antiaging.svg";
import logoBodyshock from "./images/logo-bodyshock.svg";
import logoPigmentControl from "./images/logo-pigment.svg";
import localstoragekeys from "constants/localstoragekeys";
import React from "react";
import {  useHistory } from "react-router";
import useLocalStorage from "utils/transverse/useLocalStorage";
import { useTranslation } from "react-i18next";
import {AppSelectorWrapper, Img} from "./style";

function AppSelector({ permission, customHandleClick }) {
  const [appSelected, setApp] = useLocalStorage(localstoragekeys.app);
  const [t] = useTranslation("global");
  const history = useHistory();
  const logos = {
    [apps.BODYSHOCK]: logoBodyshock,
    [apps.ANTIAGING]: logoAntiaging,
    [apps.PIGMENTCONTROL]: logoPigmentControl,
    default: logoAntiaging,
  };
  const handleClick = (selectedApp) => {
    const upperApp = selectedApp.toUpperCase();
    if (apps[upperApp]) setApp(apps[upperApp]);
    if(customHandleClick) customHandleClick(apps[upperApp]);
    history.push(`/${apps[upperApp]}`);
  };
  return (
    <AppSelectorWrapper>

      <ul>
        {permission.map((app) => (
          <li className={appSelected === app ? "selected": ""} key={app} onClick={() => handleClick(app)}>
            <Img src={logos[app]} />
            <span>{t(`appSelector.${app}`)} solutions</span>
            
          </li>
        ))}
      </ul>
    </AppSelectorWrapper>
  );
}
export default AppSelector;
