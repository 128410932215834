import styled from 'styled-components';
import { Form as personForm } from 'components/Form';
import leftbgbs from './images/left-bg-bs.png';
import rightbgbs from './images/right-bg-bs.png';
import leftbgaa from './images/left-bg-aa.png';
import rightbgaa from './images/right-bg-aa.png';
import checkbox from './images/checkbox.svg';
import checkboxActive from './images/checkbox-active.svg';
/*
  ESTILOS PARA EL FORMULARIO DE REGISTRO DE PACIENTE
*/
export const PatientDataWrapper = styled.section`
  width: 100%;
  padding-bottom: 50px;
  position: relative;
  &.wrapper-bodyshock {
    &:before {
      content: url(${leftbgbs});
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
      @media (max-width: 560px) {
        content: none;
      }
    }
    &:after {
      content: url(${rightbgbs});
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: -2;
      @media (max-width: 768px) {
        content: none;
      }
    }
  }
  &.wrapper-antiaging {

    &:before {
      content: url(${leftbgaa});
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
      @media (max-width: 560px) {
        content: none;
      }
    }
    &:after {
      content: url(${rightbgaa});
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: -2;
      @media (max-width: 768px) {
        content: none;
      }
    }
  }
  iframe {
    position: fixed;
    width: 90%;
    height: 90%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    border: none;
  }
  .overlay-firma {
    width: 100vw;
    height: 100vh;
    position: fixed;
    opacity: 0.4;
    background: black;
    z-index: 1;
    top: 0;
    left: 0;
    cursor: pointer
  }

`;
export const LoadingResource = styled.div`
  width: 100vw;
  height: 90vh;
  img {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 45%;
  }
`;
export const PersonForm = styled(personForm)`
  display: block;
  background-color: transparent;
  text-align: left;
`;
export const PatientDataAdvice = styled.h2`
  color: #000000;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 23px;
  text-align: center;
  @media (max-width: 500px) {
    padding: 0 30px;
    font-size: 12px;
  }
`;
export const DatePickerWrapper = styled.div`
  position: relative;
  margin-bottom: 25px;
  input {
    border: 0;
    margin: 7px 0px;
    border-bottom: 1px solid #4a4a4a;
    background: transparent;
    width: 100%;
    padding: 6px 0;
    font-size: 14px;
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
      border-bottom: 1px solid #4a4a4a;
    }
  }
  .react-datepicker-wrapper {
    display: block;
  }
`;
export const PrivacyPolicyLabel = styled.label`
  font-family: "hn-medium", Arial;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.36px;
  line-height: 14px;
  margin: 0px 0 20px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;


  input {
    margin: 0;
    margin-right: 6px;
    min-width: 14px;
    display: none;
  }
  input:checked ~ span {
    background: transparent url(${checkboxActive}) no-repeat left 0px;
  }
  > span {
    background: transparent url(${checkbox}) no-repeat left 0px;
    padding-left: 20px;
    cursor: pointer;
    display: inline-block;
    padding-bottom: 5px;
    span{
      font-family: "hn-semibold";
      font-weight: 600;
    }
  } 
`;
export const Title = styled.h1`
  color: #000000;
  font-size: 25px;
  font-family: "hn-semibold", Arial;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 30px;
  text-align: center;
  margin-top: 0;
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;
export const ButtonSign = styled.button`
  background-color: #000000;
  padding: 7px 51px;
  color: #fff;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 26px;
  text-transform: lowercase;
  border: 1px solid #000;
  display: block;
  cursor: pointer;
  z-index: 5;
  width: 100%;
  &:disabled {
    border-color: grey;
    background-color: grey;
  }
  @media (min-width: 1100px) {
    &:hover {
      text-decoration: underline;
    }
  }

`;
