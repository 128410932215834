import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import parse from "html-react-parser";

import { useTranslation } from "react-i18next";
import { FAQContent, FAQWrapper, ProtocolsCTAs, BottomCTAsWrapper, BackButton } from "./styles";
import { useHistory } from "react-router";

const FaqPage = ({ selectedLang, token }) => {
  const history = useHistory();
  const [t] = useTranslation("global");
  const [content, setContent] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ANTIAGING_API}api/antiaging/pages`, {
        headers: {
          "Content-Language": selectedLang,
        },
        params: { identifier: "faq" },
      })
      .then(({ data }) => setContent(data.results))
      .catch(console.error);
  }, [token, selectedLang]);

  return (
    <FAQWrapper>
      <BackButton onClick={() => history.goBack()}>{t("testBar.back")}</BackButton>

      <FAQContent>
      {parse(content)}

      <BottomCTAsWrapper>
        <ProtocolsCTAs
          protocolId={true}
          onClick={() => history.push("/home")}
        >
          {t("treatment.home")}
        </ProtocolsCTAs>
      </BottomCTAsWrapper>
      </FAQContent>
    </FAQWrapper>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.data.api_token,
  selectedLang: state.languages.selectedLanguage,
});

export default connect(mapStateToProps, null)(FaqPage);
