import React, { useState } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import ProfessionalTreatment from "components/bodyshock/ProfessionalTreatment";
import HomeTreatment from "components/bodyshock/HomeTreatment";
import NavigationBar from "components/bodyshock/NavigationBar";
import PatientData from "components/bodyshock/PatientData";
import EditPatient from "components/EditPatient";
import ErrorsHandler from "components/transverse/ErrorsHandler";
import { ProtocolWrapper, Header } from "./style";
import "swiper/swiper-bundle.css";
import Loading from "components/bodyshock/Loading";
import {
  getPatientProtocolUtil,
  savePatientProtocolUtil,
  updatePatientUtil,
} from "redux/utils/bodyshock/Patients";

const Treatment = function ({
  protocols,
  patient,
  application,
  errors,
  token,
  getPatientProtocol,
  selectedLang,
  updatePatient,
  protocolId,
  saveProtocol,
}) {
  const [edit, setEdit] = useState(false);
  const { url } = useRouteMatch();
  const [t_global] = useTranslation("global");
  const handleEditSubmit = (modPatientData) => {
    updatePatient(token, modPatientData, patient.id, selectedLang);
    setEdit(false);
    getPatientProtocol(patient.id, token, selectedLang);
  };
  const handleEditClose = () => {
    setEdit(false);
  };
  const onSaveProtocol = () => {
    saveProtocol(patient.id, token, { ...protocols, id: protocolId }, selectedLang);
  };
  let path = window.location.href;
  if (protocols && patient) {
    return (
      <ProtocolWrapper>
        <Header>
          <h1>bodyshock ID profile™</h1>
          {path.includes("professional") ? (
            <p className="subtitle">{t_global("treatment.subtitleProfessional")}</p>
          ) : (
            <p className="subtitle">{t_global("treatment.subtitleHome")}</p>
          )}
        </Header>
        <PatientData application={application} patient={patient} handleEdit={setEdit} edit={edit} />
        {edit && (
          <>
            <div className="overlay-edit" onClick={() => handleEditClose()}></div>
            <EditPatient
              patient={patient}
              onSubmit={handleEditSubmit}
              close={() => handleEditClose()}
            />
          </>
        )}
        <Switch>
          <Route path={`${url}/professional`}>
            <ProfessionalTreatment
              treatmentZone={protocols.treatment_zones}
              sessions={protocols.professional}
              errors={errors}
              patient={patient}
              protocolId={protocolId}
              onSaveProtocol={onSaveProtocol}
            />
          </Route>
          <Route path={`${url}/home`}>
            <HomeTreatment
              products={protocols.home}
              errors={errors}
              patient={patient}
              protocolId={protocolId}
              onSaveProtocol={onSaveProtocol}
            />
          </Route>
        </Switch>
        <NavigationBar />
      </ProtocolWrapper>
    );
  }
  return errors ? <ErrorsHandler errors={errors} /> : <Loading />;
};

const mapStateToProps = (state) => ({
  protocols: state.protocols.protocol,
  patient: state.protocols.patient,
  application: state.application,
  errors: state.protocols.error,
  token: state.user.data.api_token,
  protocolId: state.protocols.protocol_id,
});
const mapDispatchToProps = (dispatch) => ({
  getPatientProtocol: (id, token, selectedLang) =>
    dispatch(getPatientProtocolUtil(id, token, selectedLang)),
  updatePatient: (token, patient, patientId, selectedLang) =>
    dispatch(updatePatientUtil(token, patient, patientId, selectedLang)),
  saveProtocol: (patientId, token, protocol, selectedLang) =>
    dispatch(savePatientProtocolUtil(patientId, token, protocol, selectedLang)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Treatment);
