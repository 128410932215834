import styled from "styled-components";
export const DefaultForm = styled.form`
  margin: 0 auto;
  display: inline-block;
  max-width: 350px;
  background-color: #fff;
  width: 100%;
  height: fit-content;
  padding: 25px 30px 0px 30px;
  text-align: center;
`;
