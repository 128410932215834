import {
  ERRORPROTOCOL,
  GETPROTOCOLS,
  RESETPROTOCOL,
} from "../constants/Protocols";
export const getProtocolsAction = (protocol) => ({
  type: GETPROTOCOLS,
  payload: protocol,
});
export const errorProtocol = (error) => ({
  type: ERRORPROTOCOL,
  payload: error,
});
export const resetProtocol = () => ({
  type: RESETPROTOCOL,
});