import faker from "@faker-js/faker";
import { dateFormatter } from "components/bodyshock/Appointment/utils";
const completedPatient = {
  address: faker.address.streetAddress(),
  considerations: "",
  date_of_birth: dateFormatter (faker.date.between("05-08-1932", "05-08-2002")),
  email: faker.internet.email(),
  last_name: faker.name.lastName(),
  name: faker.name.firstName(),
  phone_number: faker.phone.phoneNumber("###-###-###"),
};
export { completedPatient };
