import styled from 'styled-components';
import closeIcon from './images/ico-close.svg';
import input from './images/input.svg';
import inputCheck from './images/input-check.svg';
import icondropdown from "./images/icondropdown.svg";

export const SessionSetupWrapper = styled.div`
 .sessionSetup {
   top: 15%;
   bottom:0;
   width: 100%;
   background-color: white;
   overflow-y: scroll;
   border-radius: 10px 10px 0 0;
   box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.15);
   overflow-x: hidden;
   position: fixed;
   z-index: 3; 
 }
 .App-handle-container {
   width: 100%;
   height: 30px;
   top: 10px;
   position: absolute;
   z-index: 1;
   cursor: pointer;

 }
 .App-handle {
   margin: 0 auto;

   width: 36px;
   height: 5px;
   border-radius: 3px;
   background-color: #000000;
   opacity: 0.2;
   display: block;
   top: 6px;
 }

.header {
   padding: 30px 20px 20px;
   background-color: white;
   border-bottom: 1px solid #e2e2e2;
}
form {
   max-width: 375px;
   margin: 28px auto;
   padding-left: 45px;
   padding-right: 45px;
   .calendar {
      label {
         font-size: 10px;;
         letter-spacing: 1px;
         color: black;
         line-height: 17px;
      }
   }
   .hourwrapper {
      margin-top: 28px;
      p {
         font-size: 10px;;
         letter-spacing: 1px;
         color: black;
         line-height: 17px;
      }
      select {
         appearance: none;
         border: none;
         border-bottom: 1px solid #4a4a4a;
         width: 36px;
         background: url(${icondropdown}) transparent no-repeat right center;
         margin-bottom: 10px;
         line-height: 26px;
         font-size: 14px;
      }
      .separator {
         margin: 0 8px;
      }
   }
   .emailwrapper {
      margin-top: 20px;
      margin-bottom: 20px;
      input {
         display: none;
         & ~ label {
            background: url(${input}) no-repeat left center;
         }
         &:checked ~ label {
            background: url(${inputCheck}) no-repeat left center;

         }
      }
      label {
         font-size: 10px;;
         letter-spacing: 0.36px;
         color: black;
         line-height: 17px;
         padding: 10px 0 10px 21px;

      }
      textarea {
         border: 1px solid #000;
         margin-top: 10px;
         padding: 12px;
         font-family: "hn-medium", Arial;
         letter-spacing: 0.36px;
         line-height: 14px;
         font-size: 10px;
         color: #000000;
         width: 100%;
         &:focus {
           border-radius: 0;
           border-color: black;
         }
         &:focus-visile {
            outline: none;
         }
       }


   }
   .react-datepicker-wrapper {
      display: block;
      input {
         border: none;
         border-bottom: 1px solid black;
         font-size: 14px;
         line-height: 26px;
         color: black;
         width: 100%;
         &:focus {
            outline: none;
         }
      }
   }
}
 h3 {
    margin: 0;
    text-align: center;
    font-size: 18px;
    font-family: hn-semibold, Arial;
    font-weight: 600;
    line-height: 26px;
 }
 .close {
    background: url(${closeIcon}) no-repeat center;
    display: inline-block;
    width: 28px;
    height: 28px;
    position: absolute;
    top: -15px;
    left: 0px;
    cursor: pointer;
    z-index: 3;
  }
`;
export const Button = styled.button`
  width: 100%;
  border: 1px solid #000000;
  background-color: #000000;
  color: #ffffff;
  font-family: "hn-semibold", Arial;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.34px;
  line-height: 20px;
  text-align: center;
  padding: 12px 20px;
  cursor: pointer;
  @media (min-width: 1100px) {
      &:hover {
         text-decoration: underline;
      }
   }
`;