import React, { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { QtySwitcher } from "../QtySwitcher";
import crystalfiber from "./images/crystalfiber.svg";
import firmingmask from "./images/instantfirming.svg";
import {
  MaskUnit,
  MasksWrapper,
  EditProfessionalProtocolWrapper,
  Booster,
  BoosterBox,
  BoosterQty,
} from "./styles";
import antiwrinkle from "./images/antiwrinkle.svg";
import redensifying from "./images/redensyfing.svg";
import firming from "./images/firming.svg";
import brightening from "./images/brightening.svg";
import { CloseButton, ComponentCheck } from "../EditHomeProtocol/styles";
const boostersIcons = {
  antiwrinkle: antiwrinkle,
  redensifying: redensifying,
  firming: firming,
  brightening: brightening,
};
const boosterColors = {
  BRIGHTENING: "#ae9b89",
  ANTIWRINKLE: "#b4918f",
  FIRMING: "#a6a9aa",
  REDENSIFYING: "#8C7A4F",
};
const maskIcons = {
  crystalfiber,
  instantfirming: firmingmask,
};

export default ({
  session,
  boosters,
  mask,
  onSaveProtocol,
  close,
  protocol,
  refProp,
}) => {
  const [t] = useTranslation("global");
  const [pulsations, setPulsations] = useState(false);
  const [activeBoosters, setActiveBoosters] = useState([]);
  const [currentMask, setCurrentMask] = useState();
  const [error, setError] = useState(false);
  const [qtyError, setQtyError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [width, setWidth] = useState(252);
  const boosterWidth = useRef(null);

  useEffect(() => {
    setActiveBoosters([...session.boosters]);
    setCurrentMask([...session.mask]);
  }, [session]);
  useEffect(() => {
    if (boosterWidth.current) {
      setWidth(boosterWidth.current.clientWidth);
    }
  }, [boosterWidth]);
  const getActiveClass = (boosterName) => {
    if (
      activeBoosters.filter(
        (boost) => boost.boost_name.toLowerCase() === boosterName.toLowerCase()
      ).length
    )
      return "active";

    return "";
  };
  const handleClick = (boosterName) => {
    let included = activeBoosters.some(
      (boost) => boost.boost_name.toLowerCase() === boosterName.toLowerCase()
    );
    if (included) {
      const newBoosters = activeBoosters.filter(
        (boost) => boost.boost_name.toLowerCase() !== boosterName.toLowerCase()
      );
      return setActiveBoosters([...newBoosters]);
    }
    if (!included && activeBoosters.length <= 2)
      return setActiveBoosters([
        ...activeBoosters,
        {
          product_qty: "0",
          product_units: "ml",
          boost_name: boosterName.toUpperCase(),
        },
      ]);
    showError();
  };
  const handleChange = (evt, index) => {
    const value = pulsations ? (evt.target.value - 5) / 10 : evt.target.value;
    const booster = { ...activeBoosters[index] };
    if (calculateQtys(booster.product_qty, value)) {
      booster.product_qty = calculateQtys(booster.product_qty, value)
        ? value
        : booster.product_qty;
      const newBoostersArray = [...activeBoosters];
      newBoostersArray[index] = { ...booster };
      setActiveBoosters([...newBoostersArray]);
    }
    return false;
  };
  const calculateQtys = (originalValue, value) => {
    const maxValue = 3;
    const accumulatedObj = activeBoosters.reduce((acc, currentBoost) => ({
      product_qty: Number(acc.product_qty) + Number(currentBoost.product_qty),
    }));
    const accumulated = +accumulatedObj.product_qty - +originalValue;
    if (+accumulated + +value <= +maxValue) {
      return true;
    }
    return false;
  };
  const changeMask = (maskName) => {
    const newMask = [...currentMask];
    newMask[0].mask = maskName;
    setCurrentMask([...newMask]);
  };
  const updateProtocol = () => {
    if (boosters) {
      let totalQty = 0;
      // eslint-disable-next-line
      const cleanBoosters = activeBoosters.filter((boost) => {
        if (boost.product_qty > 0) return boost;
      });
      cleanBoosters.map((boost) => (totalQty = +totalQty + +boost.product_qty));
      if (totalQty === 3) {
        protocol[session.session - 1].boosters = [...cleanBoosters];
        onSaveProtocol();
        setSuccess(true);
        return setTimeout(() => {
          setSuccess(false);
          close();
        }, 2000);
      }
      return showError(true);
    }
    protocol[session.session - 1].mask = [...currentMask];
    onSaveProtocol();
    setSuccess(true);
    return setTimeout(() => {
      setSuccess(false);
      close();
    }, 2000);
  };
  const showError = (qty) => {
    if (!qty) {
      setError(true);
      setTimeout(() => setError(false), 3500);
      return;
    }
    setQtyError(true);
    setTimeout(() => setQtyError(false), 3500);
  };
  return (
    <EditProfessionalProtocolWrapper
      active={session}
      professional={boosters}
      ref={refProp}
    >
      <div className="inner">
        <CloseButton onClick={close} style={{ top: "-25px" }} />
        <div className="header">
          <h3>
            {t("editProfessional.editSession")} {session.session}
          </h3>
          {boosters && (
            <Fragment>
              <QtySwitcher
                pulsations={pulsations}
                setPulsations={setPulsations}
                t={t}
              />
              <p className="advice">
                {t("editProfessional.advice1")}
                <span>
                  {pulsations
                    ? t("editProfessional.advice2pumps")
                    : t("editProfessional.advice2")}
                </span>
              </p>
              {error && (
                <p className="error">{t("editProfessional.recommended")}</p>
              )}
              {qtyError && (
                <p className="error">
                  {" "}
                  {pulsations
                    ? t("editProfessional.advice2pumps")
                    : t("editProfessional.recommendedQty")}
                </p>
              )}
              <div className="boosters-boxes">
                <div
                  className={`brightening ${getActiveClass("brightening")}`}
                  onClick={() => handleClick("Brightening")}
                >
                  <img src={boostersIcons.brightening} alt="Brightening icon" />
                  <p style={{ color: boosterColors.BRIGHTENING }}>
                    Brightening
                  </p>
                </div>
                <div
                  className={`firming ${getActiveClass("firming")}`}
                  onClick={() => handleClick("Firming")}
                >
                  <img src={boostersIcons.firming} alt="Firming icon" />
                  <p style={{ color: boosterColors.FIRMING }}>Firming</p>
                </div>
                <div
                  className={`antiwrinkle ${getActiveClass("anti-wrinkle")}`}
                  onClick={() => handleClick("Anti-wrinkle")}
                >
                  <img
                    src={boostersIcons.antiwrinkle}
                    alt="Anti-wrinkle icon"
                  />
                  <p style={{ color: boosterColors.ANTIWRINKLE }}>
                    Anti-wrinkle
                  </p>
                </div>
                <div
                  className={`redensifying ${getActiveClass("Redensifying")}`}
                  onClick={() => handleClick("Redensifying")}
                >
                  <img
                    src={boostersIcons.redensifying}
                    alt="Redensifying icon"
                  />
                  <p style={{ color: boosterColors.REDENSIFYING }}>
                    Redensifying
                  </p>
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <div className="interline">
          {boosters
            ? t("treatment.activationPhase")
            : t("treatment.contributionPhase")}
        </div>
        <div className="content">
          {boosters &&
            activeBoosters.map((booster, boostIndex) => (
              <BoosterBox key={boostIndex}>
                <Booster
                  booster={booster.boost_name.toLowerCase()}
                  color={
                    boosterColors[
                      booster.boost_name.split("-").join("").toUpperCase()
                    ]
                  }
                  icon={booster.boost_name.split("-").join("").toUpperCase()}
                >
                  {booster.boost_name.toLowerCase()}
                </Booster>
                <div className="proporciones">
                  <BoosterQty
                    value={
                      (pulsations && Number(booster.product_qty) * 10 + 5) ||
                      Number(booster.product_qty)
                    }
                    bgcolor={
                      boosterColors[
                        booster.boost_name.split("-").join("").toUpperCase()
                      ]
                    }
                    type="range"
                    min="0"
                    max={pulsations ? "40" : "3"}
                    step={pulsations ? "5" : "0.5"}
                    ref={boosterWidth}
                    onChange={(e) => handleChange(e, boostIndex)}
                    proportions={width}
                  />
                  <div className="qty-box">
                    <input
                      type="text"
                      readOnly
                      step={pulsations ? "5" : "0.5"}
                      min={pulsations ? "5" : "0.5"}
                      max={pulsations ? "40" : "3"}
                      onChange={(e) => handleChange(e, boostIndex)}
                      value={
                        (pulsations &&
                          `${(
                            Number(booster.product_qty) * 10 +
                            5
                          ).toString()}`) ||
                        `${Number(booster.product_qty).toString()}ml`
                      }
                    />
                  </div>
                  <div
                    className="action"
                    onClick={() => handleClick(booster.boost_name)}
                  ></div>
                </div>
              </BoosterBox>
            ))}
          {mask && currentMask && (
            <MasksWrapper>
              <MaskUnit
                active={
                  currentMask[0].mask.split(" ").join("").toLowerCase() ===
                  "instantfirming"
                }
                onClick={() => changeMask("INSTANT FIRMING")}
              >
                <img src={maskIcons.instantfirming} alt="Mask icon" />
                <p>instant firming mask</p>
              </MaskUnit>
              <MaskUnit
                active={
                  currentMask[0].mask.split(" ").join("").toLowerCase() ===
                  "crystalfiber"
                }
                onClick={() => changeMask("CRYSTAL FIBER")}
              >
                <img src={maskIcons.crystalfiber} alt="Mask icon" />
                <p>crystal fiber mask</p>
              </MaskUnit>
            </MasksWrapper>
          )}
        </div>
        <div className="actions">
          {success && <ComponentCheck size="40px" color="black" />}
          {!success && (
            <button onClick={updateProtocol}>
              {t("editProfessional.save")}
            </button>
          )}
        </div>
      </div>
    </EditProfessionalProtocolWrapper>
  );
};
