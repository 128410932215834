import { GETPRODUCTS } from "redux/constants/Product";

export default (state = {}, action) => {
  switch (action.type) {
    case GETPRODUCTS:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};
