import React from "react";
import Button from "components/Button";
import StringInput from "components/Input";
import { Form as RegisterForm } from "components/Form";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { useForm } from "react-hook-form";
import propTypes from "prop-types";
import { QrIcon } from "./style";
const Codeform = ({
  setWantQR,
  wantQR,
  checkCode,
  codeActive,
  code,
  setCode,
  handleScan,
  errorCode,
  validatedCode,
}) => {
  const [t] = useTranslation("global");
  const { handleSubmit, register, errors } = useForm();
  document.title = "Sign in mesoestetic diagnosis solutions™";
  return (
    <RegisterForm onSubmit={handleSubmit(checkCode)}>
      <StringInput
        name="code"
        active={codeActive}
        type="text"
        defaultValue={code}
        ref={register({ required: true })}
        error={errors.code}
        label={t("register.inputCode") + "*"}
        onChange={setCode}
      >
        <QrIcon onClick={() => setWantQR(!wantQR)} />
      </StringInput>
      {wantQR && (
        <div>
          <QrReader delay={1000} onScan={handleScan} />
          <p>{!code && "Keep trying"}</p>
        </div>
      )}
      {errorCode && <p>{t(`register.${errorCode}`)}</p>}
      {validatedCode === false && t("register.invalidCode")}
      <Button style={{ margin: "auto" }} type="submit">
        {t("register.validate")}
      </Button>
    </RegisterForm>
  );
};
Codeform.propTypes = {
  formErrors: propTypes.object,
  validateCode: propTypes.func,
  validatedCode: propTypes.string,
  userErrors: propTypes.string,
};

export default Codeform;
